import React, {Component} from 'react';
import Page from './page';
import Dialogo from '../../components/formulario/dialogo';
import Grid from '@material-ui/core/Grid';

//Datos en el props
// props= { TituloL :Ttiulos de lado izquierdo ,Libres: lado izquierdo, TituloG: titulo lado derecho, Grupo: lado derecho}
export default class Conexiones extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          titulol: this.props.TituloL ? this.props.TituloL : 'Listado Libre',
          titulog: this.props.TituloG ? this.props.TituloG : 'Listado Grupos',
          quitarl: this.props.Quitarl ? this.props.Quitarl : false,
          quitarg: this.props.Quitarg ? this.props.Quitarg : true,
          filtrar: this.props.FiltrarL,
          listaf:[],
          datos:{
            Libres:this.props.Libres ? this.props.Libres : [] , 
            Grupos:this.props.Grupos ? this.props.Grupos : []
          },
          Actualizar: this.Actualizar,
          Guardar: this.Guardar,
          Cancelar: this.Cancelar,
          Iniciar: this.Iniciar,
          Copiar: this.Copiar,
          boton_guardar:true,
          Filtrar: this.Filtrar,
          Listado : this.Listado,
          Open_dialogo: this.Open_dialogo,
          Dialogo:{
            open:false,
            Titulo:'Mi cuenta',
            tam:'xs',
            Cerrar: this.Cerrar_dialogo,
          }

      }
  }
  
  Open_dialogo = (valor) =>{
    console.log('abrir dialogo', valor)
    let {Dialogo}=this.state
    Dialogo.Titulo= valor.titulo
    Dialogo.tam='sm';
    Dialogo.Cuerpo = 
      <div >
        {valor.childen.map(hijo=>
          <Grid
            key={hijo.key} 
            item xs={12}  
          >
            {hijo.titulo}
          </Grid>  
        )}
      </div>
    
    if (this.state.props.Editar_dialogo){
      if (this.state.props.Editar_dialogo.editar) Dialogo.Editar= ()=>this.Editar_dialogo(valor)
      if (this.state.props.Editar_dialogo.copiar) 
        Dialogo.Copiar= ()=>{
          this.state.props.Editar_dialogo.copiar(valor)
          this.Cerrar_dialogo() 
        }
      if (this.state.props.Editar_dialogo.pegar) 
        Dialogo.Pegar= ()=>{
          this.state.props.Editar_dialogo.pegar(valor)
          this.Cerrar_dialogo() 
        }
      if (this.state.props.Editar_dialogo.eliminar) 
        Dialogo.Eliminar= ()=>{
          this.state.props.Editar_dialogo.eliminar(valor)
          this.Cerrar_dialogo() 
        }
    }else{
      Dialogo.Editar=null
    }
    Dialogo.open=true
    this.setState({Dialogo})
  }

  Editar_dialogo = (valor) =>{
    console.log(valor)
    let {Dialogo}=this.state
    Dialogo.Titulo=valor.editar_titulo;
    Dialogo.Editar=null
    Dialogo.Copiar=null
    Dialogo.Pegar=null
    Dialogo={...Dialogo, ...this.state.props.Editar_dialogo, Cuerpo:this.state.props.Editar_dialogo.Cuerpo(valor)}
    // Dialogo.open=false;
    this.setState({Dialogo})
    
  }
  Cerrar_dialogo = () =>{
    let {Dialogo}=this.state
    Dialogo.open=false
    this.setState({Dialogo})
  }

  Listado=(lis, filtro)=>{
    const lista = []
    lis.map(l=>{
      const pos = lista.findIndex(v=> v.title===l[filtro])
      if (pos===-1){
        lista.push({title:l[filtro]})
      }
      return l
    })
    lista.sort((a,b)=> a.title>b.title ? 1 : a.title<b.title ? -1 :0)
    return lista
  }

  Filtrar = (valor) =>{
    // let {datos, respaldo, filtrar}= this.state
    // if (valor===null){
    //   datos={...respaldo}
    // }else{
    //   const Libres =  respaldo.Libres.filter(f=> f[filtrar]===valor.title)
    //   datos={...respaldo, Libres}
    // }
    this.setState({filtro: valor})
  }
  Guardar = async(datos) =>{
    this.state.props.Guardar(datos)
    this.setState({boton_guardar:true})
  }
  Cancelar = () =>{
    this.state.props.Iniciar()
    this.setState({boton_guardar:false})
  }
  Iniciar = () =>{
    let {datos}=this.state

    datos.Grupos.map(g=>{
      g.childen.map(h=>{
        const pos = datos.Libres.findIndex(v=> v.key === h.key)
        if (pos===-1) datos.Libres.push(h)
        return h
      })
      g.childen=[]
      return g
    })
    this.setState({datos})
    this.Actualizar(datos)
  }
  Copiar = ()=>{
    if (this.state.props.Copiar) this.state.props.Copiar(this.state.datos)
    this.setState({boton_guardar:false})
  }
  Actualizar = (datos) =>{
    this.setState({boton_guardar:false})
    if (this.state.props.Actualizar)  this.state.props.Actualizar(datos)
    this.setState({datos})
  }
  componentDidMount(){
    let {titulol, titulog, quitarl, quitarg, filtrar, listaf}=this.state
    const {TituloL, TituloG, Quitarl, Quitarg, datos, FiltrarL, Guardar, Iniciar}=this.props
    if (Guardar){
      this.setState({Guardar:this.Guardar})
    }else{
      this.setState({Guardar:null})
    }
    if (Iniciar){
      this.setState({Cancelar:this.Cancelar})
    }else{
      this.setState({Cancelar:null})
    }
    titulol= TituloL ? TituloL : 'Libres'
    titulog= TituloG ? TituloG : 'Grupos'
    quitarl= Quitarl !==undefined ? Quitarl : true
    quitarg= Quitarg !==undefined ? Quitarg : true
    filtrar=FiltrarL
    if (filtrar){
      listaf =  this.Listado(datos.Libres, filtrar)
    }
    this.setState({
      props:this.props,
      titulol, titulog, quitarl, quitarg, filtrar, listaf,
      datos: datos!==undefined ? datos : this.state.datos,
      respaldo: datos!==undefined ? datos : this.state.datos,
      datosR: datos!==undefined ? JSON.parse(JSON.stringify(datos)) : JSON.parse(JSON.stringify(this.state.datos))
    })
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
      let {titulol, titulog, quitarl, quitarg, filtrar}=state
      const {TituloL, TituloG, Quitarl, Quitarg, datos,FiltrarL}=props
      titulol= TituloL ? TituloL : 'Libres'
      titulog= TituloG ? TituloG : 'Grupos'
      quitarl= Quitarl !==undefined ? Quitarl : true
      quitarg= Quitarg !==undefined ? Quitarg : true
      filtrar=FiltrarL
      let listaf = []
      if (filtrar){
        listaf =  state.Listado(datos.Libres, filtrar)
      }
      return {
        props,
        titulol, 
        titulog, 
        quitarl, 
        quitarg, 
        filtrar,
        listaf,
        datos: datos!==undefined ? datos : state.datos,
        respaldo: datos!==undefined ? datos : state.datos,
      };
    }
    // No state update necessary
    return null;
  }

  render(){
    return (
      <div>
        <Page  {...this.state}/>
        <Dialogo {...this.state.Dialogo}/>
      </div>
    )
  }
}