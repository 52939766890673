import React from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';

//Constantes
import {Valord} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

const useStyles = makeStyles((theme) => ({
  root: {
    height: window.screen.height * 0.7,
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  pantalla:{
    minHeight:window.screen.height * 0.5
  }
}));

const actions = [
  { icon: <AddCircleIcon  />, name: 'Agregar' },
  { icon: <SaveIcon />, name: 'Quitar' },
];

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiSpeedDial: {
      // Name of the rule
      fab: {
        // Some CSS
        ...config.Estilos.barra_menu
        
      },
    },
  },
});

export default function Mutiple_pantalla(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hidden] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Acciones = (index) =>{
    if (props.Cambio) props.Cambio(index)
  }
  const Quitar = (index) =>{
    if (props.Quitar) props.Quitar(index)
  }

  const Cuerpo =(valores, i)=>{
    let {titulo, subtitulo, cuerpo}=valores;
    return (
      <Card className={classes.pantalla}>
        <CardHeader
          action={ i!==0 ?
            <IconButton aria-label="salir" onClick={()=>Quitar(i)}>
              <CloseIcon />
            </IconButton> : null
          }
          title={titulo}
          subheader={subtitulo ? subtitulo : ''}
        />
          <CardContent>
            {cuerpo}
          </CardContent>
      </Card>
    )
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {props.pantalla.map((val,i) =>
          <Grid item xs={props.pantalla.length===1 ? 12 :6} key={'pantalla-'+i}>
            {Cuerpo(val,i)}
          </Grid>
        )}
        
      </Grid>
      <ThemeProvider theme={theme}>
        <SpeedDial
          ariaLabel="SpeedDial"
          className={classes.speedDial}
          hidden={hidden}
          icon={<SpeedDialIcon  openIcon={<EditIcon />} />}
          
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={()=>Acciones(action.name)}
            />
          ))}
        </SpeedDial>
      </ThemeProvider>
    </div>
  );
}
