import React, {Component} from 'react';
import Esperar from '../esperar';
import Pagee from '../conexiones';
import {ItemTituloSupervisorAsignar, ItemSupervisor} from '../conexiones/item';

//Asignar operadores a supervisores
export default class Supervisor extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{Libres:[],Grupos:[]},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          
          Iniciar: this.Cancelar,
          Actualizar:this.Actualizar
      }
  }

  Cancelar = async()=>{
     
    this.Iniciar(this.state.props.rdatos)
  }

  Iniciar= async(valores) =>{
    // this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    // let respuesta= await conexiones.Leer_C(['Data_personal'], 
    //   {'Data_personal':{$or:[ {categoria:3} , {categoria:4}]},}
    //   );
    // let personal=[]
    // if (respuesta.Respuesta==='Ok'){
    //     personal= respuesta.datos['Data_personal'].sort((a, b) => (a.categoria >b.categoria ? 1 : a.categoria < b.categoria ? -1 : 0))
    // }
    let datos={
      Grupos:[
        {key:'supervisores',
          titulo: <ItemTituloSupervisorAsignar sup={{titulo:'Supervisores', childen:[]}}/>,
          childen:[]
        },
        {key:'operadores', 
          titulo: <ItemTituloSupervisorAsignar sup={{titulo:'Operadores', childen:[]}}/>,
          childen:[]
        }
      ],
      Libres:[]
    }
    if (valores.asignados){
      datos.Grupos=valores.asignados.Grupos.map(g=>{
        let childen = g.childen.map(h=>{
          let categoria=g.key==='supervisores' ? 3 : 4
          return {...h, categoria, titulo:<ItemSupervisor op={{...h, turno:h.turno, categoria}}/>}
        })
        return {...g,childen, titulo: <ItemTituloSupervisorAsignar sup={{titulo:g.key==='supervisores' ? 'Supervisores' : 'Operadores', childen}}/> }
      })
      datos.Libres=valores.asignados.Libres.map(val=>{
        
        return {...val, key:val._id, titulo:<ItemSupervisor op={{...val}}/>}
      })
    }else{
      datos = {
        Grupos:[
          {key:'supervisores',
            titulo: <ItemTituloSupervisorAsignar sup={{titulo:'Supervisores', childen:[]}}/>,
            childen:[]
          },
          {key:'operadores', 
            titulo: <ItemTituloSupervisorAsignar sup={{titulo:'Operadores', childen:[]}}/>,
            childen:[]
          }
        ],
        Libres:[]
      }
      valores.personal.map(val=>{
        val={...val,categoria:null}
        if (val.turno!=='')
          datos.Libres.push({...val, key:val._id, titulo:<ItemSupervisor op={{...val}}/>})
          // if (val.categoria==='3'){
          //   val.operadores= val.operadores.filter(o=>  o.categoria==='4')
          //   datos.Grupos.push(
          //     {...val, key:val._id, 
          //       titulo:<Item_titulo_supervisor sup={val}/>, 
          //       childen: val.operadores.map(o=>{
          //         let pos = personal.findIndex(v=> v._id===o._id)
          //         console.log(pos, personal[pos].turno)      
          //         return {...o, key:o._id, titulo:<Item_supervisor op={{...o, turno:personal[pos].turno}}/>}
          //       })
          //     })
          // }else{
          //   let pos = this.Buscar_operador(datos.Grupos,val)
          //   if (pos===-1) datos.Libres.push({...val, key:val._id, titulo:<Item_supervisor op={val}/>})
          // }
        return val
      })
    }
    
    
    this.setState({datos, TituloL:'Personal', FiltrarL:'turno',  TituloG:'Asignacion de Personal', Esperar:{esperar:false}})
    this.Actualizar(datos, false)
  }
  Buscar_operador =(datos,operador)=>{
    let pos=-1
    datos.map(val=>{
      if (pos===-1)
        pos= val.operadores.findIndex((v)=> v._id===operador._id)
      return val
    })
    return pos
  }
  Actualizar = (datos, modificar=true) =>{
    datos.Libres.map(l=>{
      l={...l, categoria:null, titulo:<ItemSupervisor op={{...l, turno:l.turno, categoria:null}}/>}
      return l
    })
    let Grupos=[]
    datos.Grupos.map(g=>{
      let childen = g.childen.map(h=>{
        let categoria=g.key==='supervisores' ? 3 : 4
        return {...h, categoria, titulo:<ItemSupervisor op={{...h, turno:h.turno, categoria}}/>}
      })
  
      Grupos.push({...g,childen})
  
      return g
    })
    datos.Grupos=Grupos
    this.setState({datos})
    if (this.state.props.Actualizar){
      this.state.props.Actualizar({asignados:datos},modificar)
    }
  }
  async componentDidMount(){
    this.Iniciar(this.props.datos)
    // let resultados= await conexiones.Leer_C(['Data_personal'], 
    //   {['Data_personal']:{$and:[ {categoria:{$ne:0}} , {categoria:{$ne:5}}]},}
    //   );
    // console.log(resultados)
  }

  static getDerivedStateFromProps(props, state) {
    
    if (props !== state.props) {
        // state.Iniciar(props.datos.personal)
        
      return {
        props:{...state.props, datos:props.datos},
        
      };
    }
    // No state update necessary
    return null;
  }

  render(){
    return this.state.Esperar.esperar ? 
      <Esperar {...this.state.Esperar}/> :
      <div>
        <Pagee  {...this.state}/>  
      </div>
    
  }
}