import ChatIcon from '@material-ui/icons/Chat';
import EmailIcon from '@material-ui/icons/Email';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import DraftsIcon from '@material-ui/icons/Drafts';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import StreamIcon from '@mui/icons-material/Stream';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import MissedVideoCallIcon from '@material-ui/icons/MissedVideoCall';
import { green, red } from '@material-ui/core/colors';
//MISSED MESSAGE
import FeedbackIcon from '@material-ui/icons/Feedback';
//UNANSWERED EMAIL
import { RiMailForbidFill } from "react-icons/ri";
//MISSED INVITE
import { FiUserX } from "react-icons/fi";
//PRESENT
import { RiHandHeartLine } from "react-icons/ri";


export const categoria_usuario=[
    {_id:0, titulo:'AdministradorCHS',
      permisos:['*','**','*CHS']},
    {_id:1, titulo:'Director',
    permisos:['*']},
    {_id:2, titulo:'Administrador Agencia',
      permisos:['Guardar_perfil']},
    {_id:3, titulo:'Supervisor',
      permisos:['planificar','eliminar-operador']},
    {_id:4, titulo:'Operador',
      permisos:[]},
    {_id:5, titulo:'Personal indirecto',
      permisos:[]},
    
];

const meses=[
    
  {_id:0, titulo:'Inscripcion', campo:'inscripcion', mes:-1},{_id:1, titulo:'Septiembre', campo:'septiembre', mes:8},
  {_id:2, titulo:'Octubre', campo:'octubre', mes:9},{_id:3, titulo:'Noviembre', campo:'noviembre', mes:10},
  {_id:4, titulo:'Diciembre', campo:'diciembre', mes:11},{_id:5, titulo:'Enero', campo:'enero', mes:0},
  {_id:6, titulo:'Febrero', campo:'febrero', mes:1},{_id:7, titulo:'Marzo', campo:'marzo', mes:2},
  {_id:8, titulo:'Abril', campo:'abril', mes:3},{_id:9, titulo:'Mayo', campo:'mayo', mes:4},
  {_id:10, titulo:'Junio', campo:'junio', mes:5},{_id:11, titulo:'Julio', campo:'julio', mes:6},
  {_id:12, titulo:'Agosto', campo:'agosto', mes:7}
]

const bancos= [
  {_id:"0156", titulo:'100%BANCO'},
  {_id:"0196", titulo:'ABN AMRO BANK'},
  {_id:"0172", titulo:'BANCAMIGA BANCO MICROFINANCIERO, C.A.'},
  {_id:"0171", titulo:'BANCO ACTIVO BANCO COMERCIAL, C.A.'},
  {_id:"0166", titulo:'BANCO AGRICOLA'},
  {_id:"0175", titulo:'BANCO BICENTENARIO'},
  {_id:"0128", titulo:'BANCO CARONI, C.A. BANCO UNIVERSAL'},
  {_id:"0164", titulo:'BANCO DE DESARROLLO DEL MICROEMPRESARIO'},
  {_id:"0102", titulo:'BANCO DE VENEZUELA'},
  {_id:"0114", titulo:'BANCO DEL CARIBE'},
  {_id:"0149", titulo:'BANCO DEL PUEBLO SOBERANO'},
  {_id:"0163", titulo:'BANCO DEL TESORO'},
  {_id:"0176", titulo:'BANCO ESPIRITO SANTO'},
  {_id:"0115", titulo:'BANCO EXTERIOR C.A.'},
  {_id:"0003", titulo:'BANCO INDUSTRIAL DE VENEZUELA.'},
  {_id:"0173", titulo:'BANCO INTERNACIONAL DE DESARROLLO'},
  {_id:"0105", titulo:'BANCO MERCANTIL'},
  {_id:"0191", titulo:'BANCO NACIONAL DE CREDITO'},
  {_id:"0116", titulo:'BANCO OCCIDENTAL DE DESCUENTO.'},
  {_id:"0138", titulo:'BANCO PLAZA'},
  {_id:"0108", titulo:'BANCO PROVINCIAL BBVA'},
  {_id:"0104", titulo:'BANCO VENEZOLANO DE CREDITO'},
  {_id:"0168", titulo:'BANCRECER S.A. BANCO DE DESARROLLO'},
  {_id:"0134", titulo:'BANESCO BANCO UNIVERSAL'},
  {_id:"0177", titulo:'BANFANB'},
  {_id:"0146", titulo:'BANGENTE'},
  {_id:"0174", titulo:'BANPLUS BANCO COMERCIAL'},
  {_id:"0190", titulo:'CITIBANK.'},
  {_id:"0121", titulo:'CORP BANCA.'},
  {_id:"0157", titulo:'DELSUR BANCO UNIVERSAL'},
  {_id:"0151", titulo:'FONDO COMUN'},
  {_id:"0601", titulo:'INSTITUTO MUNICIPAL DE CREDITO POPULAR'},
  {_id:"0169", titulo:'MIBANCO BANCO DE DESARROLLO'},
  {_id:"0137", titulo: 'SOFITASA'},
]

const estatus = [
  {_id:'0', titulo:'Por verificar'},
  {_id:'1', titulo:'Verificando'},
  {_id:'2', titulo:'Aprobado'},
  {_id:'3', titulo:'Rechazado'},
  {_id:'4', titulo:'Aprobado y Rechazado'},
]

const forma_pago = [
  {_id:'0', titulo:'Transferencia'},
  {_id:'1', titulo:'Debito'},
  {_id:'2', titulo:'Efectivo Bolivar'},
  {_id:'3', titulo:'Efectivo Dolar'},
  {_id:'4', titulo:'Otro'},
]

// const menuchs=[
//   {
//     value: 'CONFIGURACION Ronald',
//     children: [
//       {value: 'LISTAS DE SELECCION'},
//       {value: 'LISTA DE CONFIGURACION'},
//       {value: 'CAMPOS DE ENLACES'},
//       {value: 'NUMEROS DOCUMENTOS'},
//       {value: 'CONFIGURACION ASIENTO CONTABLE'},
//       {value: 'CONFIGURACION SISTEMA'},
//       {value: 'CONFIGURACION USUARIOS'},
//     ]
//   },
//   {
//     value: 'SALDOS INICIALES',
//     children: [
//       {value: 'SALDOS INICIALES - BALANCE'},
//       {value: 'SALDOS INICIALES - RESULTADOS'},
//       {value: 'SALDOS INICIALES - INVENTARIO'},
//     ]
//   },
//   {
//     value: 'DATOS MAESTROS',
//     children: [
//       {value: 'DATOS MAESTROS - CTAS BALANCE'},
//       {value: 'DATOS MAESTROS - CTAS RESULTADO'},
//       {value: 'DATOS MAESTROS - CTA BCOS PROP'},
//       {value: 'DATOS MAESTROS - GRUPO ARTICULO'},
//       {value: 'DATOS MAESTROS - ARTICULOS'},
//       {value: 'DATOS MAESTROS - LISTA MATERIAL'},
//       {value: 'DATOS MAESTROS - TIEMPO ENTREGA'},
//       {value: 'DATOS MAESTROS - ALMACENES'},
//       {value: 'DATOS MAESTROS - PROVEEDORES'},
//       {value: 'DATOS MAESTROS - CLIENTES'},
//       {value: 'DATOS MAESTROS - VENDEDORES'},
//       {value: 'DATOS MAESTROS - TABULADOR'},
//       {value: 'DATOS MAESTROS - COLABORADORES'},
//     ]
//   },
//   {
//     value: 'CONTABILIDAD',
//     children: [
//       {value: 'CONTABILIDAD - ASIENTO CONTABLE'},
//       {value: 'CONTABILIDAD - LIBRO DIARIO'},
//     ]
//   },
//   {
//     value: 'INVENTARIO',
//     children: [
//       {value: 'INVENTARIO - CONTEO INVENTARIO'},
//       {value: 'INVENTARIO - TRASLADO MATERIAL'},
//       {value: 'INVENTARIO - MOV ARTICULOS'},
//       {value: 'INVENTARIO - INVENTARIO ACTUAL'},
//     ]
//   },
//   {
//     value: 'PRODUCCION',
//     children: [
//       {value: 'PRODUCCION - PLAN DE PRODUCCION'},
//       {value: 'PRODUCCION - ORDEN PRODUCCION'},
//       {value: 'PRODUCCION - EXPLOSION MATERIAL'},
//       {value: 'PRODUCCION - COMISIONES'},
//     ]
//   },

// ]

const menu=[
  {value: 'AMOLATINA'},
  {value: 'CONFIGURACION USUARIOS'},
]

// si se cambia algo hay que cambiar en puntos personales
const service={
  'dialogs.messages:intervals':{value: 'Online Chat', icon: <ChatIcon style={{color:green[500]}}/>},
  'dialogs.messages:messages':{value:'Chat Message', icon:<QuestionAnswerIcon style={{color:green[500]}}/>},
  'dialogs.letters:send':{value:'Received Email', icon:<DraftsIcon style={{color:green[500]}}/>},
  'dialogs.letters:read':{value: 'Send Email', icon: <EmailIcon style={{color:green[500]}}/>},
  'dialogs.attachments':{value:'Viewed Attachment', icon:<AttachmentIcon style={{color:green[500]}}/>},
  'dialogs.media':{value:'Video Stream', icon:<OndemandVideoIcon style={{color:green[500]}}/>},
  'paidresource:video':{value: 'Profile Video', icon:<VideoLibraryIcon style={{color:green[500]}}/>},
  'dialogs.attachments.audio':{value: 'Voice Message', icon:<PermPhoneMsgIcon style={{color:green[500]}}/>},
  'present':{value:'Present', icon:<RiHandHeartLine style={{fontSize: 25, color:green[500]}}/>},
  'manual':{value:'Manual Bonus', icon:<AttachMoneyIcon style={{color:green[500]}}/>},
  'cheers':{value:'Gift', icon:<CardGiftcardIcon style={{color:green[500]}}/>},
  
  'streaming.message.stream.gift.send':{value:'Streaming gift in stream', icon:<StreamIcon style={{color:green[500]}}/>},
  'streaming.message.private.gift.send':{value:'Streaming gift in private message', icon:<StreamIcon style={{color:green[500]}}/>},
  'streaming.message.private.text.send':{value:'Streaming text in private message', icon:<StreamIcon style={{color:green[500]}}/>},
  'streaming.message.private.photo.send':{value:'Streaming photo sent in private message', icon:<StreamIcon style={{color:green[500]}}/>},
  'streaming.message.private.photo.view':{value:'Streaming photo viewed in private message', icon:<StreamIcon style={{color:green[500]}}/>},
  'streaming.message.private.video.send':{value:'Streaming video sent in private message', icon:<StreamIcon style={{color:green[500]}}/>},
  'streaming.message.private.video.view':{value:'Streaming video viewed in private message', icon:<StreamIcon style={{color:green[500]}}/>},
  'streaming.message.private.sticker.send':{value:'Streaming sticker in private message', icon:<StreamIcon style={{color:green[500]}}/>},
  'streaming.message.private.gift.smile':{value:'Streaming smile in private message', icon:<StreamIcon style={{color:green[500]}}/>},
  'streaming.videocall.outgoing':{value:'Streaming outgoing video call', icon:<StreamIcon style={{color:green[500]}}/>},
  'streaming.videocall.incoming':{value:'Streaming incoming video call', icon:<StreamIcon style={{color:green[500]}}/>},

  'dialogs.interval.added:missed':{value:'Missed Invite', icon:<FiUserX style={{fontSize: 25, color:red[500]}}/>},
  'dialogs.interval.answer:missed': { value: 'Missed Answer', icon:<SpeakerNotesOffIcon style={{color:red[500]}}/>},
  'dialogs.message:missed':{value:'Missed Message', icon:<FeedbackIcon style={{fontSize: 25, color:red[500]}}/>},
  'dialogs.letter:missed': {value:'Unanswered Email', icon:<RiMailForbidFill style={{fontSize: 25, color:red[500]}}/>},
  'dialogs.letter:missed.hopelessly': {value:'Missed Email', icon:<EmailIcon style={{color:red[500]}}/>},
  'dialogs.media:missed':{value:'Missed Video Invite', icon:<MissedVideoCallIcon style={{color:red[500]}}/>},
  'present:missed':{value:'Missed Present', icon:< CardGiftcardIcon style={{color:red[500]}}/>},
  'manual:missed': {value:'Manual Write Off', icon:<AttachMoneyIcon style={{color:red[500]}}/>}
}

export const Valord={
  Titulo:'CHS',
  Inicio_recibo:100,
  meses_cancelar:3,
  Tiempo_portada: 8000,
  categoria_usuario,
  meses,
  estatus,
  forma_pago,
  menu,
  service,
  Estilos:{
    barra1:{
      backgroundImage:'linear-gradient(180deg, #0836f2 0, #1c2a29 16.67%, #161d1c 33.33%, #0b0b0b 50%, #000000 66.67%, #000000 83.33%, #0836f2 100%)',
      // backgroundImage:'linear-gradient(180deg, #00ffff 0, #00e5ff 12.5%, #10a6f8 25%, #10a6fa 37.5%, #1e6ca3 50%, #1e6ca5 62.5%, #153959 75%, #15395b 87.5%, #000000 100%)',
      
    },
    barra:{
      backgroundColor:'rgba(0, 19, 36)',
    },
    barra_menu:{
      backgroundColor:'#000000'// 'rgba(0, 99, 169)',
    },

    select_menu_item: {
      backgroundColor:'#153959',
      borderRadius: 20,
      boxShadow: '0 3px 5px 2px rgba(255, 255, 255, .9)',
    },
  },
  bancos
}
