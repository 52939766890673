import React, {Component} from 'react';

import moment from "moment";
import {conexiones} from '../../procesos/servicios';
import socketIOClient from "socket.io-client";


import List from '@material-ui/core/List';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


import { ListaPerfilesEsperar, ListaMingles, ListaPerfilesCabezera } from '../listas/lista_amolatina';

//Constantes
import {Valord, const_procesos} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

const Filtrar = (User, personal, puntos_perfiles, mostrar)=>{

  if (['3','4'].indexOf(User.categoria)!==-1){
    let nuevo=[]
    
    if (personal.length!==0)
      personal[0].perfiles.map(f=>{
        const pos = puntos_perfiles.filter(v=> v['user-id']===f)
        nuevo=[...nuevo,...pos]
        return f
      })
    
    puntos_perfiles=[...nuevo]
    
  }
  if (!mostrar){
    puntos_perfiles=puntos_perfiles.filter(f=>f.online)
  }

  return puntos_perfiles
}

export default class Puntos_por_perfil extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          midgle:[],
          mostrar:true,
          planificacion:[]
      }
  }

  Iniciar =async()=>{
    this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    let User = await JSON.parse(localStorage.getItem(const_procesos.dir_user));
    let respuesta= await conexiones.Leer_C(
      ['Data_perfil_amolatina', 'Data_agencia','Data_personal','Data_planificacion_dia', 'Data_turno'], 
      {
        'Data_perfil_amolatina':{$and:[ {suspended:false},{'has-mingle':false} ]} ,
        'Data_agencia':{},
        'Data_personal':{cedula:User.cedula},
        'Data_planificacion_dia':{fecha:moment.utc(new Date()).format('YYYY-MM-DD')},
        'Data_turno':{},
      }
    );
    let midgle=[]
    let agencias=[]
    let personal=[]
    let planificacion=[]
    let turno=[]
    if (respuesta.Respuesta==='Ok'){
      if (respuesta.datos['Data_planificacion_dia'].length!==0)
        planificacion=respuesta.datos['Data_planificacion_dia'][0].asistencia

      personal= respuesta.datos['Data_personal']
      agencias= respuesta.datos['Data_agencia']
      turno=respuesta.datos['Data_turno']
      if (personal.length!==0){  
        
        const posss= planificacion.filter(f=> f._id===personal[0]._id)
        personal[0].perfiles=[]
        if (posss.length!==0){
          personal[0].perfiles=posss[0].perfiles
          
          if ([3,'3'].indexOf(posss[0].categoria)!==-1 ){
            posss[0].operadores.map(op=>{
              const posop= planificacion.findIndex(f=> f._id===op)
              if (posop!==-1){
                personal[0].perfiles=[...personal[0].perfiles, ...planificacion[posop].perfiles]
              }
              return op
            })
          }
          
        }
      }
      midgle= this.Midgle(respuesta.datos['Data_perfil_amolatina'],agencias,planificacion, turno)
      // respuesta.datos['Data_perfil_amolatina'].map(p=>{
      //   const agen= agencias.filter(f=> f['user-id']===p['parent-id'])[0]
      //   let pers={}
      //   planificacion.map((pe,i)=>{
      //     let supervisor={}
      //     if (pe.categoria===4 || pe.categoria==='4'){
      //       supervisor= supervisores.filter(f=>f.operadores.findIndex(v=> v===pe._id)!==-1)[0]
      //     }
      //     const pos= pe.perfiles.indexOf(p['user-id'])
      //     if (pos!==-1 && !pers.nombres){
      //       pers={foto:pe.foto, nombres:pe.nombres, apellidos:pe.apellidos, _id:pe._id, cedula:pe.cedula, supervisor}
      //     }
          
      //     return pe
      //   })
      //   return {...p, agencia:agen.nombre ? agen.nombre : agen.email, pers}
      // })
      midgle=Filtrar(User,personal,midgle, this.state.mostrar)
        
    }else{
      setTimeout(()=>{
        this.Iniciar()
      }, 5000)
    }
    
    this.setState({turno, planificacion, midgle, User, personal, agencias,Esperar:{esperar:false,mensaje:'Cargando datos...'}})
  }
  
  Resta_hora = (hora1, hora2)=>{
    let resultado=new Date()
    resultado.setHours(Number(hora1.split(':')[0]))
    resultado.setMinutes(Number(hora1.split(':')[1]))
    const hora= Number(hora2.split(':')[0])
    const minuto= Number(hora2.split(':')[1])
    resultado.setHours(resultado.getHours()-hora);
    resultado.setMinutes(resultado.getMinutes()+minuto);

    return moment(resultado).format('HH:mm')
  }
  Midgle=(datos ,agencias, planificacion, turnos) =>{
    let supervisores= planificacion.filter(f=> f.categoria===3 || f.categoria==='3')
    let hora =new Date()
    hora = moment.utc(hora).format('HH:mm')
    let nturnos =[]
    turnos.map(t=>{
      let hora_inicio=this.Resta_hora(t.hora_inicio,t.hora)
      let hora_fin=this.Resta_hora(t.hora_fin,t.hora)
      hora_fin= hora_fin.split(':')[0]!=='00' ? hora_fin : `24:${hora_fin.split(':')[1]}`
      nturnos.push({
        turno:t.turno, pais:t.pais, hora_inicio, hora_fin
      })
      return t
    })

    nturnos=nturnos.filter(f=>
       (f.hora_inicio<f.hora_fin && f.hora_inicio<=hora && hora<f.hora_fin)||
      (f.hora_inicio>f.hora_fin && (f.hora_inicio<=hora || hora<f.hora_fin))
    )
    
    return datos.map(p=>{
      const agen= agencias.filter(f=> f['user-id']===p['parent-id'])[0]
      let pers={}
      planificacion.map((pe,i)=>{

        let supervisor={}
        if (pe.categoria===4 || pe.categoria==='4'){
          supervisor= supervisores.filter(f=>f.operadores.findIndex(v=> v===pe._id)!==-1)[0]
        }
        const pos= pe.perfiles.indexOf(p['user-id'])
        const post= nturnos.findIndex(f=>f.turno===pe.turno.split('-')[0] && f.pais===pe.turno.split('-')[1])
        if (pos!==-1 && !pers.nombres && post!==-1){
          pers={foto:pe.foto, nombres:pe.nombres, apellidos:pe.apellidos, _id:pe._id, cedula:pe.cedula, supervisor}
        }
        
        return pe
      })
      return {...p, agencia:agen.nombre ? agen.nombre : agen.email, pers}
    })
  }
  async componentDidMount(){
    this.Iniciar()
    const socket=socketIOClient("/")
    socket.on('Perfiles',(dato)=>{
        const {agencias, planificacion, turno}=this.state
        let midgle= dato.filter(f=> !f.suspended && !f['has-mingle'])
        midgle=this.Midgle(midgle,agencias,planificacion, turno)
        // midgle.map(p=>{
        //   const agen= agencias.filter(f=> f['user-id']===p['parent-id'])[0]
        //   let pers={}
        //   planificacion.map((pe,i)=>{
        //     const pos= pe.perfiles.indexOf(p['user-id'])
        //     if (pos!==-1 && !pers.nombres){
        //       pers={foto:pe.foto, nombres:pe.nombres, apellidos:pe.apellidos, _id:pe._id, cedula:pe.cedula} 
        //     }
        //     return pe
        //   })
        //   return {...p, agencia:agen.nombre ? agen.nombre : agen.email, pers}
        // })
        midgle=Filtrar(this.state.User, this.state.personal, midgle, this.state.mostrar)
        this.setState({midgle})
    })
    this.setState({socket, Esperar:{esperar:false,mensaje:'Cargando datos...'}})
  }
  componentWillUnmount(){
      let { socket}=this.state
      if (socket !== undefined && socket!==null){
        socket.disconnect();
        socket=null
      }
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        // const socket = props.socket
        // state.Resumen_perfil(socket)
        
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  Mostrar = () =>{
    this.setState({mostrar:!this.state.mostrar})
    setTimeout(()=>{
        this.Iniciar()
    }, 500)
  }

  render(){
    
    return (
      <List 
          subheader={
            <ListaPerfilesCabezera Titulo={`Mingle`} Open={this.Mostrar} 
              Icon={this.state.mostrar ? 
                <VisibilityIcon fontSize="small" style={{ color: '#ffffff' }} /> : 
                <VisibilityOffIcon fontSize="small" style={{ color: '#ffffff' }} />
              }
            />
            
          }
      >
        {this.state.Esperar.esperar ? 
            <ListaPerfilesEsperar/>:
            <ListaMingles datos={this.state.midgle}/>
        }
        
      </List>
   )   
  }
}