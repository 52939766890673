import React, {useState} from 'react';
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { red, blue } from '@material-ui/core/colors';
// import { Scrollbars } from 'rc-scrollbars';
import Scrollbars from '../formulario/MyScrollbars';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

//Constantes
import { Valord} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

const useStyles = makeStyles((theme) => ({
  root: {
    // width: window.screen.width,
    width: window.innerWidth
  },
  bloques:{
    width:200,
    height:300,
    backgroundColor:'#ffffff',
    marginRight: 10,
  },
  paper:{
    height:260,
    width:'100%',
    cursor: 'pointer'
  },
  grupos:{
    width:'99%',
    height:window.innerHeight * 0.82,//window.screen.height * 0.68,
    
    padding:theme.spacing(0.5, 1, 1, 1),
    borderRadius: 10,
    boxShadow: '5px 5px 5px 0px rgba(255, 255, 255, .9)',
    ...config.Estilos.barra_menu
  },
  grupo1:{
    width:'100%',
    height:window.innerHeight * 0.82,//window.screen.height * 0.68,
    padding:theme.spacing(0.5, 1, 1, 1),
    borderRadius: 10,
    boxShadow: '5px 5px 5px 0px rgba(0, 94, 172, .9)',
    backgroundColor:'#ffffff'
  },
  subgrupo:{
    height:100,
  },
  titulo:{
    color:'#ffffff'
  },
  titulol:{
    color:config.Estilos.barra_menu.backgroundColor
  },
  
}));

const ButtonOk = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    backgroundColor: config.Estilos.barra_menu.backgroundColor,
    '&:hover': {
      backgroundColor: blue[900],
    },
  },
}))(Button);

const ButtonEliminar = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[900],
    },
  },
}))(Button);

const themeb = createMuiTheme({
  palette: {
    error: {
      main: '#b71c1c',
    },
    primary: {
      main: '#1b5e20',
    },
    secondary: {
      main: '#ffeb3b',
    },
  },
});

export default function Page(props) {
  const classes = useStyles();
  let datos= props.datos
  const [select,setSelect] = useState(null)
  const [selecciones, setSelecciones] = useState({})
  const Selecciones = (index, sel) =>{
    if (Object.keys(selecciones).indexOf(index)!==-1){
      delete selecciones[index]
    }else{
      selecciones[index]=sel
    }
    setSelecciones({...selecciones})
  }
  const Entro = (index) =>(e)=>{
    e.preventDefault()
  }
  const Salio = (index) =>(e)=>{
    e.preventDefault()
  }
  const Comienza = (index)=>(e) =>{
    setSelect(index)
  }
  const Soltado = (index)=>(e) =>{
    setSelect(null)
  }
  const Dentro = (indice)=>{
    let sel= select
    let index= indice
    if (index===select.grupo || (index==='Libres' && select.grupo.indexOf('Libres-')!==-1 )) return 
    
    while (sel!==null){
      const skey = sel.key
      const sgrupo = sel.grupo
      if (index!=='Libres'){
        //Destino
        const posd = datos.Grupos.findIndex(v=> v.key === index)
        const pose = datos.Grupos[posd].childen.findIndex(v=> v.key === skey)
        //Agregamos en Grupo
        if (pose===-1) datos.Grupos[posd].childen.push(sel)
        if (sel.grupo.indexOf('Libres-')!==-1){
          //Origen
          const pos = datos.Libres.findIndex(v=> v.key === skey)
          //Quitar
          if (props.quitarl) datos.Libres.splice(pos, 1)
        }else{
          const i = datos.Grupos.findIndex(v=> v.key === sgrupo)
          //Origen
          let pos = datos.Grupos[i].childen.findIndex(v=> v.key === skey)
          
          //Quitar
          if (props.quitarg) datos.Grupos[i].childen.splice(pos, 1)
        }
        
      }else{
        const i = datos.Grupos.findIndex(v=> v.key === sgrupo)
        //Origen
        let pos = datos.Grupos[i].childen.findIndex(v=> v.key === skey)
        const pose = datos.Libres.findIndex(v=> v.key === skey)
        if (pose===-1) datos.Libres.push(sel)
        //Quitar del grupo destino
        datos.Grupos[i].childen.splice(pos, 1)
      }
      if (Object.keys(selecciones).length>0){
        delete selecciones[sel.key]
        let key=Object.keys(selecciones)[0]
        // index=key
        console.log(key)
        sel=key!== undefined ? selecciones[key] : null
        
      }else{
        sel=null
        setSelecciones({})
      }
    }
    props.Actualizar(datos)
  }
  const Libres= props.filtrar && props.filtro ? datos.Libres.filter(f=> f[props.filtrar]===props.filtro.title) : datos.Libres
  const Grupos = (datos)=>{
    return (
      <Grid container spacing={2}>
        { Libres ? 
          <Grid item xs={3}>
            <div className={classes.grupo1}
                onDragOver={Entro('Libres')}
                onDragLeave={Salio('Libres')}
                onDrop={()=>Dentro('Libres')}   
            >
              
              <Typography variant="h5" component="h5" gutterBottom className={classes.titulol}>
                {props.titulol}
              </Typography>
              {props.filtrar ? 
                <Autocomplete
                  options= {props.listaf}
                  getOptionLabel= {(option) => option.title}
                  onChange={(event, newValue) => {
                    // if (newValue!==null){
                      // console.log(newValue)
                      props.Filtrar(newValue)
                    // }
                  }}
                  id="debug"
                  debug
                  style={{marginTop:-40}}
                  renderInput={(params) => <TextField {...params} label="Filtrar" margin="normal" />}
                /> : null
              }
              <Scrollbars style={{height:'90%'}} >
              {Libres.map(val =>
                
                <div 
                  key={val.key} 
                  draggable={true}
                  onDragStart={Comienza({...val, grupo:'Libres-'+val.key})}
                  onDragEnd={Soltado(val)} 
                  style={{display:'flex', marginLeft:-12}}
                >  
                  <Checkbox
                    edge="end"
                    onChange={()=> Selecciones(val.key,{...val, grupo:'Libres-'+val.key})}
                    checked={selecciones[val.key]!==undefined}
                    
                  />
                  {val.titulo} 
                </div>
              )}
              </Scrollbars>
            </div>
          </Grid>: null
        }
        <Grid   item xs={Libres ? 9 : 12}>
          <div className={classes.grupos}>
          
            <Typography variant="h5" component="h5" gutterBottom className={classes.titulo}>
              {props.titulog}
            </Typography>
            <Scrollbars style={{height:'87%'}} >
            <Grid container spacing={1} item>
                {datos.Grupos.map((val,i)=>
                  <Grid container item xs={3} key={val.key}>
                    <Paper className={classes.paper} 
                      key={'paper-'+val.key}
                      onDragOver={Entro(val.key)}
                      onDragLeave={Salio(val.key)}
                      onDrop={()=>Dentro(val.key)}
                      elevation={3}
                      onClick={()=>props.Open_dialogo(val)}
                    >{val.titulo}
                      <Scrollbars style={{height:'70%'}} >
                      {val.childen.map(hijo=>
                        <Grid
                          key={hijo.key} 
                          item xs={12} 
                          draggable={Libres ? !hijo.static : false}
                          onDragStart={Comienza({...hijo, grupo:val.key})}
                          onDragEnd={Soltado(hijo)} 
                        >
                          
                          {hijo.titulo}
                        </Grid>  
                      )}
                      </Scrollbars>
                    </Paper>
                  </Grid>
                )}
            </Grid>
            </Scrollbars>
          </div>
          </Grid>
      </Grid>
    )
  }

  return (
    <ThemeProvider theme={themeb} >
    <Scrollbars style={{height:window.innerHeight * 0.92}} >
      {Grupos(datos)}
      <Grid item xs={12} container justify={'center'} alignItems={'center'} style={{marginTop:20}}>
        { props.Guardar ? 
          <ButtonOk 
              variant="contained" color="primary" 
              style={{marginRight:10}} 
              title='Guardar los cambios realizados'
              onClick={()=>props.Guardar(datos)}
              disabled={props.boton_guardar }
          >
              Guardar
          </ButtonOk> : null
        }
        { props.Cancelar ? 
          <ButtonEliminar 
              variant="contained" color="secondary" 
              style={{marginRight:10}}
              title='Cancelar los cambios realizados'
              onClick={props.Cancelar}
              disabled={props.props.boton_modificar}
          >
              Cancelar
          </ButtonEliminar> :null
        }
        {Libres ?
          <Button 
              variant="contained" style={{marginRight:10}}
              title='Iniciar los datos'
              onClick={props.Iniciar}
              disabled={props.props.boton_modificar}
          >
              Iniciar
          </Button>: null
        }
        {props.props.boton_copiar ?
          <Button 
              variant="contained" style={{marginRight:10}}
              color="primary" 
              title='Copiar los datos a siguiente semana'
              onClick={props.Copiar}
          >
              Copiar
          </Button> :null
      
        }
      </Grid>

    </Scrollbars>
    </ThemeProvider>
  );
}


