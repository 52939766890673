import React, {Component} from 'react';
import Avatar from '@material-ui/core/Avatar';
import {conexiones} from '../../procesos/servicios';
import moment from "moment";
import Typography from '@material-ui/core/Typography';
// import Rating from '@material-ui/lab/Rating';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Grid from '@material-ui/core/Grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

//Constantes
import {Valord} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

const Seleccion = withStyles((theme) => ({
  inputRoot: {
    
    backgroundColor:'#ffffff',
    bordeColor: config.Estilos.barra_menu.backgroundColor,
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  input: {
    
    bordeColor: config.Estilos.barra_menu.backgroundColor,
    '&:hover': {
      backgroundColor: '#ff000f',
    },
  },
}))(Autocomplete);

export default class General_perfiles extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          puntos_perfiles:[],
          apuntos_perfiles:[],
          Iniciar: this.Iniciar,
          Resumen_perfil: this.Resumen_perfil,
          total:0,
          inicio:moment(new Date()).format('YYYY-MM-DD'),
          fin:moment(new Date()).format('YYYY-MM-DD'),
          Agencias:[],
          select_agencia:null,
          Servicios: Object.keys(config.service).map(v=>{
            return {_id:v, value:config.service[v].value, icon: config.service[v].icon}
          }),
          select_service:null,
          perfiles:[],
          select_perfil:null
      }
  }

  

  Iniciar= async(inicio, fin, puntos_estrellas, select, service, perfil) =>{
    
    this.setState({puntos_perfiles:[], puntos_estrellas:[], Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    let cantidad=20;
    let pag=0;
    let salir= false
    console.log(inicio, fin)
    let puntos_perfiles=[]
    
    let total=0
    
    while(!salir){
      let condicion = {$and:[{fecha:{$gte:inicio}}, {fecha:{$lte:fin}}]}
      if (select!==null){
        condicion['$and'].push({'agency-id':select})
      }
      if (service!==null){
        condicion['$and'].push({'service':service})
      }
      if (perfil!==null){
        condicion['$and'].push({'user-id':perfil})
      }
      let respuesta= await conexiones.Leer_C(['Data_resumen_perfil'], 
        {
          'Data_resumen_perfil':{pagina:true, cantidad, pag, 
              condicion
          },
        }
      );
      let nuevo=[]
      if (respuesta.Respuesta==='Ok'){
        nuevo=respuesta.datos['Data_resumen_perfil'].map(v=>{
            total+= v.points  
            return {...v, puntose:0}//puntose:this.Calcular(v, puntos_estrellas)}
        })
        // .sort( (a,b) =>  a['fecha']>b['fecha'] ? 1 :  a['fecha']<b['fecha'] ? -1 : 0)
          //sort( (a,b) =>  a.hora>b.hora ? -1 :  a.hora<b.hora ? 1 : 0) 
        puntos_perfiles= [...puntos_perfiles , ...nuevo] 
      }

      this.setState({puntos_perfiles, apuntos_perfiles:puntos_perfiles,puntos_estrellas, total})
      if (nuevo.length<cantidad){
        salir = true;
      }else{
        pag+=1
      }
    }
    this.setState({Esperar:{esperar:false,mensaje:'Cargando datos...'}})
  }

  async componentDidMount(){
    let puntos_estrellas=[]
    let Agencias=[]
    let perfiles=[]
    let respu= await conexiones.Leer_C(['Data_punto','Data_agencia', 'Data_perfil_amolatina'], 
        {
          'Data_punto':{involucrado:0},
          'Data_agencia':{},
          'Data_perfil_amolatina':{suspended: false}
        }
    );
    if (respu.Respuesta==='Ok'){
      puntos_estrellas= respu.datos['Data_punto']
      Agencias=respu.datos['Data_agencia']
      perfiles=respu.datos['Data_perfil_amolatina']
    }
    this.setState({Agencias, puntos_estrellas, perfiles})
    this.Iniciar(this.state.inicio,this.state.fin, puntos_estrellas, this.state.select_agencia, this.state.select_service, this.state.select_perfil)
  }

  componentWillUnmount(){
  }


  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        // const socket = props.socket
        // state.Resumen_perfil(socket)
        
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }
  Calcular = (punto, puntos_estrellas)=>{      
      const pos = puntos_estrellas.findIndex(f=> f.desde<=punto.points && punto.points<=f.hasta )
      if (pos!==-1){
        let valor= punto.points/(puntos_estrellas[pos].hasta-puntos_estrellas[pos].desde)
        valor+=puntos_estrellas[pos].desde
        
        return valor
      }else{
       return 0 
      }
  }
  Estilos = {
    barra_lista:{
        backgroundColor:config.Estilos.barra.backgroundColor, color:'#ffffff',    
    },
    cuerpo_lista:{
        height: window.screen.height * 0.65,
        padding:10,
        overflow:'scroll',
    },
    item_lista:{
        backgroundColor: '#E4DDDD', 
        padding:5, border:1, borderRadius:10, marginBottom:10,
        cursor:'pointer', 
    },
    avatar:{
      alignItems:'center',
      textAlign:'center'
    },
    fecha:{
      display:'flex',
      alignItems:'center'
    }
  }

  Cambio =(index)=>(fecha) =>{
    let nuevo = moment(fecha).format('YYYY-MM-DD')
    this.setState({[index]:nuevo})
    let {select_agencia, select_service, select_perfil, puntos_estrellas}= this.state
    let inicio = index==='inicio' ? nuevo : this.state.inicio;
    let fin = index==='fin' ? nuevo : this.state.fin;
    this.Iniciar(inicio,fin, puntos_estrellas, 
                  select_agencia!==null ? select_agencia['user-id'] : null, 
                  select_service=== null ? null : select_service._id,
                  select_perfil=== null ? null : select_perfil['user-id']
                )
  }

  Fecha = (fecha, index) =>{
    
    // fecha= fecha.split('-')
    // fecha = fecha[1]+'-'+fecha[2]+'-'+fecha[0]
    fecha= new Date(fecha)
    fecha.setDate(fecha.getDate()+1)
    
    return(
      <DatePicker
        style={{marginLeft:10}}
        selected={fecha}
        locale="es"
        customInput={<CalendarTodayIcon />}
        onChange={this.Cambio(index)} 
            
      ></DatePicker>
    )
  }

  Seleccionar_agencia = async (agencia)=>{
    let {select_agencia, select_service, select_perfil, puntos_estrellas, inicio, fin, perfiles}= this.state
    let condicion = {$and:[{suspended:false}]}
    if (agencia!==null){
      condicion['$and'].push({'parent-id':agencia['user-id']})
    }
    let respu= await conexiones.Leer_C(['Data_perfil_amolatina'], 
        {
          'Data_perfil_amolatina':condicion
        }
    );
    if (respu.Respuesta==='Ok'){
      perfiles=respu.datos['Data_perfil_amolatina']
      select_perfil=null
    }
    select_agencia=agencia
    
    this.Iniciar(inicio,fin, puntos_estrellas, 
                  select_agencia===null ? null : select_agencia['user-id'], 
                  select_service!==null  ? select_service._id : null,
                  select_perfil=== null ? null : select_perfil['user-id']
                )
    this.setState({select_agencia, perfiles, select_perfil})
  }

  Seleccionar_servicio = (service)=>{
    let {select_agencia, select_service, select_perfil, puntos_estrellas, inicio, fin}= this.state
    select_service=service
    this.Iniciar(inicio,fin, puntos_estrellas, 
                  select_agencia!==null ? select_agencia['user-id'] : null, 
                  service=== null ? null : service._id,
                  select_perfil=== null ? null : select_perfil['user-id']
                  )
    this.setState({select_service})
  }

  Seleccionar_perfiles = (perfil)=>{
    let {select_agencia, select_service, select_perfil, puntos_estrellas, inicio, fin}= this.state
    select_perfil=perfil
    this.Iniciar(inicio,fin, puntos_estrellas, 
                select_agencia!==null ? select_agencia['user-id'] : null, 
                select_service=== null ? null : select_service._id,
                select_perfil=== null ? null : select_perfil['user-id']
                )
    this.setState({select_perfil})
  }

  render(){
    return (
      <div>
        <List 
          subheader={
            <ListSubheader component="div" id="nested-list-subheader"
              style={this.Estilos.barra_lista}
            >
              {`Puntos de los Perfiles`}
              {this.state.Esperar.esperar ? <LinearProgress /> : null}
                <Grid container spacing={2} justify="center" alignItems="center" direction="row">
                    <Grid container item spacing={2}>
                        <Grid item xs>
                          <div style={this.Estilos.fecha}>
                            <Typography variant="caption" display="block" gutterBottom>
                              {`${this.state.inicio}`}
                            </Typography>
                            {this.Fecha(this.state.inicio, 'inicio')} 
                          </div>
                        </Grid>
                        <Grid item xs>
                          <div style={this.Estilos.fecha}>
                            <Typography variant="caption" display="block" gutterBottom>
                              {`${this.state.fin}`}
                            </Typography>
                            {this.Fecha(this.state.fin, 'fin')} 
                          </div>
                        </Grid>
                        <Grid item xs>
                          <Seleccion
                              autoComplete
                              name={'select_agencia'}
                              options={this.state.Agencias}
                              getOptionLabel={(option) => {
                                return `${option['nombre']!==undefined ? option['nombre']: ''}`}
                              }
                              onChange={(event, newValue)=>{
                                this.Seleccionar_agencia(newValue)
                              }}
                              value={this.state.select_agencia}
                              renderInput={(params) => 
                                <TextField {...params} label={'Seleccionar Agencia'} variant="outlined" 
                                    
                                />
                              }
                          />
                        </Grid>
                        <Grid item xs>
                          <Seleccion
                              autoComplete
                              name={'select_sevicios'}
                              options={this.state.Servicios}
                              getOptionLabel={(option) => {
                                return `${option.value}`}
                              }
                              onChange={(event, newValue)=>{
                                this.Seleccionar_servicio(newValue)
                              }}
                              value={this.state.select_service}
                              style= {{ color:'#ffffff' }}
                              renderInput={(params) => 
                                <TextField {...params} label={'Seleccionar servicio'} variant="outlined" 
                                    style= {{ color:'#ffffff' }}
                                />
                              }
                          />
                        </Grid>
                        <Grid item xs>
                          <Seleccion
                              autoComplete
                              name={'select_perfil'}
                              options={this.state.perfiles}
                              getOptionLabel={(option) => {
                                return `${option['first-name']}`}
                              }
                              onChange={(event, newValue)=>{
                                this.Seleccionar_perfiles(newValue)
                              }}
                              value={this.state.select_perfil}
                              style= {{ color:'#ffffff' }}
                              renderInput={(params) => 
                                <TextField {...params} label={'Seleccionar perfil'} variant="outlined" 
                                    style= {{ color:'#ffffff' }}
                                />
                              }
                          />
                        </Grid>
                        <Grid item xs>
                          {`Total: ${new Intl.NumberFormat('es-VE').format(this.state.total)}`} 
                        </Grid>
                        <Grid item xs>
                          {`${this.state.puntos_perfiles.length}`} 
                        </Grid>
                    </Grid>
                </Grid>
            </ListSubheader>
          }
        >
           <div style={this.Estilos.cuerpo_lista}>
            {this.state.puntos_perfiles.map((punto,i) =>
                <ListItem 
                    key={punto._id+i} alignItems="flex-start" 
                    style={this.Estilos.item_lista}
                >
                    <ListItemAvatar>
                      <div style={this.Estilos.avatar}>
                        <Avatar
                            style={{marginLeft:15}}
                            alt={`${punto['nombre-perfil']}`}
                            src={`https://api7.amolatina.com/users/${punto['user-id']}/photos/${punto['imagen-perfil']}.490x480.thumb-fd`}
                        />
                        <Typography variant="caption" display="block" gutterBottom>
                            {punto['user-id']}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom style={{marginTop:-7}}>
                            {punto['nombre-perfil']}
                        </Typography>
                      </div>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography variant="caption" display="block" gutterBottom>
                                {`Fecha: ${punto.fecha} Hora: ${punto.hora}  \nservice: ${config.service[punto.service].value}`}
                            </Typography>
                        }
                        secondary={
                            `Puntos: ${Number(punto.points===undefined ? 0 : punto.points).toFixed(2)} Multa: ${punto.mpoints===undefined ? 0 : punto.mpoints}`
                        }
                    />
                    <ListItemSecondaryAction>
                        {config.service[punto.service].icon}
                    </ListItemSecondaryAction>
                </ListItem>
            )}
          </div> 
        </List>
        
      </div>
    )
  }
}