import React, {Component} from 'react';
import {conexiones} from '../../procesos/servicios';
import Esperar from '../esperar';
import Pagee from '../conexiones';
import {ItemTituloSupervisor, ItemSupervisor} from '../conexiones/item';

//Asignar operadores a supervisores
export default class Supervisor extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{Libres:[],Grupos:[]},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          Guardar: this.Guardar,
          Cancelar: this.Cancelar,
          Iniciar: this.Iniciar,
          Actualizar:this.Actualizar
      }
  }
  
  Guardar = async(datos)=>{
    
    this.setState({Esperar:{esperar:true,mensaje:'Guardando cambios...'}})
    
    Promise.all( 
      await datos.Grupos.map(async g =>{
        g.operadores= g.operadores.map( o =>{
          o={
            _id:o._id, nombres:o.nombres, apellidos:o.apellidos, categoria: o.categoria,
            foto:o.foto, turno: o.turno, cedula:o.cedula, 
          }
          return o
        })
        // const nuevo={_id:g._id, operadores:g.operadores}
        const valor = await conexiones.Guardar(g,'Data_personal')
        return valor
        
      })
    ).then(values => {
      console.log('Termino');
      this.setState({datos, Esperar:{esperar:false}})
    }, reason => {
      console.log('Error')
      this.setState({datos, Esperar:{esperar:false}})
    });
    // this.setState({datos, Esperar:{esperar:false}})
    
  }

  Cancelar = async()=>{
      //Colocamos todos los valores a TN
    let {datos} = this.state
    datos.supervisores.map((val,i)=>{
      if (val.operadores.length!==0){
        while (val.operadores.length!==0){
          datos.operadores.push(val.operadores[0])
          datos.supervisores[i].operadores.splice(0, 1)
        }
      }
      return val
    })
    
    this.setState({datos})
      
  }

  Iniciar= async() =>{
    this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    let respuesta= await conexiones.Leer_C(['Data_personal'], 
      {'Data_personal':{$or:[ {categoria:3} , {categoria:4}]},}
      );
    let personal=[]
    if (respuesta.Respuesta==='Ok'){
        personal= respuesta.datos['Data_personal'].sort((a, b) => (a.categoria >b.categoria ? 1 : a.categoria < b.categoria ? -1 : 0))
    }
    
    let datos = {
      Grupos:[],
      Libres:[]
    }
    personal.map(val=>{
        if (val.categoria==='3'){
          val.operadores= val.operadores.filter(o=>  o.categoria==='4')
          datos.Grupos.push(
            {...val, key:val._id, 
              titulo:<ItemTituloSupervisor sup={val}/>, 
              childen: val.operadores.map(o=>{
                let pos = personal.findIndex(v=> v._id===o._id)
                console.log(pos, personal[pos].turno)      
                return {...o, key:o._id, titulo:<ItemSupervisor op={{...o, turno:personal[pos].turno}}/>}
              })
            })
        }else{
          let pos = this.Buscar_operador(datos.Grupos,val)
          if (pos===-1) datos.Libres.push({...val, key:val._id, titulo:<ItemSupervisor op={val}/>})
        }
      return val
    })
    
    this.setState({datos, TituloL:'Operadores', FiltrarL:'turno',  TituloG:'Supervisores', Esperar:{esperar:false}})
  }
  Buscar_operador =(datos,operador)=>{
    let pos=-1
    datos.map(val=>{
      if (pos===-1)
        pos= val.operadores.findIndex((v)=> v._id===operador._id)
      return val
    })
    return pos
  }
  Actualizar = (datos) =>{
    datos.Libres.map(l=>{
      l={...l, titulo:<ItemSupervisor op={{...l, turno:l.turno}}/>}
      return l
    })
    datos.Grupos=datos.Grupos.map(g=>{
      // g.childen = g.childen.filter(o=>{ 
      //   if (o.turno===g.turno){
      //     return o
      //   }else{
      //     datos.Libres.push(o)
      //   }
        
      // })
      
      return {...g, operadores:g.childen, titulo: <ItemTituloSupervisor sup={{...g,operadores:g.childen}}/>}
    })
    
    this.setState({datos})
  }
  async componentDidMount(){
    this.Iniciar()
    // let resultados= await conexiones.Leer_C(['Data_personal'], 
    //   {['Data_personal']:{$and:[ {categoria:{$ne:0}} , {categoria:{$ne:5}}]},}
    //   );
    // console.log(resultados)
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        console.log('por este en turno')
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  render(){
    return this.state.Esperar.esperar ? 
      <Esperar {...this.state.Esperar}/> :
      <div>
        <Pagee  {...this.state}/>  
      </div>
    
  }
}