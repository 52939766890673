import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Scrollbars } from 'rc-scrollbars';
import Paper from '@material-ui/core/Paper';

//Constantes
import { Valord} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

const useStyles = makeStyles((theme) => ({
    root: {
      width: window.screen.width,
    },
    bloques:{
      width:200,
      height:300,
      backgroundColor:'#ffffff',
      marginRight: 10,
    },
    paper:{
      height:260,
      width:'100%',
      
    },
    grupos:{
      width:'99%',
      height:window.innerHeight * 0.75,//window.screen.height * 0.68,
      
      padding:theme.spacing(0.5, 1, 1, 1),
      borderRadius: 10,
      boxShadow: '5px 5px 5px 0px rgba(255, 255, 255, .9)',
      ...config.Estilos.barra_menu
    },
    grupo1:{
      width:'100%',
      height:window.innerHeight * 0.82,//window.screen.height * 0.68,
      padding:theme.spacing(0.5, 1, 1, 1),
      borderRadius: 10,
      boxShadow: '5px 5px 5px 0px rgba(0, 94, 172, .9)',
      backgroundColor:'#ffffff'
    },
    subgrupo:{
      height:100,
    },
    titulo:{
      color:'#ffffff'
    },
    titulol:{
      color:config.Estilos.barra_menu.backgroundColor
    },
    
  }));

const Break = (props) => {
  const {personal}=props
  const classes = useStyles();
  return (
    <div className={classes.grupos}>
        <Scrollbars autoHide style={{height:'80%'}} >
            <Grid container spacing={1} item>
                {personal.map((val,i)=>
                    <Grid container item xs={3} key={val.key}>
                    <Paper className={classes.paper} 
                        key={'paper-'+val.key}
                        elevation={3}
                    >{val.titulo}
                        <Scrollbars autoHide style={{height:'70%'}} >
                        {val.childen.map(hijo=>
                        <Grid
                            key={hijo.key} 
                            item xs={12}  
                        >
                            
                            {hijo.titulo}
                        </Grid>  
                        )}
                        </Scrollbars>
                    </Paper>
                    </Grid>
                )}
            </Grid>
        </Scrollbars>
    </div>
    
  );
};

export default Break