import React, {Component} from 'react';
import Page from './page';

// esperar: activa el esperar
// mensaje: muestra el mensaje a mostrar mientras se esperar
// progreso: el valor en la barra de progreso
class Esperar extends Component {
  constructor(props) {
      super(props);

      this.state = {

          props:this.props
      }
  }
  componentDidMount(){
    let {esperar, mensaje, progreso}= this.props

    this.setState({props: {
      esperar: esperar!==undefined ? esperar : true,
      mensaje: mensaje!==undefined ? mensaje : 'Cargando...',
      progreso,
    }});
  }


  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {

      let { esperar, mensaje, progreso}= props
      return {
        props:{
          esperar: esperar!==undefined ? esperar : true,
          mensaje: mensaje!==undefined ? mensaje : 'Cargando...',
          progreso,
        },
      };
    }
    return null;
  }

  render(){
    return (
      <Page   {...this.state.props}/>
    )
  }
}

export default Esperar;
