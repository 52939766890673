import React, {Component} from 'react';
import {conexiones} from '../../procesos/servicios';
import moment from "moment";
import 'moment/locale/es';
import Typography from '@material-ui/core/Typography';
import socketIOClient from "socket.io-client";
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AutorenewIcon from '@material-ui/icons/Autorenew';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LinearProgress from '@material-ui/core/LinearProgress';
// import { withStyles } from '@material-ui/core/styles';
import { ListaPerfilesCabezera, ListaAgencia, ListaPerfilesEsperar } from '../listas/lista_amolatina';
import Dialogo from '../../components/formulario/dialogo';

//Constantes
import {Valord} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

moment.locale('es');
export default class Puntos_agencias_mes extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          puntos_agencias:[],
          Iniciar: this.Iniciar,
          Resumen_agencias:this.Resumen_agencias,
          selecciones:{},
          totales:0,
          Open_dialogo: this.Open_dialogo,
          Dialogo:{
            open:false,
            Titulo:'Puntos por mes Agencias',
            tam:'md',
            Cerrar: this.Cerrar_dialogo,
          },
          Seleccion:this.Seleccion
      }
  }

  Open_dialogo = (valor) =>{
    let {Dialogo}=this.state
    Dialogo.Cuerpo = <Ampliada />
    Dialogo.open=true
    this.setState({Dialogo})
  }
  Cerrar_dialogo = () =>{
    let {Dialogo}=this.state
    Dialogo.open=false
    this.setState({Dialogo})
  }
  Iniciar= async() =>{
    const dia= moment(new Date()).format('YYYY-MM')+'-01'
    let respuesta= await conexiones.Leer_C(['Data_resumen_agencia','Data_agencia'], 
      {
          'Data_resumen_agencia':{fecha:{$gte:dia}},
          'Data_agencia':{}
      }
    );
    let puntos_agencias=[]
    let totales=0
    let selecciones={}
    if (respuesta.Respuesta==='Ok'){
      respuesta.datos['Data_agencia'].map(A=>{
        const p_a= respuesta.datos['Data_resumen_agencia'].filter(v=> v['agency-id']===A['user-id'])
        let points=0
        let total=0
        p_a.map(p=>{
            points+= p.points
            total+= p.total
            return p
        })
        puntos_agencias.push({...p_a[0], points, puntos:points, total, nombre: A.nombre, api:A.api,email:A.email})
        return A
      })
  
      puntos_agencias= puntos_agencias.sort((a,b)=> a.points>b.points ? -1 : a.points<b.points ? 1 : 0)  
      puntos_agencias.map((v,i)=>{
        selecciones[i]=true
        totales+= v.puntos
        return v
      })
      if (respuesta.datos['Data_resumen_agencia'].length!==0){
        this.setState({
          puntos_agencias,
          selecciones,
          totales,
          Esperar:{esperar:false,mensaje:'Cargando datos...'}
        })
      }else{
        setTimeout(()=>{
          this.Iniciar()
        }, 3000)
      }
    }else{
      setTimeout(()=>{
        this.Iniciar()
      }, 5000)
    }
    
    this.setState({
      Esperar:{esperar:false,mensaje:'Cargando datos...'}
    })
  }
  
  async componentDidMount(){
    this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    this.Iniciar()
    const socket=socketIOClient("/")
    socket.on('Puntos_agencia',(dato)=>{
      // dato.map(d=>{
        // this.Iniciar()
      //   return d
      // })
      setTimeout(()=>{
        this.Iniciar()
      }, 2000)
    })
    this.setState({socket})
  }
  
  componentWillUnmount(){
    let { socket}=this.state
    if (socket!==undefined && socket!==null){
        socket.disconnect();
        socket=null
    }
  }

  Resumen_agencias = (newsocket) =>{
    let {socket}=this.state
    let {puntos_agencias}=this.state
    if (socket ===undefined || socket===null) {
      socket=newsocket
      socket.on('Puntos_agencia',(dato)=>{
        const pos = puntos_agencias.findIndex(v=> v._id===dato.agencia._id)
        if (pos!==-1){
          puntos_agencias[pos]={...dato.agencia, puntos: dato.puntos, total: dato.total.toFixed(3)}
        }else{
          puntos_agencias.push({...dato.agencia, puntos: dato.puntos, total: dato.total.toFixed(3)})
        }
        puntos_agencias= puntos_agencias.sort((a,b)=> a.points>b.points ? -1 : a.points<b.points ? 1 : 0)  
        this.setState({puntos_agencias, socket})
      })
    }

  }
  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
      // state.Resumen_agencias(props.socket)
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  Seleccion = (index) =>{
    let {selecciones, puntos_agencias}=this.state
    selecciones[index]=selecciones[index]===undefined || !selecciones[index] ? true : false
    let totales=0
    Object.keys(selecciones).map(key=>{
      totales= selecciones[key] ? totales + puntos_agencias[key].puntos : totales
      return key
    })
    this.setState({selecciones, totales})
  }
  render(){
    
    return( 
      <div>
        <List 
          style={{}}
          subheader={
            <ListaPerfilesCabezera 
              Open={this.Open_dialogo}
              Icon={<VisibilityIcon fontSize="small" style={{ color: '#ffffff' }} />}
              Titulo={`Puntos del mes Agencia \n Puntos seleccionados: ${new Intl.NumberFormat('es-VE').format(this.state.totales)}`} 
              alto={40}
              variant="caption"
            />
            
          }
        >
          {this.state.Esperar.esperar ? 
            <ListaPerfilesEsperar alto={110}/>:
            <ListaAgencia  {...this.state} alto={110}/>
          }
        
        </List>
        <Dialogo {...this.state.Dialogo}/>
      </div>
    )  
  }
}
// const Seleccion = withStyles((theme) => ({
//   inputRoot: {
    
//     backgroundColor:'#ffffff',
//     bordeColor: config.Estilos.barra_menu.backgroundColor,
//     '&:hover': {
//       backgroundColor: '#ffffff',
//     },
//   },
//   input: {
    
//     bordeColor: config.Estilos.barra_menu.backgroundColor,
//     '&:hover': {
//       backgroundColor: '#ff000f',
//     },
//   },
// }))(Autocomplete);

class Ampliada extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          inicio:new Date(),
          
          Agencias:[],
          
          perfiles:[],
          select_perfil:null,
          puntos_agencias:[]
      }
  }

  Iniciar= async(dia) =>{
    this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    const fin =moment(dia).format('YYYY-MM')+'-31'
    dia= moment(dia).format('YYYY-MM')+'-01'
    
    let respuesta= await conexiones.Leer_C(['Data_resumen_agencia','Data_agencia'], 
      {
          'Data_resumen_agencia':{$and:[{fecha:{$gte:dia}}, {fecha:{$lte:fin}}]},
          'Data_agencia':{}
      }
    );
    let puntos_agencias=[]
    let totales=0
    let selecciones={}
    if (respuesta.Respuesta==='Ok'){
      respuesta.datos['Data_agencia'].map(A=>{
        const p_a= respuesta.datos['Data_resumen_agencia'].filter(v=> v['agency-id']===A['user-id'])
        let points=0
        let total=0
        p_a.map(p=>{
            points+= p.points
            total+= p.total
            return p
        })
        puntos_agencias.push({...p_a[0], points, puntos:points, total, nombre: A.nombre, email:A.email})
        return A
      })
  
      puntos_agencias= puntos_agencias.sort((a,b)=> a.points>b.points ? -1 : a.points<b.points ? 1 : 0)  
    }else{
      setTimeout(()=>{
        this.Iniciar()
      }, 5000)
    }
    puntos_agencias.map((v,i)=>{
      selecciones[i]=true
      totales+= v.puntos
      return v
    })
    this.setState({
      puntos_agencias,
      selecciones,
      totales,
      Esperar:{esperar:false,mensaje:'Cargando datos...'}
    })
  }
  Cambio =(index)=>(fecha) =>{
    
    
    this.setState({[index]:fecha})  
    
    this.Iniciar(fecha)
  }
  Fecha = (fecha, index) =>{
    
    // fecha= fecha.split('-')
    // fecha = fecha[1]+'-'+fecha[2]+'-'+fecha[0]
    fecha= new Date(fecha)
    fecha.setDate(fecha.getDate()+1)
    
    return(
      <DatePicker
        style={{marginLeft:10}}
        selected={fecha}
        locale="es"
        customInput={<CalendarTodayIcon />}
        onChange={this.Cambio(index)} 
        dateFormat="MM/yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
        withPortal
      ></DatePicker>
    )
  }
  
  Estilos = {
    barra_lista:{
        backgroundColor:config.Estilos.barra.backgroundColor, color:'#ffffff',
        marginBottom: 20, borderTop:10, borderTopLeftRadius:10,
        borderTopRightRadius:10, padding:20    
    },
    cuerpo_lista:{
        height: window.screen.height * 0.65,
        padding:10,
        overflow:'scroll',
    },
    item_lista:{
        backgroundColor: '#E4DDDD', 
        padding:5, border:1, borderRadius:10, marginBottom:10,
        cursor:'pointer', 
    },
    avatar:{
      alignItems:'center',
      textAlign:'center'
    },
    fecha:{
      display:'flex',
      alignItems:'center'
    }
  }

  async componentDidMount(){
    this.Iniciar(this.state.inicio)
    const socket=socketIOClient("/")
    socket.on('Actualizando_mes',(dato)=>{
      
      const {inicio}=this.state
      this.setState({
        Esperar:{
          esperar:dato.dia==='Finalizo' ? false: true,
          mensaje:`Actualizando mes ${moment(inicio).format('MMMM')} del ${moment(inicio).format('YYYY')} => ${dato.mensaje}`
        }
      })
    })
    this.setState({socket})
  }
  componentWillUnmount(){
    let { socket}=this.state
    if (socket!==undefined && socket!==null){
        socket.disconnect();
        socket=null
    }
  }
  Actualizar = async()=>{
    const {inicio}=this.state
    this.setState({Esperar:{esperar:true,mensaje:`Actualizando mes ${moment(inicio).format('MMMM')} del ${moment(inicio).format('YYYY')}`}})
    await conexiones.Enviar({
      datos:{mes:moment(inicio).format('MM'), ano: moment(inicio).format('YYYY')},
      http_destino:'/amolatina/actualizar',
      method:'POST',
    });
  }
  render(){
    
    return (
      <div>
        <List 
            subheader={
              <ListSubheader component="div" id="nested-list-subheader"
                style={this.Estilos.barra_lista}
                disableGutters
              >
                  {this.state.Esperar.esperar ? 
                    <div>
                      <LinearProgress />
                      <Typography variant="caption" display="block" gutterBottom>
                        {`${this.state.Esperar.mensaje}`}
                      </Typography>
                    </div> : 
                <Grid container spacing={2} justify="center" alignItems="center" direction="row">
                    <Grid container item spacing={2}>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={2}>
                          
                        </Grid>
                        <Grid item xs={3}>
                          <div style={this.Estilos.fecha}>
                            <Typography variant="caption" display="block" gutterBottom>
                              {`${moment(this.state.inicio).format('MMMM YYYY')}`}
                            </Typography>
                            {this.state.Esperar.esperar ? null : this.Fecha(this.state.inicio, 'inicio')} 
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <IconButton   size="small" onClick={this.Actualizar} title={'Actualizar puntos'}>
                            <AutorenewIcon fontSize="large" style={{ color: '#ffffff' }} />
                          </IconButton>
                        </Grid>
                        <Grid item xs={2}>
                          <div style={this.Estilos.fecha}>
                            <Typography variant="caption" display="block" gutterBottom>
                              {`${Number(this.state.totales===undefined ? 0 : this.state.totales).toFixed(2)}`}
                            </Typography>
                          </div>
                        </Grid>                        
                    </Grid>
                </Grid> }
              </ListSubheader>
            }
        >
          {this.state.Esperar.esperar && this.state.puntos_agencias.length===0 ? 
            <ListaPerfilesEsperar alto={200}/>:
            <ListaAgencia  {...this.state}/>
          }
        </List>
      </div>

    )
  }   
  
}