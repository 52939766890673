import React, {Component} from 'react';
import {conexiones} from '../../procesos/servicios';
// import CircularProgress from '@material-ui/core/CircularProgress';
import moment from "moment";
import { Bar } from '@reactchartjs/react-chart.js'
import socketIOClient from "socket.io-client";
import Skeleton from '@material-ui/lab/Skeleton';

const rand = () => Math.floor(Math.random() * 255)
const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
}

export default class Grafica_resumen extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
            props: this.props,
            turnos:{},
            data:{ labels: [],datasets: [] },
            Esperar:{
              esperar:false,
              mensaje:'Cargando...',
            },
            puntos_agencias:[],
            Iniciar: this.Iniciar,
        }
    }
  
  
    Iniciar= async() =>{
      let fecha = new Date()
      fecha.setDate(fecha.getDate()-30)
      const dia= moment(fecha).format('YYYY-MM-DD')
      let respuesta= await conexiones.Leer_C(['Data_resumen_agencia','Data_agencia'], 
        {
            'Data_resumen_agencia':{fecha:{$gte:dia}},
            'Data_agencia':{}
        }
      );
      let puntos_agencias=[]
      let data={labels: [],datasets: []}
      if (respuesta.Respuesta==='Ok'){
        puntos_agencias= respuesta.datos['Data_resumen_agencia'].sort( (a,b)=> a.fecha<b.fecha ? -1 : a.fecha>b.fecha ? 1 : 0)
        puntos_agencias.map(A=>{
          const pos= data.labels.indexOf(A.fecha)
          if (pos===-1) data.labels.push(A.fecha)
          const ag= respuesta.datos['Data_agencia'].filter(f=> f['user-id']===A['agency-id'])[0]
          A.nombre=ag.nombre
          const posa= data.datasets.findIndex(v=> v.label===A['nombre'])
          if (posa===-1){
            const color=[rand(),rand(),rand()]
            data.datasets.push({
                type: 'line',
                label: A.nombre,
                data: [Number(A.points).toFixed(3)],
                fill: true,
                backgroundColor: `rgb(${color[0]}, ${color[1]}, ${color[2]}, 0.4)`,
                borderColor: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
                
            })
          }else{
            data.datasets[posa].data.push(Number(A.points).toFixed(3)) 
          }  
          return A
        })
        
        puntos_agencias= puntos_agencias.sort((a,b)=> a.points>b.points ? -1 : a.points<b.points ? 1 : 0)  
      }else{
        setTimeout(()=>{
          this.Iniciar()
        }, 5000)
      }
      
      this.setState({data, puntos_agencias,Esperar:{esperar:false,mensaje:'Cargando datos...'}})
    }
    
    async componentDidMount(){
      this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
      this.Iniciar()
      const socket=socketIOClient("/")
      socket.on('Puntos_agencia',(dato)=>{
        dato.map(d=>{
          this.Iniciar()
          return d
        })
      })
      this.setState({socket})
    }
    
    componentWillUnmount(){
      let { socket}=this.state
      if (socket!==undefined && socket!==null){
        socket.disconnect();
        socket=null
      }
    }
  
    
    static getDerivedStateFromProps(props, state) {
  
      if (props !== state.props) {
        // state.Resumen_agencias(props.socket)
        return {
          props,
        };
      }
      // No state update necessary
      return null;
    }
  
    render(){
      
      return this.state.Esperar.esperar ? 
        <div>
          <Skeleton variant="rect" width={210} height={118} />
        </div>
        : (
          <Bar data={this.state.data} options={options} />
        )
    }
}

