import React, {Component} from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import {conexiones} from '../../procesos/servicios';
import Page from './page';

import moment from "moment";
import 'moment/locale/es';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

//Constantes
import {Valord} from '../../constantes';
// import { AiOutlineConsoleSql } from 'react-icons/ai';

let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

// const categoria_usuario= config.categoria_usuario;
  
  
export default class Nomina extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
            progreso:0,
          },
          datos:[],
          cantidad:0,
          fecha:new Date(),
          meses:[
            {_id:'01', mes:'Enero'}, {_id:'02', mes:'Febrero'}, {_id:'03', mes:'Marzo'},
            {_id:'04', mes:'Abril'}, {_id:'05', mes:'Mayo'}, {_id:'06', mes:'Junio'},
            {_id:'07', mes:'Julio'}, {_id:'08', mes:'Agosto'}, {_id:'09', mes:'Septiembre'},
            {_id:'10', mes:'Octubre'}, {_id:'11', mes:'Noviembre'}, {_id:'12', mes:'Diciembre'}
          ],
          select_mes:{
            _id:'01', mes:'Enero'
          },
          funciones:{
            Dialogo: this.Dialogo,
            Actualizar_data: this.Actualizar_data,
            Refrescar: this.Refrescar,
            Select_mes: this.Select_mes,
            Cancelar: this.Cancelar,
            Pagar: this.Pagar
          }, 
          dato:{},
      }
  }

  Pagar = async() =>{
    let {dato}= this.state;
    dato.pagado=true
    this.setState({Esperar: {esperar:true, mensaje_espera:'Guardando datos...', progreso:0}})  
    const nuevos = await conexiones.Guardar(dato,'Data_nomina', 'Guardando datos...', this.Acciones);
    console.log('pagar', dato, nuevos)
    if (nuevos.Respuesta!=='Ok'){
      
      confirmAlert({
        title: 'Error-' + nuevos.code,
        message: 'Los datos no fueron guardados',
        buttons: [{label: 'OK'}]
      });
    }
    this.setState({Esperar: {esperar:false}, open:false})

  }

  Acciones = (posicion) =>{
    this.setState({Esperar:{...this.state.Esperar, progreso: posicion}})
  }

  Cancelar = ()=>{
    this.setState({open:false})
  }
  Dialogo = async(dato)=>{
    const cedula= dato.cedula
    const {select_mes,ano} = this.state
    let resultados= await conexiones.Leer_C(['Data_personal'], 
      {'Data_personal':{cedula},}
    );
    
    dato={...dato, 
      cuentas:resultados.datos.Data_personal[0].cuentas,
      mes:`${moment(select_mes).format('MM')}-${ano}`
    }
    console.log(dato)
    this.setState({open:true, dato})
  }
  
  async componentDidMount(){
    // let mes = dia.getMonth()+1
    // mes= mes<10 ? '0'+mes : ''+mes
    // const select_mes = this.state.meses.filter(f=>f._id===mes)[0]
    // this.Select_mes(select_mes)
    this.Select_mes(this.state.fecha)
    this.setState({open:false})
  }

  Select_mes = async(nuevo) =>{
    console.log(nuevo)
    
    // const dia = new Date()
    // let ano = dia.getFullYear()
    // console.log(ano)
    // const mes= nuevo._id
    let ano=moment(nuevo).format('YYYY');
    let resultados= await conexiones.Leer_C(['Data_nomina'], 
      {'Data_nomina':{mes:moment(nuevo).format('MM'), ano: moment(nuevo).format('YYYY')},}
    );
    this.setState({fecha:nuevo,select_mes:nuevo, ano, datos:resultados.datos.Data_nomina, cantidad:resultados.datos.Data_nomina.length})
  }


  Refrescar = ()=>{
    this.setState({datos:[], cantidad:-1})
  }

  Actualizar_data=(data)=>{
    this.setState({datos:data.datos, cantidad:data.cantidad})
  }

  
  componentWillUnmount(){
     
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        // const socket = props.socket
        // state.Resumen_perfil(socket)
        
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }
  
  Fecha = (fecha) =>{
    
    // fecha= fecha.split('-')
    // fecha = fecha[1]+'-'+fecha[2]+'-'+fecha[0]
    fecha= new Date(fecha)
    fecha.setDate(fecha.getDate()+1)
    
    return(
      <DatePicker
        style={{marginLeft:10}}
        selected={fecha}
        locale="es"
        customInput={<CalendarTodayIcon />}
        onChange={this.Select_mes} 
        dateFormat="MM/yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
        withPortal
      ></DatePicker>
    )
  }

  render(){
    
    return (
      <Page {...this.state} Fecha={this.Fecha(this.state.fecha)}/>
    )
  }
}