import crypto from 'crypto';


// const key='CHS_W3S1P4Y_InG.Y0Fr3dD';
const key='CHS_INV3NT4R10_InG.Y0Fr3dD';

export const encriptado = {
  Hash_texto,
  Hash_password
};

async function Hash_texto(texto) {
  const hash = crypto.createHash('sha512');
  await hash.update(key + texto);
  const resultado = await hash.digest('hex');
  return resultado;

}

async function Hash_password(texto) {
  const hash = crypto.createHash('sha256');
  await hash.update(key + texto);
  const resultado = await hash.digest('hex');
  return resultado;

}
