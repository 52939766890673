import React, {Component} from 'react';
import Esperar from '../esperar';
import Pagee from '../conexiones';
import {ItemTituloSupervisor, ItemSupervisor} from '../conexiones/item';

//Asignar operadores a supervisores
export default class Supervisor extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{Libres:[],Grupos:[]},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          Iniciar: this.Cancelar,
          Actualizar:this.Actualizar
      }
  }
  

  Cancelar = async()=>{
   this.Iniciar(this.state.props.rdatos)
  }

  Iniciar= async(valores) =>{
    
    let datos = {
      Grupos:[],
      Libres:[]
    }
    if (valores.supervisor_vs_operador){
      datos.Grupos=valores.supervisor_vs_operador.Grupos.map(g=>{
        let childen = g.childen.map(h=>{
          return {...h, titulo:<ItemSupervisor op={{...h}}/>}
        })
        return {...g, childen, titulo: <ItemTituloSupervisor sup={{...g, operadores:g.childen}}/>}
      })
      datos.Libres=valores.supervisor_vs_operador.Libres.map(val=>{
        
        return {...val, key:val._id, titulo:<ItemSupervisor op={{...val}}/>}
      })
    }else if (valores.asignados){
      valores.asignados.Grupos.map(g=>{
        g.childen.map(hijo=>{
          if (g.key==='supervisores'){
            datos.Grupos.push(
              {...hijo, key:hijo._id,
                titulo:<ItemTituloSupervisor sup={hijo}/>,
                childen:[]
              }
            )
          }else{
            datos.Libres.push(
              {...hijo, key:hijo._id,
                titulo:<ItemSupervisor op={hijo}/>,
              }
            )
          }
          return hijo
        })
        return g
      })
      
    }
    
    this.setState({datos, TituloL:'Operadores', FiltrarL:'turno',  TituloG:'Supervisores', })
    this.Actualizar(datos)
  }
  Buscar_operador =(datos,operador)=>{
    let pos=-1
    datos.map(val=>{
      if (pos===-1)
        pos= val.operadores.findIndex((v)=> v._id===operador._id)
      return val
    })
    return pos
  }
  Actualizar = (datos) =>{
    datos.Libres.map(l=>{
      l={...l, titulo:<ItemSupervisor op={{...l, turno:l.turno}}/>}
      return l
    })
    datos.Grupos=datos.Grupos.map(g=>{
      
      // g.childen = g.childen.filter(o=>{ 
      //   if (o.turno===g.turno){
      //     return o
      //   }else{
      //     datos.Libres.push(o)
      //   }
        
      // })
      let childen =[]
      g.childen.map(o=>{ 
        if (o.turno===g.turno){
          childen.push(o)
        }else{
          datos.Libres.push(o)
        }
        return o
      })
      g.childen=childen
      
      return {...g, operadores:g.childen, titulo: <ItemTituloSupervisor sup={{...g,operadores:g.childen}}/>}
    })
    this.setState({datos})
    if (this.state.props.Actualizar){
      this.state.props.Actualizar({supervisor_vs_operador:datos})
    }
  }
  async componentDidMount(){
    this.Iniciar(this.props.datos)
    // let resultados= await conexiones.Leer_C(['Data_personal'], 
    //   {['Data_personal']:{$and:[ {categoria:{$ne:0}} , {categoria:{$ne:5}}]},}
    //   );
    // console.log(resultados)
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  render(){
    return this.state.Esperar.esperar ? 
      <Esperar {...this.state.Esperar}/> :
      <div>
        <Pagee  {...this.state}/>  
      </div>
    
  }
}