import React from 'react';
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';

//Constantes
import {Valord} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;
const categoria_usuario= config.categoria_usuario;

const useStyles = makeStyles((theme) => ({
    titulo_primary:{
      color:config.Estilos.barra.backgroundColor,
      marginBottom:-3
    },
    subtitulo:{
        backgroundColor:config.Estilos.barra.backgroundColor,
        borderTop:5, borderTopLeftRadius:5,
        borderTopRightRadius:5
    },
    subtitulob:{
      backgroundColor:config.Estilos.barra_menu.backgroundColor,
      border: `1px solid ${theme.palette.background.paper}`,
      borderTopLeftRadius:5,
      borderTopRightRadius:5
  },
    subtitulo_primary:{
        color:'#ffffff',
        fontWeight:'both',
        marginBottom:-3
    },
    subtitulo_secondary:{
        color:'#ffab00',
        marginBottom:-5
    },
    subtitulo_secondary_:{
      color:'#ffab00',
      marginTop:-15,
      marginBottom:-5
    },
    titulo_secondary:{
      color:config.Estilos.barra_menu.backgroundColor,
      marginBottom:-3
    },
    titulo_suplente:{
      color:'red',
      marginBottom:-3
    },
    numsupervisor:{
      color: 'red'
    },
    numoperador:{
      color:'#ffab00',
    }
}));

const theme = createMuiTheme({
  palette: {
    error: {
      main: '#b71c1c',
    },
    primary: {
      main: '#1b5e20',
    },
    secondary: {
      main: '#ffeb3b',
    },
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 0,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    
  },
}))(Badge);

export const ItemTituloTurnos = (props) =>{
    const {p}=props
    const classes = useStyles();
    return (
        <ListSubheader disableGutters title={`${p.turno} ${p.pais} (${p.cantidad})`}>
            <ListItem className={classes.subtitulo}>
                <ListItemText  
                primary={
                    <div>
                        <Typography variant="subtitle1" gutterBottom noWrap className={classes.subtitulo_primary}>
                            {`${p.turno}`}  
                        </Typography>
                        <Typography variant="caption" gutterBottom component="h2" noWrap className={classes.subtitulo_secondary}>
                          {`${p.pais} (${p.cantidad})`}
                        </Typography>
                    </div>
                } 
                />
                
            </ListItem>
        </ListSubheader>
    )
}

export const ItemTurnos = (props)=>{
    const {p}=props
    const classes = useStyles();
    
    return categoria_usuario[p.categoria] === undefined ? null :(
        <ListItem key={'list-'+p._id} 
          button
          title={`${p.nombres} ${p.apellidos}`}
        >
          <ListItemAvatar>
            <Avatar
              alt={`${p.nombres}`}
              src={`${p.foto}`}
            />
          </ListItemAvatar>
          <ListItemText 
            primary={ 
              <div>
                <Typography variant="h6" gutterBottom noWrap className={classes.titulo_primary}>
                  {`${p.nombres}`} 
                </Typography>
                <Typography variant="caption" gutterBottom className={classes.titulo_secondary}>
                  {`${categoria_usuario[p.categoria].titulo}`}
                </Typography>
              </div>
            }
          />

        </ListItem>
    )

}

export const ItemTituloSupervisor = (props)=>{
  const {sup}=props
  const classes = useStyles();
  const titl=`${categoria_usuario[sup.categoria].titulo} ${sup.turno} \n`
  let title=''
  if (sup.childen!==undefined && sup.childen.length!==0){ 
    title=`Perfiles asignados: (${sup.childen.length})\n`
    sup.childen.map(o=> title+= `${o['first-name']}\n`) 
  } else if (sup.operadores!==undefined && sup.operadores.length!==0){
    title=`Operadores asignados: (${sup.operadores.length})\n`
    sup.operadores.map(o=> title+=`${o['nombres']} ${o['apellidos']} ${o.turno}\n`) 
  }else title=''
  const pos=  sup.turno.indexOf('Break')
  return (
    <ListSubheader disableGutters title={titl + title} className={pos===-1 ? classes.subtitulo :classes.subtitulob}>
      <ListItem className={pos===-1 ? classes.subtitulo :classes.subtitulob}>
        <ListItemAvatar>
          <StyledBadge badgeContent={ sup.cantidad ===undefined ? 0 : sup.cantidad}  
              color={'primary'}
          >
            <Avatar
              alt={`${sup.nombres}`}
              src={`${sup.foto}`}
            />
          </StyledBadge>
        </ListItemAvatar>
        <ListItemText  
            primary= {
              <div>
                <Typography variant="h6" gutterBottom noWrap className={classes.subtitulo_primary}>
                  {`${sup.nombres} `}
                </Typography>
                <Typography variant="caption" gutterBottom component="h2" noWrap className={classes.subtitulo_secondary}>
                  {`${categoria_usuario[sup.categoria].titulo} ${sup.turno} (${sup.operadores.length})`}
                </Typography>
              </div>
            }  
        />
        
      </ListItem>
    </ListSubheader>
  )

} 

export const ItemSupervisor = (props)=>{
  const {op}=props
  const classes = useStyles();
  return(
    <ListItem key={op._id} 
              button
              title={`${op.nombres} ${op.apellidos} \n ${op.turno}`}
    >
      <ListItemAvatar>
          <Avatar
            alt={`${op.nombres}`}
            src={`${op.foto}`}
          />
      </ListItemAvatar>
      <ListItemText  
          primary= {
            <div>
              <Typography variant="h6" gutterBottom noWrap className={classes.titulo_primary}>
                {`${op.nombres} `}
              </Typography>
              <Typography variant="caption" gutterBottom  component="h2" noWrap className={classes.titulo_secondary}>
                {`${op.categoria!==null ? categoria_usuario[op.categoria].titulo : ''} ${op.turno}`}
              </Typography>
            </div>
          }  
      />

    </ListItem>
  )
}

export const ItemTituloSupervisorAsignar = (props)=>{
  const {sup}=props
  const classes = useStyles();
  return (
    <ListSubheader disableGutters className={classes.subtitulo}>
      <ListItem className={classes.subtitulo}>
        <ListItemText  
            primary= {
              <div>
                <Typography variant="h6" gutterBottom noWrap className={classes.subtitulo_primary}>
                  {`${sup.titulo} `}
                </Typography>
                
              </div>
            }  
        />
        
      </ListItem>
    </ListSubheader>
  )

} 

export const ItemSupervisorAsignar = (props)=>{
  const {op}=props
  const classes = useStyles();
  return(
    <ListItem key={op._id} 
              button
              title={`${op.nombres} ${op.apellidos} \n ${op.turno}`}
    >
      <ListItemAvatar>
          <Avatar
            alt={`${op.nombres}`}
            src={`${op.foto}`}
          />
      </ListItemAvatar>
      <ListItemText  
          primary= {
            <div>
              <Typography variant="h6" gutterBottom noWrap className={classes.titulo_primary}>
                {`${op.nombres} `}
              </Typography>
              <Typography variant="caption" gutterBottom  component="h2" noWrap className={classes.titulo_secondary}>
                {`${categoria_usuario[op.categoria].titulo} ${op.turno}`}
              </Typography>
            </div>
          }  
      />

    </ListItem>
  )
}

export const ItemPerfiles = (props) =>{
  let {op}=props
  const classes = useStyles();
  op.operadores= op.operadores===undefined ? [] : op.operadores
  let title='Personal y turno asignado: \n'
  if (op.operadores!==undefined && op.operadores.length!==0) 
      op.operadores.map(o=> title+=`${o.nombres} ${o.turno} ${o.suplente} \n`) 
                
  return(
    <ListItem key={'list-'+op['user-id']} 
              button
              title={title}
    >
      <ListItemAvatar >
        <ThemeProvider theme={theme}>
          <StyledBadge badgeContent={op.perfil ? op.operadores ===undefined || op.operadores.length === 0 ? '' : op.operadores.length : 0}  
            color={op.operadores ===undefined || op.operadores.length === 0 ? 'error': op.operadores.length === 1 ? 'secondary' : 'primary'}
          >
            <Avatar
              alt={`${op['first-name']}`}
              src={`https://api7.amolatina.com/users/${op['user-id']}/photos/${op.thumbnail}.490x480.thumb-fd`}
            />
          </StyledBadge>
        </ThemeProvider>
      </ListItemAvatar>
      <ListItemText 
        primary={ 
          <div>
            <Typography variant="h6" gutterBottom noWrap className={classes.titulo_primary}>
              {`${op['first-name']}`} 
            </Typography>

            {!op.perfil ? 
              <Typography variant="caption" gutterBottom className={classes.titulo_secondary}>
                { `Perfil`}
              </Typography>:
                op.operadores!==undefined && op.operadores.length!==0 ?
                  op.operadores.map(o=>
                    <Typography key={o._id} variant="caption" gutterBottom component="h2" noWrap className={o.suplente === '' ? classes.titulo_secondary : classes.titulo_suplente}>
                      {`${o.nombres} ${o.turno} `  }
                    </Typography>
                  ) 
                  :null
            }
            
          </div>
        }
      />

    </ListItem>
  )
}

export const ItemDias = (props)=>{
  const {dato}=props
  const classes = useStyles();
  const titl=`${dato.dia_d} ${dato.dia} \n`
  let title=''
  let supervisor=0
  let operador=0
  if (dato.childen!==undefined && dato.childen.length!==0){ 
    title=`Personal: (${dato.childen.length})\n`
    dato.childen.map(o=> {
      title+= `${o['nombres']} ${o['apellidos']}\n`
      supervisor = Number(o.categoria)===3 ? supervisor+1 : supervisor
      operador = Number(o.categoria)===4 ? operador+1 : operador
      return o
    }) 
  }
  return(
    <ListSubheader title={titl + title} disableGutters>
      <ListItem className={classes.subtitulo}>
        <ListItemText  
          primary={
            <div>
              <Typography variant="subtitle1" gutterBottom noWrap className={classes.subtitulo_primary}>
                {`${dato.dia_d} ${dato.dia}`}  
              </Typography>
              <Typography variant="caption" gutterBottom noWrap className={classes.subtitulo_primary}>
                <div className={classes.numsupervisor}>{`Supervisores: (${supervisor})`}</div>
                <div className={classes.numoperador}>{`Operadores: (${operador})`}</div>
              </Typography>
             
            </div>
          } 
        />
        
      </ListItem>
    </ListSubheader>
  ) 
}

export const ItemDiasGrupo = (props) =>{
  const {op}= props
  const classes = useStyles();
  return (
    <ListItem key={op._id} 
      button
    >
      <ListItemAvatar>
        <Avatar
          className={classes.Avatar}  
          alt={`${op.nombres}`}
          src={`${op.foto}`}
        />
      </ListItemAvatar>
      <ListItemText  
        primary={
          <div>
            <Typography variant="subtitle2" gutterBottom noWrap className={classes.titulo_primary}>
              {`${op.nombres}`} 
            </Typography>
            <Typography variant="caption" gutterBottom component="h2" noWrap className={classes.titulo_secondary}>
              {`${categoria_usuario[op.categoria].titulo} ${op.turno}`}
            </Typography>
            <Typography variant="caption"  className={classes.titulo_secondary}>
              {`${op.dias!==undefined ? op.dias : ''}`}
            </Typography>
          </div>
        }  
      />

    </ListItem>
  )
}