import React from 'react';
import { makeStyles, createMuiTheme, ThemeProvider, } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Scrollbars } from 'rc-scrollbars';


//Constantes
import { Valord} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;
// let User = JSON.parse(localStorage.getItem(const_procesos.dir_user));

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const theme_lista = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiListItemIcon:{
          root:{
              color:'white',
          }
      },
      MuiListItemText: {
        // Name of the rule
        primary: {
          // Some CSS
          color: 'white',
          fontSize:13
        },
      },
      MuiListItem:{
        "root": {
            "&$selected": {
              ...config.Estilos.barra
            }
        }
      }
    },
});

export default function Menus(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState({});
  const [selectedIndex, setSelectedIndex] = React.useState(props.selected);

  const handleListItemClick = (event, index,) => {
    open[index]=!open[index]
    setOpen(open);
    setSelectedIndex(index);
    if (props.seleccion){
        props.seleccion(index)
    }
  };

  const render = (lista) =>{
    return (
      
        lista.map((valor,index)=>
            <div key={'div-'+index}>
                <ListItem
                    key={valor.primary+index}
                    button
                    selected={selectedIndex === valor.primary}
                    onClick={(event) => handleListItemClick(event, valor.primary)}
                >
                    <ListItemIcon>
                        {valor.icon}
                    </ListItemIcon>
                    <ListItemText  {...valor} className={classes.item}/>
                    {valor.childen ?  (open[valor.primary] ? <ExpandLess style={{color:'#ffffff'}}/> : <ExpandMore style={{color:'#ffffff'}}/>) : null}
                </ListItem>
                {valor.childen ?(
                    <Collapse key={'collapse-'+index+valor.primary} in={open[valor.primary]} timeout="auto" unmountOnExit>
                        <List component="div" style={{paddingLeft:10}}>
                            {render(valor.childen)}
                        </List>
                    </Collapse>
                ): null}
            </div>
        )
    )
  }
  return (
    <ThemeProvider theme={theme_lista}>
        <List component="nav" aria-labelledby="menus" >
          <Scrollbars style={{height:window.innerHeight * 0.90}}>
            {render(props.listado)}
          </Scrollbars>
        </List>
    </ThemeProvider>
  );
}