import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import moment from "moment";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

//Componentes propios
import Tabla from '../tabla';
import Formulario from '../../components/formulario';
import Cuerpo from '../../components/formulario/cuerpo';
import Multiples from '../../components/multiples';
import Movimientos from '../../components/conexiones';
import Turnos from '../../components/turnos';
import Supervisores from '../../components/supervisor';
import Operadores from '../../components/operadores';
import Planificar from '../../components/planificar';
import PlanificarN from '../../components/planificar/index_n';
import PlanificarD from '../../components/planificar/index_d';
import Clientes from '../../components/real_amolatina/clientes';
import ToOperador from '../../components/real_amolatina/to_operador';
import Meta from '../../components/real_amolatina/metas';
import TopAgencia from '../../components/real_amolatina/top_agencia';
import TopServicio from '../../components/real_amolatina/top_servicios';
import PuntosP from '../../components/real_amolatina/puntos_perfiles';
import PuntosPP from '../../components/real_amolatina/puntos_por_perfil';
import PuntosPM from '../../components/real_amolatina/puntos_perfiles_mes';
import PuntosA from '../../components/real_amolatina/puntos_agencias';
import PuntosAM from '../../components/real_amolatina/puntos_agencias_mes';
import Actualizar from '../../components/real_amolatina/actualizar_datos';
import GraficaResumen from '../../components/real_amolatina/Grafica_resumen_agencia';
import GeneralPerfil from '../../components/real_amolatina/general_perfil'
import GeneralPersonal from '../../components/real_amolatina/general_personal'
import Midgles from '../../components/real_amolatina/midgles'
import Presentes from '../../components/usuario/presentes'

import Nomina from '../../components/nomina'


//Iconoos
import AddIcon from '@material-ui/icons/AddCircle';
import CheckIcon from '@material-ui/icons/Check';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';

//Procesos
import {Permiso} from '../../procesos/servicios/funciones';
//Constantes
import {const_procesos, Valord} from '../../constantes';



let config = Valord //JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

const categoria_usuario= config.categoria_usuario;
let User = JSON.parse(localStorage.getItem(const_procesos.dir_user));

const theme = createMuiTheme({
  palette: {
    error: {
      main: '#b71c1c',
    },
    primary: {
      main: '#1b5e20',
    },
    secondary: {
      main: '#ffeb3b',
    },
  },
});


function Bloque_Configuracion_sistema(datos,funciones){
    let bloques=
      <Box p={1} 
         component="div"
         my={1}
         whiteSpace="normal"
         textOverflow="ellipsis"
         overflow="hidden"
         
      >
        <Typography variant="h4" gutterBottom>
            {datos.Titulo ? datos.Titulo :"CONFIGURACION SISTEMA"}
        </Typography>
        EN CONSTRUCCION
      </Box>;
    return bloques;
  
}

function Bloque_Configuracion_usuarios(datos,funciones){
    const tabla='User_api'
    const Titulo=datos.Titulo ? datos.Titulo :"CONFIGURACION USUARIOS"
    const Titulo_tabla='Usuarios'
    const Datos_tabla={
      Dialogo: Titulo,
      Titulo_tabla,
      tabla,
      Titulos :[
        {title:'Usuario',field:'username'},{title:'Cedula',field:'cedula'},
        {title:'Nombres',field:'nombres'},{title:'Correo',field:'correo'},
        {title:'Categoria',field:'categoria', tipo:'lista', lista:categoria_usuario}
      ],
      Datos: datos.datos !== undefined && datos.datos[tabla] ? datos.datos[tabla] : [],
      Cantidad: datos.datos !== undefined && datos.datos[tabla+'_cantidad'] ? datos.datos[tabla+'_cantidad'] : null,
      Acciones: 
        <div>
          <IconButton color={'primary'} title={'Refrescar valores de '+Titulo_tabla} onClick={()=>funciones.Refrescar(Titulo)}>
            <AutorenewIcon />
          </IconButton>
          <IconButton color={'primary'} title={'Agregrar nuevo Usuario'} onClick={()=>funciones.Agregar(tabla)}>
            <AddIcon />
          </IconButton>
        </div>,
      funciones
    }
   
    let bloques=
      <Box p={1} 
         component="div"
         whiteSpace="normal"
         textOverflow="ellipsis"
      >
        <Typography variant="h4" gutterBottom>
            {Titulo}
        </Typography>
        {General_tabla( Datos_tabla)}  
      </Box>;
    return bloques;
  
}

function Bloque_amolatina(datos,funciones){
  
  const tabla_perfiles='Data_perfil_amolatina'
  const Datos_tabla_perfiles={
    Dialogo: 'Perfiles',
    Titulo_tabla: 'Perfiles',
    tabla:tabla_perfiles,
    Titulos :[
      {title:'user-id',field:'user-id'},{title:'parent-id',field:'parent-id'},
      {title:'thumbnail',field:'thumbnail'},{title:'first-name',field:'first-name'},
      {title:'last-login',field:'last-login', formato: (dato)=> moment(dato).format('DD/MM/YYYY hh:mm:ss')}
    ],
    Datos: datos.datos !== undefined && datos.datos[tabla_perfiles] ? datos.datos[tabla_perfiles] : [],
    Cantidad: datos.datos !== undefined && datos.datos[tabla_perfiles+'_cantidad'] ? datos.datos[tabla_perfiles+'_cantidad'] : null,
    Acciones: 
        <div>
          <IconButton color={'primary'} title={'Refrescar valores'} onClick={()=>funciones.Refrescar(datos.Titulo)}>
            <AutorenewIcon />
          </IconButton>
        </div>,
    funciones,
    ordenar: (valores)=> {
      const campo = 'last-login'
      const result=valores.sort((a, b) => (a[campo] >b[campo] ? 1 : a[campo] < b[campo] ? -1 : 0))
      return result
    }
  }
  const tabla_bono='Data_bono'
  const Datos_tabla_bonos={
    Dialogo: 'Bonos',
    Titulo_tabla: 'Bonos',
    tabla:tabla_bono,
    Titulos :[
      {title:'timestamp',field:'timestamp', formato: (dato)=> moment(dato).format('DD/MM/YYYY hh:mm:ss')},
      {title:'curator-id',field:'curator-id'},{title:'user-id',field:'user-id'},
      {title:'target-id',field:'target-id'},{title:'service',field:'service'}, 
      {title:'share',field:'share'},
      {title:'commission-id',field:'commission-id'},
      {title:'fact',field:'fact'},
      {title:'points',field:'points'}, {title:'profit',field:'profit'}, 
      {title:'agency-id',field:'agency-id'},
    ],
    Datos: datos.datos !== undefined && datos.datos[tabla_bono] ? datos.datos[tabla_bono] : [],
    Cantidad: datos.datos !== undefined && datos.datos[tabla_bono+'_cantidad'] ? datos.datos[tabla_bono+'_cantidad'] : null,
    funciones,
    ordenar: (valores)=> {
      const campo = 'timestamp'
      const result=valores.sort((a, b) => (a[campo] < b[campo] ? 1 : a[campo] > b[campo] ? -1 : 0))
      return result
    }
  }
  const tabla_perdidas='Data_perdida'
  const Datos_tabla_perdidas={
    Dialogo: 'Perdidas',
    Titulo_tabla: 'Perdidas',
    tabla:tabla_perdidas,
    Titulos :[
      {title:'timestamp',field:'timestamp', formato: (dato)=> moment(dato).format('DD/MM/YYYY hh:mm:ss')},
      {title:'commission-id',field:'commission-id'},{title:'agency-id',field:'agency-id'},
      {title:'curator-id',field:'curator-id'},{title:'fact',field:'fact'},
      {title:'points',field:'points'}, {title:'profit',field:'profit'},
      {title:'service',field:'service'}, {title:'share',field:'share'},
      {title:'target-id',field:'target-id'}, 
      {title:'user-id',field:'user-id'}
    ],
    Datos: datos.datos !== undefined && datos.datos[tabla_perdidas] ? datos.datos[tabla_perdidas] : [],
    Cantidad: datos.datos !== undefined && datos.datos[tabla_perdidas+'_cantidad'] ? datos.datos[tabla_perdidas+'_cantidad'] : null,
    funciones,
    ordenar: (valores)=> {
      const campo = 'timestamp'
      const result=valores.sort((a, b) => (a[campo] < b[campo] ? 1 : a[campo] > b[campo] ? -1 : 0))
      return result
    }
  }
  let elementos={
    'Perfiles':General_tabla(Datos_tabla_perfiles),
    'Bonos':General_tabla(Datos_tabla_bonos),
    'Perdidas':General_tabla(Datos_tabla_perdidas),
  }
  let cuerpo={
    submenu:Object.keys(elementos),
    bloques:elementos,
  }
  let bloques=
    <Cuerpo {...cuerpo} />
  return bloques;

}

function Bloque_resumenes(datos,funciones, classes={}, socket=null){
  
  let bloques=
    <div className={classes.resumenes} >
    <Grid container spacing={1} justify="center" alignItems="center" direction="row">
        <Grid container item spacing={1}>
            <Grid item xs={2}>
              <Paper className={classes.papers}>
                <Clientes socket={socket}/>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.papers}>
                <Meta socket={socket}/>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.papers}>
                <ToOperador socket={socket}/>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.papers}>
                <TopAgencia socket={socket}/>
              </Paper>
            </Grid>
            <Grid container item spacing={1}  xs={3}> 
            
              <Grid item xs>
              {['3','4'].indexOf(User.categoria)===-1 ? 
                <Paper className={classes.paper_agencia}>
                  <PuntosA socket={socket}/>
                  <PuntosAM/>
                </Paper>
                :
                <Paper className={classes.paper_agencia}>
                  <TopServicio socket={socket} alto={260}/>
                </Paper>
              }
              </Grid> 
              
              
            </Grid>
            
        </Grid>
        <Grid container item spacing={1}>
          {['3','4'].indexOf(User.categoria)===-1 ?
            <Grid item xs={2}>
              <Paper className={classes.paper}>
                <TopServicio socket={socket}/>
              </Paper>
            </Grid>
            : null
          }
            <Grid item xs={3}>
              <Paper className={classes.paper}>
                <Midgles/>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>
                <PuntosP socket={socket}/>
              </Paper>
            </Grid>
            <Grid item xs={['3','4'].indexOf(User.categoria)===-1 ? 2 : 3}> 
              <Paper className={classes.paper}>
                <PuntosPP socket={socket}/>
              </Paper>
            </Grid>
            <Grid item xs= {['3','4'].indexOf(User.categoria)===-1 ? 2 : 3}>
              <Paper className={classes.paper}>
                <PuntosPM />
              </Paper>
            </Grid>
        </Grid>
        
        {['3','4'].indexOf(User.categoria)===-1 ? 
          <Grid container item spacing={1}>
              <Grid item xs={12}>
                <Paper className={classes.paper_agencia1}>
                  <GraficaResumen />
                </Paper>
              </Grid>
              
          </Grid> : null
        }
        {['3','4'].indexOf(User.categoria)!==-1 ? 
          <Grid container item spacing={1}>
            <Grid item xs={12}>
              <Paper className={classes.paper_agencia_o}>
                <PlanificarD socket={socket}/>
              </Paper>
            </Grid> 
          </Grid>: null
        }
        
    </Grid>
    </div>
    return bloques;
  
}

function Bloque_pruebas(datos,funciones, classes={}, socket=null){
  let bloques=
    <div className={classes.resumenes} >
    <Grid container spacing={2} justify="center" alignItems="center" direction="row">
        <Grid container item spacing={2}>
            <Grid item xs>
              <Paper className={classes.paper}>
              <Actualizar></Actualizar>
              </Paper>
            </Grid>
            <Grid item xs> 
              <Paper className={classes.paper}>
                <Presentes/>
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paper}>
                
              </Paper>
            </Grid>
            
        </Grid>
        <Grid container item spacing={2}>
            
            <Grid item xs>
              <Paper className={classes.paper}>
                
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paper}>

              </Paper>
            </Grid>
            <Grid item xs><Paper className={classes.paper}></Paper></Grid>
        </Grid>
        <Grid container item spacing={2}>
            <Grid item xs>
              <Paper className={classes.paper}>
                <GraficaResumen />
              </Paper>
            </Grid>
            <Grid item xs><Paper >
              </Paper></Grid>
        </Grid>
        
    </Grid>
    </div>
    return bloques;
  
}

//Bloques de registros
//Bloques Usuarios ver si se deja o se fuciona con bloques personal
function Bloque_usuarios(datos,funciones, classes={}, socket=null){
  const tabla='User_api'
  const Titulo_tabla=datos.Titulo;
  const Datos_tabla={
    Dialogo:Titulo_tabla,
    Titulo_tabla,
    tabla,
    Titulos :[
      {title:'Avatar',field:'filename', formato : (dato)=> 
        Lista_avatar({nombre:'', imagen:dato['foto']?dato['foto']:'', codigo:'', classes, local:true})  
      },
      {title:'Usuario',field:'username'},
      {title:'Nombres',field:'nombres'},
      {title:'Categoria',field:'categoria', tipo:'lista', lista:categoria_usuario}
    ],
    Datos: datos.datos,
    Cantidad: datos.cantidad,
    Acciones: 
      <div>
        <IconButton color={'primary'} title={'Refrescar valores de '+Titulo_tabla} onClick={()=>funciones.Refrescar(Titulo_tabla)}>
          <AutorenewIcon />
        </IconButton>
        <IconButton color={'primary'} title={'Agregrar nuevo Usuario'} onClick={()=>funciones.Agregar(Titulo_tabla)}>
          <AddIcon />
        </IconButton>
      </div>,
    funciones
  }
  
  let categoria=categoria_usuario.filter(val=> Number(val._id)===Number(datos.dato.categoria))[0];
  
  categoria = categoria===undefined ? categoria_usuario[2] : categoria;
  
  let titulos={
    foto:{
      label: 'Foto',
      helperText:'',
      value:datos.dato.filename ? datos.dato.filename[0] : '',
      tipo:'avatar'
    },
    personal:{
      multiples:true,
      value:{
              username:{
                label: 'Usuario', helperText:'',
                value:datos.dato.username, disabled:datos.dato.username===undefined ? false: true,
                required:true,
                mensaje_error:'Debe indicar usuario',
              },
              nombres:{
                label: 'Nombres', helperText:'',
                value:datos.dato.nombres, 
                mensaje_error:'Indique nombre y apellido',
              },
      }
    },
    seguridad:{
      multiples:true,
      value:{
              npassword:{
                label: 'Nueva Contraseña', helperText:'',
                value:'', tipo:'password',
                comparar:'true', con:'confpassword',
                mensaje_error:'Contraseñas no son iguales',
                required: datos.dato.username===undefined ? true : false,
              },
              confpassword:{
                label: 'Confirmar Contraseña', helperText:'',
                value:'', tipo:'password',
                comparar:'true', con:'npassword',
                mensaje_error:'Contraseña no son iguales',
              },
      }
    },
    categoria:{
      label: 'Categoria',
      helperText:'',
      tipo:'select',
      lista: Permiso('',true) ? categoria_usuario : categoria_usuario.filter(val=> val._id!==0),
      value:categoria ? categoria : categoria_usuario[2],
      getOptionLabel: (option)=> `${option.titulo}`,
      getOptionSelected: (option)=> option.titulo,
    },

  }
  let botones=[
    {
      name:'guardar', label:'Guardar', title:'Guardar usuario',
      variant:"contained", color:"primary", icono:<CheckIcon/>,
      onClick: funciones.Guardar(datos.Titulo, tabla), validar:'true',
      disabled: !Permiso('guardar'),
    },
    {
      name:'eliminar', label:'Eliminar', title:'Eliminar usuario',
      variant:"contained", color:"secondary", icono:<DeleteIcon/>,
      onClick:funciones.Eliminar(datos.Titulo, tabla),
      disabled: User._id === datos.dato.username ? true :
                Number(User.categoria._id)===0 && Permiso('',true) ? false : 
                Number(User.categoria._id)!==0 && Permiso('eliminar') ? datos.dato._id===undefined ? true : false: true,
    },
    {
      name:'cancelar', label:'Cancelar', title:'Cancelar',
      variant:"contained", color:"default", icono:<CancelIcon/>,
      onClick:()=>funciones.Cancelar(datos.Titulo)
    },
  ];
  let bloques=
  <div className={classes.resumenes} >
  <Grid container spacing={2} justify="center" alignItems="center" direction="row">
      <Grid item xs={12}>
        <Collapse in={!datos.open}>
          {General_tabla( Datos_tabla)}
        </Collapse>
        <Collapse in={datos.open}>
            <Paper className={classes.paperformulario} elevation={3}>
              <Formulario datos={datos.dato}
                  titulos= {titulos}
                  botones= {botones}
              />
            </Paper>
        </Collapse>
      </Grid>
  </Grid>
  </div>
  return bloques;

}
//Bloque agencia
function Bloque_agencia(datos,funciones, classes={}, socket=null){
  const tabla='Data_agencia'
  const Titulo_tabla=datos.Titulo;
  const Datos_tabla={
    Dialogo:Titulo_tabla,
    Titulo_tabla,
    tabla,
    Titulos :[
      {title:'Agencia',field:'agency-id', formato: (dato)=> 
        Lista_avatar({nombre:`${dato.nombre} ${dato.api}`, imagen:dato['thumbnail'], codigo:dato['first-name'], classes, online:dato.online})
      },
      {title:'Email',field:'email'},
      {title:'Nombre Referencial',field:'nombre'},
    ],
    Datos: datos.datos,
    Cantidad: datos.cantidad,
    Acciones: 
      <div>
        <IconButton color={'primary'} title={'Refrescar valores de '+Titulo_tabla} onClick={()=>funciones.Refrescar(Titulo_tabla)}>
          <AutorenewIcon />
        </IconButton>
        <IconButton color={'primary'} title={'Agregrar nuevo Usuario'} onClick={()=>funciones.Agregar(Titulo_tabla)}>
          <AddIcon />
        </IconButton>
      </div>,
    funciones
  }
  const Lista=[
    {_id:0, titulo:'www.amolatina.com', value:'amolatina'},
    {_id:1, titulo:'www.dating.com', value:'dating'},
    
  ];
  
  let api=Lista.filter(val=> val.value===datos.dato.api)[0];
  let titulos={
    data:{
      multiples:true,
      value:{
        api:{
          label: 'Pagina', helperText:'', 
          required:true,
          title:'Pagina a que pertenece',
          tipo:'select',
          lista: Lista,
          value:api ? api : Lista[0],
          getOptionLabel: (option)=> `${option.titulo}`,
          getOptionSelected: (option)=> option.titulo,
        },
        nombre:{
          label: 'Nombre de referencial', helperText:'',
          value:datos.dato.nombre, 
          required:true,
          title:'Nombre con el que desea ver la agencia, en el sistema',
          mensaje_error:'Debe indicar nombre de referencia de agencia',
        },
      }
    },
    personal:{
      multiples:true,
      value:{
              email:{
                label: 'Email', helperText:'',
                value:datos.dato.email, 
                required:true,
                mensaje_error:'Debe indicar correo de la cuenta en Amolatina',
              },
              password:{
                label: 'Contraseña ', helperText:'',
                value:datos.dato.password, 
                required:true,
                title:'Indique contraseña  de Amolatina',
                mensaje_error:'Indique contraseña  de Amolatina',
                tipo:'password'
              },
      }
    },
    dato_amo:{
      multiples:true,
      value:{
              'user-id':{
                label: 'Codigo de Agencia', value:datos.dato['user-id'], 
                disabled:true
              },
              children:{
                label: 'Perfiles registrados', value:datos.dato.children, 
                disabled:true
              },
      }
    }
  }
  
  let botones=[
    {
      name:'guardar', label:'Guardar', title:'Guardar usuario',
      variant:"contained", color:"primary", icono:<CheckIcon/>,
      onClick: funciones.Guardar_Agencia(datos.Titulo, tabla), validar:'true',
      disabled: [0,1].indexOf(Number(User.categoria._id))!==-1 || [0,1].indexOf(Number(User.categoria))!==-1 ? false : true
    },
    {
      name:'eliminar', label:'Eliminar', title:'Eliminar agencia',
      variant:"contained", color:"secondary", icono:<DeleteIcon/>,
      onClick:funciones.Eliminar(datos.Titulo, tabla),
      disabled: datos.dato._id===undefined ? true :
                [0,1].indexOf(Number(User.categoria._id))!==-1 || [0,1].indexOf(Number(User.categoria))!==-1 ? false : true
    },
    {
      name:'cancelar', label:'Cancelar', title:'Cancelar',
      variant:"contained", color:"default", icono:<CancelIcon/>,
      onClick:()=>funciones.Cancelar(datos.Titulo)
    },
];
  let bloques=
  <div className={classes.resumenes} >
  <Grid container spacing={2} justify="center" alignItems="center" direction="row">
      <Grid item xs={12}>
        <Collapse in={!datos.open}>
          {General_tabla( Datos_tabla)}
        </Collapse>
        <Collapse in={datos.open}>
            <Paper className={classes.paperformulario} elevation={3}>
              <Formulario datos={datos.dato}
                  titulos= {titulos}
                  botones= {botones}
              />
            </Paper>
        </Collapse>
      </Grid>
  </Grid>
  </div>
  return bloques;

}
//Bloques perfiles
function Bloque_perfil(datos,funciones, classes={}, socket=null){
  
  if (datos.datos.length===datos.cantidad){
    datos.datos= datos.datos.filter(v=> !v.suspended)
    datos.cantidad= datos.datos.length
  }
  const tabla='Data_perfil_amolatina'
  const Titulo_tabla=datos.Titulo;
  const Datos_tabla={
    Dialogo:Titulo_tabla,
    Titulo_tabla,
    tabla,
    Titulos :[
      {title:'Agencia',field:'nombre-agency', formato: (dato)=> 
        Lista_avatar({nombre:`${dato['nombre-agency']}`, imagen:dato['imagen-agency'], codigo:dato['parent-id'], classes, online:dato['online-agency']})
      },
      {title:'Perfil',field:'user-id', formato: (dato)=>
        Lista_avatar({nombre:dato['first-name'], imagen:dato['thumbnail'], codigo:dato['user-id'], classes, online:dato.online})
      },
      {title:'Nombre',field:'nombre', formato : (dato) => `${dato.nombre ? dato.nombre : ''} ${dato.apellido ? dato.apellido : ''}`},
      
    ],
    Datos: datos.datos,
    Cantidad: datos.cantidad,
    Acciones: 
      <Grid container spacing={1}>
        <Grid item >
          <IconButton color={'primary'} title={'Refrescar valores de '+Titulo_tabla} onClick={()=>funciones.Refrescar(Titulo_tabla)}>
            <AutorenewIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Autocomplete
              autoComplete
              name={'select_agencia'}
              options={datos.agencias}
              getOptionLabel={(option) => {
                return `${option['nombre']!==undefined ? option['nombre']: ''}`}
              }
              onChange={(event, newValue)=>{
                  funciones.Select_agencia(newValue)
              }}
              value={datos.select_agencia}
              renderInput={(params) => <TextField {...params} label={'Seleccionar Agencia'} variant="outlined" />}
          />
        </Grid>
      </Grid>,
    funciones,
    
  }
  
  let hoy = new Date();
  hoy= `${hoy.getFullYear()}-${hoy.getMonth() +1 < 10 ? '0' : ''}${hoy.getMonth() +1}-${hoy.getDate()<10 ? '0'+hoy.getDate() : hoy.getDate() }`;

  let fechan = datos.dato.fecha_nacimiento && datos.dato.fecha_nacimiento!==undefined ? new Date(datos.dato.fecha_nacimiento) : hoy
  fechan= fechan===hoy ? hoy : `${fechan.getFullYear()}-${fechan.getMonth() +1 < 10 ? '0' : ''}${fechan.getMonth() +1}-${fechan.getDate()<10 ? '0'+(fechan.getDate()+1) : fechan.getDate()+1 }`
  let titulos={
    perfil_amolanina:{
      multiples:true,
      value:{
          'user-id':{ label: 'Id Perfil', value:datos.dato['user-id'], disabled:true},  
          'first-name':{ label: 'Nombre del Perfil', value:datos.dato['first-name'], disabled:true},
          'nombre-agency':{ label: 'Nombre de Agencia', value:datos.dato['nombre-agency'], disabled:true},
      }
    },
    foto:{
      label: 'Foto',
      helperText:'',
      value:datos.dato.foto ? datos.dato.foto : '',
      tipo:'avatar', title:'Foto del personal'
    },
    perfil_personalizados:{
      multiples:true,
      value:{
          cedula:{ label: 'Cedula o Pasaporte', value:datos.dato.cedula, title:'Documento de indentidad'},
          'image-cedula':{
            label: 'Imagen de la cedula', helperText:'',
            value:datos.dato['image-cedula'] ? datos.dato['image-cedula'] : '', 
            tipo:'imagen', title: 'Imagen de cedula o pasaporte'
          },
      }
    },
    perfil_personalizados1:{
      multiples:true,
      value:{
          nombre:{ label: 'Nombres', value:datos.dato.nombre, title:'Nombre'},
          apellido:{ label: 'Apellidos', value:datos.dato.apellido, title:'Apellido'},
          fecha_nacimiento:{ 
            label: 'Fecha de Nacimiento', 
            value:fechan,
            title:'Fecha de Nacimiento',
            type:'date'
          },
      }
    },
    direccion:{
      label: 'Dirección', helperText:'',
      value:datos.dato.direccion, title:'Dirección de habitación',
      multiline:true, rowsMax:3,
    },
    cuenta:{
      label: 'Cuentas', helperText:'',
      value:datos.dato.cuenta, title:'Informacion de las cuentas, banco, pago movil o zelle',
      multiline:true, rowsMax:3,
    },
    video:{
      label: 'Video', helperText:'',
      value:datos.dato.video, 
      tipo:'video'
    },
  }
  let botones=[
    {
      name:'guardar', label:'Guardar', title:'Guardar usuario',
      variant:"contained", color:"primary", icono:<CheckIcon/>,
      onClick: funciones.Guardar(datos.Titulo, tabla), validar:'true',
      disabled: !Permiso('Guardar_perfil'),
    },
    {
      name:'cancelar', label:'Cancelar', title:'Cancelar',
      variant:"contained", color:"default", icono:<CancelIcon/>,
      onClick:()=>funciones.Cancelar(datos.Titulo)
    },
];
  let bloques=
  <div className={classes.resumenes} >
  <Grid container spacing={2} justify="center" alignItems="center" direction="row">
      <Grid item xs={12}>
        <Collapse in={!datos.open}>
          {General_tabla( Datos_tabla)}
        </Collapse>
        <Collapse in={datos.open}>
            <Paper className={classes.paperformulario} elevation={3}>
              <Formulario datos={datos.dato}
                  titulos= {titulos}
                  botones= {botones}
              />
            </Paper>
        </Collapse>
      </Grid>
  </Grid>
  </div>
  return bloques;

}
//Bloque personal
function Bloque_personal(datos,funciones, classes={}, socket=null){
  const tabla='Data_personal'
  const Titulo_tabla=datos.Titulo;
  const Datos_tabla={
    Dialogo:Titulo_tabla,
    Titulo_tabla,
    tabla,
    Titulos :[
      {title:'Foto',field:'foto', formato : (dato)=> 
        Lista_avatar({nombre:'', imagen:dato['foto'], codigo:'', classes, local:true})  
      },
      {title:'Nombre y apellido',field:'nombres', formato: (dato)=> `${dato.nombres} ${dato.apellidos}` },
      {title:'Categoria',field:'categoria', tipo:'lista', lista:categoria_usuario}
    ],
    Datos: datos.datos,
    Cantidad: datos.cantidad,
    Acciones: 
      <div>
        <IconButton color={'primary'} title={'Refrescar valores de '+Titulo_tabla} onClick={()=>funciones.Refrescar(Titulo_tabla)}>
          <AutorenewIcon />
        </IconButton>
        <IconButton color={'primary'} title={'Agregrar nuevo Usuario'} onClick={()=>funciones.Agregar(Titulo_tabla)}>
          <AddIcon />
        </IconButton>
      </div>,
    funciones
  }
  let hoy = new Date();
  hoy= `${hoy.getFullYear()}-${hoy.getMonth() +1 < 10 ? '0' : ''}${hoy.getMonth() +1}-${hoy.getDate()<10 ? '0'+hoy.getDate() : hoy.getDate() }`;
  let categoria=categoria_usuario.filter(val=> Number(val._id)===Number(datos.dato.categoria))[0];
  
  let fechan = datos.dato.fnacimiento && datos.dato.fnacimiento!==undefined ? new Date(datos.dato.fnacimiento) : hoy
  fechan= fechan===hoy ? hoy : `${fechan.getFullYear()}-${fechan.getMonth() +1 < 10 ? '0' : ''}${fechan.getMonth() +1}-${fechan.getDate()<10 ? '0'+(fechan.getDate()+1) : fechan.getDate()+1 }`
  
  categoria = categoria===undefined ? categoria_usuario[2] : categoria;
  const agencias = [0,1].indexOf(Number(User.categoria._id))!==-1 || [0,1].indexOf(Number(User.categoria))!==-1 ?
      datos.agencias.map(a=>{
        return {...a, titulo:a['nombre']}
      }):
      datos.agencias.filter(a=>{

        return {...a, titulo:a['nombre']}
      })
  let titulos={
    foto:{
      label: 'Foto',
      helperText:'',
      value:datos.dato.foto ? datos.dato.foto : '',
      tipo:'avatar', title:'Foto del personal'
    },
    personal:{
      multiples:true,
      value:{
              cedula:{
                label: 'Cedula', helperText:'',
                value:datos.dato.cedula,
                required:true,
                mensaje_error:'Debe indicar cedula del personal',
                title:'Cedula de identidad del personal'
              },
              'image-cedula':{
                label: 'Imagen de la cedula', helperText:'',
                value:datos.dato['image-cedula'] ? datos.dato['image-cedula'] : '', 
                tipo:'imagen'
              },
              
      }
    },
    personal1:{
      multiples:true,
      value:{
              nombres:{
                label: 'Nombres', helperText:'',
                value:datos.dato.nombres, 
                mensaje_error:'Indique nombres',
                required:true,
              },
              apellidos:{
                label: 'Apellidos', helperText:'',
                value:datos.dato.apellidos, 
                mensaje_error:'Indique apellidos',
                required:true,
              },
      }
    },
    contacto:{
      multiples:true,
      value:{
          tipo_sangre:{
            label: 'Tipo de Sangre', helperText:'',
            value:datos.dato.tipo_sangre, 
          },    
          correo:{
            label: 'Correo electronico', helperText:'',
            value:datos.dato.correo, 
          },
          telefonos:{
            label: 'Telefonos , (Celular o Casa)', helperText:'',
            value:datos.dato.telefonos, 
          },
      }
    },
    direccion:{
      label: 'Dirección', helperText:'',
      value:datos.dato.direccion, title:'Dirección de habitación',
      multiline:true, rowsMax:3,
    },
    data:{
      multiples:true,
      value:{
          alergias:{
            label: 'Alergico a', helperText:'',
            value:datos.dato.alergias,
            title:'Si es alergico indicar a lo que es alergico'
          },
          fnacimiento:{
            label: 'Fecha de nacimiento', helperText:'',
            value:fechan,
            type:'date' 
          },
      }
    },
    data1:{
      multiples:true,
      value:{
          contacto:{
            label: 'Contacto Extra', helperText:'',
            value:datos.dato.contacto,
          },
          referido:{
            label: 'Referido por', helperText:'',
            value:datos.dato.referido,
          },
      }
    },
    cuentas:{
      label: 'Cuentas', helperText:'',
      value:datos.dato.cuentas, title:'Informacion de las cuentas, banco o pago movil',
      multiline:true, rowsMax:3,
    },
    salario_fijo:{
      label: 'Salario fijo', helperText:'',
      value:datos.dato.salario_fijo, title:'Salario fijo del personal',
      type:'number'
    },
    categoria:{
      label: 'Categoria',
      helperText:'',
      tipo:'select',
      lista: categoria_usuario.filter(val=> val._id!==0),
      value:categoria ? categoria : categoria_usuario[2],
      getOptionLabel: (option)=> `${option.titulo}`,
      getOptionSelected: (option)=> option.titulo,
    },
    agencias:{
      label: 'Agencias',
      helperText:'',
      tipo:'select',
      multiple:true,
      lista: Permiso('',true) ? agencias : agencias,
      value: datos.dato.agencias ? datos.dato.agencias : [],
      getOptionLabel: (option)=> `${option.titulo}`,
      getOptionSelected: (option)=> option.titulo,
    },


  }
  let botones=[
    {
      name:'guardar', label:'Guardar', title:'Guardar usuario',
      variant:"contained", color:"primary", icono:<CheckIcon/>,
      onClick: funciones.Guardar_Personal(datos.Titulo, tabla), validar:'true',
      disabled: !Permiso('guardar'),
    },
    {
      name:'eliminar', label:'Eliminar', title:'Eliminar personal',
      variant:"contained", color:"secondary", icono:<DeleteIcon/>,
      onClick:funciones.Eliminar(datos.Titulo, tabla),
      disabled: datos.dato._id===undefined ? true :
                Number(User.categoria._id)===0 && Permiso('',true) ? false : 
                Number(User.categoria._id)!==0 && Permiso('eliminar') ? false: true,
    },
    {
      name:'cancelar', label:'Cancelar', title:'Cancelar',
      variant:"contained", color:"default", icono:<CancelIcon/>,
      onClick:()=>funciones.Cancelar(datos.Titulo)
    },
];
  let bloques=
  <div className={classes.resumenes} >
  <Grid container spacing={2} justify="center" alignItems="center" direction="row">
      <Grid item xs={12}>
        <Collapse in={!datos.open}>
          {General_tabla( Datos_tabla)}
        </Collapse>
        <Collapse in={datos.open}>
            <Paper className={classes.paperformulario} elevation={3}>
              <Formulario datos={datos.dato}
                  titulos= {titulos}
                  botones= {botones}
              />
            </Paper>
        </Collapse>
      </Grid>
  </Grid>
  </div>
  return bloques;

}
//Bloque puntos
function Bloque_puntos(datos,funciones, classes={}, socket=null){
  const tabla='Data_punto'
  const Lista=[
    {_id:0, titulo:'Perfil'},
    {_id:1, titulo:'Operador'},
    {_id:2, titulo:'Supervisor'},
  ]
  const Titulo_tabla=datos.Titulo;
  const Datos_tabla={
    Dialogo:Titulo_tabla,
    Titulo_tabla,
    tabla,
    Titulos :[
      {title:'Involucrado',field:'involucrado',  tipo:'lista', lista:Lista},
      {title:'Desde',field:'desde'},
      {title:'Hasta',field:'hasta'},
      {title:'Remuneración',field:'remuneracion'},
      {title:'Etiqueta',field:'etiqueta'},
    ],
    Datos: datos.datos,
    Cantidad: datos.cantidad,
    Acciones: 
      <div>
        <IconButton color={'primary'} title={'Refrescar valores de '+Titulo_tabla} onClick={()=>funciones.Refrescar(Titulo_tabla)}>
          <AutorenewIcon />
        </IconButton>
        <IconButton color={'primary'} title={'Agregrar nuevo Usuario'} onClick={()=>funciones.Agregar(Titulo_tabla)}>
          <AddIcon />
        </IconButton>
      </div>,
    funciones
  }
  
  let involucrado=Lista.filter(val=> Number(val._id)===Number(datos.dato.involucrado))[0];
  let titulos={
    involucrado:{
      label: 'Involucrado', helperText:'', 
      required:true,
      title:'Personal involucrado',
      tipo:'select',
      lista: Lista,
      value:involucrado ? involucrado : Lista[0],
      getOptionLabel: (option)=> `${option.titulo}`,
      getOptionSelected: (option)=> option.titulo,
    },
    puntos:{
      multiples:true,
      value:{
              desde:{
                label: 'Desde', 
                value:datos.dato.desde, 
                required:true,
                type:'number',
                mensaje_error:'Debe indicar desde que puntaje desea asignar',
              },
              hasta:{
                label: 'Hasta ',
                value:datos.dato.hasta, 
                required:true,
                title:'Debe indicar el puntaje final',
                mensaje_error:'Debe indicar el puntaje final',
                type: 'number'
              },
      }
    },
    ganacia:{
      multiples:true,
      value:{
              porcentaje:{
                label: 'Remuneración/Porcentaje', value:datos.dato.porcentaje, 
                tipo:'Checkbox'
              },
              remuneracion:{
                label: 'Remuneración ', value:datos.dato.remuneracion, 
                type:'number'
              },
              
              etiqueta:{
                label: 'Etiqueta', value:datos.dato.etiqueta,
              }
      }
    }
  }
  let botones=[
    {
      name:'guardar', label:'Guardar', title:'Guardar ',
      variant:"contained", color:"primary", icono:<CheckIcon/>,
      onClick: funciones.Guardar(datos.Titulo, tabla), validar:'true',
      disabled: !Permiso('Guardar_conversion'),
    },
    {
      name:'eliminar', label:'Eliminar', title:'Eliminar agencia',
      variant:"contained", color:"secondary", icono:<DeleteIcon/>,
      onClick:funciones.Eliminar(datos.Titulo, tabla),
      disabled: datos.dato._id===undefined ? true :
                Number(User.categoria._id)===0 && Permiso('',true) ? false : 
                Number(User.categoria._id)!==0 && Permiso('eliminar') ? false: true,
    },
    {
      name:'cancelar', label:'Cancelar', title:'Cancelar',
      variant:"contained", color:"default", icono:<CancelIcon/>,
      onClick:()=>funciones.Cancelar(datos.Titulo)
    },
];
  let bloques=
  <div className={classes.resumenes} >
  <Grid container spacing={2} justify="center" alignItems="center" direction="row">
      <Grid item xs={12}>
        <Collapse in={!datos.open}>
          {General_tabla( Datos_tabla)}
        </Collapse>
        <Collapse in={datos.open}>
            <Paper className={classes.paperformulario} elevation={3}>
              <Formulario datos={datos.dato}
                  titulos= {titulos}
                  botones= {botones}
              />
            </Paper>
        </Collapse>
      </Grid>
  </Grid>
  </div>
  return bloques;

}
//Bloque multas
function Bloque_multas(datos,funciones, classes={}, socket=null){
  const tabla='Data_multa'
  const Lista=[
    {_id:0, titulo:'Operador'},
    {_id:1, titulo:'Supervisor'},
  ]
  let cont=0
  let service=[] 
  Object.keys(config.service).map(f=> {
    if (f.indexOf('missed')!==-1) {
      const valor={_id:cont, titulo:config.service[f].value}
      cont+=1
      service.push(valor)
    }
    return f
  })
  
  const Titulo_tabla=datos.Titulo;
  const Datos_tabla={
    Dialogo:Titulo_tabla,
    Titulo_tabla,
    tabla,
    Titulos :[
      {title:'Involucrado',field:'involucrado',  tipo:'lista', lista:Lista},
      {title:'Servicio',field:'service', tipo:'lista', lista:service},
      {title:'Multa',field:'multa'},
    ],
    Datos: datos.datos,
    Cantidad: datos.cantidad,
    Acciones: 
      <div>
        <IconButton color={'primary'} title={'Refrescar valores de '+Titulo_tabla} onClick={()=>funciones.Refrescar(Titulo_tabla)}>
          <AutorenewIcon />
        </IconButton>
        <IconButton color={'primary'} title={'Agregrar nuevo Usuario'} onClick={()=>funciones.Agregar(Titulo_tabla)}>
          <AddIcon />
        </IconButton>
      </div>,
    funciones
  }
  
  
  let involucrado=Lista.filter(val=> Number(val._id)===Number(datos.dato.involucrado))[0];
  let servicio=service.filter(val=> Number(val._id)===Number(datos.dato.service))[0];
  let titulos={
    involucrado:{
      label: 'Involucrado', helperText:'',
      required:true,
      title:'Personal involucrado',
      tipo:'select',
      lista: Lista,
      value:involucrado ? involucrado : Lista[0],
      getOptionLabel: (option)=> `${option.titulo}`,
      getOptionSelected: (option)=> option.titulo,
    },
    puntos:{
      multiples:true,
      value:{
              service:{
                label: 'Servicio', 
                tipo:'select',
                lista: service,
                value:servicio ? servicio : service[0],
                getOptionLabel: (option)=> `${option.titulo}`,
                getOptionSelected: (option)=> option.titulo,
              },
              multa:{
                label: 'Multa',
                value:datos.dato.multa, 
                title:'Debe indicar el  monto de la multa',
                mensaje_error:'Debe indicar monto de la multa',
                type: 'number'
              },
      }
    },
    
  }
  let botones=[
    {
      name:'guardar', label:'Guardar', title:'Guardar ',
      variant:"contained", color:"primary", icono:<CheckIcon/>,
      onClick: funciones.Guardar(datos.Titulo, tabla), validar:'true',
      disabled: !Permiso('Guardar_conversion'),
    },
    {
      name:'eliminar', label:'Eliminar', title:'Eliminar agencia',
      variant:"contained", color:"secondary", icono:<DeleteIcon/>,
      onClick:funciones.Eliminar(datos.Titulo, tabla),
      disabled: datos.dato._id===undefined ? true :
                Number(User.categoria._id)===0 && Permiso('',true) ? false : 
                Number(User.categoria._id)!==0 && Permiso('eliminar') ? false: true,
    },
    {
      name:'cancelar', label:'Cancelar', title:'Cancelar',
      variant:"contained", color:"default", icono:<CancelIcon/>,
      onClick:()=>funciones.Cancelar(datos.Titulo)
    },
];
  let bloques=
  <div className={classes.resumenes} >
  <Grid container spacing={2} justify="center" alignItems="center" direction="row">
      <Grid item xs={12}>
        <Collapse in={!datos.open}>
          {General_tabla( Datos_tabla)}
        </Collapse>
        <Collapse in={datos.open}>
            <Paper className={classes.paperformulario} elevation={3}>
              <Formulario datos={datos.dato}
                  titulos= {titulos}
                  botones= {botones}
              />
            </Paper>
        </Collapse>
      </Grid>
  </Grid>
  </div>
  return bloques;

}
//Bloque de bonos y perdidas
function Bloque_bonos(datos,funciones, classes={}, socket=null){
  const Titulo_tabla=datos.Titulo;
  const tabla=Titulo_tabla==='Bonos' ? 'Data_bono' : 'Data_perdida'
  const Datos_tabla={
    Dialogo:Titulo_tabla,
    Titulo_tabla,
    tabla,
    Titulos :[
      {title:'Fecha y Hora',field:'timestamp', formato: (dato)=> moment(dato.timestamp).format('DD/MM/YYYY hh:mm:ss')},
      {title:'Agencia',field:'agency-id', formato: (dato)=> 
        Lista_avatar({nombre:dato['nombre-agency'], imagen:dato['imagen-agency'], codigo:dato['agency-id'], classes})
      },
      {title:'Perfil',field:'user-id', formato: (dato)=>
        Lista_avatar({nombre:dato['nombre-perfil'], imagen:dato['imagen-perfil'], codigo:dato['user-id'], classes})
      },
      {title:'Servicio',field:'service', formato: (dato)=>{
        let valor = config.service[Titulo_tabla==='Bonos' ? dato.service : dato.service==='manual' ? dato.service+':missed' : dato.service ]
        valor= valor===undefined ? dato.service : valor;
        return valor
      }}, 
      {title:'Puntos',field:'points'}, 
      {title:'Turno',field:'turno'}, 
      
    ],
    Datos: datos.datos,
    Cantidad: datos.cantidad,
    Acciones: 
      <div>
        
      </div>,
    funciones,
    ordenar: (valores)=> {
      
      try {
        const campo = 'timestamp'
        const result=valores.sort((a, b) => (a[campo] < b[campo] ? 1 : a[campo] > b[campo] ? -1 : 0))
        return result
      }catch(error) {
        console.log('Error en ordenar bonos',error);
        return valores;
      }
      
    }
  }
  
  
  let bloques=
  <div className={classes.resumenes} >
  <Grid container spacing={2} justify="center" alignItems="center" direction="row">
      <Grid item xs={12}>
          {General_tabla( Datos_tabla)}
      </Grid>
  </Grid>
  </div>
  return bloques;

}

//Bloque de pruebas
function Bloque_multiple(datos,funciones, classes={}, socket=null){
  console.log('En multiple',datos)
  let pantalla = {titulo:'Pantalla usuarios',cuerpo: Bloque_usuarios(datos,funciones,classes)}
  let bloques=
  <div className={classes.resumenes} >
    <Multiples Pantalla={pantalla}/>
  </div>
  return bloques;

}

//Bloque de pruebas
function Bloque_movimiento(datos,funciones, classes={}, socket=null){
  
  let bloques=
  <div className={classes.resumenes} >
    <Movimientos />
  </div>
  return bloques;

}
//Bloque turno agregar
function Bloque_turno_add(datos,funciones, classes={}, socket=null){
  const tabla='Data_turno'
  const Titulo_tabla=datos.Titulo;
  const Datos_tabla={
    Dialogo:Titulo_tabla,
    Titulo_tabla,
    tabla,
    Titulos :[
      {title:'Nombre Turno',field:'turno'},
      {title:'Pais',field:'pais'},
      {title:'Inicia',field:'hora_inicio'},
      {title:'Break 1',field:'hora_inicio_b', formato: (dato)=> dato.descanso ? `${dato.hora_inicio_b} - ${dato.hora_fin_b}` : ''},
      {title:'Break 2',field:'hora_fin_c', formato: (dato)=> dato.descanso ? `${dato.hora_inicio_c} - ${dato.hora_fin_c}` : ''},
      {title:'Finaliza',field:'hora_fin'},
    ],
    Datos: datos.datos,
    Cantidad: datos.cantidad,
    Acciones: 
      <div>
        <IconButton color={'primary'} title={'Refrescar valores de '+Titulo_tabla} onClick={()=>funciones.Refrescar(Titulo_tabla)}>
          <AutorenewIcon />
        </IconButton>
        <IconButton color={'primary'} title={'Agregrar nuevo Turno'} onClick={()=>funciones.Agregar(Titulo_tabla)}>
          <AddIcon />
        </IconButton>
      </div>,
    funciones
  }
  
  let pais=null
  datos.listapais.map(p=>{
    if (datos.dato.pais===p.titulo) pais=p
    return p
  })
  let zona = null
  let listazona=[]
  if (pais!==null)
    pais.zona.map(z=>{
      listazona.push({_id:z, titulo:z})
      if (datos.dato.zona===z) zona={_id:z, titulo:z}
      return z
    })
  
  const listaturno=[{_id:0, titulo:'DIA'},{_id:2, titulo:'NOCHE'}]
  let sturno =datos.dato.turno !==undefined ? datos.dato.turno.split('(')[1] : datos.dato.turno
  sturno = sturno !== undefined ? sturno.split(')')[0] : sturno
  if (sturno===undefined){
    sturno=null
  }else{
    sturno= listaturno.filter(v=> v.titulo===sturno)[0]
  }
   
  let titulos={
    personal:{
      multiples:true,
      value:{
              turno:{
                label: 'Nombre Turno', helperText:'',
                value:datos.dato.turno !==undefined ? datos.dato.turno.split('(')[0].trim() : datos.dato.turno, 
                required:true,
                title:'Nombre del turno',
                mensaje_error:'Debe indicar nombre del turno',
              },
              pais:{
                label: 'Pais', helperText:'',
                required:true,
                title:'Pais en el que aplica turno',
                mensaje_error:'Debe indicar pais del turno',
                value: pais,
                tipo:'select',
                lista: datos.listapais,
                
                onChange: funciones.Cambios_pais
              },
              zona:{
                label: 'Zona Horaria', helperText:'',
                value: zona, 
                title:'Zona horaria del pais',
                tipo:'select',
                lista: listazona,
                getOptionLabel: (option)=> `${option.titulo}`,
                getOptionSelected: (option)=> option.titulo,
              },
              hora_inicio:{
                label: 'Hora Inicia', helperText:'',
                value:datos.dato.hora_inicio, 
                required:true,
                title:'Indique hora en que inicia el turno',
                mensaje_error:'Indique hora en que inicia el turno',
                type:'time'
              },
              hora_fin:{
                label: 'Hora Finalizar', helperText:'',
                value:datos.dato.hora_fin, 
                required:true,
                title:'Indique hora en que finaliza el turno',
                mensaje_error:'Indique hora en que finaliza el turno',
                type:'time'
              },
      },
      
    },
    descanso:{
      label: 'Con Break/ Sin Break', value:datos.dato.descanso, 
      tipo:'Checkbox',
      onChange: funciones.Cambios
    },
    horas:{
      multiples:true,
      value:{
              sturno:{
                label: 'turno', helperText:'',
                title:'Seleccione turno',
                value: sturno,
                tipo:'select',
                lista: listaturno,
              },
              hora_inicio_b:{
                label: 'Hora Inicia Break 1', helperText:'',
                value:datos.dato.hora_inicio_b, 
                title:'Indique hora en que inicia Break 1 ',
                mensaje_error:'Indique hora en que inicia Break 1',
                type:'time',
                disabled: !datos.dato.descanso
              },
              hora_fin_b:{
                label: 'Hora Finalizar Break 1', helperText:'',
                value:datos.dato.hora_fin_b, 
                title:'Indique hora en que finaliza el Break 1',
                mensaje_error:'Indique hora en que finaliza el Break 1',
                type:'time',
                disabled: !datos.dato.descanso
              },
              hora_inicio_c:{
                label: 'Hora Inicia Break 2', helperText:'',
                value:datos.dato.hora_inicio_c, 
                title:'Indique hora en que inicia Break 2',
                mensaje_error:'Indique hora en que inicia Break 2',
                type:'time',
                disabled: !datos.dato.descanso
              },
              hora_fin_c:{
                label: 'Hora Finalizar Break 2', helperText:'',
                value:datos.dato.hora_fin_c, 
                title:'Indique hora en que finaliza Break 2',
                mensaje_error:'Indique hora en que finaliza Break 2',
                type:'time',
                disabled: !datos.dato.descanso
              },
      },
      
    },
    descripcion:{
      label: 'Descripción', helperText:'',
      value:datos.dato.descripcion, title:'Descripción sobre el turno',
      multiline:true, rowsMax:2,
    },
    
  }
  let botones=[
    {
      name:'guardar', label:'Guardar', title:'Guardar usuario',
      variant:"contained", color:"primary", icono:<CheckIcon/>,
      onClick: funciones.Guardar(datos.Titulo, tabla), validar:'true',
      disabled: !Permiso('Guardar_agencia'),
    },
    {
      name:'eliminar', label:'Eliminar', title:'Eliminar usuario',
      variant:"contained", color:"secondary", icono:<DeleteIcon/>,
      onClick:funciones.Eliminar(datos.Titulo, tabla),
      disabled: datos.dato._id===undefined ? true :
                Number(User.categoria._id)===0 && Permiso('',true) ? false : 
                Number(User.categoria._id)!==0 && Permiso('eliminar') ? false: true,
    },
    {
      name:'cancelar', label:'Cancelar', title:'Cancelar',
      variant:"contained", color:"default", icono:<CancelIcon/>,
      onClick:()=>funciones.Cancelar(datos.Titulo)
    },
];
  let bloques=
  <div className={classes.resumenes} >
  <Grid container spacing={2} justify="center" alignItems="center" direction="row">
      <Grid item xs={12}>
        <Collapse in={!datos.open}>
          {General_tabla( Datos_tabla)}
        </Collapse>
        <Collapse in={datos.open}>
            <Paper className={classes.paperformulario} elevation={3}>
              <Formulario datos={datos.dato}
                  titulos= {titulos}
                  botones= {botones}
              />
            </Paper>
        </Collapse>
      </Grid>
  </Grid>
  </div>
  return bloques;

}
//Bloque de pruebas
function Bloque_turnos(datos,funciones, classes={}, socket=null){
  
  let bloques=
  <div className={classes.resumenes} >
    <Turnos />
  </div>
  return bloques;

}

//Bloque de supervisores
function Bloque_supervisores(datos,funciones, classes={}, socket=null){
  
  let bloques=
  <div className={classes.resumenes} >
    <Supervisores />
  </div>
  return bloques;

}

//Bloque de operadores
function Bloque_operadores(datos,funciones, classes={}, socket=null){
  
  let bloques=
  <div className={classes.resumenes} >
    <Operadores />
  </div>
  return bloques;

}

//Bloque de planificar
function Bloque_planificar(datos,funciones, classes={}, socket=null){
  
  let bloques=
  <div className={classes.resumenes} >
    <Planificar />
  </div>
  return bloques;

}
//Bloque de planificar
function Bloque_planificar_n(datos,funciones, classes={}, socket=null){
  
  let bloques=
  <div className={classes.resumenes} >
    <PlanificarN />
  </div>
  return bloques;

}
//Bloque de calculos
function Bloque_calculos(datos,funciones, classes={}, socket=null){
  let bloques=
    <div className={classes.resumenes} >
    <Grid container spacing={2} justify="center" alignItems="center" direction="row">
        <Grid container item spacing={2}>
            <Grid item xs>
              <Paper >
                <GeneralPerfil />
              </Paper>
            </Grid>
        </Grid>
    </Grid>
    </div>
    return bloques;
  
}
//Bloque de calculos
function Bloque_calculos_personal(datos,funciones, classes={}, socket=null){
  let bloques=
    <div className={classes.resumenes} >
    <Grid container spacing={2} justify="center" alignItems="center" direction="row">
        <Grid container item spacing={2}>
            <Grid item xs>
              <Paper >
                <GeneralPersonal />
              </Paper>
            </Grid>
        </Grid>
    </Grid>
    </div>
    return bloques;
  
}
//Bloque de nomina
function Bloque_calculos_nomina(datos,funciones, classes={}, socket=null){
  let bloques=
    <div className={classes.resumenes} >
      <Nomina />
    </div>
    return bloques;
  
}
//Bloque de planificar
function Bloque_usua(datos,funciones, classes={}, socket=null){
  
  let bloques=
  <div className={classes.resumenes} >

  </div>
  return bloques;

}

function General_tabla( Datos_tabla){
  // console.log('>>>>>>>>>>>>>>',Datos_tabla)
  const {Dialogo, Titulo_tabla,Titulos, tabla, Cantidad, Datos, funciones, Acciones, ordenar, Condicion}=Datos_tabla
  
  let bloques=
      
      <Tabla  Titulo={Titulo_tabla}
            titulos={Titulos}
            table={tabla}
            cantidad={Cantidad}
            cargacompleta={funciones.Actualizar_data}
            datos={Datos}
            Accion={funciones.Dialogo ? funciones.Dialogo(Dialogo) : null}
            acciones={Acciones}
            ordenar={ordenar}
            condicion = {Condicion }
      />  
  return bloques;
}

function Lista_avatar(Datos){
  const {codigo,nombre,imagen, classes, local, online}=Datos
  const direccion = local ? imagen : `https://api7.amolatina.com/users/${codigo}/photos/${imagen}.490x480.thumb-fd`;
  const Image = <Avatar className={classes.avatar} alt={nombre} src={imagen===undefined ? '' : direccion} />
  return codigo==='' && nombre==='' ? (
      <div style={{display:'flex', justifyContent:'center', justifyItems:'center',alignItems:'center',}}>
        {Image}
      </div>
    ) : (
    <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <ThemeProvider theme={theme}>
            <Badge color={online ? "primary" : "error"} overlap="circle" badgeContent=" " variant="dot">
              {Image}
            </Badge>
          </ThemeProvider>
        </ListItemAvatar>
        <ListItemText
          primary={<Typography
            component="span"
            variant="h6"
            className={classes.inline}
            color="textPrimary"
          >
            {nombre}
          </Typography>}
          secondary={codigo}
        />
      </ListItem>
  )
}

//Bloque turno agregar
function Bloque_metas_add(datos,funciones, classes={}, socket=null){
  const tabla='Data_meta'
  const Titulo_tabla=datos.Titulo;
  const listatipo=[{_id:0, titulo:'Mes'},{_id:1, titulo:'Dia'}]
  const Datos_tabla={
    Dialogo:Titulo_tabla,
    Titulo_tabla,
    tabla,
    Titulos :[
      {title:'Meta',field:'tipo'},
      {title:'Fecha',field:'fecha'},
      {title:'Puntos',field:'puntos'},
    ],
    Datos: datos.datos,
    Cantidad: datos.cantidad,
    Acciones: 
      <div>
        <IconButton color={'primary'} title={'Refrescar valores de '+Titulo_tabla} onClick={()=>funciones.Refrescar(Titulo_tabla)}>
          <AutorenewIcon />
        </IconButton>
        <IconButton color={'primary'} title={'Agregrar nuevo Turno'} onClick={()=>funciones.Agregar(Titulo_tabla)}>
          <AddIcon />
        </IconButton>
      </div>,
    funciones
  }
  
  let tipo = null;

  listatipo.map(p=>{
    if (datos.dato.tipo===p.titulo) tipo=p
    return p
  })
  if (tipo===null) tipo=listatipo[0];
  
  const listaturno=[{_id:0, titulo:'DIA'},{_id:2, titulo:'NOCHE'}]
  let sturno =datos.dato.turno !==undefined ? datos.dato.turno.split('(')[1] : datos.dato.turno
  sturno = sturno !== undefined ? sturno.split(')')[0] : sturno
  if (sturno===undefined){
    sturno=null
  }else{
    sturno= listaturno.filter(v=> v.titulo===sturno)[0]
  }
  
  let titulos={
    personal:{
      multiples:true,
      value:{
              tipo:{
                label: 'Tipo de la Meta', helperText:'',
                required:true,
                title:'Tipo de la meta',
                mensaje_error:'Indique el tipo de la meta',
                value: tipo,
                tipo:'select',
                lista: listatipo,
                
                onChange: funciones.Cambios_tipo
              }, 
              fecha:{
                label: 'Fecha', helperText:'',
                value:datos.dato.fecha, 
                required:true,
                title:'Indique la fecha de la meta',
                mensaje_error:'Indique la fecha de la meta',
                type:tipo._id===0 ? 'month' : 'date'
              },
              ...tipo._id===0 ?{} : {
                fechaf:{
                  label: 'Fecha', helperText:'',
                  value:new Date().now, 
                  title:'Indique la fecha de la meta',
                  mensaje_error:'Indique la fecha de la meta',
                  type:'date'
                },
              },
              puntos:{
                label: 'Puntos', helperText:'',
                value:datos.dato.puntos, 
                required:true,
                title:'Indique los puntos de la meta',
                mensaje_error:'Indique puntos de la meta',
                type:'number'
              },
      },
      
    },
    
  }
  let botones=[
    {
      name:'guardar', label:'Guardar', title:'Guardar usuario',
      variant:"contained", color:"primary", icono:<CheckIcon/>,
      onClick: funciones.Guardar(datos.Titulo, tabla), validar:'true',
      disabled: !Permiso('Guardar_meta'),
    },
    {
      name:'eliminar', label:'Eliminar', title:'Eliminar usuario',
      variant:"contained", color:"secondary", icono:<DeleteIcon/>,
      onClick:funciones.Eliminar(datos.Titulo, tabla),
      disabled: datos.dato._id===undefined ? true :
                Number(User.categoria._id)===0 && Permiso('',true) ? false : 
                Number(User.categoria._id)!==0 && Permiso('eliminar') ? false: true,
    },
    {
      name:'cancelar', label:'Cancelar', title:'Cancelar',
      variant:"contained", color:"default", icono:<CancelIcon/>,
      onClick:()=>funciones.Cancelar(datos.Titulo)
    },
];
  let bloques=
  <div className={classes.resumenes} >
  <Grid container spacing={2} justify="center" alignItems="center" direction="row">
      <Grid item xs={12}>
        <Collapse in={!datos.open}>
          {General_tabla( Datos_tabla)}
        </Collapse>
        <Collapse in={datos.open}>
            <Paper className={classes.paperformulario} elevation={3}>
              <Formulario datos={datos.dato}
                  titulos= {titulos}
                  botones= {botones}
              />
            </Paper>
        </Collapse>
      </Grid>
  </Grid>
  </div>
  return bloques;

}

export const Bloques={
    "CONFIGURACION SISTEMA":Bloque_Configuracion_sistema,
    "CONFIGURACION USUARIOS":Bloque_Configuracion_usuarios,
    "AMOLATINA":Bloque_amolatina,
    "Dashboard":Bloque_resumenes,
    "Usuarios":Bloque_usuarios,
    "Bonos":Bloque_bonos,
    "Perdidas":Bloque_bonos,

    //Registros
    "Agencias": Bloque_agencia,
    "Perfiles": Bloque_perfil,
    "Personal": Bloque_personal,
    "Agregar Turno":Bloque_turno_add,
    "Tabla de conversión": Bloque_puntos,
    "Multas": Bloque_multas,
    "Turnos": Bloque_turnos,
    "Supervisor VS Operador": Bloque_supervisores,
    "Operador VS Perfil": Bloque_operadores,
    "Planificar_n": Bloque_planificar,
    "Planificar": Bloque_planificar_n,
    "Calculos":Bloque_calculos,
    "Calculos Personal":Bloque_calculos_personal,
    "Calculos Nomina":Bloque_calculos_nomina,
    "Usuario":Bloque_usua,
    "Multiples":Bloque_multiple,
    "Movimientos":Bloque_movimiento,
    "Pruebas":Bloque_pruebas,
    "Metas":Bloque_metas_add
}
