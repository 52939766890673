import React from 'react';
import { makeStyles,  useTheme,  withStyles  } from '@material-ui/core/styles';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
// import {conexiones} from '../../procesos/servicios';
import Dialogo from '../../components/formulario/dialogo';
// import { Scrollbars } from 'rc-scrollbars';
import Scrollbars from '../formulario/MyScrollbars';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import MenuItem from '@material-ui/core/MenuItem';
// import CakeIcon from '@material-ui/icons/Cake';
import Menu from '@material-ui/core/Menu';
// import Badge from '@material-ui/core/Badge';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ReactCountryFlag from "react-country-flag";

import Menus from './menu'
import logo from '../../imagenes/logo.png';
import Hora from './hora';
// import Torta from './torta';

import {Bloques} from './bloques'

//Constantes
import {const_procesos, Valord} from '../../constantes';

let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;
let User = JSON.parse(localStorage.getItem(const_procesos.dir_user));

const tamanologo= 64;
const tamanopantalla= window.screen.height*0.72;
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  divlogo:{
    display:'flex',
    justifyContent:'center',
    justifyItems:'center',
    alignItems:'center',
    height: tamanologo,
    ...config.Estilos.barra
  },
  logo:{
    height:40,
    marginRight:5
  },
  titulo:{
    marginTop:10,
    color:'rgba(255, 255, 255)'
  },
  menu: {
    height: tamanopantalla,
    width:'100%',
    // overflow:'scroll',
    ...config.Estilos.barra_menu
  },
  cuerpo:{
    height: tamanopantalla + tamanologo -theme.spacing(2),
    // overflow:'scroll',
    backgroundColor:'rgba(0, 19, 35)',
    justifyContent:'center',
    justifyItems:'center',
    alignItems:'center',
    padding:theme.spacing(1),
    marginLeft: -5,

  },
  pie:{
    position:'absolute',
    bottom:0,
    width:'100%',
    backgroundColor: 'rgba(0, 0, 0, .8)',
    display:'flex',
    justifyContent:'center',
    justifyItems:'center',
    alignItems:'center',
    
  },
  item:{
    
  },
  resumenes: {
    flexGrow: 1,
    padding:10,
    paddingBottom:20
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height:600,
    backgroundColor:'#000000'
    
  },
  papers: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height:330,
    backgroundColor:'#000000'
    
  },
  paper_agencia: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height:330,
    backgroundColor:'#000000'
  },
  paper_agencia_o: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    // height:410,
    backgroundColor:'#000000'
  },
  paper_agencia1: {
    // height:410,
    backgroundColor:'#000000'
  },
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...config.Estilos.barra_menu
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    ...config.Estilos.barra_menu
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
    ...config.Estilos.barra_menu
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    overflow: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    ...config.Estilos.barra_menu
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    
    // padding: theme.spacing(0, 1),
    marginLeft:theme.spacing(5),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    overflow: 'hidden',
    overflowX: 'hidden',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    ...config.Estilos.barra,
    height:  window.innerHeight,
    overflowX: 'hidden',
    overflowY: 'hidden',
    // overflow:'scroll',
  },
  paperformulario:{
    // padding: theme.spacing(1),
    // paddingRight: theme.spacing(2),
    
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight:theme.spacing(1)
  },
  title: {
    flexGrow: 1,
  },
  cumple:{
    color:'red'
  },
  cumplea:{
    marginLeft:10,
    color:'#000000',
    fontWeight:'blod'
  }
}));


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    ...config.Estilos.barra_menu
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: '#000000',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);


export default function Page(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  // const [personal, setPersonal] = React.useState(null);
  // const [perfil, setPerfil] = React.useState(null);
  // const [inicio, setInicio] = React.useState(false);
  // const [cumple_hoy, setCumple_hoy] = React.useState(true);
  
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  
  // setInterval(()=>{
  //   setHora(new Date())
  // }, 30000);
  return (

    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          { !open ? <img src={logo} className={classes.logo} alt="logo" /> : null}
          <Typography variant="h6" noWrap className={classes.title}>
            {props.pantalla ? props.pantalla.bloque:''}
          </Typography>
            
            
          <div style={{display:'flex'}}>
            <Hora />
            {props.props.Geodata ? 
            <ReactCountryFlag
              countryCode={props.props.Geodata.country}
              svg
              cdnSuffix="svg"
              title={props.props.Geodata.country}
              style={{
                width:'3em',
                height: '2em',
                marginTop:10,
                marginRight:5,
              }}
            /> : null}
            <Typography variant="h6" noWrap className={classes.title} style={{marginTop:10}}>
              {User.username}
            </Typography>
            {/* <Torta /> */}
            <IconButton
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <StyledMenu
              id="menu-appbar"
              anchorEl={anchorEl}
              keepMounted
              open={open1}
              onClose={handleClose}
            >
              <StyledMenuItem onClick={()=>{
                  handleClose()
                  props.Dialogo.Abrir()
                }}
              >
                <ListItemIcon>
                    <AccountBoxIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Mi cuenta" />
              </StyledMenuItem>
              <StyledMenuItem onClick={()=>{
                    handleClose()
                    props.props.Cerrar_sesion(User)
                }}
              >
                  <ListItemIcon>
                    <LockIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Cerra sesion" />
                </StyledMenuItem>
              </StyledMenu>
          </div>
            
        </Toolbar>
        
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        
        <div className={classes.divlogo}>
            <img src={logo} className={classes.logo} alt="logo" />
            <div className={[classes.toolbar]}>
              <IconButton onClick={handleDrawerOpen} style={{ color: '#ffffff' }}>
                {theme.direction === 'rtl' ? <ChevronRightIcon style={{ fontSize: 30 }}/> : <ChevronLeftIcon />}
              </IconButton>
            </div>
        </div>
          
        <Divider />
        <Menus {...props} />
        
      </Drawer>
      <main className={classes.content}>
        <Scrollbars >
          <div className={classes.toolbar} />
          {props.pantalla ? Bloques[props.pantalla.bloque](props.pantalla.datos,props.pantalla.funciones,classes, props.props.socket) : null}
        </Scrollbars>
      </main>
      <Dialogo {...props.Dialogo}/>
    </div>
  );
}