import React from 'react';
import { createMuiTheme, makeStyles, ThemeProvider  } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
// import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
// import AutorenewIcon from '@material-ui/icons/Autorenew';
import Typography from '@material-ui/core/Typography';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import TextField from '@material-ui/core/TextField';
import Tabla from '../tabla';
import Formulario from '../../components/formulario';

import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import 'moment/locale/es';


//Constantes
import {Valord} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

const categoria_usuario= config.categoria_usuario;
moment.locale('es');
const useStyles = makeStyles((theme) => ({
    
    resumenes: {
      flexGrow: 1,
      padding:10,
    },
    
    paperformulario:{
      // padding: theme.spacing(1),
      // paddingRight: theme.spacing(2),
      
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight:theme.spacing(1)
    },
    fecha:{
      display:'flex',
      alignItems:'center',
      textAlign:'center'
    },
    
}));

const theme = createMuiTheme({
    palette: {
      error: {
        main: '#b71c1c',
      },
      primary: {
        main: '#1b5e20',
      },
      secondary: {
        main: '#ffeb3b',
      },
    },
});


export default function Page(props) {
    const classes = useStyles();

    const General_tabla = ( Datos_tabla)=>{
        
        const {Titulo_tabla,Titulos, tabla, Cantidad, Datos, funciones, Acciones, ordenar}=Datos_tabla
        
        let bloques=
            
            <Tabla  Titulo={Titulo_tabla}
                  titulos={Titulos}
                  table={tabla}
                  cantidad={Cantidad}
                  cargacompleta={funciones.Actualizar_data}
                  datos={Datos}
                  Accion={funciones.Dialogo ? funciones.Dialogo : null}
                  acciones={Acciones}
                  ordenar={ordenar}
            />  
        return bloques;
    }
    const tabla='Data_nomina'
    const Titulo_tabla='Nomina';
    const Datos_tabla={
        Dialogo:Titulo_tabla,
        Titulo_tabla,
        tabla,
        Titulos :[
            {title:'Foto',field:'foto', formato : (dato)=> 
            Lista_avatar({nombre:'', imagen:dato['foto'], codigo:'', classes, local:true})  
            },
            {title:'Nombre y apellido',field:'nombres', formato: (dato)=> `${dato.nombres} ${dato.apellidos}` },
            // {title:'Categoria',field:'categoria', tipo:'lista', lista:categoria_usuario},
            {title:'Puntos por Operar',field:'points', align:'right', formato: (dato)=> `${new Intl.NumberFormat('es-VE', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(dato.points)}`},
            {title:'Multas por Operar',field:'mpoints', align:'right', formato: (dato)=> `${new Intl.NumberFormat('es-VE',{maximumFractionDigits: 2, minimumFractionDigits: 2}).format(dato.mpoints)}`},
            {title:'Remuneración por Operar',field:'remuneracion', align:'center',formato: (dato)=> `${new Intl.NumberFormat('es-VE').format(dato.remuneracion)} ${dato.tipo ? dato.tipo : ''}`},
            {title:'Puntos Supervisados',field:'sup_points', align:'right', formato: (dato)=> `${new Intl.NumberFormat('es-VE', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(dato.sup_points)}`},
            {title:'Multas Supervisados',field:'sup_mpoints', align:'right', formato: (dato)=> `${new Intl.NumberFormat('es-VE',{maximumFractionDigits: 2, minimumFractionDigits: 2}).format(dato.sup_mpoints)}`},
            {title:'Remuneración Supervisado',field:'remuneracion_sup', align:'center', formato: (dato)=> `${new Intl.NumberFormat('es-VE').format(dato.remuneracion_sup)} ${dato.sup_tipo ? dato.sup_tipo : ''}`},
            {title:'Salario Fijo',field:'salario', align:'right', formato: (dato)=> `${new Intl.NumberFormat('es-VE', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(dato.salario)}`},
            {title:'Cancelar',field:'monto', align:'right', formato: (dato)=> `${new Intl.NumberFormat('es-VE',{maximumFractionDigits: 2, minimumFractionDigits: 2}).format(dato.monto)}`},
        ],
        Datos: props.datos,
        Cantidad: props.cantidad,
        Acciones: 
          <Grid container spacing={1}>
            
            <Grid item xs={10}>
              {/* <Autocomplete
                  autoComplete
                  name={'select_mes'}
                  options={props.meses}
                  getOptionLabel={(option) => {
                    return `${option.mes}`}
                  }
                  onChange={(event, newValue)=>{
                    props.funciones.Select_mes(newValue)
                  }}
                  value={props.select_mes}
                  renderInput={(params) => <TextField {...params} label={'Seleccionar mes'} variant="outlined" />}
              /> */}
              
              <div className={classes.fecha}>
                <Typography variant="caption" display="block" gutterBottom>
                  {`${moment(props.fecha).format('MMMM YYYY')}`}
                </Typography>
                {props.Fecha} 
              </div>
              
            </Grid>
          </Grid>,
        funciones: props.funciones
    }
    let categoria=props.dato.categoria === undefined ? '' :categoria_usuario.filter(val=> Number(val._id)===Number(props.dato.categoria))[0].titulo;
    let titulos={
      foto:{
        label: 'Foto',
        helperText:'',
        value:props.dato.foto ? props.dato.foto : '',
        tipo:'avatar', title:'Foto del personal',
        disabled:true
      },
      personal:{
        multiples:true,
        value:{
                nombres:{
                  label: 'Nombres', helperText:'',
                  value:props.dato.nombres,
                  title:'Nombres del personal', disabled:true
                },
                apellidos:{
                  label: 'Apellidos', helperText:'',
                  value:props.dato.apellidos ? props.dato.apellidos : '', 
                  title:'Apellidos del personal', disabled:true
                },
                categoria:{
                  label: 'Categoria', helperText:'',
                  value:categoria, disabled:true
                },
                
        }
      },
      puntos:{
        multiples:true,
        value:{
            points:{
              label: 'Puntos por perfil', helperText:'',
              value: `${new Intl.NumberFormat('es-VE').format(props.dato.points)}`, 
              disabled:true
            },    
            sup_points:{
              label: 'Puntos por supervisor', helperText:'',
              value: `${new Intl.NumberFormat('es-VE').format(props.dato.sup_points)}`, 
              disabled:true
            },
            remuneracion:{
              label: 'Remuneración', helperText:'',
              value: props.dato.remuneracion, 
              disabled:true
            },
            salario:{
              label: 'Salario Fijo', helperText:'',
              value: props.dato.salario, 
              disabled:true
            },
        }
      },
      cuentas:{
        label: 'Cuentas', helperText:'',
        value:props.dato.cuentas, title:'Informacion de las cuentas, banco o pago movil',
        multiline:true, rowsMax:3,
        disabled:true
      },
      nomina:{
        multiples:true,
        value:{
            mes:{
              label: 'Mes a cancelar', helperText:'',
              value: props.dato.mes, 
              disabled:true
            },    
            monto:{
              label: 'Total a cancelar', helperText:'',
              value: `${new Intl.NumberFormat('es-VE').format(props.dato.monto)}`, 
              disabled:true
            },
        }
      },
    }
    let botones=[
      {
        name:'pagado', label:'Pagado', title:'Indicar como pagado',
        variant:"contained", color:"primary", icono:<CheckIcon/>,
        onClick: props.funciones.Pagar, 
        disabled: props.dato.pagado
      },
      {
        name:'cancelar', label:'Cancelar', title:'Cancelar',
        variant:"contained", color:"default", icono:<CancelIcon/>,
        onClick: props.funciones.Cancelar
      },
    ];
    const Lista_avatar=(Datos)=>{
        const {codigo,nombre,imagen, classes, local, online}=Datos
        const direccion = local ? imagen : `https://api7.amolatina.com/users/${codigo}/photos/${imagen}.490x480.thumb-fd`;
        const Image = <Avatar className={classes.avatar} alt={nombre} src={imagen===undefined ? '' : direccion} />
        return codigo==='' && nombre==='' ? (
            <div style={{display:'flex', justifyContent:'center', justifyItems:'center',alignItems:'center',}}>
              {Image}
            </div>
          ) : (
          <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <ThemeProvider theme={theme}>
                  <Badge color={online ? "primary" : "error"} overlap="circle" badgeContent=" " variant="dot">
                    {Image}
                  </Badge>
                </ThemeProvider>
              </ListItemAvatar>
              <ListItemText
                primary={<Typography
                  component="span"
                  variant="h6"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {nombre}
                </Typography>}
                secondary={codigo}
              />
            </ListItem>
        )
    }

    return(
        <div className={classes.resumenes} >
            <Grid container spacing={2} justify="center" alignItems="center" direction="row">
                <Grid item xs={12}>
                <Collapse in={!props.open}>
                    {General_tabla( Datos_tabla)}
                </Collapse>
                <Collapse in={props.open}>
                  <Paper className={classes.paperformulario} elevation={3}>
                    <Formulario datos={props.dato}
                        titulos= {titulos}
                        botones= {botones}
                    />
                  </Paper>
                </Collapse>
                </Grid>
            </Grid>
        </div>
    )
}