import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import {formatoBolivar, formatoDolar} from '../../procesos/servicios';
import moment from "moment";
import Checkbox from '@material-ui/core/Checkbox';
import Skeleton from '@material-ui/lab/Skeleton';
import Scrollbars from '../formulario/MyScrollbars';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

//Constantes
import {Valord} from '../../constantes';

let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;


function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    ...config.Estilos.barra
  },
  body: {
    fontSize: 14,
  },
  
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    cursor:'pointer'
  },
}))(TableRow);

const useStyles = makeStyles((theme) =>({
  root: {
    minWidth: 275,
    // margin:theme.spacing(2)
  },
  title: {
    fontSize: 24,
  },
  pos: {
    marginBottom: 12,
  },
  titulos:{
    fontColor:'#ffffff',
    backgroundColor:'#000000'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: 0,
    marginLeft:theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    backgroundColor: fade(theme.palette.common.black, 0.35),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    borderRadius: theme.shape.borderRadius,
  },
  container: {
    height:window.innerHeight* 0.40,
    maxHeight: 440,
  },
  Paginacion:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function Page (props) {
  const classes = useStyles();
  const {titulos, datos, paginacion, Cambio, Accion, Titulo, Esperar,
          buscar, cambio_buscar, acciones, acciones1, actualizando, progreso
        }= props;
  // console.log(Esperar, datos)
  const alto=window.innerHeight* 0.60;
  const valor_lista = (lista,valor) =>{
    let resultado=lista.filter(lis => String(lis._id)===String(valor));
    return resultado[0]===undefined ? {titulo:'No existe'} : resultado[0];
  }
  const Referencia = (campo, datos) =>{
    const resultado = datos.map(val=>{
      return val[campo];
    })
    return <Grid container justify={'center'} alignItems={'center'} key={campo}>
      { resultado.map ( (val, i)=>
          <Grid item xs={12} key={i}>
              {campo==='fecha' ? moment(val).format('DD/MM/YYYY') : val}
          </Grid>
      )}
    </Grid>;
  }
  const Representados = (campo, data) =>{

    return (
      <div style={{width:400, }}>
        {data.map(val=>
          <Typography key={val.cedula} variant="body1" gutterBottom>
           {val.cedula + ' ' + val.nombres + ' ' + val.apellidos}
          </Typography>  
          
        )}
      </div>
    )
  }
  return (
    <Card className={classes.root} variant="outlined" >
      <CardContent>
        <Grid container spacing={2} >
            <Grid item xs container alignItems="center">
              <Typography className={classes.title}  gutterBottom>
                {Titulo ? Titulo : 'Titulo'}
              </Typography>
            </Grid>
            <Grid item xs={6} >
              {
                acciones ? acciones : null
              }
            </Grid>
            <Grid item xs container alignItems="center">
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder={'Que busca?'}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  value ={buscar}
                  onChange = {cambio_buscar}
                  name= {'buscar'}
                />

              </div>
            </Grid>
            {
              acciones1 ? acciones1 : null
            }
        </Grid>
        {
          Esperar ?
            <Grid container spacing={2} >
                <Grid item xs={4}>
                  <Typography  gutterBottom>
                    {'Cargando datos pendientes...'}
                    
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  
                  <LinearProgressWithLabel value={progreso!==undefined ? progreso : 0} />
                </Grid>
          
            </Grid> : null
        }
        
        <TableContainer component={Paper} style={{height:alto}}>
        <Scrollbars autoHeight autoHeightMax={alto}>
          <Table  stickyHeader aria-label="sticky table">
            <TableHead >
              <TableRow >
                {titulos && titulos !== undefined ? titulos.map((valor,i)=>
                  <StyledTableCell  key={'title-'+valor.field+i}
                              align="center"
                              
                  >
                    {valor.title}
                  </StyledTableCell>

                ):null}

              </TableRow>
            </TableHead>
            <TableBody>
              {datos.length!==0 ? datos.map((data,i) => (
                <StyledTableRow key={'data-'+i}
                                onClick={()=>Accion(data)}
                                hover role="checkbox"
                >
                  {titulos.map((campo, j)=>
                    <StyledTableCell
                                key={'col-'+i+'-'+j+'-'+campo.field}
                                align={campo.align ? campo.align : campo.tipo && campo.tipo.indexOf('moneda')!==-1 ? 'right' :'left'}
                    >
                      { campo.formato ?
                        campo.formato(data):
                      typeof data[campo.field] === 'object' && campo.tipo===undefined? 
                          JSON.stringify(data[campo.field],null,2):
                          campo.tipo===undefined ?
                          data[campo.field]:
                          campo.tipo==='lista' ?
                          valor_lista(campo.lista,data[campo.field]).titulo:
                          campo.tipo==='representados' ?
                          Representados(campo, data[campo.field]) :  
                          campo.tipo==='moneda-bolivar' ?  
                          formatoBolivar.format(data[campo.field]):
                          campo.tipo==='moneda-dolar' ?
                          formatoDolar.format(data[campo.field]):
                          campo.tipo==='fecha' ?
                          moment(data[campo.field]).format('DD/MM/YYYY'):
                          campo.tipo==='referencia' ?
                          Referencia(campo.campo,data[campo.field]) :
                          campo.tipo==='booleam' ?
                          <Checkbox checked={data[campo.field]} color="primary" key={campo.field+i}/>:
                          campo.tipo==='porcentaje' ?
                          `${data[campo.field] ? data[campo.field] : 0} %`:
                          campo.tipo==='link' ?
                          <Link href={data[campo.field] ? data[campo.field] : '#'} target="_blank">
                            {data[campo.field] ? data[campo.field] : ''}
                          </Link>:
                          campo.tipo
                      }
                    </StyledTableCell>
                  )}

                </StyledTableRow>
              )): Esperar ? 
                 [0,1,2,3,4,5,6,7,8,9].map((data) => (
                    <StyledTableRow key={'esqueletor-'+data}>
                      {titulos ? titulos.map((campo, j)=>
                          <StyledTableCell
                              key={'col-'+j+'-'+campo.field}
                          >
                            <Skeleton key={'esqu'+j+'-'+campo.field} variant="text" />
                          </StyledTableCell>
                      ): <StyledTableCell ><Skeleton variant="text" /></StyledTableCell>}
                    </StyledTableRow>
                  )) : null
                
              }
            </TableBody>
          </Table>
          </Scrollbars>
        </TableContainer>
      </CardContent>
        <CardActions className={classes.Paginacion}>
        {paginacion && paginacion !== undefined && paginacion.paginas.length > 1 ?
          <div>
            {actualizando ? <LinearProgress /> :null}
            <Pagination count={paginacion.paginas.length}
                        variant="outlined" shape="rounded"
                        onChange={Cambio }
            />
            {actualizando ? <LinearProgress /> :null}
          </div>:
          null
        }
        </CardActions>
    </Card>


  )
}

export default Page;
