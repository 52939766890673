import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';

import {Valord} from '../../constantes';

let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box elevation={3} >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#000fff',
    '& > span': {
      width: '100%',
      backgroundColor: '#fff000',
      ...config.Estilos.barra
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#FFFFFF',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(18),
    '&:hover': {
      color: '#FFFFFF',
      opacity: 0.8,
    },
    marginRight: theme.spacing(1),
    '&:focus': {
      color: '#fff000',
      
    },
    
    
  },
  
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    // paddingLeft:theme.spacing(2),
    // paddingRight:theme.spacing(2),
  },
  barra:{
    // backgroundColor: '#151D26'
    ...config.Estilos.barra
  },
  bloques:{
    backgroundColor: '#ffffff',
    padding:theme.spacing(1),
    marginTop:theme.spacing(1),
    height: window.innerHeight * 0.8,
  },
  title_esperar:{
    width:'100%',
    padding:theme.spacing(1),
  }
}));

export default function VerticalTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const {submenu, bloques }=props;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.barra}>
          <StyledTabs
            variant="scrollable"
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"

          >
            {
              submenu ? submenu.map((valor,i)=>
                <StyledTab label={valor} key={valor+i} {...a11yProps(i)} />
              ):
                <StyledTab label={''} icon={<Skeleton style={{width: 100}}/>} />

            }

          </StyledTabs>
      </AppBar>
      {submenu ? submenu.map((valor,i)=>
        <TabPanel key={'bloque'+valor+i} value={value} index={i}>
          
            {bloques[valor]}

        </TabPanel>
      ):<TabPanel key={'bloque_espera'} value={0} index={0}>
        <Box className={classes.bloques}>
          <Typography gutterBottom variant="body2">
            <Skeleton variant="rect" width={210} height={118} />
          </Typography>
        </Box>
      </TabPanel>}

    </div>
  );
}
