import React, {Component} from 'react';
import {conexiones} from '../../procesos/servicios';
import moment from "moment";
// import Rating from '@material-ui/lab/Rating';
import socketIOClient from "socket.io-client";

import List from '@material-ui/core/List';

import { ListaPerfilesEsperar,  ListaPerfilesCabezera, ListaTopAgencias } from '../listas/lista_amolatina';

//Constantes
import {Valord, const_procesos} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

// const Filtrar = (User, personal, puntos_perfiles)=>{
//   if (['3','4'].indexOf(User.categoria)!==-1){
//     let nuevo=[]
//     personal[0].perfiles.map(f=>{
//       const pos = puntos_perfiles.filter(v=> v['user-id']===f['user-id'])
//       nuevo=[...nuevo,...pos]
//       return f
//     })
//     puntos_perfiles=[...nuevo]
    
//   }
//   return puntos_perfiles
// }

export default class Puntos_metas extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          puntos_perfiles:[],
          puntos_operadores:[],
          Iniciar: this.Iniciar,
          Resumen_perfil: this.Resumen_perfil
      }
  }

  
  
  Iniciar= async() =>{
    let User = await JSON.parse(localStorage.getItem(const_procesos.dir_user));
    
    // console.log(dia,dia1)
    const resultados= await conexiones.Enviar({
      datos:{User, dia:moment.utc(new Date()).format('YYYY-MM-DD')},
      http_destino:'/api/metas',
      method:'POST',
    });
    let puntos_perfiles={}
    if (resultados.Respuesta==='Ok'){
        
      const metas = resultados.metas
      Object.keys(metas.Dia).map(item=>{
        puntos_perfiles[item]=[
            {nombre:'Logrado', puntos:metas.Dia[item]},
            {nombre:'Restan', puntos: metas.meta_dia.length!==0 ? Number((metas.meta_dia[0].puntos) - Number(metas.Dia[item])) : -1  },
        ]

        return item
      })
    //   puntos_perfiles['Del Dia']=[
    //       {nombre:'Logrado', puntos:metas.Dia},
    //       {nombre:'Restan', puntos: metas.meta_dia.length!==0 ? Number((metas.meta_dia[0].puntos) - Number(metas.Dia)) : -1  },
    //   ]
      puntos_perfiles['Del Mes']=[
        {nombre:'Logrado', puntos:metas.Mes},
        {nombre:'Restan', puntos: metas.meta_mes.length!==0 ? Number(metas.meta_mes[0].puntos)- Number(metas.Mes) : -1},
    ]

      this.setState({ puntos_perfiles})
    }
    this.setState({ Esperar:{esperar:false,mensaje:'Cargando datos...'}})
    // let respuesta= await conexiones.Leer_C(['Data_resumen_perfil', 'Data_punto', 'Data_perfil_amolatina','Data_personal'], 
    //   {
    //     'Data_resumen_perfil':{fecha:{$gte:dia}},
    //     'Data_punto':{involucrado:0},
    //     'Data_perfil_amolatina':{suspended: false},
    //     'Data_personal':{cedula:User.cedula}
    //   }
    // );
    // let puntos_perfiles=[]
    // let puntos_estrellas=[]
    // let perfiles=[]
    // let personal=[]
    // if (respuesta.Respuesta==='Ok'){
    //   personal= respuesta.datos["Data_personal"]
    //   perfiles= respuesta.datos["Data_perfil_amolatina"]
    //   perfiles= Filtrar(User,personal,perfiles)
    //   puntos_estrellas= respuesta.datos['Data_punto']
    //   puntos_perfiles=this.Puntaje(perfiles, respuesta.datos['Data_resumen_perfil'], puntos_estrellas)
      
    // }else{
    //   setTimeout(()=>{
    //     this.Iniciar()
    //   }, 5000)
    // }
    
    // this.setState({User,personal, puntos_perfiles, puntos_estrellas, perfiles, Esperar:{esperar:false,mensaje:'Cargando datos...'}})
  }

  Puntaje = (perfiles, datos, puntos_estrellas)=>{
    let puntos_perfiles=[]
    perfiles.map( perfil =>{
        const data=  datos.filter(f=> f['user-id']===perfil['user-id'])
        let puntos=0
        let multas=0
        data.map(d=>{
            puntos+=d.points
            multas+= d.mpoints
            return d
        })
        puntos_perfiles.push({...perfil, points: puntos, mpoints: multas, puntose: this.Calcular(puntos, puntos_estrellas)})
        return perfil
    })
    puntos_perfiles= puntos_perfiles.sort((a,b)=> a.points>b.points ? -1 : a.points<b.points ? 1 : 0)
    return puntos_perfiles
  }
  async componentDidMount(){
    // let User = await JSON.parse(localStorage.getItem(const_procesos.dir_user));
    this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    // if (['3','4'].indexOf(User.categoria)!==-1){
    //   this.Iniciar_personal()
    // }else{
    //   this.Iniciar()
    // }
    
    const socket=socketIOClient("/")
    socket.on('Metas',(dato)=>{
        // console.log('>>>>>Top cliente', dato)
        let puntos_perfiles={}
        const metas = dato
        Object.keys(metas.Dia).map(item=>{
            puntos_perfiles[item]=[
                {nombre:'Logrado', puntos:metas.Dia[item]},
                {nombre:'Restan', puntos: metas.meta_dia.length!==0 ? Number((metas.meta_dia[0].puntos) - Number(metas.Dia[item])) : -1  },
            ]
    
            return item
        })
        // puntos_perfiles['Del Dia']=[
        //     {nombre:'Logrado', puntos:metas.Dia},
        //     {nombre:'Restan', puntos: metas.meta_dia.length!==0 ? Number((metas.meta_dia[0].puntos) - Number(metas.Dia)) : -1  },
        // ]
        puntos_perfiles['Del Mes']=[
            {nombre:'Logrado', puntos:metas.Mes},
            {nombre:'Restan', puntos: metas.meta_mes.length!==0 ? Number(metas.meta_mes[0].puntos)- Number(metas.Mes) : -1},
        ]

      this.setState({ puntos_perfiles})
    })
    socket.on('Resumen_perfil',(dato)=>{
    // if (this.state.iniciando) return
    this.Iniciar()
    })
    this.setState({socket})
    setTimeout(()=>{
        this.Iniciar()
    }, 3000)
  }
  componentWillUnmount(){
      let { socket}=this.state
      if (socket!==undefined && socket!==null){
        socket.disconnect();
        socket=null
    }
  }


  Resumen_perfil = (newsocket)=>{
    let {socket, puntos_perfiles}=this.state
    
    if (socket ===undefined || socket===null) {
      socket=newsocket
      socket.on('Resumen_perfil',(dato)=>{
        const {perfiles}=this.state
        
        puntos_perfiles= this.Puntaje(perfiles,dato)
        this.setState({puntos_perfiles})
      })
      this.setState({ socket})
    }
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        // const socket = props.socket
        // state.Resumen_perfil(socket)
        
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  Calcular = (punto, puntos_estrellas)=>{      
    const pos = puntos_estrellas.findIndex(f=> punto<=f.hasta )
    if (pos!==-1){
      let valor= pos + ((punto-puntos_estrellas[pos].desde)/(puntos_estrellas[pos].hasta-puntos_estrellas[pos].desde))
      return valor
    }else{
     return 0 
    }
}

  render(){
    
    return( 
      <List 
          subheader={
            <ListaPerfilesCabezera Titulo={`Metas`} />
            
          }
      >
        {this.state.Esperar.esperar ? 
          <ListaPerfilesEsperar alto={260}/>:
          <ListaTopAgencias datos={this.state.puntos_perfiles} alto={260}/>
        }
      </List>
    )  
  }
}