import React, {Component} from 'react';
import {conexiones} from '../../procesos/servicios';
import moment from "moment";
// import Rating from '@material-ui/lab/Rating';
import socketIOClient from "socket.io-client";

import List from '@material-ui/core/List';

import { ListaPerfilesEsperar, ListaPerfiles, ListaPerfilesCabezera } from '../listas/lista_amolatina';

//Constantes
import {Valord, const_procesos} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

// const Filtrar = (User, personal, puntos_perfiles)=>{
//   if (['3','4'].indexOf(User.categoria)!==-1){
//     let nuevo=[]
//     personal[0].perfiles.map(f=>{
//       const pos = puntos_perfiles.filter(v=> v['user-id']===f['user-id'])
//       nuevo=[...nuevo,...pos]
//       return f
//     })
//     puntos_perfiles=[...nuevo]
    
//   }
//   return puntos_perfiles
// }

export default class Puntos_perfiles_mes extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          puntos_perfiles:[],
          Iniciar: this.Iniciar,
          Resumen_perfil: this.Resumen_perfil
      }
  }

  
  Iniciar_personal= async() =>{
    let User = await JSON.parse(localStorage.getItem(const_procesos.dir_user));
    const dia= moment(new Date()).format('YYYY-MM')+'-01'
    let respuesta= await conexiones.Leer_C(['Data_resumen_personal', 'Data_punto', 'Data_perfil_amolatina','Data_personal'], 
      {
        'Data_resumen_personal':{$and:[{fecha:{$gte:dia}}, {id_user:User._id}]},
        'Data_punto':{involucrado:0},
        'Data_perfil_amolatina':{suspended: false},
        'Data_personal':{cedula:User.cedula}
      }
    );
    let puntos_perfiles=[]
    let puntos_estrellas=[]
    let perfiles=[]
    let personal=[]
    if (respuesta.Respuesta==='Ok'){
      
      respuesta.datos["Data_resumen_personal"].map(dat=>{
        // perfiles=[...perfiles, ... dat.totales]

        dat.totales.map(per=>{
          const pos = perfiles.findIndex(f=> f['user-id']===per['user-id'])
          if (pos===-1){
            perfiles.push(per)
          }else{
            perfiles[pos].total+=per.total
            perfiles[pos].multa+=per.multa
          }
          return per 
        })

        dat.operadores.map(per=>{
          const pos = perfiles.findIndex(f=> f._id===per._id)
          if (pos===-1){
            perfiles.push(per)
          }else{
            perfiles[pos].total+=per.total
            perfiles[pos].multa+=per.multa
          }
          return per
        })
        return dat
      })
      puntos_perfiles=perfiles
      
      puntos_perfiles= puntos_perfiles.sort((a,b)=> a.total>b.total ? -1 : a.total<b.total ? 1 : 0)
      // personal= respuesta.datos["Data_personal"]
      // perfiles= respuesta.datos["Data_perfil_amolatina"]
      // perfiles= Filtrar(User,personal,perfiles)
      // puntos_estrellas= respuesta.datos['Data_punto']
      // puntos_perfiles=this.Puntaje(perfiles, respuesta.datos['Data_resumen_perfil'], puntos_estrellas)
      
    }else{
      setTimeout(()=>{
        this.Iniciar_personal()
      }, 5000)
    }
    
    this.setState({User,personal, puntos_perfiles, puntos_estrellas, perfiles, Esperar:{esperar:false,mensaje:'Cargando datos...'}})
  }

  Iniciar= async() =>{
    let User = await JSON.parse(localStorage.getItem(const_procesos.dir_user));
    const dia= moment(new Date()).format('YYYY-MM')+'-01';
    var diasMes = new Date(Number(dia.split('-')[0]), Number(dia.split('-')[1]), 0).getDate();
    const dia1=moment(new Date()).format('YYYY-MM')+diasMes;
    // console.log(dia,dia1)
    const resultados= await conexiones.Enviar({
      datos:{User, dia, dia1},
      http_destino:'/api/puntos_dia',
      method:'POST',
    });
    let puntos_perfiles=[]
    if (resultados.Respuesta==='Ok'){
      puntos_perfiles=resultados.puntos_perfiles
      this.setState({ puntos_perfiles})
    }
    this.setState({ Esperar:{esperar:false,mensaje:'Cargando datos...'}})
    // let respuesta= await conexiones.Leer_C(['Data_resumen_perfil', 'Data_punto', 'Data_perfil_amolatina','Data_personal'], 
    //   {
    //     'Data_resumen_perfil':{fecha:{$gte:dia}},
    //     'Data_punto':{involucrado:0},
    //     'Data_perfil_amolatina':{suspended: false},
    //     'Data_personal':{cedula:User.cedula}
    //   }
    // );
    // let puntos_perfiles=[]
    // let puntos_estrellas=[]
    // let perfiles=[]
    // let personal=[]
    // if (respuesta.Respuesta==='Ok'){
    //   personal= respuesta.datos["Data_personal"]
    //   perfiles= respuesta.datos["Data_perfil_amolatina"]
    //   perfiles= Filtrar(User,personal,perfiles)
    //   puntos_estrellas= respuesta.datos['Data_punto']
    //   puntos_perfiles=this.Puntaje(perfiles, respuesta.datos['Data_resumen_perfil'], puntos_estrellas)
      
    // }else{
    //   setTimeout(()=>{
    //     this.Iniciar()
    //   }, 5000)
    // }
    
    // this.setState({User,personal, puntos_perfiles, puntos_estrellas, perfiles, Esperar:{esperar:false,mensaje:'Cargando datos...'}})
  }

  Puntaje = (perfiles, datos, puntos_estrellas)=>{
    let puntos_perfiles=[]
    perfiles.map( perfil =>{
        const data=  datos.filter(f=> f['user-id']===perfil['user-id'])
        let puntos=0
        let multas=0
        data.map(d=>{
            puntos+=d.points
            multas+= d.mpoints
            return d
        })
        puntos_perfiles.push({...perfil, points: puntos, mpoints: multas, puntose: this.Calcular(puntos, puntos_estrellas)})
        return perfil
    })
    puntos_perfiles= puntos_perfiles.sort((a,b)=> a.points>b.points ? -1 : a.points<b.points ? 1 : 0)
    return puntos_perfiles
  }
  async componentDidMount(){
    let User = await JSON.parse(localStorage.getItem(const_procesos.dir_user));
    this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    if (['3','4'].indexOf(User.categoria)!==-1){
      this.Iniciar_personal()
    }else{
      this.Iniciar()
    }
    
    const socket=socketIOClient("/")
    socket.on('Resumen_perfil_mes',(dato)=>{
      let {User}=this.state 
      if (['3','4'].indexOf(User.categoria)!==-1){
        this.Iniciar_personal()
      }else{
        // this.Iniciar()
        this.setState({ puntos_perfiles:dato})
      }
    })
    this.setState({socket, User})
  }
  componentWillUnmount(){
      let { socket}=this.state
      if (socket!==undefined && socket!==null){
        socket.disconnect();
        socket=null
    }
  }


  Resumen_perfil = (newsocket)=>{
    let {socket, puntos_perfiles}=this.state
    
    if (socket ===undefined || socket===null) {
      socket=newsocket
      socket.on('Resumen_perfil',(dato)=>{
        const {perfiles}=this.state
        
        puntos_perfiles= this.Puntaje(perfiles,dato)
        this.setState({puntos_perfiles})
      })
      this.setState({ socket})
    }
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        // const socket = props.socket
        // state.Resumen_perfil(socket)
        
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  Calcular = (punto, puntos_estrellas)=>{      
    const pos = puntos_estrellas.findIndex(f=> punto<=f.hasta )
    if (pos!==-1){
      let valor= pos + ((punto-puntos_estrellas[pos].desde)/(puntos_estrellas[pos].hasta-puntos_estrellas[pos].desde))
      return valor
    }else{
     return 0 
    }
}

  render(){
    
    return( 
      <List 
          subheader={
            <ListaPerfilesCabezera Titulo={`Puntos por perfiles del mes`} />
            
          }
      >
        {this.state.Esperar.esperar ? 
          <ListaPerfilesEsperar/>:
          <ListaPerfiles datos={this.state.puntos_perfiles}/>
        }
      </List>
    )  
  }
}