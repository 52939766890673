import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

registerLocale('es', es)


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const Calendario = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {fecha, Cambio_fecha, fechai, fechaf} = props
  return (
    <div>
      <IconButton   onClick={handleClickOpen}>
        <CalendarTodayIcon fontSize="large" style={{ color: '#ffffff' }} />
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
      <DialogTitle id="alert-calendario">{"Seleccionar semana"}</DialogTitle>
        <DialogContent>
          
          <DatePicker 
              locale="es"
              selected={fechai ? fechai : fecha}
              onChange={Cambio_fecha} 
              startDate={fechai ? fechai : fecha}
              endDate={fechaf ? fechaf : fecha}
              disabledKeyboardNavigation
              inline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    
  );
};

export default Calendario