import React, {Component} from 'react';
import {conexiones} from '../../procesos/servicios';
import moment from "moment";
// import Rating from '@material-ui/lab/Rating';
import socketIOClient from "socket.io-client";

import List from '@material-ui/core/List';

import { ListaPerfilesEsperar,  ListaPerfilesCabezera, ListaTopAgencias } from '../listas/lista_amolatina';

//Constantes
import {Valord, const_procesos} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

// const Filtrar = (User, personal, puntos_perfiles)=>{
//   if (['3','4'].indexOf(User.categoria)!==-1){
//     let nuevo=[]
//     personal[0].perfiles.map(f=>{
//       const pos = puntos_perfiles.filter(v=> v['user-id']===f['user-id'])
//       nuevo=[...nuevo,...pos]
//       return f
//     })
//     puntos_perfiles=[...nuevo]
    
//   }
//   return puntos_perfiles
// }

export default class Puntos_top_agencia extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          puntos_perfiles:[],
          puntos_agencia:[],
          Iniciar: this.Iniciar,
          Resumen_perfil: this.Resumen_perfil
      }
  }

  
  Iniciar_personal= async() =>{
    let User = await JSON.parse(localStorage.getItem(const_procesos.dir_user));
    const dia= moment(new Date()).format('YYYY-MM-DD')
    
    let respuesta= await conexiones.Leer_C(['Data_resumen_personal','Data_turno'], 
      {
        'Data_resumen_personal':{$and:[{fecha:{$gte:dia}}]},
        'Data_turno':{}
      }
    );
    
    let puntos_operadores=[]
    let puntos_estrellas=[]
    let perfiles=[]
    let personal=[]
    if (respuesta.Respuesta==='Ok'){
        puntos_operadores=respuesta.datos["Data_resumen_personal"];
        puntos_operadores.sort((a,b)=> a.total>b.total ? -1 : a.total<b.total ? 1 : 0)
        let porturno={}
        
        respuesta.datos["Data_turno"].map(v=>{
            const nuevo = puntos_operadores.filter(f=> f.turno===v.turno+'-'+v.pais)
            porturno[v.turno]=nuevo
            return v
        })
        puntos_operadores=porturno
    //   respuesta.datos["Data_resumen_personal"].map(dat=>{
    //     // perfiles=[...perfiles, ... dat.totales]

    //     dat.totales.map(per=>{
    //       const pos = perfiles.findIndex(f=> f['user-id']===per['user-id'])
    //       if (pos===-1){
    //         perfiles.push(per)
    //       }else{
    //         perfiles[pos].total+=per.total
    //         perfiles[pos].multa+=per.multa
    //       }
    //       return per 
    //     })

    //     dat.operadores.map(per=>{
    //       const pos = perfiles.findIndex(f=> f._id===per._id)
    //       if (pos===-1){
    //         perfiles.push(per)
    //       }else{
    //         perfiles[pos].total+=per.total
    //         perfiles[pos].multa+=per.multa
    //       }
    //       return per
    //     })
    //     return dat
    //   })
    //   puntos_perfiles=perfiles
      
    //   puntos_perfiles= puntos_perfiles.sort((a,b)=> a.total>b.total ? -1 : a.total<b.total ? 1 : 0)
      // personal= respuesta.datos["Data_personal"]
      // perfiles= respuesta.datos["Data_perfil_amolatina"]
      // perfiles= Filtrar(User,personal,perfiles)
      // puntos_estrellas= respuesta.datos['Data_punto']
      // puntos_perfiles=this.Puntaje(perfiles, respuesta.datos['Data_resumen_perfil'], puntos_estrellas)
      
    }else{
      setTimeout(()=>{
        this.Iniciar_personal()
      }, 5000)
    }
    
    this.setState({User,personal, puntos_operadores, puntos_estrellas, perfiles, Esperar:{esperar:false,mensaje:'Cargando datos...'}})
  }

  Iniciar= async() =>{

    this.setState({iniciando:true})
   
    let User = await JSON.parse(localStorage.getItem(const_procesos.dir_user));
    const resultados= await conexiones.Enviar({
      datos:{User, dia:moment.utc(new Date()).format('YYYY-MM-DD')},
      http_destino:'/api/top_agencia',
      method:'POST',
    });
    
    if (resultados.Respuesta==='Ok'){
      let puntos_agencia=resultados.puntos_agencia
      this.setState({puntos_agencia,iniciando:false, Esperar:{esperar:false}})
      
    }else{
      setTimeout(()=>{
        this.Iniciar()
      }, 5000)
    }

    
    
    // this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    // let cantidad=250;
    // let pag=0;
    // // let salir= false
    // let horaf= new Date()
    // let horai= new Date()
    // horaf.setHours(horaf.getHours()+4)
    // horaf= horaf.getHours()<10 ? `0${horaf.getHours()}` : String(horaf.getHours())
    // horai.setHours(horai.getHours()-4)
    // horai= horai.getHours()<10 ? `0${horai.getHours()}` : String(horai.getHours())
    // let User = await JSON.parse(localStorage.getItem(const_procesos.dir_user));

    // // while(!salir){
    //   let respuesta= await conexiones.Leer_C(['Data_resumen_perfil', 'Data_punto', 'Data_personal','Data_planificacion_dia', 'Data_turno', 'Data_agencia'], 
    //     {
    //       'Data_resumen_perfil':{pagina:true, cantidad, pag,
    //           sort:{fecha:-1, hora:-1},
    //           condicion:{$and:[{fecha:{$gte:moment.utc(new Date()).format('YYYY-MM-DD')}}]}},
    //       'Data_punto':{involucrado:0},
    //       'Data_personal':{cedula:User.cedula},
    //       'Data_planificacion_dia':{fecha:moment.utc(new Date()).format('YYYY-MM-DD')},
    //       'Data_turno':{},
    //       'Data_agencia':{}
    //     }
    //   );
    //   let puntos_agencia=[]
    //   let puntos_estrellas=[]
    //   let personal=[]
    //   let planificacion=[]
      
    //   if (respuesta.Respuesta==='Ok'){
          
    //       let turnos={}
    //       let resumen_perfiles=respuesta.datos['Data_resumen_perfil']
    //       let agencias=respuesta.datos['Data_agencia']
    //     respuesta.datos['Data_turno'].map(val=>{
    //         let horai = Number(val.hora_inicio.split(':')[0])-Number(val.hora.split(':')[0])
    //         let horaf = Number(val.hora_fin.split(':')[0])-Number(val.hora.split(':')[0])
    //         horai = horai>23 && horai>horaf ? 24 - horai : horai;
    //         horaf = horaf>23 && horai>horaf ? 24 - horaf : horaf;
    //         horai = horai + ':' + val.hora_inicio.split(':')[1]
    //         horaf = horaf + ':' + val.hora_fin.split(':')[1]
            
    //         const nuevo =  resumen_perfiles.filter(f=> f.hora>= horai && f.hora<horaf);
    //         let datos= []
    //         agencias.map(ag=>{
    //             const agenci = nuevo.filter(f=>ag['user-id']===f['agency-id'])
    //             let puntos = 0;
    //             agenci.map(punt=>{
    //                 puntos=puntos + punt.points
    //                 return punt
    //             })
    //             datos.push({...ag, puntos})
    //             return ag
    //         })
    //         datos.sort( (a,b) =>  a.puntos>b.puntos ? -1 :  1)
    //         turnos[val.turno]=datos
    //         return val
    //     })
    //     console.log(turnos)
    //     puntos_agencia=turnos   
    // }
      
    //   this.setState({puntos_agencia, Esperar:{esperar:false,mensaje:'Cargando datos...'}})
    //   // if (puntos_perfiles.length<cantidad){
    //   //   salir = true;
    //   // }else{
    //     pag+=1
    //   // }
    // // }
    
  }


  Puntaje = (perfiles, datos, puntos_estrellas)=>{
    let puntos_perfiles=[]
    perfiles.map( perfil =>{
        const data=  datos.filter(f=> f['user-id']===perfil['user-id'])
        let puntos=0
        let multas=0
        data.map(d=>{
            puntos+=d.points
            multas+= d.mpoints
            return d
        })
        puntos_perfiles.push({...perfil, points: puntos, mpoints: multas, puntose: this.Calcular(puntos, puntos_estrellas)})
        return perfil
    })
    puntos_perfiles= puntos_perfiles.sort((a,b)=> a.points>b.points ? -1 : a.points<b.points ? 1 : 0)
    return puntos_perfiles
  }
  async componentDidMount(){
    let User = await JSON.parse(localStorage.getItem(const_procesos.dir_user));
    this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    // if (['3','4'].indexOf(User.categoria)!==-1){
    //   this.Iniciar_personal()
    // }else{
      this.Iniciar()
    // }
    
    
    const socket=socketIOClient("/")
    socket.on('Resumen_perfil',(dato)=>{
      if (this.state.iniciando) return
      
      this.Iniciar()
    })
    
    this.setState({socket, User})
  }
  componentWillUnmount(){
      let { socket}=this.state
      if (socket!==undefined && socket!==null){
        socket.disconnect();
        socket=null
    }
  }


  Resumen_perfil = (newsocket)=>{
    let {socket, puntos_perfiles}=this.state
    
    if (socket ===undefined || socket===null) {
      socket=newsocket
      socket.on('Resumen_perfil',(dato)=>{
        const {perfiles}=this.state
        
        puntos_perfiles= this.Puntaje(perfiles,dato)
        this.setState({puntos_perfiles})
      })
      this.setState({ socket})
    }
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        // const socket = props.socket
        // state.Resumen_perfil(socket)
        
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  Calcular = (punto, puntos_estrellas)=>{      
    const pos = puntos_estrellas.findIndex(f=> punto<=f.hasta )
    if (pos!==-1){
      let valor= pos + ((punto-puntos_estrellas[pos].desde)/(puntos_estrellas[pos].hasta-puntos_estrellas[pos].desde))
      return valor
    }else{
     return 0 
    }
}

  render(){
    
    return( 
      <List 
          subheader={
            <ListaPerfilesCabezera Titulo={`Top Agencia`} />
            
          }
      >
        {this.state.Esperar.esperar ? 
          <ListaPerfilesEsperar alto={260}/>:
          <ListaTopAgencias datos={this.state.puntos_agencia} alto={260}/>
        }
      </List>
    )  
  }
}