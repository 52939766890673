import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/LockOpen';
import Formulario from '../formulario';
import {conexiones} from '../../procesos/servicios';
import {const_procesos} from '../../constantes';
import logo from '../../imagenes/logo.png';


const useStyles = makeStyles({
  root: {
    width: window.screen.height*0.45,
    marginTop:-20
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 10,
    textAlign:'justify',
    marginTop:10
  },
  div:{
      
    display: "flex",
    justifyContent:'center',
    alignItems:'center',
    // backgroundImage:'linear-gradient(180deg, #0836f2 0, #1c2a29 16.67%, #161d1c 33.33%, #0b0b0b 50%, #000000 66.67%, #000000 83.33%, #0836f2 100%)',
    backgroundColor:'rgba(0, 19, 36)',
    height:window.innerHeight,
    
  },
  logo: {
    
    width:160,
  },
  dlogo:{
    display: "flex",
    justifyContent:'center',
    alignItems:'center',
  },
  error:{
    color:'red',
    textAlign:'center'
  }
});

export default function Login(props) {
  const classes = useStyles();
  const [state, setState]= useState({});

  const cambiarState = (nuevostate)=>{
    setState({...state, ...nuevostate})
  }

  const Iniciar = async(datos) =>{

    const respuesta= await conexiones.Enviar(
        {
          datos:{...datos, mantener:true, crear: false},
          http_destino:'/api/login',
        }
    )
    
    if (respuesta.Respuesta==='OK'){
        console.log(respuesta.user)
        localStorage.setItem(const_procesos.dir_user,JSON.stringify(respuesta.user));
        window.location.reload()
        // props.Refrescar()
    }else{
        cambiarState({error:respuesta.Mensaje})
    }
  }

  const formulario={
    datos:{nombre:'',password:''},
    titulos:{
      username:{
        label: 'Usuario',
        helperText:'',
        value:'',
        type:'email',
      },
      password:{
        label: 'contraseña',
        helperText:'',
        value:'',
        tipo:'password',
        onKeyPress:Iniciar
      },
    },
    botones:[
        {
          name:'iniciar', label:'Inciar', title:'Iniciar sesion',
          variant:"contained", color:"primary", icono:<CheckIcon />,
          onClick:Iniciar
        },
    ],
    estilospage:{
      height:220
    }
  };

  return (
    <div className={classes.div}>
        <Card  variant="outlined">
            <CardContent>
                
                <Typography variant="h5" component="h2">
                    INICIAR
                </Typography>
                <div className={classes.dlogo}>
                    <img src={logo} className={classes.logo} alt="logo" />
                </div>
                
                <Formulario {...formulario}/>
                
                {state.error ? 
                    <div style={{marginTop: -10}}>
                        <Divider />
                    
                        <Typography className={classes.error}>
                            {state.error}
                        </Typography>
                    </div>:
                    null
                }
                
                <Typography className={classes.pos} color="textSecondary">
                    Si no posee usuario comuníquese con el administrador
                </Typography>
                
            </CardContent>
        </Card>
    </div>
  );
}