import React, {Component} from 'react';
import Avatar from '@material-ui/core/Avatar';

import Button from '@material-ui/core/Button';

import socketIOClient from "socket.io-client";
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { Scrollbars } from 'rc-scrollbars';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';


//Constantes
import {Valord} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

export default class Presentes extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          usuarios:[],
      }
  }

  Usuarios= async(dato) =>{
    console.log('Presentes recibidos', dato)
    let usuarios=[]
    Object.keys(dato).map(p=>{

        console.log(dato[p])
        usuarios.push(dato[p])
        return p
    })
    this.setState({usuarios,Esperar:{esperar:false,mensaje:'Cargando datos...'}})
  }

  componentDidMount(){
    this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    const socket=socketIOClient("/")
    socket.on('Usuario_presentes',(dato)=>{
        this.Usuarios(dato)
    })
    socket.emit('Presentes')
    this.setState({socket})
  }

  componentWillUnmount(){
      let { socket}=this.state
      if (socket!==undefined && socket!==null){
        socket.disconnect();
        socket=null
    }
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        // const socket = props.socket
        // state.Resumen_perfil(socket)
        
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }


  render(){
    
    return this.state.Esperar.esperar ? 
      <div style={{backgroundColor:'#E4DDDD', padding:5, border:1, borderRadius:10, marginBottom:10}}>
          <Button variant="contained" onClick={()=>{this.state.socket.emit('Presentes')}}>Default</Button>
        {[0,1,2,3].map(v=>
          <div  key={v} style={{display:'flex', marginBottom:10}}>
                <div style={{alignItems: 'center', justifyContent:'center', alignContent:'center', textAlign:'center', marginRight:10}}>
                  <Skeleton variant="circle" width={40} height={40} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </div>
                <Skeleton variant="rect" width={'80%'} height={'80'} />  
          </div>
        )}    
      </div>:
      <List 
          subheader={
            <ListSubheader component="div" id="nested-list-subheader"
              style={{
                backgroundColor:config.Estilos.barra.backgroundColor, color:'#ffffff',
                borderTop:10, borderTopLeftRadius:10,
                borderTopRightRadius:10
              }}
              disableGutters
            >
              {`Usuarios Conectados`}
            </ListSubheader>
          }
      >
        <Scrollbars autoHide autoHeight autoHeightMax={320}>
          {this.state.usuarios.map((user,i) =>
            <ListItem 
              key= {i+'-' + user.username} style={{backgroundColor:'#E4DDDD', padding:5, border:1, borderRadius:10, marginBottom:10}}
            >
              <ListItemAvatar>
                  <div style={{alignItems: 'center', justifyContent:'center', alignContent:'center', textAlign:'center', padding:10}}>
                    <Avatar
                      style={{marginLeft:15}}
                      alt={`${user.username}`}
                      src={`${user.foto}`}
                    />
                    <Typography variant="caption" display="block" gutterBottom>
                        {`${user.username}`}
                    </Typography>
                    
                  </div>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="caption" display="block" gutterBottom>
                    {`${user.nombres===undefined ? '' : user.nombres} ${user.apellidos===undefined ? '':user.apellidos}`}
                  </Typography>
                }
                
              />
            </ListItem>
                
          )}
        </Scrollbars>
      </List>
      
  }
}