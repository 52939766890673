import React, {Component} from 'react';
import {Paginas, Titulo_default, Resultado_encontrados, conexiones} from '../../procesos/servicios';
import Page from './page';

const itemsF=20;

class Tabla extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props:this.props,
          pagina:1,
          buscar:'',
          items:itemsF,
          datos:[]
      }
  }
  actualizando = false;

  Cargar_todo = async(Titulo,cantidad, table, datos, items=itemsF, condicion=null, cargacompleta=null, ordenar=null) =>{
    
    if (cantidad> datos.length && !this.actualizando){
      
      this.actualizando=true;
      this.setState({actualizando:true});
      const paginas= cantidad / items;
      let pag=1;
      let nuevodatos=datos;
      while (pag<paginas+1){
        let resultados= await conexiones.Leer_C([table], 
                                              {
                                                [table]:{pagina:true, cantidad:items, pag},
                                                
                                              }
                                              );
        nuevodatos=[...nuevodatos, ...resultados.datos[table]];
        pag+=1;
        
        let paginacion =Paginas({
          datos:nuevodatos, cantp: items,
          pagina: this.state.pagina,
          buscar: this.state.buscar
        });
        paginacion.cantidad=items;
        if (condicion){
          nuevodatos= condicion(nuevodatos);
        }
        const progreso = nuevodatos.length * 100 / cantidad;
        nuevodatos= ordenar ? ordenar(nuevodatos) : nuevodatos
        this.setState({datos:nuevodatos, paginacion, progreso})
      }
      // console.log('Finalizo >>>>>>>>>>>', nuevodatos.length)
      if (cargacompleta){
        
        cargacompleta({Titulo, datos:nuevodatos, cantidad});
      }
      this.actualizando=false;
      this.setState({actualizando:false});
    }
  }

  Iniciar_descarga = async(table,Titulo, ordenar, items, cargacompleta)=>{
    
    const respuesta = await conexiones.Leer([table])
    if (respuesta.Respuesta==='Ok'){
      let datos=respuesta.datos[table]
      let cantidad=respuesta.datos[table+'_cantidad']
      datos= ordenar ? ordenar(datos) : datos
      // if (cantidad<items) {
        cargacompleta({Titulo, datos, cantidad});
      // }
      // return {datos,cantidad}
    }else{
      let datos=[]
      let cantidad=0
      cargacompleta({Titulo, datos, cantidad});
    }

  }

  async componentDidMount(){
    let {Titulo,titulos, datos, items, cantidad, table, condicion, cargacompleta, ordenar}= this.state.props;
    // console.log('>>>>> Primera', cantidad, table)
    items= items===undefined ? itemsF : items;
    if (cantidad ===-1){
      this.Iniciar_descarga(table,Titulo, ordenar, items, cargacompleta)
    }
    
    if (cantidad>items)
        this.Cargar_todo(Titulo,cantidad, table, datos, items, condicion, cargacompleta, ordenar);
    let {pagina, buscar}=this.state;
    // datos= Object.keys(datos).map(valor=> datos[valor]);
    datos= ordenar ? ordenar(datos) : datos
    let paginacion =Paginas({
                              datos, cantp: items,
                              pagina,buscar
                            });
    if (titulos===undefined && datos.length!==0){
      titulos= Titulo_default(datos[0]);
    }
    this.setState({ordenar, titulos, datos, paginacion, 
                   total:datos, items, Cargar_todo:this.Cargar_todo, 
                   Iniciar_descarga:this.Iniciar_descarga, cantidad});
  }


  static getDerivedStateFromProps(props, state) {
    // Store prevId in state so we can compare when props change.
    // Clear out previously-loaded data (so we don't render stale stuff).
    if (props !== state.props ) {
      
      let {Titulo,datos, titulos, items, cantidad, table, condicion, cargacompleta, ordenar}= props;
      // console.log('>>>>> Segunda', cantidad)
      items= items===undefined ? itemsF : items;
      if (cantidad ===-1){
        state.Iniciar_descarga(table,Titulo, ordenar, items, cargacompleta)
      }
      // datos= Object.keys(datos).map(valor=> datos[valor])
      
      datos= ordenar ? ordenar(datos) : datos
      
      if (titulos===undefined && datos.length!==0){
        titulos= Titulo_default(datos[0]);
      }
      
      if (cantidad>items)
        state.Cargar_todo(Titulo,cantidad, table, datos, items, condicion, cargacompleta, ordenar);
      
      let paginacion =Paginas({
                                datos, cantp: items,
                                pagina: state.pagina,
                                buscar: state.buscar
                              });
      paginacion.cantidad=items;
      paginacion.datos=datos;
      
      return {  
        props,
        datos,
        paginacion,
        titulos, total:datos,
        items, table,
        ordenar
      };
    }
    // No state update necessary
    return null;
  }

  Cambio_pagina = (e, page)=>{
    let {pagina, datos, items}= this.state;
    pagina= page;
    let paginacion =Paginas({datos, cantp: items, pagina: pagina, buscar: this.state.buscar});
    this.setState({paginacion, pagina});
  }

  Accion = (valor)=>{

    if (this.state.props.Accion){
      this.state.props.Accion(valor);
    }else{
      console.log('No se asigno accion');
    }

  }

  Buscar = (e)=>{
    let {pagina, datos, total, buscar, items}= this.state;
    const {name, value}=e.target;
    pagina=1;
    if (buscar===''){
      total=datos;
      datos=[];
    }else if (value===''){
      datos=total;
    }
    if (value!==''){
      datos=Resultado_encontrados(total,value);
    }
    let paginacion =Paginas({datos, cantp: items, pagina: pagina, buscar: value});


    this.setState({[name]:value, pagina, total, paginacion, datos})
  }
  
  render(){
    return (
      <Page  {...this.props}
            datos={this.state.paginacion ?
                    this.state.paginacion.datos :
                    []
                  }
            Esperar={this.actualizando}
            titulos={this.state.titulos}
            Cambio={this.Cambio_pagina}
            Pagina={this.state.pagina}
            paginacion={this.state.paginacion}
            actualizando={this.state.actualizando}
            progreso={this.state.progreso}
            Accion={this.Accion}
            buscar={this.state.buscar}
            cambio_buscar={this.Buscar}
      />
    )
  }
}

export default Tabla;
