import {const_procesos, Valord} from '../../constantes';
// import {categoria_usuario} from '../../constantes/datos';
// import {categorias} from '../datos/usuarios';
// localStorage.setItem(const_procesos.dir_config,null);
import {conexiones} from './conexiones';

let config;
let categoria_usuario;

export const formatoBolivar = new Intl.NumberFormat('es-VE', {
  style: 'currency',
  currency: 'VES',
  minimumFractionDigits: 2
})

export const formatoDolar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})

const Inicio=async()=>{
  try{
    config = await JSON.parse(localStorage.getItem(const_procesos.dir_config));  
  }catch(error) {
    console.log('error');
  }
  if (config===null || config===undefined){
    const respuesta = await conexiones.Leer_data('data/datos.json');
    if (respuesta.Respuesta==='Ok') {
      await localStorage.setItem(const_procesos.dir_config,respuesta.datos);
      config=JSON.parse(respuesta.datos);
    }else{
      config=Valord
    }
    
  }
  categoria_usuario= config.categoria_usuario;
}
Inicio();

export const MaysPrimera=(string)=>{
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const ArrayMaysPrimera=(datos)=>{
  return datos.map((valor)=> MaysPrimera(valor))
}

export const Titulo_default=(datos)=>{
  return Object.keys(datos).map((valor)=> {
    // if (valor === 'categoria') {
    //   return { title: MaysPrimera(valor), field: valor, lookup: categorias }
    // }else
    if (['createdAt','updatedAt','fecha', 'Fecha'].indexOf(valor)!==-1){
      return { title: MaysPrimera(valor), field: valor , editable: 'never' , fecha:true}
    }else if (['_id','__v','createdAt','updatedAt',
               'actualizado', 'cod_chs', 'seq_chs', 'hash_chs'].indexOf(valor)!==-1){
      return { title: MaysPrimera(valor), field: valor , editable: 'never'}
    }else{
      return { title: MaysPrimera(valor), field: valor }
    }
  })
}

export const Permiso =  (accion, superadmin=false) =>{
  let User = JSON.parse(localStorage.getItem(const_procesos.dir_user));
  let resultado=categoria_usuario.filter(lis => String(lis._id)===String(User.categoria));
  if (!superadmin) {
    if (resultado.length!==0 && resultado[0].permisos!==undefined &&
          (resultado[0].permisos.indexOf(accion)!==-1 || resultado[0].permisos.indexOf('*')===0)
        ) {
      return true;

    }else {
      return false;
    }
  }else{
    if (resultado.length!==0 && resultado[0].permisos!==undefined && resultado[0].permisos.indexOf('**')===1) {
      return true;
    }else{
      return false;
    }
  }


}

export const Quitar_valores = (props) =>{
  const {datos,quitar}=props;
  let resultado={}
  Object.keys(datos).map((campo)=>{
    if (quitar.indexOf(campo)===-1){
      resultado={...resultado,[campo]:datos[campo]};
    }
    return campo;
  })
  return resultado;
}

export const Resultado_encontrados = (datos,valor) =>{
  let resultado=[];
  if (datos !== undefined)
    datos.map((dato)=>{
      if (Ver_igualdad(dato,valor)){
        resultado.push(dato);
      }
      return valor;
    })
  return resultado
}


export const Ver_igualdad = (dato, valor)=>{
  
    if (dato=== null || dato ===undefined)
      return false;
    const campos= Object.keys(dato);
    delete dato['$setOnInsert']
    const no=['password','passwordA','token'];
    let resultado=false;
     campos.map((campo)=>{
       if (typeof dato[campo]==='object' && dato[campo]!==null){
        dato[campo].map( val=>{
          if (Ver_igualdad(val,valor)){
            resultado=true;
          }
          return val
        })
       }
       const val= '' + dato[campo];
       if ((no.indexOf(campo)===-1 && val.toLowerCase().indexOf(valor.toLowerCase())!==-1)|| valor===''){
         resultado=true;
       }
       return campo
     })
    return resultado;
}

export const Resultado_encontrados_k = (datos,valor) =>{
  let resultado=[];
  datos.map((dato)=>{
    if (Ver_igualdad_K(dato,valor)){
      resultado.push(dato);
    }
    return valor;
  })
  return resultado
}

export const Resultado_encontrados_k_p = (datos,valor) =>{
  let resultado=-1;
  for(let i=0; i<datos.length;i++){
    if (Ver_igualdad_K(datos[i],valor)){
      resultado=i;
      break;
    }

  }
  return resultado
}

export const Ver_igualdad_K = (dato, valor)=>{
    const campos= Object.keys(valor);
    let resultado=false;
    let cont=0;
     campos.map((campo)=>{
       if (''+dato[campo]===''+valor[campo]){
         cont++;
       }
       return campo
     })

     if (cont>=campos.length){
       resultado=true;
     }
    return resultado;
}

export const Buscar_array = (datos,valor) => {
  let resultado=[];
  datos.map((dato)=>{
    let igual =0;

    valor.map((v,i)=> {
      if (v===dato[i]) {
        igual++;
      }
      return v;
    })
    if (igual===valor.length){
      resultado.push(dato);
    }

    return dato;
  })
  return resultado;
}
export const Buscar_array_posicion = (datos,valor) => {
  let resultado=-1;
  for (let i=0;i < datos.length;i++){

      if (valor===datos[i][0]){
        resultado=i;
        break;
      }
  }
  return resultado;
}

export const Paginas = (props) =>{
  let rdatos=[];
  let paginas=[1];
  // let cont=0;
  // let contp=2;
  const {datos,cantp,pagina,buscar}=props;
  const inicio=cantp*(pagina-1);
  const fin=inicio+cantp;
  const datosE=Resultado_encontrados(datos,buscar);
  

  for (var i=0;i<datosE.length;i++){
    if (i>inicio-1 && i < fin){
      // if (buscar===''){
        rdatos.push({...datosE[i],id:i+1});

      // }else if (Ver_igualdad(datos[i],buscar)){
        // rdatos.push({...datos[i],id:i+1});

      // }
    }
    // if (buscar===''){
      // cont++;
    // }else if (Ver_igualdad(datos[i],buscar)){
    //   cont++;
    //   contr++;
    // }

    // if (cont>cantp){
    //   cont=0;
    //   paginas.push(contp);
    //   contp++;
    // }
  }
  // if (cont>0 && contp!==2){
  //   paginas.push(contp);
  // }
  paginas=[1];
  for ( i=2; i<(datosE.length/cantp)+1;i++){
    paginas.push(i);
  }
  const comienza=inicio+1;
  const finaliza= datosE.length< fin ? datosE.length : fin;
  const total='('+comienza+' al '+finaliza+') <> (Total:'+datosE.length+')';
  return {datos:rdatos,paginas:paginas,total:total}

}

export var numeroALetras = (function() {
  // Código basado en el comentario de @sapienman
  // Código basado en https://gist.github.com/alfchee/e563340276f89b22042a
  function Unidades(num) {

      switch (num) {
          case 1:
              return 'UN';
          case 2:
              return 'DOS';
          case 3:
              return 'TRES';
          case 4:
              return 'CUATRO';
          case 5:
              return 'CINCO';
          case 6:
              return 'SEIS';
          case 7:
              return 'SIETE';
          case 8:
              return 'OCHO';
          case 9:
              return 'NUEVE';
          default:
              return '';
      }

      // return '';
  } //Unidades()

  function Decenas(num) {

      let decena = Math.floor(num / 10);
      let unidad = num - (decena * 10);

      switch (decena) {
          case 1:
              switch (unidad) {
                  case 0:
                      return 'DIEZ';
                  case 1:
                      return 'ONCE';
                  case 2:
                      return 'DOCE';
                  case 3:
                      return 'TRECE';
                  case 4:
                      return 'CATORCE';
                  case 5:
                      return 'QUINCE';
                  default:
                      return 'DIECI' + Unidades(unidad);
              }
          case 2:
              switch (unidad) {
                  case 0:
                      return 'VEINTE';
                  default:
                      return 'VEINTI' + Unidades(unidad);
              }
          case 3:
              return DecenasY('TREINTA', unidad);
          case 4:
              return DecenasY('CUARENTA', unidad);
          case 5:
              return DecenasY('CINCUENTA', unidad);
          case 6:
              return DecenasY('SESENTA', unidad);
          case 7:
              return DecenasY('SETENTA', unidad);
          case 8:
              return DecenasY('OCHENTA', unidad);
          case 9:
              return DecenasY('NOVENTA', unidad);
          case 0:
              return Unidades(unidad);
          default: return ''
      }
  } //Unidades()

  function DecenasY(strSin, numUnidades) {
      if (numUnidades > 0)
          return strSin + ' Y ' + Unidades(numUnidades)

      return strSin;
  } //DecenasY()

  function Centenas(num) {
      let centenas = Math.floor(num / 100);
      let decenas = num - (centenas * 100);

      switch (centenas) {
          case 1:
              if (decenas > 0)
                  return 'CIENTO ' + Decenas(decenas);
              return 'CIEN';
          case 2:
              return 'DOSCIENTOS ' + Decenas(decenas);
          case 3:
              return 'TRESCIENTOS ' + Decenas(decenas);
          case 4:
              return 'CUATROCIENTOS ' + Decenas(decenas);
          case 5:
              return 'QUINIENTOS ' + Decenas(decenas);
          case 6:
              return 'SEISCIENTOS ' + Decenas(decenas);
          case 7:
              return 'SETECIENTOS ' + Decenas(decenas);
          case 8:
              return 'OCHOCIENTOS ' + Decenas(decenas);
          case 9:
              return 'NOVECIENTOS ' + Decenas(decenas);
          default: Decenas(decenas);
      }

      // return Decenas(decenas);
  } //Centenas()

  function Seccion(num, divisor, strSingular, strPlural) {
      let cientos = Math.floor(num / divisor)
      let resto = num - (cientos * divisor)

      let letras = '';

      if (cientos > 0)
          if (cientos > 1)
              letras = Centenas(cientos) + ' ' + strPlural;
          else
              letras = strSingular;

      if (resto > 0)
          letras += '';

      return letras;
  } //Seccion()

  function Miles(num) {
      let divisor = 1000;
      let cientos = Math.floor(num / divisor)
      let resto = num - (cientos * divisor)

      let strMiles = Seccion(num, divisor, 'UN MIL', 'MIL');
      let strCentenas = Centenas(resto);

      if (strMiles === '')
          return strCentenas;

      return strMiles + ' ' + strCentenas;
  } //Miles()

  function Millones(num) {
      let divisor = 1000000;
      let cientos = Math.floor(num / divisor)
      let resto = num - (cientos * divisor)

      let strMillones = Seccion(num, divisor, 'UN MILLON DE', 'MILLONES DE');
      let strMiles = Miles(resto);

      if (strMillones === '')
          return strMiles;

      return strMillones + ' ' + strMiles;
  } //Millones()

  return function NumeroALetras(num, currency) {
      currency = currency || {};
      let data = {
          numero: num,
          enteros: Math.floor(num),
          centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
          letrasCentavos: '',
          letrasMonedaPlural: currency.plural || 'PESOS CHILENOS', //'PESOS', 'Dólares', 'Bolívares', 'etcs'
          letrasMonedaSingular: currency.singular || 'PESO CHILENO', //'PESO', 'Dólar', 'Bolivar', 'etc'
          letrasMonedaCentavoPlural: currency.centPlural || 'CHIQUI PESOS CHILENOS',
          letrasMonedaCentavoSingular: currency.centSingular || 'CHIQUI PESO CHILENO'
      };

      if (data.centavos > 0) {
          data.letrasCentavos = 'CON ' + (function() {
              if (data.centavos === 1)
                  return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular;
              else
                  return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural;
          })();
      };

      if (data.enteros === 0)
          return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
      if (data.enteros === 1)
          return Millones(data.enteros) + ' ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos;
      else
          return Millones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
  };

})();