import React, {Component} from 'react';
import {conexiones} from '../../procesos/servicios';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Scrollbars } from 'rc-scrollbars';

import {Valord} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;
const categoria_usuario= config.categoria_usuario;

export default class Ver_calendario extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          semanas:[],
          semanas_perfil:[],
          per_perf:true,
      }
  }
  
  
  async componentDidMount(){ 
    let semanas=[]
    let semanas_perfil=[] 
    if (this.props.datos){
        console.log(this.props.datos)
    }else{
        let inicio = this.props.calendario.fechai;
        // inicio.setHours(inicio.getHours() - inicio.getHours(),0,0)
        let fin =this.props.calendario.fechaf;

        let respuesta= await conexiones.Leer_C(['Data_planificacion', 'Data_personal', 
                                                'Data_perfil_amolatina', 'Data_turno'], 
            {
                'Data_planificacion':{$and:[{fecha:{$gte:inicio}}, {fecha:{$lte:fin}}]},
                'Data_personal':{$or:[{categoria:3}, {categoria:4}]},
                'Data_perfil_amolatina':{suspended: false},
                'Data_turno':{}
            }
        );
        
        
        if (respuesta.Respuesta==='Ok'){
            respuesta.datos['Data_perfil_amolatina'].map(per=>{
                semanas_perfil.push({...per, 
                    dias:{Lunes:{perfiles:[],estado:false}, Martes:{perfiles:[],estado:false},
                        Miercoles:{perfiles:[],estado:false}, Jueves:{perfiles:[],estado:false},
                        Viernes: {perfiles:[],estado:false}, Sabado: {perfiles:[],estado:false},
                        Domingo:{perfiles:[],estado:false}}
                })
                return per
            })

            let masturnos={}
            let turnos = respuesta.datos['Data_turno'].map(p=>{
                if (p.descanso){
                    const tur= `Break/Corte (${p.turno.split('(')[1].split(')')[0]})`
                    if (masturnos[tur]===undefined){
                        masturnos[tur]={...p}
                        masturnos[tur].turno=tur
                        masturnos[tur].hora_inicio=p.hora_inicio_b
                    }
                    masturnos[tur].hora_inicio=masturnos[tur].hora_inicio> p.hora_inicio_b ? p.hora_inicio_b : masturnos[tur].hora_inicio
                    masturnos[tur].hora_fin=masturnos[tur].hora_fin< p.hora_fin ? p.hora_fin : masturnos[tur].hora_fin   
                }
                return {...p}
            })
            Object.keys(masturnos).map(t=>{
                turnos.push(masturnos[t])
                return t
            })
            respuesta.datos['Data_personal'].map(p=>{
                const pos=  turnos.findIndex(v=>v.turno===p.turno.split('-')[0])
                let dato={
                    _id:p._id,
                    apellidos: p.apellidos, categoria: p.categoria,
                    cedula: p.cedula,
                    foto: p.foto,
                    nombres: p.nombres,
                    turno: p.turno,
                    hora_inicio: turnos[pos].hora_inicio,
                    hora_fin:turnos[pos].hora_fin,
                    dias:{Lunes:false, Martes:false, Miercoles:false, Jueves:false, Viernes: false, Sabado: false, Domingo:false}
                }
                respuesta.datos['Data_planificacion'].map(pla=>{
                    const pos = pla.personal.findIndex(v=>v._id===p._id)
                    if (pos!==-1){
                        dato.dias[pla.dia]=true
                        p.perfiles.map(per=>{
                            const pos_p= semanas_perfil.findIndex(v=>v._id===per._id)
                            semanas_perfil[pos_p].dias[pla.dia].estado=true
                            const pos_o = semanas_perfil[pos_p].operadores.findIndex(v=> v._id===p._id)
                            if (pos_o!==-1){
                                const pos_o1= semanas_perfil[pos_p].dias[pla.dia].perfiles.findIndex(v=> v.turno===p.turno)
                                if (pos_o1===-1){
                                    semanas_perfil[pos_p].dias[pla.dia].perfiles.push({...semanas_perfil[pos_p].operadores[pos_o]})
                                }else{
                                    const pos_o2= semanas_perfil[pos_p].operadores.findIndex(v=> v._id===semanas_perfil[pos_p].dias[pla.dia].perfiles[pos_o1]._id)
                                    if (pos_o<pos_o2){
                                        semanas_perfil[pos_p].dias[pla.dia].perfiles[pos_o1]={...semanas_perfil[pos_p].operadores[pos_o]}
                                    }
                                }
                            }
                            return per
                        })
                    }
                    
                    return pla
                })
                semanas.push(dato)
                return p
            })
        }
        semanas.sort((a,b)=> a.turno>b.turno ? 1 : a.turno<b.turno ? -1 : 0)
    }    
    this.setState({semanas, semanas_perfil})
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        console.log('por este en turno')
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  estilo=(valor)=>{

    return{ 
        textAlign:'center', height:'100%',
        alignItems:'center', 
        color:'#ffffff',
        backgroundColor: valor ? config.Estilos.barra_menu.backgroundColor : '#ffffff'
    }
  }
  render_personal = (personal)=>{
      let titulo='';
      personal.map(valor=>{
        titulo+=`${valor.nombres} ${valor.turno} \n`
        return valor
      })
      return(
        <div title={titulo} style={{display: 'flex', flexFlow: 'column'}}>
          {personal.map(valor=>
            <Typography variant="caption" componet={'h2'}gutterBottom noWrap key={valor._id}>
                {`${valor.nombres} ${valor.turno}`} 
            </Typography>
            )}
        </div>
      )
  }
  render(){
    return(
        <div style={{width:'100%'}}>
            <FormControlLabel
                control={<Switch checked={this.state.per_perf} onChange={()=>this.setState({per_perf:!this.state.per_perf})} />}
                label={!this.state.per_perf ? 'Perfiles' : 'Personal' }
            />
            <Collapse in={this.state.per_perf}>
                <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={5} >
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Personal
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={1} >
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Lunes
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={1}>
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Martes
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={1}>
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Miercoles
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={1}>
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Jueves
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={1}>
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Viernes
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={1}>
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Sabado
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={1}>
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Domingo
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Scrollbars style={{padding:10, height: window.screen.height * 0.55 }}>
                    {this.state.semanas.map(valor=>
                        <Grid key={valor._id} container item xs={12} spacing={1}>
                                <Grid item xs={5} >
                                    <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                <Avatar
                                                alt={`${valor.nombres}`}
                                                src={`${valor.foto}`}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText 
                                                primary={ 
                                                <div>
                                                    <Typography variant="h6" gutterBottom noWrap >
                                                        {`${valor.nombres} ${valor.apellidos} (${categoria_usuario[valor.categoria].titulo})`} 
                                                    </Typography>
                                                    <Typography variant="caption" gutterBottom noWrap >
                                                        {`${valor.turno} ${valor.hora_inicio} - ${valor.hora_fin}`} 
                                                    </Typography>
                                                </div>
                                                }
                                            />

                                        </ListItem>

                                    </Paper>
                                </Grid>
                                <Grid item xs={1} >
                                    <Paper style={this.estilo(valor.dias.Lunes)} title={`${valor.turno}`}>
                                    </Paper>
                                </Grid>
                                <Grid item xs={1}>
                                    <Paper style={this.estilo(valor.dias.Martes)} title={`${valor.turno}`}>
                                    </Paper>
                                </Grid>
                                <Grid item xs={1}>
                                    <Paper style={this.estilo(valor.dias.Miercoles)} title={`${valor.turno}`} >
                                    </Paper>
                                </Grid>
                                <Grid item xs={1}>
                                    <Paper style={this.estilo(valor.dias.Jueves)} title={`${valor.turno}`}>
                                    </Paper>
                                </Grid>
                                <Grid item xs={1}>
                                    <Paper style={this.estilo(valor.dias.Viernes)} title={`${valor.turno}`}>
                                        
                                    </Paper>
                                </Grid>
                                <Grid item xs={1}>
                                    <Paper style={this.estilo(valor.dias.Sabado)} title={`${valor.turno}`}>
                                    </Paper>
                                </Grid>
                                <Grid item xs={1}>
                                    <Paper style={this.estilo(valor.dias.Domingo)} title={`${valor.turno}`}>
                                    </Paper>
                                </Grid>
                            </Grid>      
                    )}
                    </Scrollbars>
                </Grid>
            </Collapse>
            <Collapse in={!this.state.per_perf}>
                <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={5} >
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Perfil
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={1} >
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Lunes
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={1}>
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Martes
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={1}>
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Miercoles
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={1}>
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Jueves
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={1}>
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Viernes
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={1}>
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Sabado
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={1}>
                            <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                <Typography variant="h6" gutterBottom noWrap >
                                    Domingo
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Scrollbars style={{padding:10, height: window.screen.height * 0.55 }}>
                    {this.state.semanas_perfil.map(valor=>
                        <Grid key={valor._id} container item xs={12} spacing={1}>
                            <Grid item xs={5} >
                                <Paper style={{...this.estilo(), backgroundColor:config.Estilos.barra.backgroundColor}}>
                                    <ListItem button>
                                        <ListItemAvatar>
                                            <Avatar
                                            alt={`${valor.nombres}`}
                                            src={`https://api7.amolatina.com/users/${valor['user-id']}/photos/${valor.thumbnail}.490x480.thumb-fd`}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText 
                                            primary={ 
                                            <div>
                                                <Typography variant="h6" gutterBottom noWrap >
                                                {`${valor['first-name']}`} 
                                                </Typography>
                                            </div>
                                            }
                                        />

                                    </ListItem>

                                </Paper>
                            </Grid>
                            <Grid item xs={1} >
                                <Paper style={this.estilo(valor.dias.Lunes.estado)}>{this.render_personal(valor.dias.Lunes.perfiles)}</Paper>
                            </Grid>
                            <Grid item xs={1}>
                                <Paper style={this.estilo(valor.dias.Martes.estado)}>{this.render_personal(valor.dias.Martes.perfiles)}</Paper>
                            </Grid>
                            <Grid item xs={1}>
                                <Paper style={this.estilo(valor.dias.Miercoles.estado)}>{this.render_personal(valor.dias.Miercoles.perfiles)}</Paper>
                            </Grid>
                            <Grid item xs={1}>
                                <Paper style={this.estilo(valor.dias.Jueves.estado)}>{this.render_personal(valor.dias.Jueves.perfiles)}</Paper>
                            </Grid>
                            <Grid item xs={1}>
                                <Paper style={this.estilo(valor.dias.Viernes.estado)}>{this.render_personal(valor.dias.Viernes.perfiles)}</Paper>
                            </Grid>
                            <Grid item xs={1}>
                                <Paper style={this.estilo(valor.dias.Sabado.estado)}>{this.render_personal(valor.dias.Sabado.perfiles)}</Paper>
                            </Grid>
                            <Grid item xs={1}>
                                <Paper style={this.estilo(valor.dias.Domingo.estado)}>{this.render_personal(valor.dias.Domingo.perfiles)}</Paper>
                            </Grid>
                        </Grid>      
                    )}
                    </Scrollbars>
                </Grid>
            </Collapse>
        </div>  
      
    )
  }
}