import React, {Component} from 'react';
// import {conexiones} from '../../procesos/servicios';
import Esperar from '../esperar';
import Pagee from '../conexiones';
import Dialogo from '../formulario/dialogo';
import IconButton from '@material-ui/core/IconButton';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import Typography from '@material-ui/core/Typography';
import {ItemTituloSupervisor, ItemPerfiles} from '../conexiones/item';
import Pbreak from './personalbreak'
//Asignar operadores a supervisores
export default class Operador extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{Libres:[],Grupos:[]},
          personalb:[],
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          Iniciar: this.Cancelar,
          Actualizar:this.Actualizar,
          Dialogo:{
            open:false,
            Titulo:'Mi cuenta',
            tam:'xl',
            Cerrar: this.Cerrar_dialogo,
          }
      }
  }
  

  Cancelar = async()=>{
    // console.log('Cancelar en operadorvsperfil')
    this.Iniciar(this.state.props.rdatos)
  }

  Iniciar= async(valores) =>{
    
    let personalb=[]
    let datos = {
      Grupos:[],
      Libres:[],
    }

    if (valores.operador_vs_perfil){
      datos.Grupos=valores.operador_vs_perfil.Grupos.map(g=>{
        let childen = g.childen.map(h=>{
          return {...h, titulo:<ItemPerfiles key={h._id} op={{...h}}/>}
        })
        return {...g, childen, titulo: <ItemTituloSupervisor key={g._id} sup={{...g, operadores:g.childen}}/>}
      })
      datos.Libres=valores.operador_vs_perfil.Libres.map(val=>{
        
        return {...val, titulo:<ItemPerfiles op={{...val, perfil:true, key:val._id,}}/>}
      })
      personalb=valores.operador_vs_perfil.personalb ? valores.operador_vs_perfil.personalb : []
      personalb= personalb.map(g=>{
        let childen = g.childen.map(h=>{
          return {...h, titulo:<ItemPerfiles key={h._id} op={{...h}}/>}
        })
        return {...g, childen, titulo: <ItemTituloSupervisor key={g._id} sup={{...g, operadores:childen}}/>}
      })
      
    }else if(valores.asignados && valores.supervisor_vs_operador){
      
      valores.asignados.Grupos.map(a=>{
        
        a.childen.map(h=>{
          const childen = []
          datos.Grupos.push(
            {...h, key:h._id, 
              titulo:<ItemTituloSupervisor sup={{...h,  childen}}/>, 
              childen
            }
          )
          return h
        })
        return a
      })
      // valores.personal.map(val=>{
      //   const childen = [] //val.perfiles.filter(o=>{
      //   //   if (o.turno === val.turno)
      //   //     return {...o, key:o._id, titulo:<Item_perfiles op={{...o, perfil:false}}/>}
      //   //   return -1
      //   // })
      //   datos.Grupos.push(
      //     {...val, key:val._id, 
      //       titulo:<Item_titulo_supervisor sup={{...val, cantidad: childen.length, childen}}/>, 
      //       childen 
      //   })
      //   return val    
      // })
      personalb=datos.Grupos.filter(f=> f.turno.indexOf('Break')!==-1)
      datos.Grupos = datos.Grupos.filter(f=> f.turno.indexOf('Break')===-1).sort((a, b) => (a.turno > b.turno ? 1 : a.turno < b.turno ? -1 : 0))
      
    }
    datos.Libres = valores.perfiles.map( p =>{
      let operadores=[]//=p.operadores
      datos.Grupos.map(g=>{
        const pos = operadores.findIndex(v=> v._id===g._id) 
        const post = operadores.findIndex(v=> v.turno===g.turno) 
        if (g.childen.findIndex((v)=> v._id===p._id)!==-1){
          const anterior = post===-1 || operadores[post]._id===g._id ? '' :  operadores[post].turno
          if (pos===-1){
            operadores.push({
              _id:g._id, nombres:g.nombres, apellidos:g.apellidos, 
              turno:g.turno, cedula:g.cedula, foto:g.foto, 
              suplente: anterior!==g.turno ? '' : 'Suplente'
            })
          }else{
            operadores[pos]={
              _id:g._id, nombres:g.nombres, apellidos:g.apellidos, 
              turno:g.turno, cedula:g.cedula, foto:g.foto, 
              suplente: anterior!==g.turno ? '' : 'Suplente'
            }
          }
        }else if (pos!==-1) {
          operadores.splice(pos, 1)
        }
        return g
      })
      operadores.sort((a,b)=> a.turno > b.turno ? 1 : a.turno < b.turno ? -1 : 0)
      return {...p, key:p._id, operadores, titulo:<ItemPerfiles op={{...p, perfil:true, operadores}}/>}
    })
    this.setState({personalb, datos, Quitarl: false, Quitarg: false, 
      TituloL:'Perfiles', 
      TituloG:
      <div style={{display:'flex'}}>
          <Typography variant="h5" component="h4" gutterBottom 
            style={{ color:'#ffffff', marginTop:10}}
          >
            {'Supervisores / Operadores'}
          </Typography>
          <IconButton aria-label="atras" onClick={this.Ver}>
            <FindInPageIcon  style={{ color: '#ffffff' }}/>
          </IconButton>
          
        </div>,  
      Esperar:{esperar:false}
    })
    
    this.Actualizar({...datos, personalb})
  }
  
  Ver = () =>{
    let {Dialogo, personalb}=this.state
    Dialogo.open=true
    Dialogo.Titulo=`Personal de los Break`
    Dialogo.Cuerpo= <Pbreak personal={personalb}/>
    this.setState({Dialogo})
  }

  Cerrar_dialogo = () =>{
    let {Dialogo}=this.state
    Dialogo.open=false
    this.setState({Dialogo})
  }

  Agregar_break = (dato, personal) =>{
    personal = personal.map(p=>{
      if (
        p.turno.split('-')[1] === dato.turno.split('-')[1] &&
        p.turno.split('(')[1].split(')')[0] === dato.turno.split('(')[1].split(')')[0]
      ){
        p.childen=[...p.childen, ...dato.childen]
        p.titulo=<ItemTituloSupervisor sup={{...p, cantidad:p.childen.length, operadores:p.childen}}/>
      }
      return p
    })
    return personal
  }

  Actualizar = (datos) =>{
    let personalb= datos.personalb ? datos.personalb : this.state.personalb
    personalb=personalb.map(p=>{

      return {...p, childen:[]}
    })

    datos.Grupos= datos.Grupos.map(g=>{
      let childen=[];
      g.childen.map(o=>{
        
        if (o.turno || o.operadores.length<=0 || o.operadores[0].turno.split('-')[1]=== g.turno.split('-')[1]){
          childen.push({...o, key:o._id, titulo:<ItemPerfiles op={{...o, perfil:false}}/>})
        }
        return {...o, key:o._id, titulo:<ItemPerfiles op={{...o, perfil:false}}/>}
      })
      g.childen=childen;
      g.titulo=<ItemTituloSupervisor sup={{...g, cantidad:g.childen.length}}/>
      personalb= this.Agregar_break(g,personalb)
      return g
    })
    
    datos.Libres = datos.Libres.map( p =>{

      let operadores=p.operadores
      datos.Grupos.map(g=>{
        const pos = operadores.findIndex(v=> v._id===g._id) 
        const post = operadores.findIndex(v=> v.turno===g.turno) 
        if (g.childen.findIndex((v)=> v._id===p._id)!==-1){
          const anterior = post===-1 || operadores[post]._id===g._id ? '' :  operadores[post].turno
          if (pos===-1){
            operadores.push({
              _id:g._id, nombres:g.nombres, apellidos:g.apellidos, 
              turno:g.turno, cedula:g.cedula, foto:g.foto, 
              suplente: anterior!==g.turno ? '' : 'Suplente'
            })
          }else{
            operadores[pos]={
              _id:g._id, nombres:g.nombres, apellidos:g.apellidos, 
              turno:g.turno, cedula:g.cedula, foto:g.foto, 
              suplente: anterior!==g.turno ? '' : 'Suplente'
            }
          }
        }else if (pos!==-1) {
          operadores.splice(pos, 1)
        }
        return g
      })
      
      operadores.sort((a,b)=> a.turno > b.turno ? 1 : a.turno < b.turno ? -1 : 0)
      return {...p, key:p._id, operadores, titulo:<ItemPerfiles op={{...p, perfil:true, operadores}}/>}
    })

    
    // datos.Libres.map(l=>{
    //   l={...l, titulo:<Item_supervisor op={{...l, turno:l.turno}}/>}
    //   return
    // })
    // datos.Grupos=datos.Grupos.map(g=>{
    //   g.childen = g.childen.filter(o=>{ 
    //     if (o.turno===g.turno){
    //       return o
    //     }else{
    //       datos.Libres.push(o)
    //     }
    //   })
      
    //   return {...g, operadores:g.childen, titulo: <Item_titulo_supervisor sup={{...g,operadores:g.childen}}/>}
    // })
    
    this.setState({datos, personalb})
    if (this.state.props.Actualizar){
      this.state.props.Actualizar({operador_vs_perfil:{...datos,personalb}})
    }
  }
  async componentDidMount(){
    this.Iniciar(this.props.datos)
    // let resultados= await conexiones.Leer_C(['Data_personal'], 
    //   {['Data_personal']:{$and:[ {categoria:{$ne:0}} , {categoria:{$ne:5}}]},}
    //   );
    // console.log(resultados)
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  render(){
    return this.state.Esperar.esperar ? 
      <Esperar {...this.state.Esperar}/> :
      <div>
        <Pagee  {...this.state}/>  
        <Dialogo {...this.state.Dialogo}/>
      </div>
    
  }
}