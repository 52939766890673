import React, {Component} from 'react';
import {conexiones} from '../../procesos/servicios';
import Esperar from '../esperar';
import socketIOClient from "socket.io-client";
import moment from "moment";
import { ItemDias, ItemDiasGrupo} from '../conexiones/item';
import Dialogo from '../formulario/dialogo';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import { Scrollbars } from 'rc-scrollbars';
import Scrollbars from '../formulario/MyScrollbars';
// import FindInPageIcon from '@material-ui/icons/FindInPage';

import EditIcon from '@material-ui/icons/Edit';
import Asignar from './planificacion_dia';

//Constantes
import {Valord,const_procesos} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;
const categoria_usuario= config.categoria_usuario;

//Asignar operadores a supervisores
export default class Planificacion extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          calendario:{
            fecha: new Date(),
            Cambio_fecha: this.Cambio_fecha,
          },
          Dialogo:{
            open:false,
            Titulo:'Mi cuenta 1',
            tam:'xs',
            Cerrar: this.Cerrar_dialogo,

          },
          Editar_dialogo:{
            tam:'xl',
            Cuerpo:(datos)=><Asignar {...datos} Guardar={this.Guardar_uno}/>,
            editar:true,
            copiar:this.Copiar_uno,
          },
          Mes:{}

      }
  }
  
  
  Iniciar= async(inicio=false) =>{
    let {fecha, fechai}=this.state.calendario
    if (fechai===undefined){
      // fecha.setDate()
      fecha.setHours(0, 0, 0)
    }else{
      fecha=fechai
    }
    this.Cambio_fecha(fecha, inicio)
  }

  //////Dialogo///////
  
  Cerrar_dialogo = () =>{
    let {Dialogo}=this.state
    Dialogo.open=false
    this.setState({Dialogo})
  }
  Cambio_fecha = async(dato, iniciar=false) =>{
    if (iniciar)
      this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    
    let User = await JSON.parse(localStorage.getItem(const_procesos.dir_user));
    var inicio =new Date(dato)
    inicio.setHours(0, 0, 0, 0)
    inicio.setHours(inicio.getHours()-inicio.getHours(),0,0)
    
    inicio = new Date()
    let respuesta= await conexiones.Leer_C(
      ['Data_personal','Data_planificacion_dia','Data_perfil_amolatina','Data_resumen_personal','Data_nomina'], 
      {
        'Data_personal':{$or:[ {categoria:3} , {categoria:4}]},
        'Data_planificacion_dia':{$and:[{fecha:moment.utc(inicio).format('YYYY-MM-DD')}]},
        'Data_perfil_amolatina':{suspended: false},
        'Data_resumen_personal':{$and:[{fecha:moment.utc(inicio).format('YYYY-MM-DD')}, {'id_user':User._id}]},
        'Data_nomina':{$and:[{mes:moment.utc(inicio).format('MM')}, {ano:moment.utc(inicio).format('YYYY')},{cedula:User.cedula}]},
        'Data_multa':{}
      }
    );
    let datos={}
    let puntos= {}
    let planificacion={}
    let Mes={}
    if (respuesta.Respuesta==='Ok'){
      // personal= respuesta.datos['Data_personal']
      Mes=respuesta.datos['Data_nomina'][0]
      if (respuesta.datos['Data_planificacion_dia'].length!==0){
        
        if (respuesta.datos['Data_resumen_personal'].length!==0){
          puntos=respuesta.datos['Data_resumen_personal'][0]
        }
        console.log('>>>>>>>>>>>>>>>',puntos)
        const todos=respuesta.datos['Data_perfil_amolatina'];
        const personal= respuesta.datos['Data_personal'];
        const service=[
              'dialogs.interval.added:missed',
              'dialogs.interval.answer:missed',
              'dialogs.message:missed',
              'dialogs.letter:missed',
              'dialogs.letter:missed.hopelessly',
              'dialogs.media:missed',
              'present:missed',
              'manual:missed',
            ]
        const multas= respuesta.datos['Data_multa'];
        planificacion=respuesta.datos['Data_planificacion_dia'][0];
        datos=respuesta.datos['Data_planificacion_dia'][0].asistencia.filter(h=> h._id===User._id)[0]
        let perfiles=[]
        let operadores=[]

        if (datos){  
          datos.perfiles.map(p=>{
            const posp= puntos.totales ? puntos.totales.findIndex(v=> v['user-id']===p) : -1
            const miperfiles = puntos.miperfiles ? puntos.miperfiles.filter(f=> f['user-id']===p) : []
            const pos = todos.findIndex(v=> v['user-id']===p)
            if (pos!==-1){
              if (posp!==-1){
                let multa=0
                miperfiles.map(v=>{
                  const pserv= service.indexOf(v.service)
                  if (pserv!==-1){
                    const pmulta=multas.findIndex(m=> m.service===String(pserv) && m.involucrado==='0')
                    if (pmulta!==-1){
                      multa+=v.mpoints*multas[pmulta].multa
                    }
                  }else{
                    multa+=v.mpoints
                  }
                  return v
                })
                todos[pos]={...todos[pos],total:puntos.totales[posp].total, 
                  multa,//:puntos.totales[posp].multa, 
                  miperfiles
                }
              }

              perfiles.push(todos[pos])
            }
            return p
          })
          datos.perfiles=perfiles
          datos.operadores.map(p=>{
            const posp= puntos.operadores ? puntos.operadores.findIndex(v=> v._id===p) : -1
            const pos = personal.findIndex(v=> v._id===p)
            if (pos!==-1){
              if (posp!==-1){
                personal[pos]={...personal[pos],total:puntos.operadores[posp].total, multa:puntos.operadores[posp].multa, perfiles:puntos.operadores[posp].perfiles, miperfiles:puntos.operadores[posp].perfiles}
              }
              operadores.push(personal[pos])
            }
            return p
          })
          datos.operadores=operadores
        }else{
          datos={perfiles,operadores}
        }
        datos={...datos, sub_multa:puntos.sub_multa, sub_total:puntos.sub_total, total:puntos.total, multa:puntos.multa}
        
      }
    }
    
    // personal = personal.sort((a, b) => (a.categoria >b.categoria ? 1 : a.categoria < b.categoria ? -1 : 0))
    
    console.log('>>>>>>>>>>>>',datos)
    this.setState({ Mes, planificacion, puntos, datos, Esperar:{esperar:false}})
    // this.Actualizar(datos)

  }
  Actualizar = (datos) =>{
    
    datos.Grupos=datos.Grupos.map(g=>{
      const childen =  g.childen.map(p=>{
        return {...p, key:p._id, titulo:<ItemDiasGrupo op={{...p, dias:undefined}}/>}
      })
      let dato ={...g, childen} 
      dato={...dato, key:dato.dia, titulo:<ItemDias dato={dato}/>}
      return dato 
    })
    
    
    this.setState({datos})
  }
  async componentDidMount(){
    this.Iniciar(true)
    const socket=socketIOClient("/")
    socket.on('Resumen_perfil',(dato)=>{
      this.Iniciar()
    })
    this.setState({socket})
  }

  componentWillUnmount(){
    let { socket}=this.state
    if (socket!==undefined && socket!==null){
      socket.disconnect();
      socket=null
    }
  }
  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  Estilos = {
    barra_lista:{
        backgroundColor:config.Estilos.barra.backgroundColor, color:'#ffffff',    
    },
    cuerpo_lista:{
        // height: window.screen.height * 0.70,
        padding:10,
        
    },
    item_lista:{
        backgroundColor: '#023058', 
        padding:5, border:1, borderRadius:10, marginBottom:10,
        cursor:'pointer', 
    },
    avatar:{
      alignItems:'center',
      textAlign:'center',
      color:'#D0C6C6'
    },
    fecha:{
      display:'flex',
      alignItems:'center'
    },
    divTextA:{
      textAlign:'left', 
      color:'#D0C6C6'
    }
  }

  Puntos_perfil = (datos)=>{
    let {Dialogo}=this.state
    Dialogo.open=true
    Dialogo.tam='xs';
    Dialogo.Titulo=
      <div>
        {`Obtenido de ${datos['first-name']}`}
        <br/>
        {`Puntos: ${Number(datos.total ? datos.total : 0 ).toFixed(2)} - Multas: ${Number(datos.multa ? datos.multa : 0).toFixed(2)}`}
      </div>
    Dialogo.Cuerpo= datos.miperfiles ?
      <List>
        <Scrollbars autoHeight autoHeightMax={window.innerHeight * 0.5}>
          {datos.miperfiles.map((punto,i) =>
          
            <ListItem 
                
              key= {i+'-' + punto['user-id']+'-'+punto.service} 
              style={{backgroundColor:'#E4DDDD', padding:5, border:1, borderRadius:10, marginBottom:10}}
            >
              <ListItemAvatar>
                  <div style={{alignItems: 'center', justifyContent:'center', alignContent:'center', textAlign:'center', padding:10}}>
                    <Avatar
                      
                      alt={`${punto['nombre-perfil']}`}
                      src={`https://api7.amolatina.com/users/${punto['user-id']}/photos/${punto['imagen-perfil']}.490x480.thumb-fd`}
                    />
                  </div>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <div style={{ color:'#000000', }}>
                    <Typography variant="caption" display="block" gutterBottom>
                      {`${punto['user-id']} ${punto['nombre-perfil']}`}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom style={{marginTop:-7}}>
                      {`Puntos: ${Number(punto.points===undefined ? 0 : punto.points).toFixed(2)} ${punto.hora}`}
                    </Typography>
                    
                    <Typography variant="caption" display="block" gutterBottom style={{marginTop:-7}}>
                      <Link href={`https://www.amolatina.com/people/#${punto['target-id']}`} target="_blank">
                        {`${punto['target-id']}`}
                      </Link>
                    </Typography>
                  </div>
                }
                
              />
              <ListItemSecondaryAction>
                {config.service[punto.service].icon}
              </ListItemSecondaryAction>
            </ListItem>
              
                    
          )}
        </Scrollbars>
      </List> : null
    
    this.setState({Dialogo})
  }

  Puntos_operador = (datos)=>{
    // console.log(datos)
    let {Dialogo}=this.state
    Dialogo.open=true
    Dialogo.tam='xs';
    Dialogo.Titulo=
      <div>
        {`Obtenido de ${datos.nombres+ ' ' + datos.apellidos}`}
        <br/>
        {`Puntos: ${Number(datos.total ? datos.total : 0).toFixed(2)} - Multas: ${Number(datos.multa ? datos.multa : 0).toFixed(2)}`}
      </div>
    Dialogo.Cuerpo= datos.perfiles ? 
      <List>
        <Scrollbars autoHeight autoHeightMax={window.innerHeight * 0.5}>
          {datos.perfiles.map((punto,i) =>
          
            <ListItem 
                
              key= {i+'-' + punto['user-id']+'-'+punto.service} 
              style={{backgroundColor:'#E4DDDD', padding:5, border:1, borderRadius:10, marginBottom:10}}
            >
              <ListItemAvatar>
                  <div style={{alignItems: 'center', justifyContent:'center', alignContent:'center', textAlign:'center', padding:10}}>
                    <Avatar
                      
                      alt={`${punto['nombre-perfil']}`}
                      src={`https://api7.amolatina.com/users/${punto['user-id']}/photos/${punto['thumbnail']}.490x480.thumb-fd`}
                    />
                  </div>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <div style={{ color:'#000000', }}>
                    <Typography variant="caption" display="block" gutterBottom>
                      {`${punto['user-id']} ${punto['first-name']}`}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom style={{marginTop:-7}}>
                      {`Puntos: ${Number(punto.total===undefined ? 0 : punto.total).toFixed(2)}`}
                      <br/>
                      {`Multas: ${Number(punto.multa===undefined ? 0 : punto.multa).toFixed(2)}`}
                    </Typography>
                    
                  </div>
                }
                
              />
            </ListItem>
              
                    
          )}
        </Scrollbars>
      </List> : null
    
    this.setState({Dialogo})
  }

  Editar=()=>{
    let {planificacion}=this.state;
    let dia= planificacion.fecha.split('-')
    dia=dia[2]+'-'+dia[1]+'-'+dia[0]
    planificacion={...planificacion, childen: planificacion.asistencia, dia_d:planificacion.dia, dia}
    let {Dialogo}=this.state
    console.log(planificacion)
    Dialogo.open=true
    Dialogo.tam='xl';
    Dialogo.Titulo='Editar planificacion del dia'
    Dialogo.Cuerpo= <Asignar {...planificacion}  supervisor Guardar={this.Guardar_uno}/>
    this.setState({Dialogo})
  }
  
  Guardar_uno = async(enviar, iniciar=true)=>{
    this.setState({Esperar:{esperar:true,mensaje:'Guardando cambios...'}})
    let respuesta = await conexiones.Leer_C(['Data_planificacion_dia'],{
      'Data_planificacion_dia':{fecha:enviar.fecha}
    })
    delete enviar._id
    if (respuesta.datos['Data_planificacion_dia'].length!==0){
      enviar._id=respuesta.datos['Data_planificacion_dia'][0]._id
    }
    await conexiones.Guardar(enviar,'Data_planificacion_dia')
    if (iniciar){
      this.setState({Esperar:{esperar:false}})
      this.Cerrar_dialogo()
      this.Iniciar()
    }
  }
  Resultado = ()=>{
    const {datos}=this.state;
    const personal= datos;
    return personal._id===undefined ? null :(
      <div>
        <List>
           <div style={this.Estilos.cuerpo_lista}>
            
              <ListItem 
                  key={personal._id} alignItems="flex-start" 
                  style={this.Estilos.item_lista}
              >
                  <ListItemAvatar>
                    <div style={this.Estilos.avatar}>
                      <Avatar
                          style={{marginLeft:15, width:150, height:150}}
                          alt={`${personal.nombres}`}
                          src={`${personal.foto}`}
                      />
                      <Typography variant="caption" display="block" gutterBottom>
                          {`${personal.nombres} ${personal.apellidos}`}
                      </Typography>
                      <Typography variant="caption" display="block" gutterBottom style={{marginTop:-7}}>
                          {personal.turno}
                      </Typography>
                      <div style={this.Estilos.divTextA} >
                          {personal.categoria===3 ?
                            <div >
                              <Typography variant="caption" display="block" gutterBottom style={{marginTop:5}}>
                                {`Puntos del dia:`}
                              </Typography>
                              <Typography variant="caption" display="block" noWrap gutterBottom style={{marginTop:-5}}
                                title={`Puntos como Operador: ${personal.total===undefined ? 0 : new Intl.NumberFormat('es-VE').format(personal.total)} - Multas como Operador: ${personal.multa===undefined ? 0 :new Intl.NumberFormat('es-VE').format(personal.multa)}`}
                              >
                                {`Puntos como Operador: ${personal.total===undefined ? 0 : new Intl.NumberFormat('es-VE').format(personal.total)}`}
                              </Typography>
                              <Typography variant="caption" display="block" noWrap gutterBottom style={{marginTop:-5, color:'#F6F610'}}
                                title={`Multas como Operador: ${personal.multa===undefined ? 0 :new Intl.NumberFormat('es-VE').format(personal.multa)}`}
                              >
                                {`Multas como Operador: ${personal.multa===undefined ? 0 :new Intl.NumberFormat('es-VE').format(personal.multa)}`}
                              </Typography>
                              <Typography variant="caption" display="block" gutterBottom style={{marginTop:-5}} noWrap
                                title={`Puntos Supervisados: ${personal.sub_total===undefined ? 0 : new Intl.NumberFormat('es-VE').format(personal.sub_total)}`}
                              >
                                {`Puntos Supervisados: ${personal.sub_total===undefined ? 0 : new Intl.NumberFormat('es-VE').format(personal.sub_total)}`}
                              </Typography>
                              <Typography variant="caption" display="block" gutterBottom style={{marginTop:-5, color:'#F6F610'}} noWrap
                                title={`Multas Supervisados: ${personal.sub_multa===undefined ? 0 :new Intl.NumberFormat('es-VE').format(personal.sub_multa)}`}
                              >
                                {`Multas Supervisados: ${personal.sub_multa===undefined ? 0 :new Intl.NumberFormat('es-VE').format(personal.sub_multa)}`}
                              </Typography>
                            </div>:
                            <div >
                              <Typography variant="caption" display="block" gutterBottom style={{marginTop:5}} noWrap
                                title={`Puntos del dia: ${personal.total===undefined ? 0 : new Intl.NumberFormat('es-VE').format(personal.total)}`}
                              >
                                {`Puntos del dia: ${personal.total===undefined ? 0 : new Intl.NumberFormat('es-VE').format(personal.total)}`}
                              </Typography>
                              <Typography variant="caption" display="block" gutterBottom style={{marginTop:5, color:'#F6F610'}} noWrap
                                title={`Multas del dia: ${personal.multa===undefined ? 0 :new Intl.NumberFormat('es-VE').format(personal.multa)}`}
                              >
                                {`Multas del dia: ${personal.multa===undefined ? 0 :new Intl.NumberFormat('es-VE').format(personal.multa)}`}
                              </Typography>
                            </div>
                          }
                          <div>
                            <Typography variant="body1" display="block" gutterBottom style={{marginTop:5, fontWeight:900}}>
                              {`Puntos del mes:`}
                            </Typography>
                            <Typography variant="body1" display="block" gutterBottom style={{marginTop:-10, fontWeight:900}}
                              noWrap title={`Puntos como Operador: ${this.state.Mes===undefined || this.state.Mes.points===undefined ? 0 : new Intl.NumberFormat('es-VE').format(this.state.Mes.points)}`}
                            >
                              {`Puntos como Operador: ${this.state.Mes===undefined || this.state.Mes.points===undefined ? 0 : new Intl.NumberFormat('es-VE').format(this.state.Mes.points)}`}
                            </Typography>
                            <Typography variant="body1" display="block" gutterBottom style={{marginTop:-10, fontWeight:900, color:'#F6F610'}}
                              noWrap title={`Multas como Operador: ${this.state.Mes===undefined || this.state.Mes.mpoints===undefined ? 0 :new Intl.NumberFormat('es-VE').format(this.state.Mes.mpoints)}`}
                            >
                              {`Multas como Operador: ${this.state.Mes===undefined || this.state.Mes.mpoints===undefined ? 0 :new Intl.NumberFormat('es-VE').format(this.state.Mes.mpoints)}`}
                            </Typography>
                            <Typography variant="body1" display="block" gutterBottom style={{marginTop:-10, fontWeight:900}}
                              noWrap title={`Puntos Supervisados: ${this.state.Mes===undefined || this.state.Mes.sup_points===undefined ? 0 : new Intl.NumberFormat('es-VE').format(this.state.Mes.sup_points)}`}
                            >
                              {`Puntos Supervisados: ${this.state.Mes===undefined || this.state.Mes.sup_points===undefined ? 0 : new Intl.NumberFormat('es-VE').format(this.state.Mes.sup_points)}`}
                            </Typography>
                            <Typography variant="body1" display="block" gutterBottom style={{marginTop:-10, fontWeight:900, color:'#F6F610'}}
                              noWrap title={`Multas Supervisados: ${this.state.Mes===undefined || this.state.Mes.sup_mpoints===undefined ? 0 :new Intl.NumberFormat('es-VE').format(this.state.Mes.sup_mpoints)}`}
                            >
                              {`Multas Supervisados: ${this.state.Mes===undefined || this.state.Mes.sup_mpoints===undefined ? 0 :new Intl.NumberFormat('es-VE').format(this.state.Mes.sup_mpoints)}`}
                            </Typography>
                          </div>
                        </div>
                    </div>
                  </ListItemAvatar>
                  <ListItemText
                      primary={
                          <div style={{color:'#D0C6C6'}}>
                              <Typography variant="caption" display="block" gutterBottom>
                                  {personal.categoria==='3' || personal.categoria===3 ? 
                                    <div>
                                      {`Perfiles y Operadores del ${categoria_usuario[personal.categoria].titulo}`}
                                      <IconButton aria-label={'Editar'}  size="medium" title={'Editar planicacion del dia'}
                                        onClick={this.Editar}
                                      >
                                        <EditIcon fontSize="inherit" style={{color:'#D0C6C6'}}/>
                                      </IconButton>
                                    </div>:
                                    `Perfiles del ${categoria_usuario[personal.categoria].titulo}`
                                  }
                              </Typography>
                              <Scrollbars style={{ height: 320 }}>
                                <Grid container spacing={2} justify="center" alignItems="center" direction="row" style={{padding:20}}>
                                
                                  {personal.perfiles.map((t,ji)=>
                                      <Grid item xs={2} key={t._id+ji} onClick={()=>this.Puntos_perfil(t)}>
                                      
                                          <ListItemAvatar>
                                              <Avatar
                                                  style={{marginLeft:15}}
                                                  alt={`${t['first-name']}`}
                                                  src={`https://api7.amolatina.com/users/${t['user-id']}/photos/${t.thumbnail}.490x480.thumb-fd`}
                                              />
                                              
                                          </ListItemAvatar>
                                          <ListItemText
                                              primary={
                                                  <Typography variant="caption" display="block" noWrap gutterBottom>
                                                      {`${t['first-name']}`}
                                                  </Typography>
                                              }
                                              secondary={
                                                <Typography variant="caption" display="block" noWrap gutterBottom>
                                                  {`Perfil` }
                                                  <br/>
                                                  {`Puntos: ${t.total ? new Intl.NumberFormat('es-VE').format(t.total) : 0}`}
                                                  <br/>
                                                  {`Multas: ${t.total ? new Intl.NumberFormat('es-VE').format(t.multa) : 0}`}
                                                </Typography>

                                              }
                                          />
                                      </Grid>
                                      
                                  )}
                                  {personal.operadores.map((t,ji)=>
                                      <Grid item xs={2} key={t._id+ji} onClick={()=>this.Puntos_operador(t)} style={{backgroundColor:'#347155'}}>
                                      
                                          <ListItemAvatar>
                                              <Avatar
                                                  style={{marginLeft:15}}
                                                  alt={`${t.nombres}`}
                                                  src={`${t.foto}`}
                                              />
                                              
                                          </ListItemAvatar>
                                          <ListItemText
                                              primary={
                                                  <Typography variant="caption" display="block" noWrap gutterBottom>
                                                      {`${t.nombres} ${t.apellidos} `}
                                                  </Typography>
                                              }
                                              secondary={
                                                <Typography variant="caption" display="block"  noWrap gutterBottom>
                                                  {`Operador` }
                                                  <br/>
                                                  {`Puntos: ${t.total ? new Intl.NumberFormat('es-VE').format(t.total) : 0}`}
                                                  <br/>
                                                  {`Multas: ${t.total ? new Intl.NumberFormat('es-VE').format(t.multa) : 0}`}
                                                </Typography>
                                              }
                                          />
                                      </Grid>
                                  
                                      
                                  )}
                                  
                                </Grid>
                              </Scrollbars>
                          </div>
                      }
                      
                  />
                  
              </ListItem>
            

            </div>
          
        </List>
      </div>
    )
  }
  render(){
    return this.state.Esperar.esperar ? 
      <Esperar {...this.state.Esperar}/> :
      <div>
        {this.Resultado()}
        <Dialogo {...this.state.Dialogo}/>
      </div>
    
  }
}