import React, {Component} from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

//Componente personales
import {conexiones, encriptado} from '../../procesos/servicios';

//Iconos
import AppsIcon from '@material-ui/icons/Apps';
import PeopleIcon from '@material-ui/icons/People';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import BallotIcon from '@material-ui/icons/Ballot';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
// import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
// import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import StairsIcon from '@mui/icons-material/Stairs';
import moment from 'moment';
// import { AiOutlineSchedule } from "react-icons/ai";
// import { AiFillControl } from "react-icons/ai";
import { BiCalculator } from "react-icons/bi";


import Page from './page';
import {Bloques} from './bloques';
import Esperar from '../esperar';
import {const_procesos, Valord} from '../../constantes';
import {Permiso} from '../../procesos/servicios/funciones';
import Formulario from '../../components/formulario';

const ct = require('countries-and-timezones');

export default class Ocean extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          listado:[],
          selected:0,
          patalla:null,
          datos:{},
          esperar:false,
          mensaje_espera:'Cargando...',
          progreso:0,
          Dialogo:{
            open:false,
            Titulo:'Mi cuenta',
            Cerrar: this.Cerrar_dialogo,
            Abrir: this.Abrir_dialogo,
          },
          funciones:{
            Agregar: this.Agregar,
            Dialogo: this.Dialogo,
            Actualizar:this.Actualizar,
            Actualizar_data:this.Actualizar_data,
            Guardar: this.Guardar,
            Guardar_Agencia: this.Guardar_Agencia,
            Guardar_Personal: this.Guardar_Personal,
            Eliminar: this.Eliminar,
            Cancelar: this.Cancelar,
            Refrescar: this.Refrescar,
            Select_agencia:this.Select_agencia,
            Cambios: this.Cambios,
            Cambios_pais: this.Cambios_pais,
            Cambios_tipo: this.Cambios_tipo,
            Condicion: this.condicion
          },
          
      }
  }

  Condicion = (tabla)=>(dato)=>{
    console.log('condicion', tabla, dato)
    return dato
  }
  Guardar_dialogo =async(valor)=>{
    this.Cerrar_dialogo()
    this.setState({esperar:true, mensaje_espera:'Guardando datos...', progreso:0})  
    let dato = await this.Condiciones('User_api',valor)
    
    const nuevos = await conexiones.Guardar(dato,'User_api', 'Guardando datos...', this.Acciones);
    if (nuevos.Respuesta==='Ok'){
      
      const pos= nuevos.resultado.findIndex(v=> v._id===valor._id)
      localStorage.setItem(const_procesos.dir_user,JSON.stringify(nuevos.resultado[pos]));
      window.location.reload()
    }else{
      this.setState({esperar:false})
      confirmAlert({
        title: 'Error-' + nuevos.code,
        message: 'Los datos no fueron guardados',
        buttons: [{label: 'OK'}]
      });
    }
      
  }

  Abrir_dialogo = async()=>{
    let config = Valord //JSON.parse(localStorage.getItem(const_procesos.dir_config));
    const categoria_usuario= config.categoria_usuario;
    let dato = await JSON.parse(localStorage.getItem(const_procesos.dir_user)); 
    let categoria=categoria_usuario.filter(val=> Number(val._id)===Number(dato.categoria))[0];
    categoria = categoria===undefined ? categoria_usuario[2] : categoria;
    
    let titulos={
      foto:{
        label: 'Foto',
        helperText:'',
        value:dato.foto ? dato.foto : '',
        tipo:'avatar'
      },
      personal:{
        multiples:true,
        value:{
                username:{
                  label: 'Usuario', helperText:'',
                  value:dato.username,
                  required:true,
                  mensaje_error:'Debe indicar usuario',
                },
                nombres:{
                  label: 'Nombres', helperText:'',
                  value:dato.nombres, 
                  mensaje_error:'Indique nombre y apellido',
                },
        }
      },
      seguridad:{
        multiples:true,
        value:{
                npassword:{
                  label: 'Nueva Contraseña', helperText:'',
                  value:'', tipo:'password',
                  comparar:'true', con:'confpassword',
                  mensaje_error:'Contraseñas no son iguales',
                  required: dato.username===undefined ? true : false,
                },
                confpassword:{
                  label: 'Confirmar Contraseña', helperText:'',
                  value:'', tipo:'password',
                  comparar:'true', con:'npassword',
                  mensaje_error:'Contraseña no son iguales',
                },
        }
      },
      categoria:{
        label: 'Categoria',
        disabled:true,
        helperText:'',
        tipo:'select',
        lista: Permiso('',true) ? categoria_usuario : categoria_usuario.filter(val=> val._id!==0),
        value:categoria ? categoria : categoria_usuario[2],
        getOptionLabel: (option)=> `${option.titulo}`,
        getOptionSelected: (option)=> option.titulo,
      },
  
    }
    let botones=[
      {
        name:'guardar', label:'Guardar', title:'Guardar usuario',
        variant:"contained", color:"primary", icono:<CheckIcon/>,
        onClick: this.Guardar_dialogo, validar:'true',
        // disabled: !Permiso('guardar'),
      },
      {
        name:'cancelar', label:'Cancelar', title:'Cancelar',
        variant:"contained", color:"default", icono:<CancelIcon/>,
        onClick:this.Cerrar_dialogo
      },
    ];
    let {Dialogo}=this.state;
    Dialogo.open=true;
    Dialogo.Cuerpo= 
    <Formulario datos={dato}
      titulos= {titulos}
      botones={botones}
    />
    this.setState({Dialogo})
  }
  Cerrar_dialogo = () =>{
    let {Dialogo}=this.state;
    Dialogo.open=false;
    this.setState({Dialogo})
  }

  Agregar = (index)=>{
    this.Dialogo(index)({})
  }

  Dialogo = (index)=>(valores)=>{
    let {pantalla, funciones, datos}= this.state;
    datos[index].open=true
    datos[index].dato=valores
    pantalla={bloque:index,datos:datos[index],funciones}
    this.setState({selected:index, pantalla, datos})
  }

  Actualizar = () =>{
      console.log('Actualizar')
  }
  
  Actualizar_data = (valor) =>{
    console.log('Actualizar_data',valor)
    let {pantalla, funciones, datos}= this.state;
    datos[valor.Titulo].datos=valor.datos
    datos[valor.Titulo].cantidad=valor.cantidad
    pantalla={bloque:valor.Titulo,datos:datos[valor.Titulo],funciones}
    this.setState({ pantalla, datos})
  }
  Condiciones = async (item, dato) =>{
    
    switch (item) {
      case 'User_api':{
        if (dato.npassword!=='') {
          dato.password= await encriptado.Hash_password(dato.npassword)
        }
        dato.categoria=dato.categoria._id;
        return dato;
      }
      case 'Data_personal':{
        dato.categoria=dato.categoria._id;
        if (Number(dato.categoria)!==3){
          dato.operadores=[]
        }
        return dato;
      }
      case 'Data_punto':{
        dato.involucrado= dato.involucrado._id
        return dato
      }
      case 'Data_multa':{
        dato.involucrado= dato.involucrado._id
        dato.service= dato.service._id
        return dato
      }
      case 'Data_turno':{
        dato.turno = dato.sturno!== null && dato.sturno.titulo!==undefined ? `${dato.turno} (${dato.sturno.titulo})` : dato.turno
        dato.pais =  dato.pais.titulo ? dato.pais.titulo : dato.pais
        dato.zona =  dato.zona.titulo ? dato.zona.titulo : dato.zona
        dato.hora= ct.getTimezone(dato.zona).utcOffsetStr
        return dato
      }
      case 'Data_meta':{
        dato.tipo =  dato.tipo.titulo ? dato.tipo.titulo : dato.tipo
        
        return dato
      }
     
      default:
        return dato;
    }
  }

  Guardar = (index, tabla) => async (valor)=>{
    this.setState({esperar:true, mensaje_espera:'Guardando datos...', progreso:0})  
    let dato = await this.Condiciones(tabla,valor)
    if (tabla==='Data_turno' && dato._id===undefined){
      let respuesta= await conexiones.Leer_C(['Data_turno'],{'Data_turno':{turno:dato.turno}});
      if (respuesta.datos.Data_turno.length!==0){
        this.setState({esperar:false, mensaje_espera:'Guardando datos...', progreso:0})  
        confirmAlert({
          title: 'Aviso',
          message: `El nombre de turno (${dato.turno}) ya existe`,
          buttons: [{label: 'OK'}]
        });
        return
      }
    }
    let nuevos
    if (tabla==='Data_meta' && dato._id===undefined && moment(dato.fechaf).isSameOrAfter(dato.fecha)){
      console.log(dato)
      var fecha1 = moment(dato.fecha);
      var fecha2 = moment(dato.fechaf);
      const diferencia = fecha2.diff(fecha1, 'days');
      nuevos = await conexiones.Guardar(dato,tabla, 'Guardando datos...', this.Acciones);
      for (var i=0; i<diferencia; i++){
        fecha1.add(1,'days')
        const fecha=moment(fecha1).format('YYYY-MM-DD');
        nuevos = await conexiones.Guardar({...dato, fecha},tabla, 'Guardando datos...', this.Acciones);
      }

    }else{
      nuevos = await conexiones.Guardar(dato,tabla, 'Guardando datos...', this.Acciones);
    }
    
    let {pantalla, funciones, datos}= this.state;
    datos[index].open=false
    datos[index].dato={}
    if (nuevos.Respuesta==='Ok'){
      datos[index].datos=nuevos.resultado
      datos[index].cantidad=nuevos.resultado.length
      // this.Cerrar_dialogo();
      pantalla={bloque:index,datos:datos[index],funciones}
      this.setState({pantalla, datos, esperar:false})
    }else{
      this.setState({esperar:false})
      confirmAlert({
        title: 'Error-' + nuevos.code,
        message: 'Los datos no fueron guardados',
        buttons: [{label: 'OK'}]
      });
    }
    
  }
  Guardar_Personal= (index, tabla) => async (valor) =>{
    
    this.setState({esperar:true, mensaje_espera:'Guardando datos...', progreso:0})  
    let usuario={
      cedula:valor.cedula, 
      categoria: valor.categoria,
      foto: valor.foto, nombres: valor.nombres+ ' ' + valor.apellidos
    }
    let respuesta= await conexiones.Leer_C(['User_api'],{'User_api':{cedula:valor.cedula}});
    if (respuesta.Respuesta==='Ok'){
      if (respuesta.datos['User_api'].length===0){
        let dato = await this.Condiciones('User_api',{...usuario, npassword:valor.cedula, username : valor.cedula,})
        await conexiones.Guardar(dato,'User_api', 'Guardando datos...', this.Acciones);
      }else{
        const d=respuesta.datos['User_api'][0]
        // console.log(usuario)
        usuario={...d, categoria:usuario.categoria, foto: usuario.foto}
        delete usuario.password
        // console.log(usuario)
        let dato = await this.Condiciones('User_api',usuario)
        // console.log(dato)
        await conexiones.Guardar(dato,'User_api', 'Guardando datos...', this.Acciones);
      }
    }
    this.Guardar(index,tabla)(valor)
    
  }
  Guardar_Agencia = (index, tabla) => async (valor)=>{  
    this.setState({esperar:true, mensaje_espera:'Conectando con Amolatina...', progreso:0}) 
    let valor1={...valor, api:valor.api.value}
    valor1.api=valor1.api.value ? valor1.api.value : valor1.api;
    console.log('>>>>>> nue',valor1)
    const nuevos = await conexiones.Amolatina(valor1);
    if (Object.keys(nuevos).length!== 0 && nuevos.Respuesta===undefined){
      this.Guardar(index,tabla)({...valor1,...nuevos})
    }else{
      this.setState({esperar:false})
      confirmAlert({
        title: 'Error',
        message: `Error con Email o Contraseña  para ingresar en Amolatina, o ${nuevos.mensaje}`,
        buttons: [{label: 'OK'}]
      });
    }
  }

  Acciones = (posicion) =>{
    this.setState({progreso:posicion})
  }
  Eliminar = (index, tabla)=>(valor)=>{
      // console.log('Eliminar',index,valor)
      confirmAlert({
        title: 'Eliminar',
        message: 'Desea eliminar registro?',
        buttons: [
          {
            label: 'SI',
            onClick: async () => {
              this.setState({esperar:true, mensaje:'Eliminando datos...'})
              const nuevos = await conexiones.Eliminar(valor,[tabla]);
              let {pantalla, funciones, datos}= this.state;
              const bloque=index
              if (nuevos.Respuesta==='Ok'){
                const countries = ct.getAllCountries();
                let listapais=[]
                Object.keys(countries).map(p=>{
                  listapais.push({_id:p, titulo:countries[p].name, zona:countries[p].timezones})
                  return p
                })
                datos[bloque]={
                  Titulo:bloque,
                  datos:nuevos.datos[tabla],
                  cantidad:nuevos.datos[tabla].length,
                  open: false,
                  dato:{},
                  listapais,
                  agencias: datos[bloque].agencias,
                  select_agencia: datos[bloque] ? datos[bloque].select_agencia : null,
                }
                pantalla={bloque,datos:datos[bloque],funciones}
              }
              this.setState({esperar:false, pantalla, datos})
            }
          },
          {
            label: 'NO',
  
          }
        ]
      });
  }
  Cancelar = (index)=>{
    let {pantalla, funciones, datos}= this.state;
    datos[index].open=false
    pantalla={bloque:index,datos:datos[index],funciones}
    this.setState({selected:index, pantalla, datos})
  }
  seleccion = async (index) =>{
    let {pantalla, funciones, datos}= this.state;
    const bloque=index//listado[index].primary
    let agencias = []
    // if (index==='Perfiles'){
      let respuesta= await conexiones.Leer_C(['Data_agencia'], 
        {
          'Data_agencia':{},
        }
      );
      if (respuesta.Respuesta==='Ok'){
        agencias=respuesta.datos['Data_agencia']
      }
    // }
    const countries = ct.getAllCountries();
    let listapais=[]
    Object.keys(countries).map(p=>{
      listapais.push({_id:p, titulo:countries[p].name, zona:countries[p].timezones})
      return p
    })
    if (Object.keys(Bloques).indexOf(bloque)!==-1){
        // pantalla=Bloques[bloque]([],funciones)
        datos[bloque]={
          Titulo:bloque,
          datos:datos[bloque] ? datos[bloque].datos: [],
          cantidad:datos[bloque] ? datos[bloque].cantidad : -1,
          open: false,
          dato:{},
          agencias,
          listapais,
          select_agencia: datos[bloque] ? datos[bloque].select_agencia : null,
        }
        pantalla={bloque,datos:datos[bloque],funciones}
    }
    this.setState({selected:index, pantalla, datos})
  }
  Select_agencia = async(valor) =>{
    // console.log(valor)
    let {pantalla, funciones, datos, selected}= this.state;
    let respuesta= await conexiones.Leer_C(['Data_perfil_amolatina'], 
        {
        'Data_perfil_amolatina':valor===null ? {} : {'nombre-agency': valor['first-name']},
        }
    );
    let bloque=selected
    datos[bloque]={
      Titulo:bloque,
      datos: respuesta.datos['Data_perfil_amolatina'],
      cantidad: respuesta.datos['Data_perfil_amolatina'].length,
      open: false,
      dato:{},
      agencias:datos[bloque].agencias,
      select_agencia: valor
    }
    pantalla={bloque,datos:datos[bloque],funciones}
    this.setState({pantalla, datos})
  }
  Refrescar = (index) =>{
    let {pantalla, funciones, datos}= this.state;
    const bloque=index//listado[index].primary
    if (Object.keys(Bloques).indexOf(bloque)!==-1){
        // pantalla=Bloques[bloque]([],funciones)
        datos[bloque]={
          Titulo:bloque,
          datos: [],
          cantidad: -1,
          open: false,
          dato:{},
          agencias:datos[bloque].agencias
        }
        pantalla={bloque,datos:datos[bloque],funciones}
    }
    this.setState({selected:index, pantalla, datos})
  }
  Cambios = async(name, value)=>{
    let {selected,pantalla, funciones, datos}= this.state;
    const index=selected
    datos[index].dato=value
    pantalla={bloque:index,datos:datos[index],funciones}
    this.setState({pantalla, datos})
  }
  Cambios_pais = async(name, value)=>{
    // console.log(name,value, value[name], value[name].titulo)
    if (value[name].titulo===undefined) return
    // const countries = ct.getAllCountries();
    // console.log(countries)
    // let zona=null
    // Object.keys(countries).map(p=>{
    //   const pos=countries[p].name.indexOf(value[name]);
    //   if (pos!==-1){
    //     // const timezones = ct.getTimezone(countries[p].timezones[0]);
    //     zona=countries[p].timezones[0]
    //     console.log(zona)
    //   }
    //   return p;
    // })
    let {selected,pantalla, funciones, datos}= this.state;
    const index=selected
    datos[index].dato={...value, pais: value[name].titulo, zona:value[name].zona[0]}
    pantalla={bloque:index,datos:datos[index],funciones}
    this.setState({pantalla, datos})
  }
  Cambios_tipo = async(name, value)=>{
    // console.log(name,value, value[name], value[name].titulo)
    if (value[name].titulo===undefined) return
    
    let {selected,pantalla, funciones, datos}= this.state;
    const index=selected
    datos[index].dato={...value, tipo: value[name].titulo}
    pantalla={bloque:index,datos:datos[index],funciones}
    this.setState({pantalla, datos})
  }


  async componentDidMount(){
    let user = await JSON.parse(localStorage.getItem(const_procesos.dir_user)); 

    let listado=[
        {icon: <DashboardIcon  />, primary:'Dashboard' },//Dashboard
    ]
    if ([0,1,2].indexOf(Number(user.categoria))!==-1){
      listado=[...listado,
          {icon: <BiCalculator style={{ fontSize: 25 }}/>, primary:'Operaciones', //Registor
          childen:[
            // {icon: <MonetizationOnIcon />, primary:'Calculos' },
            {icon: <MonetizationOnIcon />, primary:'Calculos Personal' },
            {icon: <AllInboxIcon />, primary:'Calculos Nomina' },
          ] 
        },
      ]
    }
    if ([0,1,2].indexOf(Number(user.categoria))!==-1){
      listado=[...listado,
          {icon: <BallotIcon />, primary:'Configuracion', //Registor
          childen:[
            {icon: <RecentActorsIcon />, primary:'Agencias' },
            {icon: <AssignmentIndIcon />, primary:'Perfiles' },
            {icon: <GroupAddIcon />, primary:'Personal' },
            {icon: <ListAltIcon />, primary:'Tabla de conversión' },
            {icon: <AssignmentIcon />, primary:'Multas' },
            {icon: <WatchLaterIcon />, primary:'Agregar Turno' },
            {icon: <AvTimerIcon />, primary:'Turnos' },
            // {icon: <PeopleOutlineIcon />, primary:'Supervisor VS Operador' },
            // {icon: <PeopleAltIcon />, primary:'Operador VS Perfil' },
            // {icon: <CalendarTodayIcon />, primary:'Planificar' },
            {icon: <CalendarTodayIcon />, primary:'Planificar' },
            {icon: <StairsIcon />, primary:'Metas' },
            {icon: <PeopleIcon />, primary:'Usuarios' },

          ] 
        },
      ]
    }
    
    if ([0].indexOf(Number(user.categoria))!==-1){
      listado=[...listado, 
        {icon: <SettingsIcon />, primary:'Herramientas', //Registor
          childen:[
            {icon: <MonetizationOnIcon />, primary:'Bonos' }, 
            {icon: <MonetizationOnIcon />, primary:'Perdidas' }, 
            {icon: <AppsIcon />, primary:'Multiples' }, 
            {icon: <AppsIcon />, primary:'Movimientos' },
            {icon: <BallotIcon />, primary:'Pruebas'} //Registor
          ]
        }, 
      ]
    }

    const bloque=listado[0].primary
    let datos={}
    let pantalla={}
    const {funciones}=this.state
    if (Object.keys(Bloques).indexOf(bloque)!==-1){
        // pantalla=Bloques[bloque]([],funciones)
        datos[bloque]={
          Titulo:bloque,
          datos:datos[bloque] ? datos[bloque].datos: [],
          cantidad:datos[bloque] ? datos[bloque].cantidad : -1,
          open: datos[bloque] ? datos[bloque].open : false,
          dato:{}
        }
        pantalla={bloque,datos:datos[bloque],funciones}
    }
    this.setState({datos, listado, seleccion: this.seleccion, pantalla })
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
      
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  render(){
    return (
      <div>
        <Page 
              {...this.state}
        />
        <Esperar 
          esperar={this.state.esperar}
          mensaje={this.state.mensaje_espera}
          progreso={this.state.progreso}
        />
      </div>
    )
  }
}
