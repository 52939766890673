import React, {Component} from 'react';
import {Titulo_default} from '../../procesos/servicios';
// import Skeleton from '@material-ui/lab/Skeleton';
import Page from './page';

//Utilizado para la creacion de formulacios con distintos datos
//formato de los datos
// formulario= {
//    campo:{
//        label:'titulo', value:'valor inicial del campo',
//        tipo:'tipo de dato que deseas ver o incluir' avatar, imagen, video, select, lista
//        en caso de ser select, lista:'valor de seleccion',
//              multiple:'bool indica seleccion multiple o no'
//        en caso de lista editable:'bool inidica si se puede modificar o no'
//                onClik:'funcion en el momento de un click'
//                value:'{lista:[item, item2, ...,], listae:[]}'
//        personal:{
//                  multiples:true, que desee varios campos en una fila
//                  value:{ el mismo formato de lo anterior}
//                }
//    }
// }
// formato de los datos segun el tipo
//Avatar:
class Formulario extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props:this.props,
          datos:this.props.datos,
      }
    }

  Gen_Form =(titulos, datos)=>{
      let resultado={}
      titulos.map(valor=>{
        resultado[valor.field]={
          label: valor.title,
          helperText:'',
          value:datos[valor.field] ? datos[valor.field] : '',

        }
        return valor;
      })
      return resultado;

  }

  Sel_grupo= (valor, lista) =>{
    let {resultados}=this.state;
    resultados[lista.name].grupo=valor[0];
    this.setState({resultados});
  }

  Cambio = (event) =>{
    let {name, value} = event.target;
    const {form}=this.state;
    const objeto= this.Buscar_objeto(name,form);
    // console.log(name, objeto);
    let {resultados}=this.state;
    // let seleccion=[];
    // if (typeof value === 'object'){
    //   seleccion=value;
    //   value= value._id;
    // }
    if (resultados[name]!==undefined && resultados[name]!==null && resultados[name].value){
      resultados[name].value=value;
    }else{
      resultados[name]=value;
    }

    // console.log(resultados[name]);
    this.setState({resultados});

    try{
      if (objeto.onChange!== undefined){
        objeto.onChange(name, resultados)
      }
      if (objeto.onClick!== undefined){
        objeto.onClick(value)
      }
    }catch(error) {

    }
  }

  Buscar_objeto = (name, form, result=undefined) =>{
    // const {form}=this.state;
    let resultado=result;
    Object.keys(form).map(valor=>{
      if (form[valor].multiples){
        resultado= this.Buscar_objeto(name,form[valor].value, resultado);
      }else if (valor===name){
        resultado= form[valor];
      }
      return valor;
    });
    // console.log(resultado);
    return resultado;
  }

  Agregar_file = (valores, file) => {
    let resultado=[];
    let agregar=true;
    valores.map(valor=>{
      if (valor.subtitulo || !agregar){
        resultado=[...resultado, valor];
      }else if (agregar){
        agregar=false;
        resultado=[...resultado,{...valor, subtitulo:file.name}]
      }
      return valor
    })
    if (agregar)
      resultado=[...resultado, {titulo:'Capitulo '+(resultado.length+1), subtitulo:file.name}]
    return resultado;
  }

  Cambio_A = (e) =>{
    const {name, files} = e.target;
    let tipo=[];
    const {form}=this.state;
    let {resultados}=this.state;
    const objeto= this.Buscar_objeto(name,form);
    // console.log('Archivosssssssssss',objeto);
    resultados[name]=resultados[name] === undefined ? [] : resultados[name];
    resultados[name+'_url']=resultados[name+'_url'] === undefined ? [] : resultados[name+'_url'];

    const url =Object.keys(files).map(ur => {
      resultados[name] = objeto.multiple ? [...resultados[name], files[ur]] : [files[ur]];
      if (objeto.multiple){
        const grupo = resultados[name+'_lista'].grupo;
        resultados[name+'_lista'].value[grupo] = this.Agregar_file(resultados[name+'_lista'].value[grupo],
          files[ur]);
      }
      tipo=[...tipo, files[ur].type.split('/')[0]]
      return URL.createObjectURL(files[ur])
    });
    // const tipo = files.map(type => type.type.split('/')[0]);
    resultados[name+'_url']= objeto.multiple ? [...resultados[name+'_url'],...url] : url;

    // resultados={
    //   ...resultados,
    //   [name]: archivos,
    //   [name+'_url']:url,
    //   [name+'_tipo']:tipo,
    // }
    // console.log(valor.subir_videos.resultados)
    try{
      if (objeto.onChange!== undefined){
        objeto.onChange(name, resultados)
      }
    }catch(error) {

    }
    this.setState({resultados});
  }

  componentDidMount(){
    let {titulos, datos, botones}= this.state.props;
    if (titulos===undefined && Object.keys(datos).length!==0){
      titulos= this.Gen_Form(Titulo_default(datos),datos);
    }
    let resultados= {...datos,...this.Valores_inicio(titulos)};

    this.setState({form: titulos, datos, resultados, total:datos,
                   Gen_Form:this.Gen_Form, Valores_inicio:this.Valores_inicio,
                   Cambio: this.Cambio, Cambio_A:this.Cambio_A,
                   Sel_grupo:this.Sel_grupo,
                   botones, Responder: this.Responder
                  });
  }

  Componente =(name, titulos=this.state.form, anterior=null)=>{
    let resultado={
      encontrado:false,
      direccion:anterior !== null ? [anterior] : [],
    };
    Object.keys(titulos).map(valor=>{
      if (titulos[valor].multiples && !resultado.encontrado){
        const resul= this.Componente(name, titulos[valor].value, valor);
        resultado= resul.encontrado ? resul : resultado;
      }else if (valor===name){
        resultado={
          encontrado:true,
          direccion:[...resultado.direccion,valor],
          valor:titulos[valor]
        }
      }
      return valor;
    })

      return resultado;

  }

  Valido = async(name,valor) =>{
    let {resultados}=this.state;
    let valido=true;
    const val = await this.Componente(name);
    if (val.encontrado && val.valor.required &&
        ['',null,undefined].indexOf(valor)!==-1) {
      resultados['Error-'+name]=val.valor.mensaje_error ? val.valor.mensaje_error :'Indique el valor';
      this.setState({resultados})
      valido=false;
    }else if (val.encontrado && val.valor.comparar &&
              valor!=='' && valor!==resultados[val.valor.con]) {
      resultados['Error-'+name]=val.valor.mensaje_error ? val.valor.mensaje_error :'Indique el valor';
      valido=false;
    }else if (val.encontrado && val.valor.comparar &&
              valor===resultados[val.valor.con]) {
      resultados['Error-'+name]='';
    }
    return valido;
  }

  Validar = async(valores) =>{
    let respuesta=true;
    return Promise.all(Object.keys(valores).map(async(valor) =>{
      const result = await this.Valido(valor, valores[valor])
      if (!result) {
        respuesta=result;
      }
      return result;
    })
    ).then(valor=>{
      return respuesta;
    })

  }

  Responder = async(funcion, valor, validar) =>{
    
    const {datos}=this.state;
    if (validar){
        const continuar= await this.Validar(valor);
        if (continuar) {
            funcion({...datos, ...valor});
        }
       this.setState({Cambios:true}) 
    }else{
        funcion({...datos, ...valor})
    }

  }

  Valores_inicio = (valores) =>{
    // console.log('============>',valores);
    let resultado = {};
    if (valores!==undefined){
      Object.keys(valores).map(valor =>{
        // console.log('...',valor);
        if (valores[valor].multiples){
          resultado = {...resultado, ...this.Valores_inicio(valores[valor].value)}
        }else{
          resultado = {...resultado, [valor]:valores[valor].value, ['Error-'+valor]:valores[valor].helperText}
          if (valores[valor].tipo==='video'){
            resultado[valor+'_lista']=
                {
                  name:valor+'_lista',
                  label:valores[valor].label,
                  editable:true,
                  grupo:valores[valor].grupo,
                  value:{[valores[valor].grupo]:[]},
                };
          }
        }
        return valor;
      });
    }
    return resultado;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.titulos !== state.form || props.datos !== state.datos) {
      let {datos, titulos, botones, estilospage}= props;
      
      // console.log('formularo segundo', datos)
      if (titulos===undefined && Object.keys(datos).length!==0){
        titulos= state.Gen_Form(Titulo_default(datos),datos);
      }
      // let resultados= Object.keys(datos).length !== 0 ? datos : {};
      let resultados= {...datos}
      resultados = state.Valores_inicio ? {...resultados,...state.Valores_inicio(titulos)} : resultados
      
      return {
        props,
        datos,
        form: titulos,
        botones,
        estilospage,
        resultados
      };
    }
    // No state update necessary
    return null;
  }

  render(){
    return (
      <Page
          datos={{...this.state}}
          http_server={this.props.http_server}
      />
    )
  }
}

export default Formulario;
