import React, {Component} from 'react';
import {conexiones} from '../../procesos/servicios';
import moment from "moment";
import socketIOClient from "socket.io-client";
import List from '@material-ui/core/List';
import { ListaPerfilesCabezera, ListaAgencia, ListaPerfilesEsperar } from '../listas/lista_amolatina';

//Constantes
import {Valord} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

export default class Puntos_agencias extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          puntos_agencias:[],
          Iniciar: this.Iniciar,
          Resumen_agencias:this.Resumen_agencias,
          selecciones:{},
          totales:0,
          Seleccion:this.Seleccion
      }
  }


  Iniciar= async() =>{
    
    this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    let respuesta= await conexiones.Leer_C(['Data_resumen_agencia','Data_agencia'], 
      {'Data_resumen_agencia':{fecha:moment.utc(new Date()).format('YYYY-MM-DD')},
        'Data_agencia':{}
      }
    );
    let puntos_agencias=[]
    let data_agencia=[]
    let totales=0
    let selecciones={}
    if (respuesta.Respuesta==='Ok'){
      data_agencia=respuesta.datos['Data_agencia']
      puntos_agencias= 
        respuesta.datos['Data_resumen_agencia'].map((v,i)=>{
          const ag= data_agencia.filter(f=> f['user-id']===v['agency-id'])[0]
          selecciones[i]=true
          totales+=v.points
          return {...v, puntos:v.points, nombre:ag.nombre, api:ag.api, email:ag.email}
        })
      puntos_agencias= puntos_agencias.sort((a,b)=> a.points>b.points ? -1 : a.points<b.points ? 1 : 0)  
    }else{
      setTimeout(()=>{
        this.Iniciar()
      }, 5000)
    }
    
    this.setState({
      data_agencia,
      puntos_agencias,
      selecciones, totales,
      Esperar:{esperar:false,mensaje:'Cargando datos...'}
    })
  }
  
  async componentDidMount(){
    this.Iniciar()
    const socket=socketIOClient("/")
    socket.on('Puntos_agencia',(dato)=>{
      
      let {puntos_agencias, selecciones, data_agencia}=this.state
      let totales=0
      dato.map(d=>{
        const pos = puntos_agencias.findIndex(v=> v['agency-id']===d['agency-id'])
        if (pos!==-1){
          puntos_agencias[pos]={...d, puntos:d.points, nombre:puntos_agencias[pos].nombre, api:puntos_agencias[pos].api}
          selecciones[selecciones.length - 1]=true
          totales+=d.points
        }else{
          const ag= data_agencia.filter(f=> f['user-id']===d['agency-id'])[0]
          totales= selecciones[pos] ? totales + d.points : totales
          puntos_agencias.push({...d, puntos:d.points, nombre:ag})
        }
        return d
      })
      puntos_agencias= puntos_agencias.sort((a,b)=> a.points>b.points ? -1 : a.points<b.points ? 1 : 0)  
      this.setState({puntos_agencias, totales, selecciones})
    })
    this.setState({socket})
  }
  
  componentWillUnmount(){
    let { socket}=this.state
    if (socket!==undefined && socket!==null){
      socket.disconnect();
      socket=null
  }
  }

  Resumen_agencias = (newsocket) =>{
    let {socket}=this.state
    let {puntos_agencias}=this.state
    if (socket ===undefined || socket===null) {
      socket=newsocket
      socket.on('Puntos_agencia',(dato)=>{
        const pos = puntos_agencias.findIndex(v=> v._id===dato.agencia._id)
        if (pos!==-1){
          puntos_agencias[pos]={...dato.agencia, puntos: dato.puntos, total: dato.total.toFixed(3)}
        }else{
          puntos_agencias.push({...dato.agencia, puntos: dato.puntos, total: dato.total.toFixed(3)})
        }
        puntos_agencias= puntos_agencias.sort((a,b)=> a.points>b.points ? -1 : a.points<b.points ? 1 : 0)  
        this.setState({puntos_agencias, socket})
      })
    }

  }
  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
      // state.Resumen_agencias(props.socket)
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  Seleccion = (index) =>{
    let {selecciones, puntos_agencias}=this.state
    selecciones[index]=selecciones[index]===undefined || !selecciones[index] ? true : false
    let totales=0
    Object.keys(selecciones).map(key=>{
      if (puntos_agencias[key])
        totales= selecciones[key] ? totales + puntos_agencias[key].puntos : totales
      return key
    })
    this.setState({selecciones, totales})
  }
  render(){
    
    return (
      <div>
        <List 
          style={{}}
          subheader={
            <ListaPerfilesCabezera 
              Titulo={`Puntos del dia por Agencia \n Puntos seleccionados: ${new Intl.NumberFormat('es-VE').format(this.state.totales)}`}
              alto={40}
              variant="caption"
            />
          }
        >
          {this.state.Esperar.esperar ? 
            <ListaPerfilesEsperar alto={110}/>:
            <ListaAgencia  {...this.state} alto={110}/>
          }
        {/* {this.state.puntos_agencias.map((punto, i) =>
          punto['nombre-agency']===undefined ? null :
          <ListItem 
            key={punto['nombre-agency']} alignItems="flex-start" 
            style={{backgroundColor:this.state.selecciones[i] ? '#8D8484' : '#E4DDDD', 
              padding:5, border:1, borderRadius:10, marginBottom:10,
              cursor:'pointer', 
            }}
          
            onClick={() => this.Seleccion(i)}        
          >
            <ListItemAvatar>
              <Avatar
                alt={`${punto['nombre-agency']}`}
                src={[undefined,'undefined'].indexOf(punto['agency-id'])===-1 && [undefined,'undefined'].indexOf(punto['imagen-agency'])===-1 ?
                      `https://api7.amolatina.com/users/${punto['agency-id']}/photos/${punto['imagen-agency']}.490x480.thumb-fd`:
                      ''
                    }
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="caption" display="block" gutterBottom style={{color:'#000000'}}>
                  {`Agencia: ${punto['nombre'] ? punto['nombre'] : punto['email'] }`}
                </Typography>
              }
              secondary={
                <Typography variant="caption" display="block" gutterBottom style={{color:'#000000'}}>
                  {`Puntos A: ${new Intl.NumberFormat('es-VE').format(punto.puntos)} \n Puntos S: ${new Intl.NumberFormat('es-VE').format(Number(punto.total))}`}
                </Typography>
              }
            />
          </ListItem>
          
        )} */}
        </List>
      </div>
    )
  }
}