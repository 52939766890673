import React from 'react';

import './styles.css';
import logo from '../../imagenes/logo.png';

import {Backdrop, LinearProgress, Box} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';

import Fade from '@material-ui/core/Fade';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? 700 : 200],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#0000FF',
  },
}))(LinearProgress);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box width="100%" mr={1}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="subtitle1" > {`${Math.round(
          props.value,
        )}%`} </Typography>

      </Box>
    </Box>
  );
}


function Page (props) {
  const classes= useStyles();
  let {esperar, mensaje, progreso} = props;
  esperar = esperar===undefined ? false : esperar;
  // progreso= progreso ? progreso : 0;
  // console.log('------------------',esperar,mensaje,progreso);
  const nodeRef = React.useRef(null)
  return (
    
    <div ref={nodeRef} className={classes.root} >

        <Backdrop className={classes.backdrop} open={esperar}
                  transitionDuration={100}
        >
          <Fade in={esperar}>
          <div className={classes.wrapper}>
            <img src={logo} className="logo-esperar" alt="logo" />

            <Typography variant="h6"  noWrap className={classes.mensaje}> {mensaje} </Typography>
            {progreso!==undefined ? <LinearProgressWithLabel value={progreso} /> : null}
          </div>
          </Fade>
        </Backdrop>

    </div>
    

  )
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 3,
      color: '#fff',
    },
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
      width:'38vmin',
      backgroundImage:'linear-gradient(180deg, #33454a 0, #303f42 12.5%, #2b3537 25%, #242829 37.5%, #1c1919 50%, #140406 62.5%, #0a0000 75%, #000000 87.5%, #000000 100%)'
    },
    barra: {
      color: green[500]
    },
    mensaje: {
      background: 'background-image: radial-gradient(circle at 50% 50%, #393f5c 0, #12131a 50%, #000000 100%);',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 255, 255, .3)',
      color: 'white',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }))

export default Page;
