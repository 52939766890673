import React, {Component} from 'react';
import Avatar from '@material-ui/core/Avatar';
import {conexiones} from '../../procesos/servicios';
import moment from "moment";
import Typography from '@material-ui/core/Typography';
// import Rating from '@material-ui/lab/Rating';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Grid from '@material-ui/core/Grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LinearProgress from '@material-ui/core/LinearProgress';
// import { withStyles } from '@material-ui/core/styles';
// import { Scrollbars } from 'rc-scrollbars';
import Scrollbars from '../formulario/MyScrollbars';
import Dialogo from '../formulario/dialogo';
import Link from '@material-ui/core/Link';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

//Constantes
import {Valord} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;
const categoria_usuario= config.categoria_usuario;

// const Seleccion = withStyles((theme) => ({
//   inputRoot: {
    
//     backgroundColor:'#ffffff',
//     bordeColor: config.Estilos.barra_menu.backgroundColor,
//     '&:hover': {
//       backgroundColor: '#ffffff',
//     },
//   },
//   input: {
    
//     bordeColor: config.Estilos.barra_menu.backgroundColor,
//     '&:hover': {
//       backgroundColor: '#ff000f',
//     },
//   },
// }))(Autocomplete);

export default class General_personal extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          datos:{},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          puntos_perfiles:[],
          apuntos_perfiles:[],
          Iniciar: this.Iniciar,
          Resumen_perfil: this.Resumen_perfil,
          total:0,
          multa:0,
          inicio:moment(new Date()).format('YYYY-MM-DD'),
          fin:moment(new Date()).format('YYYY-MM-DD'),
          Agencias:[],
          select_agencia:null,
          Servicios: Object.keys(config.service).map(v=>{
            return {_id:v, value:config.service[v].value, icon: config.service[v].icon}
          }),
          select_service:null,
          perfiles:[],
          select_perfil:null,
          personal:[],
          turnos:[],
          Data_resumen_personal:[],
          Dialogo:{
            open:false,
            Titulo:'Mi cuenta 1',
            tam:'xs',
            Cerrar: this.Cerrar_dialogo,

          },
      }
  }

  Ajustar_hora = (hora)=>{
    hora= hora>23 ? hora-24 : hora
    return hora
  }
  Diferencia_hora = (horai, horaf)=>{
    const diferencia= horai>=horaf ? horai - horaf : horai+24 - horaf
    return diferencia  
  }

  Iniciar= async(inicio, fin, puntos_estrellas, select, service, perfil) =>{
    
    this.setState({puntos_perfiles:[], puntos_estrellas:[], Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    // let cantidad=20;
    // let pag=0;
    // let salir= false
    // let puntos_perfiles=[]
    
    // let total=0
    let ini=new Date(inicio)
    ini.setDate(ini.getDate()+1)
    ini.setHours(ini.getHours()-ini.getHours(),0,0)
    let f=new Date(fin)
    f.setDate(f.getDate()+1)
    f.setHours(f.getHours()-f.getHours(),0,0)
    let respu= await conexiones.Leer_C(['Data_planificacion', 'Data_resumen_personal'], 
      {
        'Data_planificacion':{$and:[{fecha:{$gte:ini}}, {fecha:{$lte:f}}]},
        'Data_resumen_personal':{$and:[{fecha:{$gte:moment(ini).format('YYYY-MM-DD')}}, {fecha:{$lte:moment(f).format('YYYY-MM-DD')}}]}
      }
    );
    
    // let planificacion=[]
    let Data_resumen_personal
    if (respu.Respuesta==='Ok'){
        // planificacion= respu.datos.Data_planificacion
        Data_resumen_personal= respu.datos.Data_resumen_personal.map(data=>{
          const totales= data.totales.map(dat=>{
            const perfiles = data.miperfiles.filter(f=> f['user-id']===dat['user-id'])
            return {...dat, perfiles}
          })
          return {...data, totales}
        })
        console.log(Data_resumen_personal)

    }
    
    // //Calculo el dia final con respecto a las horas
    // const hoy = new Date()
    // let final=new Date(fin)
    // final.setDate(final.getDate()+1)
    // final.setHours(hoy.getHours(), hoy.getMinutes(), hoy.getSeconds())
    // final.setMinutes(final.getMinutes()+final.getTimezoneOffset())
    
    // while(!salir){
      
    //   let condicion = {$and:[{fecha:{$gte:inicio}}, {fecha:{$lte:moment(final).format('YYYY-MM-DD')}}]}
    //   if (select!==null){
    //     condicion['$and'].push({['agency-id']:select})
    //   }
    //   if (service!==null){
    //     condicion['$and'].push({['service']:service})
    //   }
    //   if (perfil!==null){
    //     condicion['$and'].push({['user-id']:perfil})
    //   }
    //   let respuesta= await conexiones.Leer_C(['Data_resumen_perfil'], 
    //     {
    //       'Data_resumen_perfil':{pagina:true, cantidad, pag, 
    //           condicion
    //       },
    //     }
    //   );
    //   let nuevo=[]
    //   if (respuesta.Respuesta==='Ok'){
    //     nuevo=respuesta.datos['Data_resumen_perfil']
    //         .map(v=>{
    //         total+= v.points  
    //         return {...v, puntose:0}//puntose:this.Calcular(v, puntos_estrellas)}
    //     })
    //     // .sort( (a,b) =>  a['fecha']>b['fecha'] ? 1 :  a['fecha']<b['fecha'] ? -1 : 0)
    //       //sort( (a,b) =>  a.hora>b.hora ? -1 :  a.hora<b.hora ? 1 : 0) 
    //     puntos_perfiles= [...puntos_perfiles , ...nuevo] 
    //   }

    //   this.setState({puntos_perfiles, apuntos_perfiles:puntos_perfiles,puntos_estrellas, total})
    //   if (nuevo.length<cantidad){
    //     salir = true;
    //   }else{
    //     pag+=1
    //   }
    // }
    // let {personal, turnos, perfiles}=this.state
    // // turnos.sort((a,b)=> a.turno>b.turno ? 1 : a.turno<b.turno ? -1 : 0)
    // // console.log(turnos)
    // // let hora
    // let descanso= {}
    // turnos =turnos.map(valor=>{
    //   const nuevo_inicio= Number(valor.hora_inicio.split(':')[0])-Number(valor.hora.split(':')[0])
    //   const nuevo_fin= Number(valor.hora_fin.split(':')[0])-Number(valor.hora.split(':')[0])
    //   const nuevo_inicio_b= valor.hora_inicio_b===undefined ? 0 : Number(valor.hora_inicio_b.split(':')[0])-Number(valor.hora.split(':')[0])
    //   const nuevo_inicio_c= valor.hora_inicio_c===undefined ? 0 : Number(valor.hora_inicio_c.split(':')[0])-Number(valor.hora.split(':')[0])
    //   const nuevo_fin_b= valor.hora_fin_b===undefined ? 0 : Number(valor.hora_fin_b.split(':')[0])-Number(valor.hora.split(':')[0])
    //   const nuevo_fin_c= valor.hora_fin_c===undefined ? 0 : Number(valor.hora_fin_c.split(':')[0])-Number(valor.hora.split(':')[0])
    //   const nuevo={
    //     ...valor,
    //     hora_inicio:this.Ajustar_hora(nuevo_inicio),
    //     hora_fin: this.Ajustar_hora(nuevo_fin), 
    //     hora_inicio_b:this.Ajustar_hora(nuevo_inicio_b), 
    //     hora_inicio_c:this.Ajustar_hora(nuevo_inicio_c), 
    //     hora_fin_b:this.Ajustar_hora(nuevo_fin_b),
    //     hora_fin_c:this.Ajustar_hora(nuevo_fin_c),
    //   }
      
    //   // let hora_inicio_b= new Date(inicio)
    //   // let hora_fin_b= new Date(inicio)
    //   // let hora_inicio_c= new Date(inicio)
    //   // let hora_fin_c= new Date(inicio)
    //   if (valor.descanso){ 
    //     const tur= `Break (${valor.turno.split('(')[1].split(')')[0]})`

    //     if (descanso[tur]===undefined){
    //       descanso[tur]={...valor}
    //       descanso[tur].turno=tur
    //       descanso[tur].hora_inicio= nuevo_inicio_b
    //       descanso[tur].hora_fin= nuevo_fin
    //       // descanso[tur].hora_inicio_b= nuevo_inicio_b + 5
    //       // descanso[tur].hora_fin_b= nuevo_inicio_b + 6
    //       // descanso[tur].hora_inicio_c= nuevo_fin -1
    //       // descanso[tur].hora_fin_c= nuevo_fin
    //     }
    //     let diferencia_b= this.Diferencia_hora(nuevo.hora_inicio_b,nuevo.hora_inicio)
    //     let diferencia_bi= this.Diferencia_hora(nuevo.hora_fin_b,nuevo.hora_inicio_b)
    //     let diferencia_c= this.Diferencia_hora(nuevo.hora_fin_c,nuevo.hora_inicio_c)

    //     // let hora_inicio= new Date(inicio)
    //     // hora_inicio.setHours(valor.hora_inicio_b.split(':')[0],0,0)
    //     // hora_inicio.setHours(hora_inicio.getHours()-hora)
    //     // let hora_fin= new Date(inicio)
    //     // hora_fin.setHours(valor.hora_fin_b.split(':')[0],0,0)
    //     // hora_fin.setHours(hora_fin.getHours()-hora)
        
    //     descanso[tur].hora_inicio=descanso[tur].hora_inicio> nuevo.hora_inicio_b ? nuevo.hora_inicio_b : descanso[tur].hora_inicio
    //     descanso[tur].hora_fin=descanso[tur].hora_fin< nuevo.hora_fin ? nuevo.hora_fin : descanso[tur].hora_fin
        
    //     descanso[tur].hora_inicio_b=this.Ajustar_hora(descanso[tur].hora_inicio + diferencia_b)
    //     descanso[tur].hora_fin_b=this.Ajustar_hora(descanso[tur].hora_inicio_b + diferencia_bi)
    //     descanso[tur].hora_inicio_c=this.Ajustar_hora(descanso[tur].hora_fin - diferencia_c)
    //     descanso[tur].hora_fin_c=this.Ajustar_hora(descanso[tur].hora_inicio_c + diferencia_c)

    //     // hora_inicio_b.setHours(valor.hora_inicio_b.split(':')[0],0,0)
    //     // hora_inicio_b.setHours(hora_inicio_b.getHours()-hora)
      
    //     // hora_fin_b.setHours(valor.hora_fin_b.split(':')[0],0,0)
    //     // hora_fin_b.setHours(hora_fin_b.getHours()-hora)
      
    //     // hora_inicio_c.setHours(valor.hora_inicio_c.split(':')[0],0,0)
    //     // hora_inicio_c.setHours(hora_inicio_c.getHours()-hora)
      
    //     // hora_fin_c.setHours(valor.hora_fin_c.split(':')[0],0,0)
    //     // hora_fin_c.setHours(hora_fin_c.getHours()-hora)
    //   }
      
    //   // else{
    //   //   hora_inicio_b.setHours(0,0,0)
    //   //   hora_fin_b.setHours(0,0,0)
    //   //   hora_inicio_c.setHours(0,0,0)
    //   //   hora_fin_c.setHours(0,0,0)
    //   // }

    //   return nuevo
    // })
    // // console.log(turnos)
    // // console.log(descanso)

    // Object.keys(descanso).map(v=>{
    //   turnos.push(descanso[v])
    //   return v
    // })
    // personal = personal.map(p=>{
    //     return {...p, totales:[], total:0.00, multa:0.00 , sup_total:0.00, sup_multa:0.00}
    // })

    // let personalB = {}
    // planificacion.map(d=>{

    //     d.personal.map(per=>{
    //         const pos = personal.findIndex(v=>v._id===per._id)
    //         let persona = personal[pos]
    //         // console.log(persona)
    //         persona.perfiles.map(perf=>{
    //             let perfilv= perfiles.filter(f=> f._id===perf._id)[0]

    //             const perfilesv= perfilv.operadores.filter(v=> v.turno===persona.turno)
    //             const posp= perfilesv.findIndex(v=> v._id===persona._id)
    //             let miperfil= puntos_perfiles.filter(f=> f['user-id']===perf['user-id'] && f.fecha===moment(d.fecha).format('YYYY-MM-DD'))
    //             const turno= turnos.filter(f=> f.turno===persona.turno.split('-')[0])[0]
    //             // console.log(turno, persona.turno)
    //             if (turno.descanso){
    //               // const hora_b= Number(turno.hora_inicio_b.split(':')[0])
    //               // const hora_b_f= Number(turno.hora_fin_b.split(':')[0])
    //               // const hora_c= Number(turno.hora_inicio_c.split(':')[0])
    //               // const hora_c_f= Number(turno.hora_fin_c.split(':')[0])
    //               const tur= `Break (${turno.turno.split('(')[1].split(')')[0]})-${turno.pais}`
    //               const turnolibre=miperfil.filter(f=> (Number(f.hora)>=turno.hora_inicio_b && Number(f.hora)< turno.hora_fin_b) || (Number(f.hora)>=turno.hora_inicio_c && Number(f.hora)< turno.hora_fin_c))
    //               personalB[tur] = personalB[tur]===undefined ? turnolibre:
    //                                 [...personalB[tur], ...turnolibre]
    //               miperfil=miperfil.filter(f=> (Number(f.hora)>=turno.hora_inicio && Number(f.hora)< turno.hora_inicio_b) || (Number(f.hora)>=turno.hora_fin_b && Number(f.hora)< turno.hora_inicio_c))
    //             }else{
    //               miperfil=miperfil.filter(f=> f.hora>=turno.hora_inicio && f.hora< turno.hora_fin)
    //             }
                
    //             let total=0
    //             let multa=0
    //             // console.log(perfilv, perfilesv, perfilv.operadores)
    //             if (posp===0 || (perfilesv.length!==0 && d.personal.findIndex(v=>v._id===perfilesv[0]._id)===-1)){
    //                 miperfil.map(v=>{
    //                     total+=v.points
    //                     multa+=v.mpoints
    //                     return v
    //                 })
    //                 persona.totales.push({...perf, total, multa, fecha:moment(d.fecha).format('YYYY-MM-DD')})
    //                 persona.total+=total
    //                 persona.multa+=multa
    //             }
    //           return perf
    //         })
    //         personal[pos].totales=persona.totales
    //         personal[pos].total=persona.total
    //         personal[pos].multa=persona.multa
    //       return per 
    //     })
    //     return d
    // })
    // // console.log(personalB)
    // Object.keys(personalB).map(t=>{
    //   const pos = personal.findIndex(v=>v.turno===t)
      
    //   let total=0
    //   let multa=0
    //   let perf_anterior=null
    //   let total_perf=0
    //   let multa_perf=0
    //   let totales=[]
    //   personalB[t].map(v=>{
    //     total+=v.points
    //     multa+=v.mpoints
    //     if (perf_anterior!==v['nombre-perfil']){
    //       total_perf=v.points
    //       multa_perf=v.mpoints
    //       perf_anterior=v['nombre-perfil']
    //     }else{
    //       total_perf+=v.points
    //       multa_perf+=v.mpoints
    //     }
    //     const pos1= totales.findIndex(i=> i['user-id']===v['user-id'])
    //     if (pos1===-1){
    //       totales.push({
    //         ...v, total:total_perf, multa:multa_perf, 
    //         'first-name':v['nombre-perfil'], thumbnail:v['imagen-perfil'],
    //         fecha:moment(inicio).format('YYYY-MM-DD')
    //       })
    //     }else{
    //       totales[pos1]={
    //         ...v, total:total_perf, multa:multa_perf, 
    //         'first-name':v['nombre-perfil'], thumbnail:v['imagen-perfil'],
    //         fecha:moment(inicio).format('YYYY-MM-DD')
    //       }
    //     }
    //     return v
    //   })
    //   personal[pos].totales=totales
    //   personal[pos].total=total
    //   personal[pos].multa=multa
    //   return t
    // })

    // personal.map((p, pos)=>{
    //   if (Number(p.categoria)===3){
        
    //     p.operadores.map((op,i)=>{
    //       const posop= personal.filter(v=> v._id===op._id)[0]
    //       // console.log(posop.nombres, Number(posop.total), posop.multa, posop )
    //       let ototal=0
    //       let omulta=0
    //       posop.totales.map(t=>{
    //         ototal+= t.total
    //         omulta+= t.multa
    //         return t
    //       })
    //       personal[pos].sup_total+=ototal
    //       personal[pos].sup_multa+=omulta
    //       personal[pos].operadores[i].total=ototal
    //       personal[pos].operadores[i].multa=omulta
    //       // personal[pos].operadores[i].fecha=moment(d.fecha).format('YYYY-MM-DD')
    //       return op
    //     })
    //   }
    //   return p
    // })
    // personal.sort((a,b)=> a.total>b.total ? -1 : a.total<b.total ? 1 : 0)
    // console.log(personal)
    Data_resumen_personal.sort((a,b)=>a.total>b.total ? -1 : a.total<b.total ? 1 : 0)
    let total=0;
    let multa=0;
    Data_resumen_personal.map(p=>{
      total+=p.total
      multa+=p.multa
      // sup_total+=p.sup_total
      return p
    })
    // ver dia 15
    this.setState({multa, total, Data_resumen_personal,  Esperar:{esperar:false,mensaje:'Cargando datos...'}})
  }

  async componentDidMount(){
    let puntos_estrellas=[]
    let Agencias=[]
    let perfiles=[]
    let personal=[]
    let turnos=[]
    let respu= await conexiones.Leer_C([
        'Data_punto','Data_agencia', 'Data_perfil_amolatina', 'Data_personal', 'Data_turno'], 
        {
          'Data_punto':{involucrado:0},
          'Data_agencia':{},
          'Data_perfil_amolatina':{suspended: false},
          'Data_personal':{$or:[ {categoria:3} , {categoria:4}]},
          'Data_turno':{}
        }
    );
    if (respu.Respuesta==='Ok'){
      puntos_estrellas= respu.datos['Data_punto']
      Agencias=respu.datos['Data_agencia']
      perfiles=respu.datos['Data_perfil_amolatina']
      personal= respu.datos['Data_personal'].sort((a, b) => (a.categoria >b.categoria ? -1 : a.categoria < b.categoria ? 1 : 0))
      turnos=respu.datos.Data_turno
    }
    this.setState({Agencias, puntos_estrellas, perfiles, personal, turnos})
    this.Iniciar(this.state.inicio,this.state.fin, puntos_estrellas, this.state.select_agencia, this.state.select_service, this.state.select_perfil)
  }

  componentWillUnmount(){
  }


  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        // const socket = props.socket
        // state.Resumen_perfil(socket)
        
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }
  Calcular = (punto, puntos_estrellas)=>{      
      const pos = puntos_estrellas.findIndex(f=> f.desde<=punto.points && punto.points<=f.hasta )
      if (pos!==-1){
        let valor= punto.points/(puntos_estrellas[pos].hasta-puntos_estrellas[pos].desde)
        valor+=puntos_estrellas[pos].desde
        
        return valor
      }else{
       return 0 
      }
  }
  Estilos = {
    barra_lista:{
        backgroundColor:config.Estilos.barra.backgroundColor, color:'#ffffff',    
    },
    cuerpo_lista:{
        height: window.screen.height * 0.90,
        padding:10,
        overflow:'scroll',
    },
    item_lista:{
        backgroundColor: '#E4DDDD', 
        padding:5, border:1, borderRadius:10, marginBottom:10,
        cursor:'pointer', 
    },
    avatar:{
      alignItems:'center',
      textAlign:'center'
    },
    fecha:{
      display:'flex',
      alignItems:'center'
    }
  }

  Cambio =(index)=>(fecha) =>{
    let nuevo = moment(fecha).format('YYYY-MM-DD')
    this.setState({[index]:nuevo})
    let {select_agencia, select_service, select_perfil, puntos_estrellas}= this.state
    let inicio = index==='inicio' ? nuevo : this.state.inicio;
    let fin = index==='fin' ? nuevo : this.state.fin;
    this.Iniciar(inicio,fin, puntos_estrellas, 
                  select_agencia!==null ? select_agencia['user-id'] : null, 
                  select_service=== null ? null : select_service._id,
                  select_perfil=== null ? null : select_perfil['user-id']
                )
  }

  Fecha = (fecha, index) =>{

    fecha= new Date(fecha)
    fecha.setDate(fecha.getDate()+1)
    
    return(
      <DatePicker
        style={{marginLeft:10}}
        selected={fecha}
        locale="es"
        customInput={<CalendarTodayIcon />}
        onChange={this.Cambio(index)} 
            
      ></DatePicker>
    )
  }

  Seleccionar_agencia = (agencia)=>{
    let {select_agencia, select_service, select_perfil, puntos_estrellas, inicio, fin}= this.state
    select_agencia=agencia
    
    this.Iniciar(inicio,fin, puntos_estrellas, 
                  select_agencia===null ? null : select_agencia['user-id'], 
                  select_service!==null  ? select_service._id : null,
                  select_perfil=== null ? null : select_perfil['user-id']
                )
    this.setState({select_agencia})
  }

  Seleccionar_servicio = (service)=>{
    let {select_agencia, select_service, select_perfil, puntos_estrellas, inicio, fin}= this.state
    select_service=service
    this.Iniciar(inicio,fin, puntos_estrellas, 
                  select_agencia!==null ? select_agencia['user-id'] : null, 
                  service=== null ? null : service._id,
                  select_perfil=== null ? null : select_perfil['user-id']
                  )
    this.setState({select_service})
  }

  Seleccionar_perfiles = (perfil)=>{
    let {select_agencia, select_service, select_perfil, puntos_estrellas, inicio, fin}= this.state
    select_perfil=perfil
    this.Iniciar(inicio,fin, puntos_estrellas, 
                select_agencia!==null ? select_agencia['user-id'] : null, 
                select_service=== null ? null : select_service._id,
                select_perfil=== null ? null : select_perfil['user-id']
                )
    this.setState({select_perfil})
  }

  Puntos_perfil = (datos)=>{
    console.log(datos)
    let {Dialogo}=this.state
    Dialogo.open=true
    Dialogo.tam='xs';
    Dialogo.Titulo=
      <div>
        {`Obtenido de ${datos['first-name']}`}
        <br/>
        {`Puntos: ${Number(datos.total ? datos.total : 0 ).toFixed(2)} - Multas: ${Number(datos.multa ? datos.multa : 0).toFixed(2)}`}
      </div>
    Dialogo.Cuerpo= datos.perfiles ?
      <List>
        <Scrollbars autoHeight autoHeightMax={window.innerHeight * 0.5}>
          {datos.perfiles.map((punto,i) =>
          
            <ListItem 
                
              key= {i+'-' + punto['user-id']+'-'+punto.service} 
              style={{backgroundColor:'#E4DDDD', padding:5, border:1, borderRadius:10, marginBottom:10}}
            >
              <ListItemAvatar>
                  <div style={{alignItems: 'center', justifyContent:'center', alignContent:'center', textAlign:'center', padding:10}}>
                    <Avatar
                      
                      alt={`${punto['nombre-perfil']}`}
                      src={`https://api7.amolatina.com/users/${punto['user-id']}/photos/${punto['imagen-perfil']}.490x480.thumb-fd`}
                    />
                  </div>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <div style={{ color:'#000000', }}>
                    <Typography variant="caption" display="block" gutterBottom>
                      {`${punto['user-id']} ${punto['nombre-perfil']}`}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom style={{marginTop:-7}}>
                      {`Puntos: ${Number(punto.points===undefined ? 0 : punto.points).toFixed(2)} ${punto.fecha} ${punto.hora}`}
                    </Typography>
                    
                    <Typography variant="caption" display="block" gutterBottom style={{marginTop:-7}}>
                      <Link href={`https://www.amolatina.com/people/#${punto['target-id']}`} target="_blank">
                        {`${punto['target-id']}`}
                      </Link>
                    </Typography>
                  </div>
                }
                
              />
              <ListItemSecondaryAction>
                {config.service[punto.service].icon}
              </ListItemSecondaryAction>
            </ListItem>
              
                    
          )}
        </Scrollbars>
      </List> : null
    
    this.setState({Dialogo})
  }

  Puntos_operador = (datos)=>{
    // console.log(datos)
    let {Dialogo}=this.state
    Dialogo.open=true
    Dialogo.tam='xs';
    Dialogo.Titulo=
      <div>
        {`Obtenido de ${datos.nombres+ ' ' + datos.apellidos}`}
        <br/>
        {`Puntos: ${Number(datos.total ? datos.total : 0).toFixed(2)} - Multas: ${Number(datos.multa ? datos.multa : 0).toFixed(2)}`}
      </div>
    Dialogo.Cuerpo= datos.perfiles ? 
      <List>
        <Scrollbars autoHeight autoHeightMax={window.innerHeight * 0.5}>
          {datos.perfiles.map((punto,i) =>
          
            <ListItem 
                
              key= {i+'-' + punto['user-id']+'-'+punto.service} 
              style={{backgroundColor:'#E4DDDD', padding:5, border:1, borderRadius:10, marginBottom:10}}
            >
              <ListItemAvatar>
                  <div style={{alignItems: 'center', justifyContent:'center', alignContent:'center', textAlign:'center', padding:10}}>
                    <Avatar
                      
                      alt={`${punto['nombre-perfil']}`}
                      src={`https://api7.amolatina.com/users/${punto['user-id']}/photos/${punto['thumbnail']}.490x480.thumb-fd`}
                    />
                  </div>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <div style={{ color:'#000000', }}>
                    <Typography variant="caption" display="block" gutterBottom>
                      {`${punto['user-id']} ${punto['first-name']}`}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom style={{marginTop:-7}}>
                      {`Puntos: ${Number(punto.total===undefined ? 0 : punto.total).toFixed(2)}`}
                      <br/>
                      {`Multas: ${Number(punto.multa===undefined ? 0 : punto.multa).toFixed(2)}`}
                    </Typography>
                    
                  </div>
                }
                
              />
            </ListItem>
              
                    
          )}
        </Scrollbars>
      </List> : null
    
    this.setState({Dialogo})
  }

  Cerrar_dialogo = () =>{
    let {Dialogo}=this.state
    Dialogo.open=false
    this.setState({Dialogo})
  }

  render(){
    return (
      <div>
        <List 
          subheader={
            <ListSubheader component="div" id="nested-list-subheader"
              style={this.Estilos.barra_lista}
            >
              {`Puntos del Personal`}
              {this.state.Esperar.esperar ? <LinearProgress /> : null}
                <Grid container spacing={2} justify="center" alignItems="center" direction="row">
                    <Grid container item spacing={2}>
                        <Grid item xs>
                          <div style={this.Estilos.fecha}>
                            <Typography variant="caption" display="block" gutterBottom>
                              {`${this.state.inicio}`}
                            </Typography>
                            {this.Fecha(this.state.inicio, 'inicio')} 
                          </div>
                        </Grid>
                        <Grid item xs>
                          <div style={this.Estilos.fecha}>
                            <Typography variant="caption" display="block" gutterBottom>
                              {`${this.state.fin}`}
                            </Typography>
                            {this.Fecha(this.state.fin, 'fin')} 
                          </div>
                        </Grid>
                        <Grid item xs>
                          {`Total: ${new Intl.NumberFormat('es-VE').format(this.state.total)}`} 
                        </Grid>
                        <Grid item xs>
                          {`Multas: ${new Intl.NumberFormat('es-VE').format(this.state.multa)}`} 
                        </Grid>
                    </Grid>
                </Grid>
            </ListSubheader>
          }
        >
          <div style={{...this.Estilos.cuerpo_lista}}>
              Lista por aqui
            {this.state.Data_resumen_personal.map((personal,i) =>
                <ListItem 
                    key={personal.id_user+'-'+i} alignItems="flex-start" 
                    style={this.Estilos.item_lista}
                >
                    <ListItemAvatar>
                      <div style={this.Estilos.avatar}>
                        <Avatar
                            style={{marginLeft:15, width:200, height:200}}
                            alt={`${personal.nombres}`}
                            src={`${personal.foto}`}
                        />
                        <Typography variant="caption" display="block" gutterBottom>
                            {`${personal.nombres} ${personal.apellidos}`}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom style={{marginTop:-7}}>
                            {personal.turno}
                        </Typography>
                      </div>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <div style={{color:'#000000'}}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    {`Puntos del ${categoria_usuario[personal.categoria].titulo}`}
                                </Typography>
                                <Scrollbars key={personal.id_user+'-Scrollbars'}style={{  height: 190 }}>
                                  <Grid container spacing={2} justify="center" alignItems="center" direction="row" style={{padding:20}}>
                                
                                  {personal.totales ? personal.totales.map((t,ji)=>
                                      <Grid item xs={4} key={personal.id_user+'-'+t._id+ji} onClick={()=>this.Puntos_perfil(t)}>
                                      
                                          <ListItemAvatar>
                                              <Avatar
                                                  style={{marginLeft:15}}
                                                  alt={`${t['first-name']}`}
                                                  src={`https://api7.amolatina.com/users/${t['user-id']}/photos/${t.thumbnail}.490x480.thumb-fd`}
                                              />
                                              
                                          </ListItemAvatar>
                                          <ListItemText
                                              primary={
                                                  <Typography variant="caption" display="block" gutterBottom>
                                                      {`${t['first-name']} Total: ${new Intl.NumberFormat('es-VE').format(t.total)} Multas: ${new Intl.NumberFormat('es-VE').format(t.multa)}`}
                                                  </Typography>
                                              }
                                              secondary={
                                                `${t.fecha}`
                                              }
                                          />
                                      </Grid>
                                      
                                  ) : null}
                                  {personal.categoria==='3' ? personal.operadores.map((t,ji)=>
                                      <Grid item xs={4} key={t._id+ji} onClick={()=>this.Puntos_operador(t)}>
                                      
                                          <ListItemAvatar>
                                              <Avatar
                                                  style={{marginLeft:15}}
                                                  alt={`${t.nombres}`}
                                                  src={`${t.foto}`}
                                              />
                                              
                                          </ListItemAvatar>
                                          <ListItemText
                                              primary={
                                                  <Typography variant="caption" display="block" gutterBottom>
                                                      {`${t.nombres} ${t.apellidos} Total: ${new Intl.NumberFormat('es-VE').format(t.total)} Multas: ${new Intl.NumberFormat('es-VE').format(t.multa)}`}
                                                  </Typography>
                                              }
                                              secondary={
                                                `${t.fecha}`
                                              }
                                          />
                                      </Grid>
                                      
                                  ) : null}
                                  
                                </Grid>
                                </Scrollbars>
                            </div>
                        }
                        secondary={
                          personal.categoria==='3' ?
                          <div>
                            <Typography variant="caption" display="block" gutterBottom style={{marginTop:5}}>
                              {`Puntos como Operador: ${personal.total===undefined ? 0 : new Intl.NumberFormat('es-VE').format(personal.total)} - Multas como Operador: ${personal.multa===undefined ? 0 :new Intl.NumberFormat('es-VE').format(personal.multa)}`}
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom style={{marginBottom:-10}}>
                              {`Puntos de Supervisados: ${personal.sub_total===undefined ? 0 : new Intl.NumberFormat('es-VE').format(personal.sub_total)} - Multas de Supervisados: ${personal.sub_multa===undefined ? 0 :new Intl.NumberFormat('es-VE').format(personal.sub_multa)}`}
                            </Typography>
                          </div>:
                          `Puntos: ${personal.total===undefined ? 0 : new Intl.NumberFormat('es-VE').format(personal.total)} - Multas: ${personal.multa===undefined ? 0 :new Intl.NumberFormat('es-VE').format(personal.multa)}`
                        }
                    />
                    
                </ListItem>
            )}
          </div> 
        </List>
        
        <Dialogo {...this.state.Dialogo}/>
      </div>
    )
  }
}