import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    // backgroundColor: '#000000',
    border:1,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  agregar:{
    display: 'flex',
    flexDirection:'row',
    marginBottom: 10,
  }
}));

export default function Lista_Representantes(props) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(true);

  // const handleClick = () => {
  //   setOpen(!open);
  // };
  
  const {valor, cambio} = props;
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [value, setValue] = React.useState(valor.value);
  const [nuevo, setNuevo] = React.useState(null);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const Eliminar = (dato) =>{
    // console.log(valor.name,dato)
    let nuevovalor= value.filter(val => val._id!==dato._id);
    setValue(nuevovalor);
    cambio({target:{name:valor.name,value:nuevovalor}});
  }

  const agregarsubClik = () =>{
    // console.log('datos', nuevo)
    if (nuevo!==null){
      let nuevovalor= value ? value.filter(val => val.cedula===nuevo.cedula && val.nombres===nuevo.nombres && val.apellidos===nuevo.apellidos): [];
      if (nuevovalor.length===0){
        let nuevovalue=[...value, {cedula:nuevo.cedula, nombres:nuevo.nombres, apellidos:nuevo.apellidos}];
        setValue(nuevovalue)
        cambio({target:{name:valor.name,value:nuevovalue}});
      }
    }
  }
  
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {valor.label}
          {valor.activar ? 
            <div className={classes.agregar}>
                <Autocomplete
                  disabled={!valor.activar}
                  options={valor.lista}
                  getOptionLabel={valor.getOptionLabel ? valor.getOptionLabel : (option) => `${option.cedula} ${option.nombres} ${option.apellidos}`}
                  style={{ width:'96%'}}
                  renderInput={(params) => <TextField {...params} label="Estudiantes" variant="outlined" />}
                  onChange={(event, newValue) => {
                    if (newValue!==null){
                      // values.Cambio({target:{name:valor.name, value:newValue}})
                      setNuevo(newValue);
                    }
                  }}
                />
                <IconButton title='Agregar sub-titulo'
                          className={classes.margin}
                          onClick={agregarsubClik}
                          key={'buton-aceptar-'+valor.name}
                          disabled={!valor.activar}
                >
                  <AddCircleIcon key={'icon-aceptar-'+valor.name} style={{ color: green[500] }} fontSize="large"/>
                </IconButton>
            </div> : null
          }
        </ListSubheader>
      }
      className={classes.root}
    >
      {value ? value.map((val,i)=>
        <ListItem button
            key={val.cedula}
            selected={selectedIndex === i}
            onClick={(event) => handleListItemClick(event, i)}
        >

          <ListItemText key={'text-'+val.cedula} primary={`${val.cedula} ${val.nombres} ${val.apellidos}`} />
          <ListItemSecondaryAction key={'boton-'+val.cedula}>
            {valor.activar ? 
              <IconButton disabled={!valor.activar} key={'eliminar-'+val.cedula} edge="end" aria-label="delete" onClick={() => Eliminar(val)}>
                <DeleteIcon />
              </IconButton>: null
            }
          </ListItemSecondaryAction>
        </ListItem>  
        
      ):null}
      
    </List>
  );
}