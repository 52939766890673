import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {conexiones} from '../../procesos/servicios';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function Actualizar_dotos_mes() {
  const classes = useStyles();

  const Enviar = async() =>{
    console.log('por aqui')
    const respuesta = await conexiones.Enviar({
        http_destino:'/amolatina/actualizar',
        method:'POST',
    });
    console.log(respuesta)
  }
  return (
    <div className={classes.root}>
      <Button variant="contained" onClick={()=> Enviar()}>Default</Button>
      <Button variant="contained" color="primary">
        Primary
      </Button>
      <Button variant="contained" color="secondary">
        Secondary
      </Button>
      <Button variant="contained" disabled>
        Disabled
      </Button>
      <Button variant="contained" color="primary" href="#contained-buttons">
        Link
      </Button>
    </div>
  );
}
