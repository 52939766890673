import React, {Component} from 'react';
import Page from './page';



export default class Pruebas extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          Cambio: this.Cambio,
          Quitar: this.Quitar,
          pantalla:[
            {titulo:'Principal', subtitulo:'subtitulo de principal', cuerpo:<div style={{backgroundColor:'#000000'}}/>}
          ],
      }
  }

  Cambio =(index)=>{
    let {pantalla}=this.state
    if (index==='Agregar'){
      const pos = pantalla[pantalla.length-1].pos + 1
      const titulo = pantalla[0].titulo + ' ' +pos
      pantalla.push({...pantalla[0], titulo, pos})
    }else if (pantalla.length!==1){
      pantalla.pop()
    }
    this.setState({pantalla})
  }

  Quitar =(index)=>{
    let {pantalla}=this.state
    if (pantalla.length!==1){
      pantalla.splice(index, 1);
      this.setState({pantalla})
    } 
  }

  

  componentDidMount(){
    
    let {Pantalla} = this.state.props
    let {pantalla}= this.state
    pantalla=[{...Pantalla, pos:0}]
    this.setState({pantalla})
    
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
      console.log('Actualizar multiple',props)
      let {Pantalla} = props
      let {pantalla} = state
      pantalla=[{...Pantalla, pos:0}]
      return {
        props,
        pantalla
      };
    }
    // No state update necessary
    return null;
  }

  render(){
    return (
      <Page  {...this.state}/>
    )
  }
}