import React, { Component } from 'react';
import { Scrollbars} from 'rc-scrollbars';


export default class MyScrollbars extends Component {  

  constructor(props) {
    super(props);    
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
    this.renderTrack = this.renderTrack.bind(this);    
  }
  
  /*componentDidMount() {
    this.springSystem = new SpringSystem();
    this.spring = this.springSystem.createSpring();
    this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate });    
  };

  componentWillUnmount() {
    this.springSystem.deregisterSpring(this.spring);
    this.springSystem.removeAllListeners();
    this.springSystem = undefined;
    this.spring.destroy();
    this.spring = undefined;
  };*/

  renderView = ({ style, ...props }) => {
    const viewStyle = this.props.Bodystyle ? this.props.Bodystyle : {
      padding: 5,
      paddingTop: 0,
      paddingRight: 10
      // backgroundColor: `rgb(255, 255, 255)`,           
    };    
    return <div className="box" style={{ ...style, ...viewStyle }} {...props}/>;
  };
   renderThumb = ({ style, ...props }) => {    
    const thumbStyle = this.props.Thumbstyle ? this.props.Thumbstyle :  {
      backgroundColor: `#D0C6C6`,       
      borderRadius: 'inherit',
    };
    return <div style={{ ...style, ...thumbStyle,  }} {...props} />;
  };
   renderTrack = ({ style, ...props}) =>{
    const trackStyle = this.props.Pistastyles ? this.props.Pistastyles : {
      // backgroundColor: `rgb(255, 255, 255)`,      
  };
      return <div style={{ ...style, ...trackStyle }} {...props} />;
  };

  render() {
    return (
      <Scrollbars              
        renderView={this.renderView}        
        renderThumbVertical={this.renderThumb}         
        renderThumbHorizontal={this.renderThumb}  
        hideTracksWhenNotNeeded      
        renderTrackVertical={this.renderTrack} 
        renderTrackHorizontal={this.renderTrack}          
        {...this.props}
        
      />
    );
  }
}