import {Component} from 'react';
import {conexiones} from '../../procesos/servicios';
import moment from "moment";
import socketIOClient from "socket.io-client";
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Dialogo from '../../components/formulario/dialogo';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LinearProgress from '@material-ui/core/LinearProgress';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { withStyles } from '@material-ui/core/styles';
import { ListaPerfilesEsperar, ListaPerfiles, ListaPerfilesCabezera } from '../listas/lista_amolatina';

//Constantes
import {Valord, const_procesos} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

const Filtrar = (User, personal, puntos_perfiles)=>{
  if (['3','4'].indexOf(User.categoria)!==-1){
    let nuevo=[]
    personal[0].perfiles.map(f=>{
      const pos = puntos_perfiles.filter(v=> v['user-id']===f)
      nuevo=[...nuevo,...pos]
      return f
    })
    puntos_perfiles=[...nuevo]
    
  }
  return puntos_perfiles
}

export default class Puntos_por_perfil extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          puntos_perfiles:[],
          Iniciar: this.Iniciar,
          Resumen_perfil: this.Resumen_perfil,
          open:false,
          
          Agencias:[],
          Perfiles:[],
          select_agencia:null,
          select_servicio:null,
          select_perfil:null,
          Open_dialogo: this.Open_dialogo,
          Dialogo:{
            open:false,
            Titulo:'Puntos por dia',
            tam:'md',
            Cerrar: this.Cerrar_dialogo,
          }
      }
  }

  Open_dialogo = (valor) =>{
    let {Dialogo}=this.state
    Dialogo.Cuerpo = <Ampliada puntos={this.state.puntos_perfiles}/>
    Dialogo.open=true
    this.setState({Dialogo})
  }

  Cerrar_dialogo = () =>{
    let {Dialogo}=this.state
    Dialogo.open=false
    this.setState({Dialogo})
  }
  

  Iniciar= async(esperar=true) =>{
    let User = await JSON.parse(localStorage.getItem(const_procesos.dir_user));
    if (esperar) this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    const dia= moment.utc(new Date()).format('YYYY-MM-DD')
    
    const resultados= await conexiones.Enviar({
      datos:{User,dia, dia1:dia},
      http_destino:'/api/puntos_dia',
      method:'POST',
    });
    // console.log(resultados)
    let puntos_perfiles=[]
    if (resultados.Respuesta==='Ok'){
      puntos_perfiles=resultados.puntos_perfiles
      this.setState({ puntos_perfiles})
    }
    this.setState({ Esperar:{esperar:false,mensaje:'Cargando datos...'}})
    // let respuesta= await conexiones.Leer_C(
    //   ['Data_resumen_perfil', 'Data_punto', 'Data_perfil_amolatina','Data_personal','Data_planificacion_dia'], 
    //   {
    //     'Data_resumen_perfil':{fecha:{$gte:dia}},
    //     'Data_punto':{involucrado:0},
    //     'Data_perfil_amolatina':{suspended: false},
    //     'Data_personal':{cedula:User.cedula},
    //     'Data_planificacion_dia':{fecha:dia}
    //   }
    // );
    // let puntos_perfiles=[]
    // let puntos_estrellas=[]
    // let perfiles=[]
    // let personal = []
    // let planificacion=[]
    // if (respuesta.Respuesta==='Ok'){
    //   if (respuesta.datos['Data_planificacion_dia'].length!==0)
    //       planificacion=respuesta.datos['Data_planificacion_dia'][0].asistencia
    //   personal= respuesta.datos["Data_personal"]
    //   const posss= planificacion.filter(f=> f._id===personal[0]._id)
    //   if (posss.length!==0)
    //     personal[0].perfiles=posss[0].perfiles
    //   perfiles= respuesta.datos["Data_perfil_amolatina"]
    //   perfiles= Filtrar(User,personal,perfiles)
    //   puntos_estrellas= respuesta.datos['Data_punto']
    //   console.log(respuesta.datos['Data_resumen_perfil'].length)
    //   puntos_perfiles=this.Puntaje(perfiles, respuesta.datos['Data_resumen_perfil'], puntos_estrellas)

    //   this.setState({personal, planificacion, puntos_perfiles, puntos_estrellas, perfiles, Esperar:{esperar:false,mensaje:'Cargando datos...'}})
    // }else{
    //   setTimeout(()=>{
    //     this.Iniciar()
    //   }, 2000)
    // }
    // this.setState({ puntos_perfiles, Esperar:{esperar:false,mensaje:'Cargando datos...'}})
  }

  Puntaje = (perfiles, datos, puntos_estrellas)=>{
    let puntos_perfiles=[]
    perfiles.map( perfil =>{
        const data=  datos.filter(f=> f['user-id']===perfil['user-id'])
        let puntos=0
        let multas=0
        data.map(d=>{
            puntos+=d.points
            multas+= d.mpoints
            return d
        })
        puntos_perfiles.push({...perfil, points: puntos, mpoints: multas, puntose: this.Calcular(puntos, puntos_estrellas)})
        return perfil
    })
    puntos_perfiles= puntos_perfiles.sort((a,b)=> a.points>b.points ? -1 : a.points<b.points ? 1 : 0)
    return puntos_perfiles
  }

  componentDidMount(){
    this.Iniciar()
    const socket=socketIOClient("/")
    socket.on('Resumen_perfil_dia',(dato)=>{
        // let {perfiles, puntos_perfiles, puntos_estrellas}=this.state
        // // let nuevo_puntos_perfiles= dato
        // dato.map(p=>{
        //   let pos= puntos_perfiles.findIndex(v=> v._id===p._id)
        //   if (pos===-1){
        //     puntos_perfiles.push(p)
        //   }
        //   return p
        // })
        // puntos_perfiles=this.Puntaje(perfiles,puntos_perfiles, puntos_estrellas)
        // this.setState({puntos_perfiles})
        // this.Iniciar(false)
        // this.setState({ puntos_perfiles:dato})
        setTimeout(()=>{
          this.Iniciar(false)
        }, 3000)
    })
    this.setState({socket})
  }
  componentWillUnmount(){
      let { socket}=this.state
      if (socket !== undefined && socket!==null){
        socket.disconnect();
        socket=null
      }
  }


  Resumen_perfil = (newsocket)=>{
    let {socket, puntos_perfiles}=this.state
    
    if (socket ===undefined || socket===null) {
      socket=newsocket
      socket.on('Resumen_perfil',(dato)=>{
        const {perfiles}=this.state
        
        puntos_perfiles= this.Puntaje(perfiles,dato)
        this.setState({puntos_perfiles})
      })
      this.setState({ socket})
    }
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        // const socket = props.socket
        // state.Resumen_perfil(socket)
        
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  Calcular = (punto, puntos_estrellas)=>{      
    const pos = puntos_estrellas.findIndex(f=> punto<=f.hasta )
    if (pos!==-1){
      let valor= pos + ((punto-puntos_estrellas[pos].desde)/(puntos_estrellas[pos].hasta-puntos_estrellas[pos].desde))
      return valor
    }else{
     return 0 
    }
  }

  render(){
    
    return( 
      <div>
        <List 
            subheader={
              <ListaPerfilesCabezera Titulo={'Puntos del dia'} Open={this.Open_dialogo}/>
              
            }
        >
          {this.state.Esperar.esperar ? 
            <ListaPerfilesEsperar/>:
            <ListaPerfiles datos={this.state.puntos_perfiles}/>
          }
        </List>
        <Dialogo {...this.state.Dialogo}/>
      </div>
      )
  }
}

const Seleccion = withStyles((theme) => ({
  inputRoot: {
    
    backgroundColor:'#ffffff',
    bordeColor: config.Estilos.barra_menu.backgroundColor,
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  input: {
    
    bordeColor: config.Estilos.barra_menu.backgroundColor,
    '&:hover': {
      backgroundColor: '#ff000f',
    },
  },
}))(Autocomplete);

class Ampliada extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          puntos_perfiles: this.props.puntos,
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          inicio:moment(new Date()).format('YYYY-MM-DD'),
          fin:moment(new Date()).format('YYYY-MM-DD'),
          Agencias:[],
          select_agencia:null,
          Servicios: Object.keys(config.service).map(v=>{
            return {_id:v, value:config.service[v].value, icon: config.service[v].icon}
          }),
          select_service:null,
          perfiles:[],
          select_perfil:null
      }
  }

  Iniciar= async(inicio, fin, select, service, perfil) =>{
    
    this.setState({puntos_perfiles:[], Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    
    let total=0
    let condicion = {$and:[{suspended: false}]}
    if (select!==null){
      condicion['$and'].push({'parent-id':select})
    }
    let respuesta= await conexiones.Leer_C(['Data_resumen_perfil', 'Data_perfil_amolatina'], 
      {
        'Data_resumen_perfil':{fecha:inicio},
        'Data_perfil_amolatina':condicion
      }
    );
    let puntos_perfiles=[]
    let puntos_estrellas=[]
    let perfiles=[]
    if (respuesta.Respuesta==='Ok'){
      perfiles= respuesta.datos["Data_perfil_amolatina"]
      puntos_estrellas= this.state.data_punto
      puntos_perfiles=this.Puntaje(perfiles, respuesta.datos['Data_resumen_perfil'], puntos_estrellas)
      
    }
    
    total=0;
    puntos_perfiles=Filtrar(this.state.User,this.state.personal,puntos_perfiles)
    puntos_perfiles.map(v=>{
      total+=v.points
      return v
    })
    this.setState({total, puntos_perfiles, puntos_estrellas,perfiles,  Esperar:{esperar:false,mensaje:'Cargando datos...'}})
  }
  Puntaje = (perfiles, datos, puntos_estrellas)=>{
    let puntos_perfiles=[]
    perfiles.map( perfil =>{
        const data=  datos.filter(f=> f['user-id']===perfil['user-id'])
        let puntos=0
        let multas=0
        data.map(d=>{
            puntos+=d.points
            multas+= d.mpoints
            return d
        })
        puntos_perfiles.push({...perfil, points: puntos, mpoints: multas, puntose: this.Calcular(puntos, puntos_estrellas)})
        return perfil
    })
    puntos_perfiles= puntos_perfiles.sort((a,b)=> a.points>b.points ? -1 : a.points<b.points ? 1 : 0)
    return puntos_perfiles
  }
  Calcular = (punto, puntos_estrellas)=>{      
    const pos = puntos_estrellas.findIndex(f=> punto<=f.hasta )
    if (pos!==-1){
      let valor= pos + ((punto-puntos_estrellas[pos].desde)/(puntos_estrellas[pos].hasta-puntos_estrellas[pos].desde))
      return valor
    }else{
     return 0 
    }
  }
  Cambio =(index)=>(fecha) =>{
    let nuevo = moment(fecha).format('YYYY-MM-DD')
    this.setState({[index]:nuevo})
    let {select_agencia, select_service, select_perfil}= this.state
    let inicio = index==='inicio' ? nuevo : this.state.inicio;
    let fin = index==='fin' ? nuevo : this.state.fin;
    this.Iniciar(inicio,fin, 
                  select_agencia!==null ? select_agencia['user-id'] : null, 
                  select_service=== null ? null : select_service._id,
                  select_perfil=== null ? null : select_perfil['user-id']
                )
  }
  Fecha = (fecha, index) =>{
    
    // fecha= fecha.split('-')
    // fecha = fecha[1]+'-'+fecha[2]+'-'+fecha[0]
    fecha= new Date(fecha)
    fecha.setDate(fecha.getDate()+1)
    
    return(
      <DatePicker
        style={{marginLeft:10}}
        selected={fecha}
        locale="es"
        customInput={<CalendarTodayIcon />}
        onChange={this.Cambio(index)} 
        popperClassName="some-custom-class"
        popperPlacement="top-end"
        withPortal
      ></DatePicker>
    )
  }
  Seleccionar_agencia = async (agencia)=>{
    let {select_agencia, select_service, select_perfil, inicio, fin, perfiles}= this.state
    let condicion = {$and:[{suspended:false}]}
    if (agencia!==null){
      condicion['$and'].push({'parent-id':agencia['user-id']})
    }
    let respu= await conexiones.Leer_C(['Data_perfil_amolatina'], 
        {
          'Data_perfil_amolatina':condicion
        }
    );
    if (respu.Respuesta==='Ok'){
      perfiles=respu.datos['Data_perfil_amolatina']
      select_perfil=null
    }
    select_agencia=agencia
    
    this.Iniciar(inicio,fin, 
                  select_agencia===null ? null : select_agencia['user-id'], 
                  select_service!==null  ? select_service._id : null,
                  select_perfil=== null ? null : select_perfil['user-id']
                )
    this.setState({select_agencia, perfiles, select_perfil})
  }

  Seleccionar_servicio = (service)=>{
    let {select_agencia, select_service, select_perfil, inicio, fin}= this.state
    select_service=service
    this.Iniciar(inicio,fin, 
                  select_agencia!==null ? select_agencia['user-id'] : null, 
                  service=== null ? null : service._id,
                  select_perfil=== null ? null : select_perfil['user-id']
                  )
    this.setState({select_service})
  }

  Seleccionar_perfiles = (perfil)=>{
    let {select_agencia, select_service, select_perfil, inicio, fin}= this.state
    select_perfil=perfil
    this.Iniciar(inicio,fin, 
                select_agencia!==null ? select_agencia['user-id'] : null, 
                select_service=== null ? null : select_service._id,
                select_perfil=== null ? null : select_perfil['user-id']
                )
    this.setState({select_perfil})
  }
  Estilos = {
    barra_lista:{
        backgroundColor:config.Estilos.barra.backgroundColor, color:'#ffffff',
        marginBottom: 20, borderTop:10, borderTopLeftRadius:10,
        borderTopRightRadius:10, padding:20    
    },
    cuerpo_lista:{
        height: window.screen.height * 0.65,
        padding:10,
        overflow:'scroll',
    },
    item_lista:{
        backgroundColor: '#E4DDDD', 
        padding:5, border:1, borderRadius:10, marginBottom:10,
        cursor:'pointer', 
    },
    avatar:{
      alignItems:'center',
      textAlign:'center'
    },
    fecha:{
      display:'flex',
      alignItems:'center'
    }
  }

  async componentDidMount(){
    let User = await JSON.parse(localStorage.getItem(const_procesos.dir_user));
    let Agencias=[]
    let perfiles=[]
    let data_punto=[]
    let personal=[]
    let respu= await conexiones.Leer_C(['Data_punto', 'Data_agencia', 'Data_perfil_amolatina','Data_personal'], 
        {
          'Data_punto':{involucrado:0},
          'Data_agencia':{},
          'Data_perfil_amolatina':{suspended: false},
          'Data_personal':{cedula:User.cedula}
        }
    );
    if (respu.Respuesta==='Ok'){
      personal=respu.datos['Data_personal']
      data_punto=respu.datos['Data_punto']
      Agencias=respu.datos['Data_agencia']
      perfiles=respu.datos['Data_perfil_amolatina']
      perfiles= Filtrar(User,personal,perfiles)
    }else{
      setTimeout(()=>{
        this.componentDidMount()
      }, 5000)
    }
    this.setState({Agencias, perfiles, data_punto, User, personal})
    
  }
  render(){
    return (
      <div>
        <List 
            subheader={
              <ListSubheader component="div" id="nested-list-subheader"
                style={this.Estilos.barra_lista}
                disableGutters
              >
                  {this.state.Esperar.esperar ? <LinearProgress /> : 
                <Grid container spacing={2} justify="center" alignItems="center" direction="row">
                    <Grid container item spacing={2}>
                        <Grid item xs={1}/>
                        <Grid item xs={3}>
                          <div style={this.Estilos.fecha}>
                            <Typography variant="caption" display="block" gutterBottom>
                              {`${this.state.inicio}`}
                            </Typography>
                            {this.state.Esperar.esperar ? null : this.Fecha(this.state.inicio, 'inicio')} 
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <Seleccion
                              autoComplete
                              name={'select_agencia'}
                              options={this.state.Agencias}
                              getOptionLabel={(option) => {
                                return `${option['nombre']!==undefined ? option['nombre']: ''}`}
                              }
                              onChange={(event, newValue)=>{
                                this.Seleccionar_agencia(newValue)
                              }}
                              value={this.state.select_agencia}
                              renderInput={(params) => 
                                <TextField {...params} label={'Seleccionar Agencia'} variant="outlined" 
                                    
                                />
                              }
                              disabled={this.state.Esperar.esperar ? true : false }
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="caption" display="block" gutterBottom>
                              {`${Number(this.state.total===undefined ? 0 : this.state.total).toFixed(2)}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid> }
              </ListSubheader>
            }
        >
          <ListaPerfiles datos={this.state.puntos_perfiles} />
        
        </List>
      </div>

    )
  }   
  
}