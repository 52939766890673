import React, {Component} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import {conexiones} from '../../procesos/servicios';
import Esperar from '../esperar';
// import moment from "moment";
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { Scrollbars } from 'rc-scrollbars';

import AsignarTurnos from '../turnos/asignar_turno';
import Asignar from '../supervisor/asignacion';
import Supervisor from '../supervisor/asignacion_supervisor';
import Operador from '../operadores/operarorvsperfil';

//Constantes
import {Valord} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;
const categoria_usuario= config.categoria_usuario;

const useStyles = makeStyles({
  root: {
    margintop:-10,
    flexGrow: 1,
  },
});

function Cambio(props) {
  const classes = useStyles();
  const theme = useTheme();
  
  return (
    <div>
        <MobileStepper
        variant="dots"
        steps={props.cantidad}
        position='static'
        activeStep={props.seleccion}
        className={classes.root}
        nextButton={
          <div>
            <Button size="small" onClick={props.Siguiente} disabled={props.seleccion === props.cantidad}>
              {props.seleccion===props.cantidad-1 ? 'Guardar' : 'Siguiente'}
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          </div>
        }
        backButton={
            <Button size="small" onClick={props.Anterior} disabled={props.props.supervisor ? props.seleccion === 2 : props.seleccion === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Anterior
            </Button>
        }
        />
      
    </div>
    
  );
}

//Asignar operadores a supervisores
export default class Planificacion_dia extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{personal:[],perfiles:[], turnos:[]},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          seleccion:0,
          cantidad:5,
          Anterior:this.Anterior,
          Siguiente: this.Siguiente
      }
  }
  
  Anterior =()=>{
    this.setState({seleccion:this.state.seleccion-1})
  }

  Eliminar_key = (valor)=>{
    valor.Grupos.map(g=>{
      delete g.titulo
      const childen= g.childen.map(h=>{
        delete h.titulo
        return h
      })
      return {...g, childen}
    })
    valor.Libres.map(l=>{
      delete l.titulo
      return l
    })
    return valor
  }
  Siguiente = async ()=>{
    let {seleccion,cantidad, datos, props}=this.state;
    if (seleccion===cantidad-1){
      let enviar = {
        fecha:`${props.dia.split('-')[2]}-${props.dia.split('-')[1]}-${props.dia.split('-')[0]}`,
        dia:props.dia_d,
        asistencia: datos.asistencia.map(v=>{
          // delete v.operadores
          delete v.titulo
          return v
        }),
        asignados:JSON.stringify(this.Eliminar_key(datos.asignados)), 
        operador_vs_perfil:JSON.stringify(this.Eliminar_key(datos.operador_vs_perfil)),
        supervisor_vs_operador:JSON.stringify(this.Eliminar_key(datos.supervisor_vs_operador)),
        turnos_asignados:JSON.stringify(this.Eliminar_key(datos.turnos_asignados)),
      }
      
      props.Guardar(enviar)
      // this.setState({Esperar:{esperar:true,mensaje:'Guardando cambios...'}})
      // let respuesta = await conexiones.Leer_C(['Data_planificacion_dia'],{
      //   'Data_planificacion_dia':{fecha:enviar.fecha}
      // })
      // if (respuesta.datos['Data_planificacion_dia'].length!==0){
      //   enviar._id=respuesta.datos['Data_planificacion_dia'][0]._id
      // }
      // await conexiones.Guardar(enviar,'Data_planificacion_dia')
      
      // if (!enviar._id){
      //   respuesta = await conexiones.Leer_C(['Data_planificacion_dia'],{
      //     'Data_planificacion_dia':{fecha:enviar.fecha}
      //   })
      //   if (respuesta.datos['Data_planificacion_dia'].length!==0){
      //     enviar._id=respuesta.datos['Data_planificacion_dia'][0]._id
      //   }
      //   console.log('Buscar _id',enviar._id)
      // }
      // delete enviar.asistencia
      
      // datos.asignados = this.Eliminar_key(datos.asignados)
      // console.log(datos.asignados)
      // enviar.asignados=JSON.stringify(datos.asignados);
      // await conexiones.Guardar(enviar,'Data_planificacion_dia')
      // delete enviar.asignados

      // datos.supervisor_vs_operador = this.Eliminar_key(datos.supervisor_vs_operador)
      // enviar.supervisor_vs_operador=JSON.stringify(datos.supervisor_vs_operador);
      // await conexiones.Guardar(enviar,'Data_planificacion_dia');
      // delete enviar.supervisor_vs_operador;
      // datos.operador_vs_perfil = this.Eliminar_key(datos.operador_vs_perfil)
      // console.log('Guardar operador_vs_perfiles',datos.operador_vs_perfil)
      // enviar.operador_vs_perfil=JSON.stringify(datos.operador_vs_perfil);
      // await conexiones.Guardar(enviar,'Data_planificacion_dia')
      // delete enviar.operador_vs_perfil;
      // datos.turnos_asignados= this.Eliminar_key(datos.turnos_asignados)
      // enviar.turnos_asignados=JSON.stringify(datos.turnos_asignados);
      // await conexiones.Guardar(enviar,'Data_planificacion_dia')

      this.setState({datos, Esperar:{esperar:false}})

    }else {
      this.setState({seleccion:seleccion+1})
    }
    
  }

  Iniciar= async() =>{
    
    this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    let respuesta= await conexiones.Leer_C(['Data_personal','Data_perfil_amolatina','Data_turno'], 
      {
        'Data_personal':{$or:[ {categoria:3} , {categoria:4}]},
        'Data_perfil_amolatina':{suspended: false},
        'Data_turno':{},
      }
      );
      
    let personal=[]
    let perfiles=[]
    let turnos=[]
    let turnos_asignados= null
    let asignados= null
    let supervisor_vs_operador
    let operador_vs_perfil
    if(this.state.props.turnos_asignados){
      turnos_asignados=JSON.parse(this.state.props.turnos_asignados)
    }
    if(this.state.props.asignados){
      asignados=JSON.parse(this.state.props.asignados)
    }
    if(this.state.props.supervisor_vs_operador){
      supervisor_vs_operador=JSON.parse(this.state.props.supervisor_vs_operador)
    }
    if(this.state.props.operador_vs_perfil){
      operador_vs_perfil=JSON.parse(this.state.props.operador_vs_perfil)
    }
    let asistencia=this.state.props.childen
    if (respuesta.Respuesta==='Ok'){
        personal=respuesta.datos['Data_personal'].map(d=>{
          return {
            _id:d._id,nombres:d.nombres,apellidos:d.apellidos,cedula:d.cedula,
            foto:d.foto,turno:d.turno, categoria:d.categoria,salario_fijo:d.salario_fijo,
            operadores:[]
          }
        })
        personal= personal.sort((a, b) => (a.categoria >b.categoria ? 1 : a.categoria < b.categoria ? -1 : 0))
        // perfiles=respuesta.datos['Data_perfil_amolatina'].filter(f=> !f.suspended)
        perfiles=respuesta.datos['Data_perfil_amolatina'].map(p=> {
          return {
            'parent-id':p['parent-id'], 'user-id':p['user-id'], thumbnail:p.thumbnail,
            'first-name':p['first-name'], _id:p._id
          }
        })
        turnos=respuesta.datos['Data_turno']
    }
    
    this.setState({
      datos:{
        personal, perfiles, turnos, asistencia, turnos_asignados, asignados,
        supervisor_vs_operador, operador_vs_perfil
      },
      rdatos:{
        personal, perfiles, turnos, asistencia, turnos_asignados, asignados,
        supervisor_vs_operador, operador_vs_perfil
      },
      seleccion: this.props.supervisor ? 2 : 0,
      Esperar:{esperar:false}})
  }
  async componentDidMount(){
    this.Iniciar()
    // let resultados= await conexiones.Leer_C(['Data_personal'], 
    //   {['Data_personal']:{$and:[ {categoria:{$ne:0}} , {categoria:{$ne:5}}]},}
    //   );
    // console.log(resultados)
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        console.log('por este en turno')
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  Modificar = (dato, lista, campo)=>{
    let enlibre = lista.Libres.findIndex(f=> f._id===dato._id)
    if (enlibre!==-1){
      lista.Libres[enlibre][campo]=dato[campo]
    }
    lista.Grupos=lista.Grupos.map(G=>{
      let engrupo = G.childen.findIndex(f=> f._id===dato._id)
      if (G._id===dato._id){
        G[campo]=dato[campo]
      }
      
      if (engrupo!==-1){
        // G[campo]=dato[campo]
        G.childen[engrupo][campo]=dato[campo]
      }
      return G
    })
    return lista
  }
  Buscar = (dato, lista)=>{
    let enlibre = lista && lista.Libres ? lista.Libres.findIndex(f=> f._id===dato._id) : -1
    let engrupo={grupo:-1, pos:-1}
    if (lista && lista.Grupos){
      lista.Grupos.map((G,grupo)=>{
        let pos = G.childen.findIndex(f=> f._id===dato._id)
        if (pos!==-1){
          engrupo= {grupo, pos}
        }
        if(G._id===dato._id)
          engrupo.grupo= grupo
        return G
      })
    }
    return {enlibre, engrupo}
  }

  Actualizar = (valores, modificar=true)=>{
    let {datos}=this.state;
    
    let {personal}=this.state.datos
    
    if (valores.turnos_asignados && modificar){
      
      valores.turnos_asignados.Libres.map(p=>{
        const pos =personal.findIndex(v=> v._id===p._id)
        if (pos!==-1){
          personal[pos].turno='';
          const posa= datos.asistencia.findIndex(v=>v._id===p._id)
          if (posa!==-1) datos.asistencia[posa].turno='';
        }
        return p
      })
      valores.turnos_asignados.Grupos.map(p=>{
        p.childen.map(h=>{
          const pos =personal.findIndex(v=> v._id===h._id)
          if (pos!==-1){
            personal[pos].turno=p.key+'-'+p.pais
            const posa= datos.asistencia.findIndex(v=>v._id===h._id)
            if (posa!==-1) datos.asistencia[posa].turno=p.key+'-'+p.pais;
          }
          return h
        })
        return p
      })

      // delete datos.supervisor_vs_operador
      // delete datos.operador_vs_perfil
      // delete datos.asignados
    }else if (valores.asignados && modificar){
      // delete datos.supervisor_vs_operador
      // delete datos.operador_vs_perfil
      let asistencia= []
      valores.asignados.Libres.map(p=>{
        const grupo= this.Buscar(p,datos.supervisor_vs_operador)
        if (grupo.enlibre!==-1){
          const valor=datos.supervisor_vs_operador.Libres[grupo.enlibre]
          datos.supervisor_vs_operador.Libres=datos.supervisor_vs_operador.Libres.filter(f=> f._id!==valor._id)
        }else if (grupo.engrupo.grupo!==-1 && grupo.engrupo.pos===-1){
          const valor=datos.supervisor_vs_operador.Grupos[grupo.engrupo.grupo]
          datos.supervisor_vs_operador.Grupos=datos.supervisor_vs_operador.Grupos.filter(f=> f._id!==valor._id)
        }else if (grupo.engrupo.pos!==-1){
          const valor=datos.supervisor_vs_operador.Grupos[grupo.engrupo.grupo].childen[grupo.engrupo.pos]
          datos.supervisor_vs_operador.Grupos[grupo.engrupo.grupo].childen=datos.supervisor_vs_operador.Grupos[grupo.engrupo.grupo].childen.filter(f=> f._id!==valor._id)
        }
        
        const grupop= this.Buscar(p,datos.operador_vs_perfil);
        if (grupop.engrupo.grupo!==-1){
          datos.operador_vs_perfil.Grupos=datos.operador_vs_perfil.Grupos.filter(f=>f._id!==p._id)
        }
        return p
      })
      valores.asignados.Grupos.map(g=>{
        
        g.childen.map(h=>{
          asistencia.push(h)
          
          return h
        })
        return g
      }) 

      datos.asistencia=asistencia
      //Para operador vs perfil
      // if (datos.operador_vs_perfil)
      // datos.operador_vs_perfil.Grupos.map(p=>{
      //   const grupo= this.Buscar(p,datos.supervisor_vs_operador)
      //   if (grupo.engrupo.grupo===-1 && grupo.engrupo.pos===-1 && grupo.enlibre===-1){
      //     const Grupos = datos.operador_vs_perfil.Grupos.filter(f=> f._id!==p._id)
      //     datos.operador_vs_perfil.Grupos=Grupos
      //   }
      //   return p
      // })
      // if (datos.supervisor_vs_operador)
      // datos.supervisor_vs_operador.Libres.map(p=>{
      //   const grupo= this.Buscar(p,datos.operador_vs_perfil)
      //   console.log(grupo)
      //   if (grupo.engrupo.grupo===-1 && grupo.engrupo.pos===-1 && grupo.enlibre===-1){
      //     datos.operador_vs_perfil.Grupos.push({...p, childen:[]})
      //   }
      //   return p
      // })
    }else if (valores.supervisor_vs_operador){
      valores.supervisor_vs_operador.Grupos.map(g=>{
        const pos = datos.asistencia.findIndex(v=> v._id===g._id)
        if (pos!==-1){
          datos.asistencia[pos]={
            ...datos.asistencia[pos], 
            operadores:g.operadores.map(o=>{
              return o._id
            })
          }
        }
        return g
      })
    }else if (valores.operador_vs_perfil){
      
      valores.operador_vs_perfil.Grupos.map(g=>{
        const pos = datos.asistencia.findIndex(v=> v._id===g._id)
        if (pos!==-1){
          datos.asistencia[pos]={...datos.asistencia[pos], perfiles:g.childen.map(per=> per['user-id'])}
        }
        return g
      })
      valores.operador_vs_perfil.personalb.map(g=>{
        const pos = datos.asistencia.findIndex(v=> v._id===g._id)
        if (pos!==-1){
          datos.asistencia[pos]={...datos.asistencia[pos], perfiles:g.childen.map(per=> per['user-id'])}
        }
        return g
      })
    }
    if (datos.asistencia && modificar){
      
      datos.asistencia.map((a,i)=>{
        
        //>>>>>>>>>Turnos 
        if (datos.asignados) datos.asignados=this.Modificar(a,datos.asignados, 'turno')
        if (datos.supervisor_vs_operador) datos.supervisor_vs_operador=this.Modificar(a,datos.supervisor_vs_operador, 'turno')
        if (datos.operador_vs_perfil) datos.operador_vs_perfil=this.Modificar(a,datos.operador_vs_perfil, 'turno');

        //>>>>>>>>>>Supervisor vs operador
        if(datos.supervisor_vs_operador){
          //Agregar
          const grupo= this.Buscar(a,datos.supervisor_vs_operador)
          if (grupo.engrupo.grupo!==-1 && grupo.engrupo.pos===-1){
            //Cuando es un supervisor
            const valor =datos.supervisor_vs_operador.Grupos[grupo.engrupo.grupo]
            a.operadores=valor.operadores.map(v=>v._id)
            if (valor.categoria!==a.categoria){
              //Cuando cambia de categoria
              datos.supervisor_vs_operador.Libres=[...datos.supervisor_vs_operador.Libres, ...a.operadores,{...a, operadores:[]}]
              a.operadores=[]
              const Grupos = datos.supervisor_vs_operador.Grupos.filter(f=>f._id!==valor._id)
              datos.supervisor_vs_operador.Grupos=Grupos
            }
          }else if (grupo.engrupo.grupo!==-1 && grupo.engrupo.pos!==-1){
            const valor =datos.supervisor_vs_operador.Grupos[grupo.engrupo.grupo].childen[grupo.engrupo.pos];
            //si el personal pertenece a un supervisor y cambia de categoria
            if(a.categoria!==valor.categoria){
              if (a.categoria===3){
                datos.supervisor_vs_operador.Grupos.push({...a, operadores:[], childen:[]})
              }else{
                datos.supervisor_vs_operador.Libres.push(a)
              }
              const Grupos = datos.supervisor_vs_operador.Grupos[grupo.engrupo.grupo].childen.filter(f=>f._id!==valor._id)
              datos.supervisor_vs_operador.Grupos[grupo.engrupo.grupo].childen=Grupos
            }
          }else if(grupo.engrupo.grupo===-1 && grupo.engrupo.pos===-1 && grupo.enlibre!==-1){
            if (a.categoria===3){
              datos.supervisor_vs_operador.Grupos.push({...a, operadores:[], childen:[]})
              const Grupos = datos.supervisor_vs_operador.Libres.filter(f=>f._id!==a._id)
              datos.supervisor_vs_operador.Libres= Grupos
            }else{
              datos.supervisor_vs_operador.Libres[grupo.enlibre]=a
            }
            
          }else if(grupo.engrupo.grupo===-1 && grupo.engrupo.pos===-1 && grupo.enlibre===-1){
            if (a.categoria===3){
              datos.supervisor_vs_operador.Grupos.push({...a, childen:[]})
            }else{
              datos.supervisor_vs_operador.Libres.push(a)
            }
            
          }
        }
        
        if(datos.supervisor_vs_operador && datos.operador_vs_perfil){
          const grupo= this.Buscar(a,datos.operador_vs_perfil)
          if (grupo.engrupo.grupo!==-1 && grupo.engrupo.pos===-1){
            const valor = datos.operador_vs_perfil.Grupos[grupo.engrupo.grupo];
            a.perfiles= valor.childen.map(v=>v['user-id'])
            if (a.categoria!==valor.categoria) datos.operador_vs_perfil.Grupos[grupo.engrupo.grupo].categoria=a.categoria
          }else if (grupo.engrupo.grupo===-1 && grupo.engrupo.pos===-1){
            datos.operador_vs_perfil.Grupos.push({...a, childen:[]})
          }

        }
        return a
      })
      
    }
    
    datos={...datos, ...valores, personal}
    this.setState({datos})
  }

  Estilos = {
    barra_lista:{
        backgroundColor:config.Estilos.barra.backgroundColor, color:'#ffffff',    
    },
    cuerpo_lista:{
        height: window.screen.height * 0.70,
        padding:10,
        overflow:'scroll',
    },
    item_lista:{
        backgroundColor: '#E4DDDD', 
        padding:5, border:1, borderRadius:10, marginBottom:10,
        cursor:'pointer', 
    },
    avatar:{
      alignItems:'center',
      textAlign:'center'
    },
    fecha:{
      display:'flex',
      alignItems:'center'
    }
  }
  Buscar_perfiles = (personal) =>{
    const {datos}=this.state;
    const pos = datos.supervisor_vs_operador.Grupos.findIndex(v=> v._id===personal._id)
    return pos!==-1 ? datos.supervisor_vs_operador.Grupos[pos].childen : []
  }

  Resultado = ()=>{
    const {datos}=this.state;
    return(
      <div>
        <List 
          
        >
           <div style={this.Estilos.cuerpo_lista}>
            {datos.operador_vs_perfil.Grupos.map((personal,i) =>
                <ListItem 
                    key={personal._id+i} alignItems="flex-start" 
                    style={this.Estilos.item_lista}
                >
                    <ListItemAvatar>
                      <div style={this.Estilos.avatar}>
                        <Avatar
                            style={{marginLeft:15, width:200, height:200}}
                            alt={`${personal.nombres}`}
                            src={`${personal.foto}`}
                        />
                        <Typography variant="caption" display="block" gutterBottom>
                            {`${personal.nombres} ${personal.apellidos}`}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom style={{marginTop:-7}}>
                            {personal.turno}
                        </Typography>
                      </div>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <div style={{color:'#000000'}}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    {personal.categoria==='3' || personal.categoria===3 ? `Perfiles y Operadores del ${categoria_usuario[personal.categoria].titulo}`:
                                      `Perfiles del ${categoria_usuario[personal.categoria].titulo}`
                                    }
                                </Typography>
                                <Scrollbars style={{  height: 190 }}>
                                <Grid container spacing={2} justify="center" alignItems="center" direction="row" style={{padding:20}}>
                                
                                  {personal.childen.map((t,ji)=>
                                      <Grid item xs={2} key={t._id+ji}>
                                      
                                          <ListItemAvatar>
                                              <Avatar
                                                  style={{marginLeft:15}}
                                                  alt={`${t['first-name']}`}
                                                  src={`https://api7.amolatina.com/users/${t['user-id']}/photos/${t.thumbnail}.490x480.thumb-fd`}
                                              />
                                              
                                          </ListItemAvatar>
                                          <ListItemText
                                              primary={
                                                  <Typography variant="caption" display="block" gutterBottom>
                                                      {`${t['first-name']}`}
                                                  </Typography>
                                              }
                                              secondary={
                                                `Perfil`
                                              }
                                          />
                                      </Grid>
                                      
                                  )}
                                  {this.Buscar_perfiles(personal).map((t,ji)=>
                                      <Grid item xs={2} key={t._id+ji}>
                                      
                                          <ListItemAvatar>
                                              <Avatar
                                                  style={{marginLeft:15}}
                                                  alt={`${t.nombres}`}
                                                  src={`${t.foto}`}
                                              />
                                              
                                          </ListItemAvatar>
                                          <ListItemText
                                              primary={
                                                  <Typography variant="caption" display="block" gutterBottom>
                                                      {`${t.nombres} ${t.apellidos} `}
                                                  </Typography>
                                              }
                                              secondary={
                                                `Operador`
                                              }
                                          />
                                      </Grid>
                                  
                                      
                                  )}
                                  
                                </Grid>
                                </Scrollbars>
                            </div>
                        }
                        secondary={
                          ''
                        }
                    />
                    
                </ListItem>
            )}

            </div>
          
        </List>
      </div>
    )
  }
  render(){
    const {seleccion, datos, rdatos}=this.state;
    return this.state.Esperar.esperar ? 
    <Esperar {...this.state.Esperar}/> : (
      <div style={{marginTop:-10}}>
        <Cambio {...this.state}/>
        { 
          seleccion===0 ? 
          <AsignarTurnos datos={datos} rdatos={rdatos} Actualizar={this.Actualizar} Iniciar={this.Iniciar}/> :  
          seleccion===1 ? 
          <Asignar datos={datos} rdatos={rdatos} Actualizar={this.Actualizar}/> :
          seleccion===2 ?
          <Supervisor datos={datos} rdatos={rdatos} Actualizar={this.Actualizar}/>: 
          seleccion===3 ?
          <Operador datos={datos} rdatos={rdatos} Actualizar={this.Actualizar}/>: 
          this.Resultado()
        }
      </div>
    )
  }
}