import React, {Component} from 'react';
import Esperar from '../esperar';
import Pagee from '../conexiones';
import {ItemTituloTurnos, ItemTurnos} from '../conexiones/item';


export default class Turnos extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{Libres:[],Grupos:[]},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          
          Iniciar: this.Iniciar_nuevo,
          Actualizar:this.Actualizar
      }
  }
  Iniciar_nuevo = ()=>{
    this.state.props.Iniciar()
  }
  Iniciar= async(valores) =>{
    
    let Libres=[]
    let Grupos=[]
    if (valores.turnos_asignados){
      Libres=valores.turnos_asignados.Libres
      Grupos=valores.turnos_asignados.Grupos.map(g=>{
        const childen= g.childen.map(h=>{
          return {...h, key:h._id,titulo: <ItemTurnos p={h}/>}
        })
        return {...g, childen, titulo:<ItemTituloTurnos p={{turno:g.key, pais:g.pais, cantidad:childen.length}} />}
      })
    }else{
      // console.log(personal)
      valores.personal.map(p=>{
        const pos= valores.turnos.findIndex(v=> (v.turno+'-'+v.pais===p.turno)|| (v.descanso &&  p.turno===`Break (${v.turno.split('(')[1].split(')')[0]})-${v.pais}`))
        if (pos===-1) Libres.push({...p, 
          key:p._id, 
          titulo:<ItemTurnos p={p}/>
        })
        return p
      })
      let descansos = []
      valores.turnos.map(t=>{
        const turno= t.turno
        // let grupo={key:turno, titulo:<Item_titulo_turnos p={{turno}} />, childen:[]}
        if (t.descanso){
          const key=`Break (${turno.split('(')[1].split(')')[0]})`
          // const corte=`Corte (${turno.split('(')[1].split(')')[0]})`
          const post= descansos.findIndex(v=> (v.key===key && v.pais===t.pais)|| v.key=== key+'-'+t.pais)
          if (post===-1){
            descansos.push({key, pais:t.pais, childen:[]})
            valores.personal.map(p=>{
              if ((p.turno===key && p.pais===t.pais)|| p.turno=== key+'-'+t.pais) {
                descansos[descansos.length-1].childen.push({...p, key:p._id, titulo: <ItemTurnos p={p}/>})
              }
              return p
            })
            descansos[descansos.length-1].titulo= <ItemTituloTurnos p={{turno: key, pais:t.pais, cantidad:descansos[descansos.length-1].childen.length}} />
            
            // descansos.push({key:corte, pais:t.pais, childen:[]})
            // personal.map(p=>{
            //   if (p.turno===turno && p.pais===t.pais) {
            //     descansos[descansos.length-1].childen.push({...p, key:p._id, titulo: <Item_turnos p={p}/>})
            //   }
            //   return p
            // })
            // descansos[descansos.length-1].titulo= <Item_titulo_turnos p={{turno: corte, pais:t.pais, cantidad:descansos[descansos.length-1].childen.length}} />
          }
        }
        Grupos.push({key:turno, pais:t.pais, childen:[]})
        valores.personal.map(p=>{
          if ((p.turno===turno && p.pais===t.pais) || p.turno=== turno+'-'+t.pais) {
            Grupos[Grupos.length-1].childen.push({...p, key:p._id, titulo: <ItemTurnos p={p}/>})
          }
          return p
        })
        Grupos[Grupos.length-1].titulo= <ItemTituloTurnos p={{turno, pais:t.pais, cantidad:Grupos[Grupos.length-1].childen.length}} />
        return t
      })
      Grupos=[...Grupos, ...descansos]
    }
    
    const datos = {Grupos, Libres}
    this.setState({datos, TituloL:'Personal sin Turno', TituloG:'Turnos'})
    this.Actualizar(datos, false);
  }

  Actualizar = (datos, modificar=true) =>{
    datos.Grupos=datos.Grupos.map(g=>{
      
      if (g.key.indexOf('Break')!==-1){
        g.key='Break ('+ g.key.split('(')[1]
      }
      return {...g, titulo:<ItemTituloTurnos p={{turno:g.key, pais:g.pais, cantidad:g.childen.length}} />}
    })
    this.setState({datos})
    if (this.state.props.Actualizar){
      this.state.props.Actualizar({turnos_asignados:datos},modificar)
    }
  }
  async componentDidMount(){
    this.Iniciar(this.props.datos)
    // let resultados= await conexiones.Leer_C(['Data_personal'], 
    //   {['Data_personal']:{$and:[ {categoria:{$ne:0}} , {categoria:{$ne:5}}]},}
    //   );
    // console.log(resultados)
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  render(){
    return this.state.Esperar.esperar ? 
      <Esperar {...this.state.Esperar}/> :
      <div>
        <Pagee  {...this.state}/>  
      </div>
  }
}