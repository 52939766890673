import {Component} from 'react';
import Avatar from '@material-ui/core/Avatar';
import {conexiones} from '../../procesos/servicios';
import moment from "moment";
import Typography from '@material-ui/core/Typography';
import socketIOClient from "socket.io-client";
// import { Scrollbars } from 'rc-scrollbars';
import Scrollbars from '../formulario/MyScrollbars';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
// import Popper from '@material-ui/core/Popper';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import Dialogo from '../../components/formulario/dialogo';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { ListaPerfilesEsperar, ListaPerfilesServicios, ListaPerfilesCabezera } from '../listas/lista_amolatina';

//Constantes
import {Valord,const_procesos} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

const Filtrar = (User, personal, puntos_perfiles)=>{
  if (['3','4'].indexOf(User.categoria)!==-1){
    
    let nuevo=[]

    if (personal.length!==0)  {
      personal[0].perfiles.map(f=>{
        let pos = puntos_perfiles.filter(v=> v['user-id']===f)
        if (personal[0].perfilesS){
          const poos = personal[0].perfilesS.findIndex(v=> v.perfiles.indexOf(f)!==-1)
          if (poos!==-1){
            const operador=personal[0].perfilesS[poos]
            pos=pos.map(p=>{
              
              return {...p, 
                operador:{
                  _id:operador._id, nombres:operador.nombres, apellidos:operador.apellidos,
                  categoria:operador.categoria, foto:operador.foto, turno:operador.turno
                
                }
              }
            })
          }
        }
        nuevo=[...nuevo,...pos]
        return f
      })
      
    }
    puntos_perfiles=[...nuevo]
    
  }
  return puntos_perfiles
}

export default class Puntos_perfiles extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          puntos_perfiles:[],
          Iniciar: this.Iniciar,
          Resumen_perfil: this.Resumen_perfil,
          open:false,
          menu:{
            Agencias:{icon:<RecentActorsIcon />}, 
            Servicios:{icon:<AssignmentIndIcon />,
              childen:Object.keys(config.service).map(v=>{
                return {_id:v, value:config.service[v].value, icon: config.service[v].icon}
              })
            },
            Perfiles:{icon:<InboxIcon/> }
          }, 
          Agencias:[],
          Perfiles:[],
          select_agencia:null,
          select_servicio:null,
          select_perfil:null,
          //Ampliacion
          Open_dialogo: this.Open_dialogo,
          Dialogo:{
            open:false,
            Titulo:'Puntos por servicios',
            tam:'md',
            Cerrar: this.Cerrar_dialogo,
          }
      }
  }

  Open_dialogo = (valor) =>{
    let {Dialogo, puntos_perfiles, personal}=this.state
    Dialogo.Cuerpo = <Ampliada puntos={puntos_perfiles} personal={personal}/>
    Dialogo.open=true
    this.setState({Dialogo})
  }

  Cerrar_dialogo = () =>{
    let {Dialogo}=this.state
    Dialogo.open=false
    this.setState({Dialogo})
  }
  
  Iniciar= async() =>{
    
    this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    let cantidad=50;
    let pag=0;
    // let salir= false
    let horaf= new Date()
    let horai= new Date()
    horaf.setHours(horaf.getHours()+4)
    horaf= horaf.getHours()<10 ? `0${horaf.getHours()}` : String(horaf.getHours())
    horai.setHours(horai.getHours()-4)
    horai= horai.getHours()<10 ? `0${horai.getHours()}` : String(horai.getHours())
    let User = await JSON.parse(localStorage.getItem(const_procesos.dir_user));

    // while(!salir){
      let respuesta= await conexiones.Leer_C(['Data_resumen_perfil', 'Data_punto', 'Data_personal','Data_planificacion_dia'], 
        {
          'Data_resumen_perfil':{pagina:true, cantidad, pag,
              sort:{fecha:-1, hora:-1},
              condicion:{$and:[{fecha:{$gte:moment.utc(new Date()).format('YYYY-MM-DD')}}]}},
          'Data_punto':{involucrado:0},
          'Data_personal':{cedula:User.cedula},
          'Data_planificacion_dia':{fecha:moment.utc(new Date()).format('YYYY-MM-DD')}
        }
      );
      let puntos_perfiles=[]
      let puntos_estrellas=[]
      let personal=[]
      let planificacion=[]
      if (respuesta.Respuesta==='Ok'){
        if (respuesta.datos['Data_planificacion_dia'].length!==0)
          planificacion=respuesta.datos['Data_planificacion_dia'][0].asistencia

        puntos_estrellas= respuesta.datos['Data_punto']
        puntos_perfiles= 
          respuesta.datos['Data_resumen_perfil']
            .sort( (a,b) =>  a['user-id']>b['user-id'] ? -1 :  a['user-id']<b['user-id'] ? 1 : 0).map(v=>{
            return {...v, puntose:this.Calcular(v, puntos_estrellas)}
          })
          // .sort( (a,b) =>  a.hora>b.hora ? -1 :  a.hora<b.hora ? 1 : 0)
          
        personal=respuesta.datos['Data_personal']
        const posss= planificacion.filter(f=> f._id===personal[0]._id)
        if (posss.length!==0){
          
          personal[0].perfiles=posss[0].perfiles
          if ([3,'3'].indexOf(posss[0].categoria)!==-1 ){
            personal[0].perfilesS=[]
            posss[0].operadores.map(op=>{
              const posop= planificacion.findIndex(f=> f._id===op)
              
              if (posop!==-1){
                personal[0].perfiles=[...personal[0].perfiles, ...planificacion[posop].perfiles]
                personal[0].perfilesS=[...personal[0].perfilesS, planificacion[posop]]
              }
              return op
            })
          }
        }
      }
      puntos_perfiles=[...this.state.puntos_perfiles,...puntos_perfiles]
      puntos_perfiles.sort( (a,b) =>  a.fecha>b.fecha ? 1 :  a.fecha<b.fecha ? -1 : a.hora>b.hora ? -1 : a.hora<b.hora ? 1 : 0)
      puntos_perfiles= Filtrar(User,personal,puntos_perfiles)
      puntos_perfiles.sort( (a,b) =>  a.fecha>b.fecha ? 1 :  a.fecha<b.fecha ? -1 : a.hora>b.hora ? -1 : a.hora<b.hora ? 1 : 0)
      
      
      this.setState({planificacion, User, personal, puntos_perfiles, aux_puntos_perfiles:puntos_perfiles,puntos_estrellas, Esperar:{esperar:false,mensaje:'Cargando datos...'}})
      // if (puntos_perfiles.length<cantidad){
      //   salir = true;
      // }else{
        pag+=1
      // }
    // }
    
  }

  async componentDidMount(){

    let respu= await conexiones.Leer_C([
      'Data_agencia', 'Data_perfil_amolatina'], 
      {
        'Data_agencia':{},
        'Data_perfil_amolatina':{suspended: false},
      }
    );
    if (respu.Respuesta==='Ok'){
      
      let Agencias=respu.datos['Data_agencia'].map(v=>{
        return {...v, value:v['nombre'], avatar:`https://api7.amolatina.com/users/${v['user-id']}/photos/${v.thumbnail}.490x480.thumb-fd`}
      })
      let Perfiles=respu.datos['Data_perfil_amolatina'].map(v=>{
        return {...v, value:v['first-name'], avatar:`https://api7.amolatina.com/users/${v['user-id']}/photos/${v.thumbnail}.490x480.thumb-fd`}
      })
      
      let { menu}=this.state
      menu.Agencias.childen=Agencias;
      menu.Perfiles.childen=Perfiles
      this.setState({Agencias, Perfiles, menu})
    }
    this.Iniciar()
    const socket=socketIOClient("/")
    socket.on('Resumen_perfil',(dato)=>{
      let {puntos_perfiles, puntos_estrellas, aux_puntos_perfiles, select_agencia, select_perfil, select_servicio,}=this.state
      let nuevo_puntos_perfiles= dato
        .sort( (a,b) =>  a['user-id']>b['user-id'] ? -1 :  a['user-id']<b['user-id'] ? 1 : 0).map(v=>{
          return {...v, puntose:this.Calcular(v, puntos_estrellas)}
        })
        
        if (nuevo_puntos_perfiles[0].fecha<moment(new Date()).format('YYYY-MM-DD')) {
          
          return
        }else{
      
          nuevo_puntos_perfiles.sort( (a,b) =>  a.hora>b.hora ? -1 :  a.hora<b.hora ? 1 : 0)

          aux_puntos_perfiles=nuevo_puntos_perfiles
          puntos_perfiles= []
          aux_puntos_perfiles.map(f=>{
            if (
              (select_agencia===null || select_agencia['user-id']===f['agency-id']) &&
              (select_servicio===null || select_servicio._id===f.service)&&
              (select_perfil===null || select_perfil['user-id']===f['user-id'])
            ){
              puntos_perfiles.push(f)
            }

            return f
          })
          puntos_perfiles= Filtrar(this.state.User,this.state.personal,puntos_perfiles)
          puntos_perfiles.sort( (a,b) =>  a.fecha>b.fecha ? 1 :  a.fecha<b.fecha ? -1 : a.hora>b.hora ? -1 : a.hora<b.hora ? 1 : 0)
          this.setState({puntos_perfiles, aux_puntos_perfiles})
        }
    })
    this.setState({socket})
  }

  componentWillUnmount(){
    let { socket}=this.state
    if (socket!==undefined && socket!==null){
      socket.disconnect();
      socket=null
    }
  }

  Resumen_perfil = (newsocket)=>{
    let {socket, puntos_perfiles, puntos_estrellas}=this.state
    
    if (socket ===undefined || socket===null) {
      socket=newsocket
      socket.on('Resumen_perfil',(dato)=>{
        puntos_perfiles= dato
          .sort( (a,b) =>  a['user-id']>b['user-id'] ? -1 :  a['user-id']<b['user-id'] ? 1 : 0).map(v=>{
            return {...v, puntose:this.Calcular(v, puntos_estrellas)}
          })
        this.setState({puntos_perfiles})
      })
      this.setState({socket})
    }
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        // const socket = props.socket
        // state.Resumen_perfil(socket)
        
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }
  Calcular = (punto, puntos_estrellas)=>{      
      const pos = -1//puntos_estrellas.findIndex(f=> f.desde<=punto.points && punto.points<=f.hasta )
      if (pos!==-1){
        let valor= punto.points/(puntos_estrellas[pos].hasta-puntos_estrellas[pos].desde)
        valor+=puntos_estrellas[pos].desde
        
        return valor
      }else{
       return 0 
      }
  }

  Estilos= {
    paper: {
      marginTop:15,
      heigth:200,
      width: 200,
      border: '1px solid',
      padding: 10,
      backgroundColor: config.Estilos.barra_menu.backgroundColor,
      zIndex: 100
    },
    icon:{
      color:'#ffffff',
    },
    nested:{
      paddingLeft:30
    }
  }
  Abrir = (event)=> {
    this.setState({
      id: this.state.open ? undefined: 'lista', 
      open:!this.state.open,
      anchorEl: this.state.anchorEl ? null : event.currentTarget
    })
  }
  Open_submenu =(item, valor)=>{
    let {menu}=this.state
    if (Object.keys(valor).indexOf('childen')!==-1){
      menu[item].open=menu[item].open===undefined ? true : !menu[item].open
    }else{
      this.Abrir()
      let {select_agencia, select_perfil, select_servicio, puntos_perfiles, 
            aux_puntos_perfiles, Perfiles
          }=this.state
      
      if (item==='Agencias'){
        select_agencia=valor
        menu.Perfiles.childen= Perfiles.filter(f=> f['parent-id']===valor['user-id'])
      }else if (item==='Servicios'){
        select_servicio=valor
      }else{
        select_perfil=valor
      }
      puntos_perfiles= []
      aux_puntos_perfiles.map(f=>{
        if (
          (select_agencia===null || select_agencia['user-id']===f['agency-id']) &&
          (select_servicio===null || select_servicio._id===f.service)&&
          (select_perfil===null || select_perfil['user-id']===f['user-id'])
        ){
          puntos_perfiles.push(f)
        }

        return f
      })
      this.setState({puntos_perfiles, select_perfil, select_agencia, select_servicio})
    }
    this.setState({menu})
  }
  Menu = () =>{
    const {menu}=this.state
    // const open=true
    return (
      <div style={this.Estilos.paper}>
        
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={this.Estilos.root}
        >
          {Object.keys(menu).map(valor=>
            <div key={valor}>
              <ListItem button onClick={()=>this.Open_submenu(valor,menu[valor])}>
                <ListItemIcon>
                  {menu[valor].icon}
                </ListItemIcon>
                <ListItemText primary={valor} />
                {Object.keys(menu[valor]).indexOf('childen')===-1 ? null :
                  menu[valor].open ? <ExpandLess style={this.Estilos.icon}/> : <ExpandMore style={this.Estilos.icon}/> }
              </ListItem>
              {Object.keys(menu[valor]).indexOf('childen')===-1 ? null :
                <Collapse in={menu[valor].open} timeout="auto" unmountOnExit>
                  <Scrollbars style={{height:100}}>
                  {menu[valor].childen.map(v=>
                    <List component="div" disablePadding key={v._id}>
                      <ListItem button style={this.Estilos.nested} onClick={()=>this.Open_submenu(valor,v)}>
                        {v.avatar ? 
                          <ListItemAvatar>  
                              <Avatar
                                style={{marginLeft:15}}
                                alt={`${v['nombre']}`}
                                src={v.avatar}
                              />
                          </ListItemAvatar>
                          :
                          <ListItemIcon>
                            {v.icon}
                          </ListItemIcon>
                        }
                        <ListItemText primary={v.value} />
                      </ListItem>
                    </List>
                  )}
                 </Scrollbars >
                </Collapse>
              }
            </div>

          )}
        </List>
        
      </div>
    )
  }
  render(){
    
    return (
      <div>
        <List 
            subheader={
              <ListaPerfilesCabezera Titulo={`Puntos por servicios`} Open={this.Open_dialogo} />
            }
        >
          {this.state.Esperar.esperar ? 
            <ListaPerfilesEsperar/>:
            <ListaPerfilesServicios datos={this.state.puntos_perfiles}/>
          }
        
        </List>
        <Dialogo {...this.state.Dialogo}/>
      </div>
    )
  }
}


const Seleccion = withStyles((theme) => ({
  inputRoot: {
    
    backgroundColor:'#ffffff',
    bordeColor: config.Estilos.barra_menu.backgroundColor,
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  input: {
    
    bordeColor: config.Estilos.barra_menu.backgroundColor,
    '&:hover': {
      backgroundColor: '#ff000f',
    },
  },
}))(Autocomplete);

class Ampliada extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          puntos_perfiles: this.props.puntos,
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          inicio:moment(new Date()).format('YYYY-MM-DD'),
          fin:moment(new Date()).format('YYYY-MM-DD'),
          hora: this.Hora_inicio(),
          horaf: this.Hora_fin(),
          Agencias:[],
          select_agencia:null,
          Servicios: Object.keys(config.service).map(v=>{
            return {_id:v, value:config.service[v].value, icon: config.service[v].icon}
          }),
          select_service:null,
          perfiles:[],
          select_perfil:null
      }
  }

  Hora_inicio =()=>{
    let hora= new Date()
    hora.setHours(0, 0, 0);
    return hora
  }
  Hora_fin =()=>{
    let horaf= new Date()
    horaf.setHours(23, 59, 59);
    return horaf
  }
  Iniciar= async(inicio, fin, select, service, perfil, hora,  horaf) =>{
    this.setState({puntos_perfiles:[], Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    // personal = personal !==null ? personal : this.state.personal
    let {User, perfiles, personal, perfilesC}=this.state
    let cantidad=100;
    let pag=0;
    let salir= false
    let puntos_perfiles=[]
    
    let total=0
    let planificacion=[]
    let respu= await conexiones.Leer_C(['Data_planificacion_dia'], 
        {
          'Data_planificacion_dia':{$and:[{fecha:{$gte:inicio}}, {fecha:{$lte:fin}}]}
        }
    );
    if (respu.Respuesta==='Ok'){
      if (respu.datos['Data_planificacion_dia'].length!==0)
          planificacion=respu.datos['Data_planificacion_dia']
      
      let posss=[]
      let perfilesS=[]
      planificacion.map(plan=>{

        let nuevo =[]
        if ([0,'0',1,'1',2,'2'].indexOf(User.categoria)===-1){
          nuevo= plan.asistencia.filter(f=> f._id===personal[0]._id)
          console.log(personal, nuevo)
          if ([3,'3'].indexOf(nuevo[0].categoria)!==-1 ){
            
            nuevo[0].operadores.map(op=>{
              const posop= plan.asistencia.filter(f=>f._id===op)

              if (posop.length!==0){
                perfilesS=[...perfilesS, posop[0]]
              }
              return op
            })
          }
        }else{
          nuevo= plan.asistencia
        } 
        posss=[...posss,...nuevo]
        return plan
      })
      personal[0].perfiles=[]
      // const posss= planificacion.filter(f=> f._id===personal[0]._id)
      //   if (posss.length!==0){
      //     personal[0].perfiles=posss[0].perfiles
      //     if ([3,'3'].indexOf(posss[0].categoria)!==-1 ){
      //       personal[0].perfilesS=[]
      //       posss[0].operadores.map(op=>{
      //         const posop= planificacion.findIndex(f=> f._id===op)
      //         if (posop!==-1){
      //           personal[0].perfiles=[...personal[0].perfiles, ...planificacion[posop].perfiles]
      //           personal[0].perfilesS=[...personal[0].perfilesS, planificacion[posop]]
      //         }
      //         return op
      //       })
      //     }
      //   }
      if (posss.length!==0){
        
        personal[0].perfilesS=perfilesS
        posss.map(per=>{
          personal[0].perfiles=[...personal[0].perfiles, ...per.perfiles]
          
          return per
        })
        perfilesS.map(per=>{
          personal[0].perfiles=[...personal[0].perfiles, ...per.perfiles]
          return per
        })
        
      }
      
      perfiles= Filtrar(User,personal,perfilesC)
      this.setState({personal,perfiles})
    }
    
    while(!salir){
      let condicion = {$and:[{fecha:{$gte:inicio}}, {fecha:{$lte:fin}}, {hora:{$gte:moment(hora).format('HH:mm')}}, {hora:{$lte:moment(horaf).format('HH:mm')}}]}
      if (select!==null){
        condicion['$and'].push({'agency-id':select})
      }
      if (service!==null){
        condicion['$and'].push({'service':service})
      }
      if (perfil!==null){
        condicion['$and'].push({'user-id':perfil})
      }
      let respuesta= await conexiones.Leer_C(['Data_resumen_perfil'], 
        {
          'Data_resumen_perfil':{pagina:true, cantidad, pag,
              sort:{fecha:-1, hora:-1}, 
              condicion
          },
        }
      );
      let nuevo=[]
      if (respuesta.Respuesta==='Ok'){
        nuevo=respuesta.datos['Data_resumen_perfil']//.filter(f=> f.hora>=moment(hora).format('HH:mm') && f.hora<=moment(horaf).format('HH:mm'))
            
        // .sort( (a,b) =>  a['fecha']>b['fecha'] ? 1 :  a['fecha']<b['fecha'] ? -1 : 0)
          //sort( (a,b) =>  a.hora>b.hora ? -1 :  a.hora<b.hora ? 1 : 0) 
        puntos_perfiles= [...puntos_perfiles , ...nuevo] 
        
      }

      this.setState({puntos_perfiles, apuntos_perfiles:puntos_perfiles})
      
      if (respuesta.datos['Data_resumen_perfil'].length<cantidad){
        salir = true;
      }else{
        pag+=1
      }
    }
    total=0;
    puntos_perfiles=Filtrar(User,personal,puntos_perfiles)
    puntos_perfiles.map(v=>{
      total+=v.points
      return v
    })
    this.setState({total, puntos_perfiles, apuntos_perfiles:puntos_perfiles, Esperar:{esperar:false,mensaje:'Cargando datos...'}})
  }

  Cambio =(index)=>(fecha) =>{
    let nuevo = moment(fecha).format('YYYY-MM-DD')
    if (index==='hora' || index==='horaf'){
      this.setState({[index]:fecha})
    }else{
      this.setState({[index]:nuevo})  
    }
    let {select_agencia, select_service, select_perfil}= this.state
    let inicio = index==='inicio' ? nuevo : this.state.inicio;
    let fin = index==='fin' ? nuevo : this.state.fin;
    let hora = index==='hora' ? fecha : this.state.hora;
    let horaf = index==='horaf' ? fecha : this.state.horaf;
    this.Iniciar(inicio,fin, 
                  select_agencia!==null ? select_agencia['user-id'] : null, 
                  select_service=== null ? null : select_service._id,
                  select_perfil=== null ? null : select_perfil['user-id'],
                  hora,horaf
                )
  }
  Fecha = (fecha, index) =>{
    
    // fecha= fecha.split('-')
    // fecha = fecha[1]+'-'+fecha[2]+'-'+fecha[0]
    fecha= new Date(fecha)
    fecha.setDate(fecha.getDate()+1)
    
    return(
      <DatePicker
        style={{marginLeft:10}}
        selected={fecha}
        locale="es"
        customInput={<CalendarTodayIcon />}
        onChange={this.Cambio(index)} 
        popperClassName="some-custom-class"
        popperPlacement="top-end"
        withPortal
      ></DatePicker>
    )
  }
  Reloj = (hora, index) =>{
    
    // fecha= fecha.split('-')
    // fecha = fecha[1]+'-'+fecha[2]+'-'+fecha[0]
    // hora= new Date(hora)
    // fecha.setDate(fecha.getDate()+1)
    // console.log(hora)

    return(
      <DatePicker
        style={{marginLeft:10}}
        selected={hora}
        locale="es"
        customInput={<QueryBuilderIcon  />}
        onChange={this.Cambio(index)} 
        popperClassName="some-custom-class"
        popperPlacement="top-end"  
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={1}
        timeCaption="Time"
        dateFormat="h:mm aa"
        withPortal
      ></DatePicker>
    )
  }
  Seleccionar_agencia = async (agencia)=>{
    let {select_agencia, select_service, select_perfil, inicio, fin, perfiles, hora, horaf}= this.state
    let condicion = {$and:[{suspended:false}]}
    if (agencia!==null){
      condicion['$and'].push({'parent-id':agencia['user-id']})
    }
    let respu= await conexiones.Leer_C(['Data_perfil_amolatina'], 
        {
          'Data_perfil_amolatina':condicion
        }
    );
    if (respu.Respuesta==='Ok'){
      perfiles=respu.datos['Data_perfil_amolatina']
      select_perfil=null
    }
    select_agencia=agencia
    
    this.Iniciar(inicio,fin, 
                  select_agencia===null ? null : select_agencia['user-id'], 
                  select_service!==null  ? select_service._id : null,
                  select_perfil=== null ? null : select_perfil['user-id'],
                  hora, horaf
                )
    this.setState({select_agencia, perfiles, select_perfil})
  }

  Seleccionar_servicio = (service)=>{
    let {select_agencia, select_service, select_perfil, inicio, fin, hora, horaf}= this.state
    select_service=service
    this.Iniciar(inicio,fin, 
                  select_agencia!==null ? select_agencia['user-id'] : null, 
                  service=== null ? null : service._id,
                  select_perfil=== null ? null : select_perfil['user-id'],
                  hora, horaf
                  )
    this.setState({select_service})
  }

  Seleccionar_perfiles = (perfil)=>{
    let {select_agencia, select_service, select_perfil, inicio, fin, hora, horaf}= this.state
    select_perfil=perfil
    this.Iniciar(inicio,fin, 
                select_agencia!==null ? select_agencia['user-id'] : null, 
                select_service=== null ? null : select_service._id,
                select_perfil=== null ? null : select_perfil['user-id'],
                hora, horaf
                )
    this.setState({select_perfil})
  }
  Estilos = {
    barra_lista:{
        backgroundColor:config.Estilos.barra.backgroundColor, color:'#ffffff',
        marginBottom: 20, borderTop:10, borderTopLeftRadius:10,
        borderTopRightRadius:10, padding:20    
    },
    cuerpo_lista:{
        height: window.screen.height * 0.65,
        padding:10,
        overflow:'scroll',
    },
    item_lista:{
        backgroundColor: '#E4DDDD', 
        padding:5, border:1, borderRadius:10, marginBottom:10,
        cursor:'pointer', 
    },
    avatar:{
      alignItems:'center',
      textAlign:'center'
    },
    fecha:{
      display:'flex',
      alignItems:'center'
    }
  }

  async componentDidMount(){
    let User = await JSON.parse(localStorage.getItem(const_procesos.dir_user));
    
    let Agencias=[]
    let perfiles=[]
    let perfilesC=[]
    let personal=[]
    let planificacion=[]
    let respu= await conexiones.Leer_C(['Data_agencia', 'Data_perfil_amolatina','Data_planificacion_dia'], 
        {
          'Data_agencia':{},
          'Data_perfil_amolatina':{suspended: false},
          'Data_planificacion_dia':{fecha:moment(new Date()).format('YYYY-MM-DD')}
        }
    );
    if (respu.Respuesta==='Ok'){
      Agencias=respu.datos['Data_agencia']
      perfiles=respu.datos['Data_perfil_amolatina']
      perfilesC=respu.datos['Data_perfil_amolatina'];
      if (respu.datos['Data_planificacion_dia'].length!==0)
          planificacion=respu.datos['Data_planificacion_dia'][0].asistencia
      personal= this.state.props.personal
      // const posss= planificacion.filter(f=> f._id===personal[0]._id)
      // if (posss.length!==0)
      //   personal[0].perfiles=posss[0].perfiles
      
      personal[0].perfiles=[]
      const posss= planificacion.filter(f=> f._id===personal[0]._id)
      if (posss.length!==0){
        personal[0].perfiles=posss[0].perfiles
        if ([3,'3'].indexOf(posss[0].categoria)!==-1 ){
          personal[0].perfilesS=[]
          posss[0].operadores.map(op=>{
            const posop= planificacion.findIndex(f=> f._id===op)
            if (posop!==-1){
              personal[0].perfiles=[...personal[0].perfiles, ...planificacion[posop].perfiles]
              personal[0].perfilesS=[...personal[0].perfilesS, planificacion[posop]]
            }
            return op
          })
        }
      }
      
      perfiles= Filtrar(User,personal,perfiles)
      
    }else{
      setTimeout(()=>{
        this.componentDidMount()
      }, 5000)
    }
    this.setState({Agencias, perfiles, User, personal,perfilesC})
    
  }
  render(){
    return (
      <div>
        <List 
            subheader={
              <ListSubheader component="div" id="nested-list-subheader"
                style={this.Estilos.barra_lista}
                disableGutters
              >
                  {this.state.Esperar.esperar ? <LinearProgress /> : 
                <Grid container spacing={2} justify="center" alignItems="center" direction="row">
                    <Grid container item spacing={2}>
                        <Grid item xs={2}>
                          <div style={this.Estilos.fecha}>
                            <Typography variant="caption" display="block" gutterBottom>
                              {`${moment(this.state.hora).format('HH:mm')}`}
                            </Typography>
                            {this.state.Esperar.esperar ? null : this.Reloj(this.state.hora, 'hora')} 
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div style={this.Estilos.fecha}>
                            <Typography variant="caption" display="block" gutterBottom>
                              {`${moment(this.state.horaf).format('HH:mm')}`}
                            </Typography>
                            {this.state.Esperar.esperar ? null : this.Reloj(this.state.horaf, 'horaf')} 
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div style={this.Estilos.fecha}>
                            <Typography variant="caption" display="block" gutterBottom>
                              {`${this.state.inicio}`}
                            </Typography>
                            {this.state.Esperar.esperar ? null : this.Fecha(this.state.inicio, 'inicio')} 
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div style={this.Estilos.fecha}>
                            <Typography variant="caption" display="block" gutterBottom>
                              {`${this.state.fin}`}
                            </Typography>
                            {this.state.Esperar.esperar ? null : this.Fecha(this.state.fin, 'fin')} 
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div style={this.Estilos.fecha}>
                            <Typography variant="caption" display="block" gutterBottom>
                              {`${Number(this.state.total===undefined ? 0 : this.state.total).toFixed(2)}`}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <Seleccion
                              autoComplete
                              name={'select_agencia'}
                              options={this.state.Agencias}
                              getOptionLabel={(option) => {
                                return `${option['nombre']!==undefined ? option['nombre']: ''}`}
                              }
                              
                              onChange={(event, newValue)=>{
                                this.Seleccionar_agencia(newValue)
                              }}
                              value={this.state.select_agencia}
                              renderInput={(params) => 
                                <TextField {...params} label={'Seleccionar Agencia'} variant="outlined" 
                                    
                                />
                              }
                              disabled={this.state.Esperar.esperar ? true : false }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Seleccion
                              autoComplete
                              name={'select_sevicios'}
                              options={this.state.Servicios}
                              getOptionLabel={(option) => {
                                return `${option.value}`}
                              }
                              onChange={(event, newValue)=>{
                                this.Seleccionar_servicio(newValue)
                              }}
                              value={this.state.select_service}
                              style= {{ color:'#ffffff' }}
                              renderInput={(params) => 
                                <TextField {...params} label={'Seleccionar servicio'} variant="outlined" 
                                    style= {{ color:'#ffffff' }}
                                />
                              }
                              disabled={this.state.Esperar.esperar ? true : false }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Seleccion
                              autoComplete
                              name={'select_perfil'}
                              options={this.state.perfiles}
                              getOptionLabel={(option) => {
                                return `${option['first-name']} ${option.operador ? 'Operador: '+ option.operador.nombres : ''}`}
                              }
                              renderOption={(option) => (
                                <Grid container spacing={2} justify="center" alignItems="center" direction="row">
                                  <Grid item xs>
                                    {`${option['first-name']}`}
                                  </Grid>
                                  <Grid item xs>
                                    <Typography variant="caption" display="block" noWrap gutterBottom style={{marginTop:-3, marginLeft:4, fontSize:10, fontWeight:900}}>
                                      {` ${option.operador ? 'Operador: '+ option.operador.nombres : ''}`}
                                    </Typography>
                                    
                                  </Grid>
                                </Grid>  
                              )}
                              onChange={(event, newValue)=>{
                                this.Seleccionar_perfiles(newValue)
                              }}
                              value={this.state.select_perfil}
                              style= {{ color:'#ffffff' }}
                              renderInput={(params) => 
                                <TextField {...params} label={'Seleccionar perfil'} variant="outlined" 
                                    style= {{ color:'#ffffff' }}
                                />
                              }
                              disabled={this.state.Esperar.esperar ? true : false }
                          />
                        </Grid>
                        
                    </Grid>
                </Grid> }
              </ListSubheader>
            }
        >
          {this.state.Esperar.esperar  && this.state.puntos_perfiles.length===0 ? 
            <ListaPerfilesEsperar/>:
            <ListaPerfilesServicios datos={this.state.puntos_perfiles}/>
          }
        </List>
      </div>

    )
  }   
  
}