import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import {Valord} from '../../constantes';
import logo from '../../imagenes/logo.png';


let config= null //JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor:'#000000',
    color:'#ffffff',
    display:'flex',
    alignItems: 'center',
    ...config.Estilos.barra
  },
  separar:{
    width:theme.spacing(1)
  },
  Button: {
    position: 'absolute',
    right: theme.spacing(1),
    'z-index':100,
    display:'table-row'
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  editarButton: {
    color: '#ffffff'//theme.palette.grey[500],
  },
  logo: {
    height: 40,
    
 },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, onEditar, onPegar, onCopiar, onEliminar, ...other } = props;
  
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <img src={logo} className={classes.logo} alt="logo" />
      <div className={classes.separar} />
      <Typography variant="h6" gutterBottom>{children}</Typography>
      <div className={classes.Button}>
        {onCopiar ? (
            <IconButton aria-label="copiar" title={'Copiar'} className={classes.editarButton} onClick={onCopiar}>
              <FileCopyIcon />
            </IconButton>
        ) : null}
        {onPegar ? (
          <IconButton aria-label="pegar" title={'Pegar'} className={classes.editarButton} onClick={onPegar}>
            <FileCopyOutlinedIcon />
          </IconButton>
        ) : null}
        {onEditar ? (
          <IconButton aria-label="editar" title={'Editar'} className={classes.editarButton} onClick={onEditar}>
            <EditIcon />
          </IconButton>
        ) : null}
        {onEliminar ? (
          <IconButton aria-label="editar" title={'Eliminar'} className={classes.editarButton} onClick={onEliminar}>
            <DeleteIcon />
          </IconButton>
        ) : null}
        {onClose ? (
          <IconButton aria-label="close" title={'Cerrar'} className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});


export default function Dialogo(props) {
  const {open, Cerrar, Editar, Copiar, Pegar, Eliminar, Titulo, Estilo, Cuerpo, Botones, tam}=props;
  // const [open, setOpen] = React.useState(false);
  const [scroll] = React.useState(Estilo ? Estilo : 'paper');//body

  // const handleClickOpen = (scrollType) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  // };
  //
  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const descriptionElementRef = React.useRef(null);
  // React.useEffect(() => {
  //   if (open) {
  //     const { current: descriptionElement } = descriptionElementRef;
  //     if (descriptionElement !== null) {
  //       descriptionElement.focus();
  //     }
  //   }
  // }, [open]);
  return (
    <div>

      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={tam ? tam : 'md'}
        scroll={scroll}

      >
        <DialogTitle id="dialog-title" onClose={Cerrar} onEditar={Editar} onCopiar={Copiar} onPegar={Pegar} onEliminar={Eliminar}>
          {Titulo}
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          
          {Cuerpo}
        </DialogContent>
        {
          Botones ?
              <DialogActions>
                {Botones.map((val,i) =>
                  <Button key={'Boton-'+i} {...val}>
                    {val.titulo}
                  </Button>
                )}
              </DialogActions> : null
        }

      </Dialog>
    </div>
  );
}
