import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
// import { Scrollbars } from 'rc-scrollbars';
import Scrollbars from '../formulario/MyScrollbars';
// import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import SearchIcon from '@material-ui/icons/Search';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add';

//Constantes
import {const_procesos, Valord} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;
let User = JSON.parse(localStorage.getItem(const_procesos.dir_user));

const alto_lista=520;

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function TitleTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  ListItem:{
    backgroundColor:'#023058', 
    padding:5, 
    border:1, 
    borderRadius:10, 
    marginBottom:5, 
    borderBlockColor:'#ffffff',
    cursor:'pointer', 
  },
  ListItemo:{
    backgroundColor:'#347155', 
    padding:5, 
    border:1, 
    borderRadius:10, 
    marginBottom:5, 
    borderBlockColor:'#ffffff',
    cursor:'pointer', 
  },
  ListItemS:{
    backgroundColor:'#0375D7', 
    padding:5, 
    border:1, 
    borderRadius:10, 
    marginBottom:5, 
    borderBlockColor:'#5B9ED8',
    cursor:'pointer', 
  },
  divText:{ 
    marginLeft: -0, 
    marginRight:10,  
    color:'#D0C6C6'
  },
  divTexta:{ 
    marginLeft: -0, 
    // marginRight:10,  
    color:'#D0C6C6'
  },
  divTextS:{ 
    marginLeft: -0, 
    // marginRight:10,  
    color:'#FFFFFF'
  },
  divFoto:{
    alignItems: 'center', 
    justifyContent:'center', 
    alignContent:'center', 
    textAlign:'center', 
    padding:10, 
    marginLeft:-25, 
    
  },
  divSubfoto: {
    width:60, 
    marginLeft:10, 
    marginTop:5, 
    backgroundColor:'#D0C6C6',
    color:'rgba(0, 19, 36)'
  },
  divSecundari:{
    marginRight:-7, 
  },
  divSubsecundari:{
    width:60, 
    marginRight:-10, 
    marginTop:5, 
    backgroundColor:'#D0C6C6',
    color:'rgba(0, 19, 36)'
  },
}));

export const ListaPerfilesCabezera = (props)=>{

    return(
        <ListSubheader component="div" id="nested-list-subheader"
            style={{
                backgroundColor:config.Estilos.barra.backgroundColor, color:'#ffffff',
                borderTop:10, borderTopLeftRadius:10,
                borderTopRightRadius:10,
                height: props.alto ? props.alto :'auto'
            }}
            disableGutters
            >
            <Typography variant={props.variant ? props.variant: "subtitle1"} display="block" gutterBottom  noWrap style={{padding:10, cursor:'pointer'}} title={`${props.Titulo}`} onClick={props.Open}>
                {`${props.Titulo}`}

                {props.Open ? <IconButton   size="small" onClick={props.Open}>
                    {
                        props.Icon ? props.Icon :
                        <SearchIcon fontSize="small" style={{ color: '#ffffff' }} />
                    }
                </IconButton>:null}
            </Typography>
            
        </ListSubheader>
    )
}
export const ListaPerfilesEsperar = (props)=>{
  const classes=useStyles();
    return(
        <Scrollbars autoHeight autoHeightMax={props.alto ? props.alto : alto_lista}>
            {[0,1,2,3,4,5,6].map(v=>
                <ListItem 
                key= {v} className={classes.ListItem}
                >
                <ListItemAvatar>
                    <Skeleton variant="circle" width={40} height={40} />
                </ListItemAvatar>
                <ListItemText
                    primary={
                    <div>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </div>
                    }
                />
                </ListItem>
            )
            }
        </Scrollbars>
    )
}

export const ListaPerfiles = (props)=>{
  const classes=useStyles()
    return(
        <Scrollbars autoHeight autoHeightMax={alto_lista}>
            {props.datos.map((punto,i) =>
            <ListItem 
                key= {i+'-' + punto._id} 
                className={punto['user-id'] ? classes.ListItem : classes.ListItemo}
                
                title={punto['user-id'] ? `Perfil "${punto['nombre-perfil'] ? punto['nombre-perfil'] : punto['first-name']}"`: `Operador "${punto.nombres}"`}
            >
                <ListItemAvatar>
                  <div className={classes.divFoto}>
                    <Avatar
                        style={{marginLeft:15}}
                        alt={`${punto['nombre-perfil'] ? punto['nombre-perfil'] : punto['first-name'] ? punto['first-name'] : punto.nombres ? punto.nombres : ''}`}
                        src={[undefined,'undefined'].indexOf(punto['user-id'])===-1 && [undefined,'undefined'].indexOf(punto.thumbnail)===-1 ? 
                            `https://api7.amolatina.com/users/${punto['user-id']}/photos/${punto.thumbnail}.490x480.thumb-fd` : 
                            ''
                            }
                    />
                  </div>
                </ListItemAvatar>
                <ListItemText
                primary={
                  <div className={classes.divText}>
                    <Typography variant="caption" display="block" noWrap gutterBottom>
                      <Link href={`https://www.amolatina.com/people/#${punto['user-id']}`} target="_blank" color={'inherit'}>
                        {punto['user-id']}
                      </Link>
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom noWrap style={{marginTop:-7}}>
                        {punto['first-name']? punto['first-name'] : punto.nombres ? punto.nombres + ' ' + punto.apellidos :''}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom style={{marginTop:-7}}>
                        {`Puntos: ${Number(punto.points ? punto.points : punto.total ? punto.total: 0).toFixed(2)}`}
                    </Typography>
                    
                  </div>
                }
                
                />
            </ListItem>
                
            )}
        </Scrollbars>
    )
}

export const ListaTopOperador = (props)=>{
  const classes=useStyles()
  
    return(
        <Scrollbars autoHeight autoHeightMax={props.alto ? props.alto : alto_lista}>
          {Object.keys(props.datos).map(sub=>
            <div key={sub}>
            <ListSubheader style={{backgroundColor:'#000'}}>
                <Typography variant="caption" display="block" gutterBottom noWrap style={{color:'#fff'}}>
                  {`${sub}`}
                </Typography>
            </ListSubheader>  
            
          
            {props.datos[sub].map((punto,i) =>
            <ListItem 
                key= {i+'-' + punto._id} 
                className={ classes.ListItem}
            >
              
                <ListItemText
                primary={
                  <div className={classes.divText}>
                    
                    <Typography variant="caption" display="block" gutterBottom noWrap style={{marginTop:-7}}>
                        {punto.nombres + ' ' + punto.apellidos}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom style={{marginTop:-7}}>
                        {`Puntos: ${Number(punto.total).toFixed(2)}`}
                    </Typography>
                    
                  </div>
                }
                
                />
            </ListItem>
                
            )}
            </div>
          )}
        </Scrollbars>
    )
}

export const ListaTopAgencias = (props)=>{
  const classes=useStyles()
  
    return(
        <Scrollbars autoHeight autoHeightMax={props.alto ? props.alto : alto_lista}>
          {Object.keys(props.datos).map((sub,i)=>
            <div key={i+'div-'+sub}>
            <ListSubheader style={{backgroundColor:'#000'}} key={i+'-'+sub}>
                <Typography variant="caption" display="block" gutterBottom noWrap style={{color:'#fff'}}>
                  {`${sub}`}
                </Typography>
            </ListSubheader>  
            
          
            {props.datos[sub].map((punto,i) =>
            punto.nombre!=='Total'
              ? <ListItem 
                    key= {i+'-' + punto.nombre} 
                    className={ classes.ListItem}
                >
                  
                    <ListItemText
                    primary={
                      <div className={classes.divText}>
                        
                        <Typography variant="caption" display="block" gutterBottom noWrap >
                            {punto.nombre }
                        </Typography>
                        
                      </div>
                    }
                    
                    />
                    <ListItemSecondaryAction >
                    
                    <div className={classes.divText}>
                      
                      <Typography variant="caption" display="block" gutterBottom noWrap >
                          {`${punto.puntos===-1 ? 'NA' :Number(punto.puntos).toFixed(2)}`}
                      </Typography>
                      
                    </div>
                  
                  
                </ListItemSecondaryAction >
                </ListItem>
              : <ListItem 
                    key= {i+'-' + punto.nombre} 
                    className={ classes.ListItemo}
                >
                  <ListItemText
                    primary={
                      <div className={classes.divText}>
                        
                        <Typography variant="caption" display="block" gutterBottom noWrap >
                            
                        </Typography>
                        
                      </div>
                    }
                    
                    />
                  <ListItemSecondaryAction >
                    
                      <div className={classes.divText}>
                        
                        <Typography variant="caption" display="block" gutterBottom noWrap style={{marginTop:5}}>
                            {`Total    ${Number(punto.puntos).toFixed(2)}`}
                        </Typography>
                        
                      </div>
                    
                    
                  </ListItemSecondaryAction >
                </ListItem> 
            )}
            </div>
          )}
        </Scrollbars>
    )
}

export const ListaTopServicio = (props)=>{
  const classes=useStyles()
  
    return(
        <Scrollbars autoHeight autoHeightMax={props.alto ? props.alto : alto_lista}>
          {Object.keys(props.datos).map((sub,i)=>
            <div  key={sub+'-'+i}>
            <ListSubheader style={{backgroundColor:'#000'}} key={sub+i}>
                <Typography variant="caption" display="block" gutterBottom noWrap style={{color:'#fff'}}>
                  {`${sub}`}
                </Typography>
            </ListSubheader>  
            
          
            {props.datos[sub].map((punto,i) =>
            punto.nombre!=='Total'
              ? <ListItem 
                    key= {i+'-' + punto.service} 
                    className={ classes.ListItem}
                >
                  
                    <ListItemText
                    primary={
                      <div className={classes.divText}>
                        
                        <Typography variant="caption" display="block" gutterBottom noWrap >
                            {config.service[punto.service].value }
                        </Typography>
                        
                      </div>
                    }
                    
                    />
                    <ListItemSecondaryAction >
                    
                    <div className={classes.divText}>
                      
                      <Typography variant="caption" display="block" gutterBottom noWrap >
                          {`${Number(punto.points).toFixed(2)}`}
                      </Typography>
                      
                    </div>
                  
                  
                </ListItemSecondaryAction >
                </ListItem>
              : <ListItem 
                    key= {i+'-' + punto.service} 
                    className={ classes.ListItemo}
                >
                  <ListItemText
                    primary={
                      <div className={classes.divText}>
                        
                        <Typography variant="caption" display="block" gutterBottom noWrap >
                            
                        </Typography>
                        
                      </div>
                    }
                    
                    />
                  <ListItemSecondaryAction >
                    
                      <div className={classes.divText}>
                        
                        <Typography variant="caption" display="block" gutterBottom noWrap style={{marginTop:5}}>
                            {`Total    ${Number(punto.puntos).toFixed(2)}`}
                        </Typography>
                        
                      </div>
                    
                    
                  </ListItemSecondaryAction >
                </ListItem> 
            )}
            </div>
          )}
        </Scrollbars>
    )
}

const DatoMingles = (props)=>{
  // const classes = useStyles();
  return(
    <div>
      <Typography color="inherit">
        {`Supervisor: ${props.pers.supervisor && props.pers.supervisor.nombres ? props.pers.supervisor.nombres + ' ' + props.pers.supervisor.apellidos : 'No asignado'}`}
      </Typography>
      <Typography color="inherit">
        {`Operador: ${props.pers.nombres ? props.pers.nombres + ' ' + props.pers.apellidos : 'No asignado'}`}
      </Typography>
      
    </div>
  )
}

export const ListaMingles = (props)=>{
    const classes= useStyles()
    return(
        <Scrollbars autoHeight autoHeightMax={alto_lista}>
          {props.datos.map((punto,i) =>
            <TitleTooltip key= {i+'--' + punto._id+'-'}  arrow
              title={
                <DatoMingles {...punto}/>
              } 
            >
              <ListItem 
                key= {i+'-' + punto._id} 
                
                className={[0,1,2,'0','1','2'].indexOf(User.categoria)!==-1 || User._id===punto.pers._id ? classes.ListItem : classes.ListItemo}
              >
                <ListItemAvatar>
                    <div className={classes.divFoto} >
                      <Avatar
                        style={{marginLeft:15}}
                        alt={`${punto['nombre-perfil']}`}
                        src={[undefined,'undefined'].indexOf(punto['user-id'])===-1 && [undefined,'undefined'].indexOf(punto.thumbnail)===-1 ? 
                              `https://api7.amolatina.com/users/${punto['user-id']}/photos/${punto.thumbnail}.490x480.thumb-fd`:
                              ''
                            }
                      />
                      <div className={classes.divSubfoto}>
                        <Typography variant="caption" display="block" noWrap gutterBottom style={{marginTop:-3, marginLeft:4, fontSize:10, fontWeight:900}}>
                            {punto['first-name']}
                        </Typography>
                      </div>
                    </div>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div className={classes.divText}>
                      <Typography variant="caption" display="block" noWrap gutterBottom>
                        <Link href={`https://www.amolatina.com/people/#${punto['user-id']}`} target="_blank" color={'inherit'}>
                          {punto['user-id']}
                        </Link>
                      </Typography>
                      <Typography variant="caption" display="block" noWrap gutterBottom style={{marginTop:-7}}>
                          {punto['agencia']}
                      </Typography>
                      <Typography variant="caption" display="block" gutterBottom noWrap style={{marginTop:-7}} title={'Operador'}>
                        {`${punto.pers.nombres ? punto.pers.nombres : 'No asignado'} ${punto.pers.apellidos ? punto.pers.apellidos : ''}`}
                      </Typography>
                      <Typography variant="caption" display="block" gutterBottom  noWrap style={{marginTop:-7}} title={'Supervisor'}>
                        {`${punto.pers.supervisor && punto.pers.supervisor.nombres ? punto.pers.supervisor.nombres : 'No asignado'} ${punto.pers.supervisor && punto.pers.supervisor.apellidos ? punto.pers.supervisor.apellidos : ''}`}
                      </Typography>
                    </div>
                  }
                />
                <ListItemSecondaryAction >
                  <div className={classes.divSecundari}>
                      <Avatar
                        style={{marginLeft:10}}
                        alt={`${punto.pers && punto.pers.nombres ? punto.pers.nombres : 'No asignado'}`}
                        src={`${punto.pers && punto.pers.foto ? punto.pers.foto : ''}`}
                      />
                      <div className={classes.divSubsecundari}>
                        <Typography variant="caption" display="block" noWrap gutterBottom style={{marginTop:-3, marginLeft:4, fontSize:10, fontWeight:900}}>
                        {`${punto.pers.nombres ? punto.pers.nombres : 'No asignado'}`}
                        </Typography>
                      </div>
                  </div>
                </ListItemSecondaryAction>
              </ListItem>
            </TitleTooltip>   
          )}
        </Scrollbars>
    )

}

const DatoPerfil = (props)=>{
  const classes = useStyles();
  return(
    <div>
      <Typography color="inherit">{`Perfil: ${props['nombre-perfil']}`}</Typography>
      <Typography color="inherit">{`Fecha: ${props['fecha']}, Hora: ${props.hora}`}</Typography>
      <div className={classes.root}>
        <Typography color="inherit">
          {`Cliente: ${props['cliente-name'] ? props['cliente-name'] : props['target-id']}`}
        </Typography>
        <Avatar alt={props['cliente-name']} 
          src={`https://api7.amolatina.com/users/${props['target-id']}/photos/${props['cliente-foto']}.490x480.thumb-fd`} 
          className={classes.small} 
        />
      </div>
      {props.operador ?
        <div className={classes.root}>
          <Typography color="inherit">
            {`Operador: ${props.operador.nombres} ${props.operador.apellidos}`}
          </Typography>
          <Avatar alt={props.operador.nombres} src={props.operador.foto} className={classes.small} />
        </div>
        :null
      }
    </div>
  )
}

export const ListaPerfilesServicios = (props)=>{
  const classes=useStyles()
    return(
        <Scrollbars autoHeight autoHeightMax={alto_lista}>
          {props.datos.map((punto,i) =>
            <TitleTooltip key= {i+'--' + punto['user-id']+'-'+punto.service}  arrow
              title={
                <DatoPerfil {...punto}/>
              } 
            >
              <ListItem 
                key= {i+'-' + punto['user-id']+'-'+punto.service} 
                
                className={punto.operador ? classes.ListItemo : classes.ListItem}
              >
                <ListItemAvatar>
                    <div style={{alignItems: 'center', justifyContent:'center', alignContent:'center', textAlign:'center', padding:10, marginLeft:-10}}>
                      <Avatar
                        
                        alt={`${punto['nombre-perfil']}`}
                        src={[undefined,'undefined'].indexOf(punto['user-id'])===-1 && [undefined,'undefined'].indexOf(punto['imagen-perfil'])===-1 ? 
                          `https://api7.amolatina.com/users/${punto['user-id']}/photos/${punto['imagen-perfil']}.490x480.thumb-fd` : 
                          ''}
                      />
                    </div>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div className={classes.divText} >
                      <Typography variant="caption" display="block" noWrap gutterBottom>
                        {`${punto['nombre-perfil']} ${punto['user-id']}`}
                      </Typography>
                      <Typography variant="caption" display="block" gutterBottom style={{marginTop:-7}}>
                        {`Puntos: ${Number(punto.points===undefined ? 0 : punto.points).toFixed(2)}`}
                      </Typography>
                      
                      <Typography variant="caption" display="block" gutterBottom noWrap style={{marginTop:-7}}>
                        <Link href={`https://www.amolatina.com/people/#${punto['target-id']}`} target="_blank" color={'inherit'}>
                          {`${punto['cliente-name'] ? punto['cliente-name'] + ' ' + punto['target-id'] : punto['target-id']}`}
                        </Link>
                      </Typography>
                    </div>
                  }
                  
                />
                <ListItemSecondaryAction>
                  <div className={classes.divSecundari} >
                    {config.service[punto.service] ? config.service[punto.service].icon : null}
                      <Typography variant="caption" display="block" gutterBottom style={{marginTop:-7, color:'#ffffff', fontWeight: 900}}>
                        {`${punto.hora}`}
                      </Typography>
                  </div>
                </ListItemSecondaryAction>
              </ListItem>
            </TitleTooltip>
          )}
        </Scrollbars>
    )
}

export const ListaCliente = (props)=>{
  const classes=useStyles()
    return(
        <Scrollbars autoHeight autoHeightMax={props.alto ? props.alto : alto_lista}>
          {props.datos.map((punto,i) =>
            // <TitleTooltip key= {i+'--' + punto['user-id']+'-'+punto.service}  arrow
            //   title={
            //     <DatoPerfil {...punto}/>
            //   } 
            // >
              <ListItem 
                key= {i+'-' + punto['targe-id']} 
                
                className={classes.ListItem}
              >
                
                <ListItemText
                  primary={
                    <div className={classes.divText} >
                      <Typography variant="caption" display="block" noWrap gutterBottom>
                        {`${punto['cliente-name']} ${Number(punto.points).toFixed(2)}`}
                      </Typography>
                      
                    </div>
                  }
                  
                />
                <ListItemSecondaryAction>
                  <div className={classes.divSecundari} >
                    <Link href={`https://www.amolatina.com/people/#${punto['target-id']}`} target="_blank" color={'inherit'}>
                      <AddIcon style={{color:'#fff'}}/>    
                    </Link>
                  </div>
                </ListItemSecondaryAction> 
              </ListItem>
            // </TitleTooltip>
          )}
        </Scrollbars>
    )
}

const DatoAgencia = (props)=>{
  // const classes = useStyles();
  console.log(props)
  return(
    <div>
      <Typography color="inherit">{`Fecha: ${props['fecha']}`}</Typography>
      <Typography color="inherit">{`Nombre: ${props['nombre']}`}</Typography>
      <Typography color="inherit">{`Puntos en ${props.api}: ${new Intl.NumberFormat('es-VE').format(props.puntos)}`}</Typography>
      <Typography color="inherit">{`Puntos en Sistema: ${new Intl.NumberFormat('es-VE').format(props.total)}`}</Typography>
    </div>
  )
}

export const ListaAgencia = (props)=>{
  const classes=useStyles()
  return(
    <Scrollbars autoHeight autoHeightMax={props.alto ? props.alto : 200} styles={{backgroundColor:'#fff000'}}>
      {props.puntos_agencias.map((punto, i) =>
        <TitleTooltip key= {i+'--' + punto['user-id']+'-'+punto.service}  arrow
        title={
          <DatoAgencia {...punto}/>
        } 
      >
          {punto['nombre-agency']===undefined ? null :
          <ListItem 
            key={punto['nombre-agency']} alignItems="flex-start" 
            
            className={props.selecciones[i] ? classes.ListItemS : classes.ListItem}
            onClick={() => props.Seleccion(i)}        
          >
            {/* <ListItemAvatar>
              <Avatar
                alt={`${punto['nombre']}`}
                src={[undefined,'undefined'].indexOf(punto['agency-id'])===-1 && [undefined,'undefined'].indexOf(punto['imagen-agency'])===-1 ?
                      `https://api7.amolatina.com/users/${punto['agency-id']}/photos/${punto['imagen-agency']}.490x480.thumb-fd`:
                      ''
                    }
              />
            </ListItemAvatar> */}
            <ListItemText
              primary={
                <div className={props.selecciones[i] ? classes.divTextS :classes.divTexta}>
                  <Typography variant="caption" display="block" gutterBottom >
                    {`${punto['nombre'] ? punto['nombre'] : punto['email'] } Puntos A: ${new Intl.NumberFormat('es-VE').format(punto.puntos)} \n Puntos S: ${new Intl.NumberFormat('es-VE').format(Number(punto.total))}`}
                  </Typography>
                </div>
              }
              // secondary={
              //   <div className={props.selecciones[i] ? classes.divTextS :classes.divTexta}>
              //     <Typography variant="caption" display="block" gutterBottom >
              //       {`Puntos A: ${new Intl.NumberFormat('es-VE').format(punto.puntos)} \n Puntos S: ${new Intl.NumberFormat('es-VE').format(Number(punto.total))}`}
              //     </Typography>
              //   </div>
              // }
            />
          </ListItem>
          }
        </TitleTooltip>
      )}
    </Scrollbars>
  )
}