import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import {MenuItem, Grid} from '@material-ui/core';
// import clsx from 'clsx';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import DatePicker from "react-datepicker";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import { green, red, blueGrey } from '@material-ui/core/colors';
import Scrollbars from '../formulario/MyScrollbars';
import Lista from './lista';
import ListaRepresentados from './lista_representados';
import Video from './video';
// import { delete } from 'request-promise';
// import Lista from './lista';
// import Listavideo from './lista_video';

//Estilo para Botones
const Botones_theme = createMuiTheme({
  palette: {
    // primary: {
    //
    //   main:green[600],
    // },
    // secondary: {
    //     main: red[500],
    //     // dark: will be calculated from palette.secondary.main,
    //     contrastText: '#ffffff',
    //   },

  },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.

});

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    marginTop:10,
    marginBottom: 20,
    height:window.innerHeight * 0.85,
    overflow: 'auto',
    padding:theme.spacing(1),
  },
  textField: {

    marginRight: theme.spacing(1),
    width: '100%',
    textAlign: 'left',
  },
  item: {
    width: '99%',
    marginBottom:10,
    alignItems: 'center',
    textAlign: 'center',
    justifyContent:'center',
    paddingRight:theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
  media:{
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    // width:'90%',
    marginLeft:10,
    marginTop: -10,

  },
  imagen: {
    objectFit: 'cover',
    width: '80%',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  avatar:{
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
  },
  barra_botones:{
    marginTop:10,
    marginBottom:20,
    color: theme.palette.text.secondary,
  },
  cont_imagen:{
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',

  },
  listados: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  button:{
    marginLeft:5,
    backgroundColor: (props)=> props.color ==='success' ?'#ffffff' :null
  },

}));

// const Boton = styled(({ color, ...other }) => <Button {...other} />)({
//   background: (props) =>
//     props.color === 'success'
//       ? 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
//       : null,
//   marginLeft:5,
// });

export default function Page(props) {
  const classes = useStyles();

  let values = {
  };

  const {datos, http_server} = props;

  values = datos ? {...values,...datos}: values;

  const [showPassword,setShowPassword]= React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const Form = (lista) =>{
    let resultados = {...values.resultados};
    const listado= Object.keys(lista).map(valor =>{
        resultados[valor]=resultados[valor] ? resultados[valor] : '';
        return lista[valor].multiples ? {
            multiples:true,
            lista:Form(lista[valor].value)
        }:{
          ...lista[valor],
          id: "filled-"+valor,
          key: "filled-"+valor,
          name: valor,
          value: values.resultados[valor]!==null && values.resultados[valor]!==undefined ? values.resultados[valor]: '',
          label: lista[valor].label,
          type: lista[valor].type,
          lista:lista[valor].lista,
          onChange: values.Cambio,
          error:values.resultados['Error-'+valor] !== '' ? true : false,
          helperText:values.resultados['Error-'+valor],
          
          // rowsMax: lista[valor].row,
        }

      })
    return listado;
  }

  const form = values.form ? Form(values.form): [];

  const Imagen = (valor, values) =>{
    let imagen=true;
    if (values.resultados[valor.name]){
      if (values.resultados[valor.name][0].type){
        imagen= values.resultados[valor.name][0].type.indexOf('image')!==-1
        
      }else{
        imagen = ['png', 'jpg', 'jpeg', 'ico'].indexOf(values.resultados[valor.name].split('.').pop())!==-1
      }
    }
    return imagen ? (
      <img alt={valor.label ? valor.label : 'Imagen'}
            src={values.resultados[valor.name+'_url'] ?
                  String(values.resultados[valor.name+'_url']) :
                  values.resultados[valor.name] === '' ? null :
                  `${values.resultados[valor.name]}`
            }
            className={classes.imagen}
      />
    ): (
      <video alt={valor.label ? valor.label : 'Video'}
              src={values.resultados[valor.name+'_url'] ?
                  String(values.resultados[valor.name+'_url']) :
                  values.resultados[valor.name] === '' ? null :
                  `${values.resultados[valor.name]}`
              }
              className={classes.imagen}
              autoPlay
              loop
      />
    )
  }
  const renderForm =(valor)=>{
    
    return valor.multiples ? (
      <Grid container spacing={1} key={valor.lista[0]+'G'}>
        {valor.lista.map((lis,it) =>
          <Grid item xs key={'GI-'+it}>
            {renderForm(lis)}
          </Grid>
        )}
      </Grid>
    ): valor.tipo==='avatar' ?(
        <div key={'avatar-'+valor.name} className={classes.avatar}>
          <input accept="image/*"
                 className={classes.input}
                 id={"icon-button-avatar-"+valor.name}
                 type="file"
                 onChange={values.Cambio_A}
                 name={valor.name}
                 key={'avatar-i-'+valor.name}
          />
          <label key={'avatar-l-'+valor.name} htmlFor={"icon-button-avatar-"+valor.name}>
            <IconButton key={'avatar-b-'+valor.name} color="primary" aria-label="upload picture" component="span">
              <Avatar alt={valor.label}
                      src={values.resultados[valor.name+'_url'] ?
                           String(values.resultados[valor.name+'_url']) :
                           values.resultados[valor.name] === '' ? null :
                           `${values.resultados[valor.name]}`
                      }
                      className={classes.large}
                      key={'avatar-a-'+valor.name}
              />
            </IconButton>
          </label>
        </div>
    ): valor.tipo==='imagen' ?(
      <div key={'imagen-'+valor.name} className={classes.cont_imagen}>
        <input accept=".png, .jpg, .jpeg, .ico, .mp4"
               className={classes.input}
               id={"icon-button-imagen-"+valor.name}
               type="file"
               onChange={values.Cambio_A}
               name={valor.name}
               key={'imagen-i-'+valor.name}
        />
        <label key={'imagen-l-'+valor.name} htmlFor={"icon-button-imagen-"+valor.name}>
          <ButtonBase
            focusRipple
            key={'imagen-b-'+valor.name}
            color="primary"
            focusVisibleClassName={classes.focusVisible}
            component="span"
          >

            { values.resultados[valor.name] !== '' || values.resultados[valor.name+'_url'] ?
              Imagen(valor,values):
              <PhotoCamera color="primary"/>
            }
          </ButtonBase>
        </label>
      </div>
    ): valor.tipo==='video' ? (
      <Video values={values} valor={valor} http_server={http_server}/>
    ): valor.tipo==='select' ? (
      <Autocomplete
        multiple={valor.multiple}
        
        disabled={valor.disabled}
        options= {valor.lista ? valor.lista : []}
        // getOptionSelected={valor.getOptionSelected ? valor.getOptionSelected : (option) => option.titulo}
        getOptionLabel= {valor.getOptionLabel ? valor.getOptionLabel :(option) => option.titulo}
        id={'select-'+valor.name}
        autoComplete
        
        value={valor.multiple ?
                        valor.value ?
                          valor.value :
                          [] :
                          valor.value=== undefined || valor.value==='' ?
                            null :
                            valor.value
              }
        // inputValue={ valor.value=== undefined ? '' : valor.value.titulo}

        noOptionsText='No hay opciones'
        onChange={(event, newValue) => {
          if (newValue!==null){
            // console.log(newValue)
            values.Cambio({target:{name:valor.name, value:newValue}})
          }
        }}

        renderInput={(params) =>
                        <TextField {...params} variant="outlined"
                            label={valor.label}
                            margin="normal"
                            onChange={values.Cambio}
                            style= {{ margin: 8 }}
                            name={valor.name}
                        />
                    }
      />
    ): valor.tipo==='lista' ? (
      <Lista key={'Listados-'+valor.name} valor={valor} cambio={values.Cambio}/>

    ): valor.tipo==='lista_representados' ? (
      <ListaRepresentados key={'Listados-'+valor.name} valor={valor} cambio={values.Cambio}/>
      
    ): valor.tipo==='password' ? (
      <TextField
       {...valor}
       name={valor.name}
       type={showPassword ? 'text' : 'password'}
       onKeyPress={
          valor.onKeyPress ? 
          (event) =>{
            if (event.key==='Enter')
              values.Responder(valor.onKeyPress,values.resultados, valor.validar)
          } : null 
        }
       onChange={values.Cambio}
       style= {{ margin: 8 }}
       variant="outlined"
       margin="normal"
       fullWidth
       className={classes.textField}
       InputProps={{
         endAdornment: (
           <InputAdornment position="end">
             <IconButton
               aria-label="toggle password visibility"
               onClick={handleClickShowPassword}
               onMouseDown={handleMouseDownPassword}
               edge="end"
             >
               {showPassword ? <Visibility /> : <VisibilityOff />}
             </IconButton>
           </InputAdornment>
         ),
       }}
     />
    ): valor.tipo==='time' ? (
      <DatePicker
        {...valor}
        name={valor.name}
        className={classes.textField}
        selected={valor.value}
        onChange={date => values.Cambio({target:{name:valor.name, value:date}})}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={5}
        timeCaption="Time"
        dateFormat="h:mm aa"
        
      />
    ) : valor.tipo==='Checkbox' ? (
      <FormControlLabel
        control={
          <Checkbox
            checked={valor.value}
            onChange={(event) => values.Cambio({target:{name:valor.name, value:event.target.checked}})}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            name={valor.name}
          />}
        label={valor.value ? valor.label.split('/')[0] : valor.label.split('/')[1]}
      />
      
    ) :(
        <TextField
          {...valor}

          rows={valor.rowsMax}
          multiline={valor.multiline}
          style= {{ margin: 8 }}
          variant="outlined"
          margin="normal"
          fullWidth
          className={classes.textField}
        >
        {valor.lista ? valor.lista.map((lis,i)=>
          <MenuItem key={'lista-'+i+lis.titulo} value={lis._id}>
            {lis.titulo}
          </MenuItem>

        ):null}
        </TextField>
    )

  }

  return form.length===0 ? (
    <div className={[classes.root]}>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
    </div>
  ) :(
    
    <div className={classes.root} style={datos.estilospage ? datos.estilospage : {}} key={'formt'}>
      <Scrollbars >
      {form.map((valor,i) =>
        <div key= {'div'+i} className={classes.item}>
          {renderForm(valor)}
        </div>
      )}

      {values.botones ? (
        <div className={classes.barra_botones}>
          {values.botones.map(boton=>{
            // const validar= boton.validar;
            // delete boton.validar
            return (<ThemeProvider theme={Botones_theme} key={boton.name}>
                      <Button
                        {...boton}
                        key={'boton-'+ boton.name}
                        className={classes.button}
                        startIcon={boton.icono}
                        onClick={()=>values.Responder(boton.onClick,values.resultados, boton.validar)}
                      >
                        {boton.label}
                      </Button>
                    </ThemeProvider>
                    )
            }
          )}

        </div>
        ): null
      }
      </Scrollbars>
    </div>
    
  );
}
