import React, {Component} from 'react';
import {conexiones} from '../../procesos/servicios';
import Esperar from '../esperar';
import Pagee from '../conexiones';
import Dialogo from '../../components/formulario/dialogo';
import IconButton from '@material-ui/core/IconButton';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import Typography from '@material-ui/core/Typography';
import {ItemTituloSupervisor, ItemPerfiles} from '../conexiones/item';
import Pbreak from './personalbreak'
//Asignar operadores a supervisores
export default class Operador extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{Libres:[],Grupos:[]},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          Guardar: this.Guardar,
          Cancelar: this.Cancelar,
          Iniciar: this.Iniciar,
          Actualizar:this.Actualizar,
          Dialogo:{
            open:false,
            Titulo:'Mi cuenta',
            tam:'xl',
            Cerrar: this.Cerrar_dialogo,
          }
      }
  }
  
  Guardar = async(datos)=>{
    
    this.setState({Esperar:{esperar:true,mensaje:'Guardando cambios...'}})
    let {personalb}=this.state

    Promise.all( 
      await datos.Grupos.map(async g =>{
        g.perfiles= g.childen.map( o =>{
          o={
            _id:o._id, 'user-id':o['user-id'], 'first-name':o['first-name'], thumbnail:o.thumbnail,
            foto:o.foto, turno: g.turno, cedula:o.cedula, 
          }
          return o
        })
        const nuevo={_id:g._id, perfiles:g.perfiles}
        const valor = await conexiones.Guardar(nuevo,'Data_personal')
        return valor
      }),
      await personalb.map(async g =>{
        g.perfiles= g.childen.map( o =>{
          o={
            _id:o._id, 'user-id':o['user-id'], 'first-name':o['first-name'], thumbnail:o.thumbnail,
            foto:o.foto, turno: g.turno, cedula:o.cedula, 
          }
          return o
        })
        const nuevo={_id:g._id, perfiles:g.perfiles}
        const valor = await conexiones.Guardar(nuevo,'Data_personal')
        return valor
      }),

      await datos.Libres.map( async L=>{
        const nuevo={_id:L._id, operadores:L.operadores}
        const valor = await conexiones.Guardar(nuevo,'Data_perfil_amolatina')
        return valor
      })
    ).then(values => {
      console.log('Termino');
      this.setState({datos, Esperar:{esperar:false}})
    }, reason => {
      console.log('Error')
      this.setState({datos, Esperar:{esperar:false}})
    });
    // this.setState({datos, Esperar:{esperar:false}})
    
  }

  Cancelar = async()=>{
      //Colocamos todos los valores a TN
    let {datos} = this.state
    datos.supervisores.map((val,i)=>{
      if (val.operadores.length!==0){
        while (val.operadores.length!==0){
          datos.operadores.push(val.operadores[0])
          datos.supervisores[i].operadores.splice(0, 1)
        }
      }
      return val
    })
    
    this.setState({datos})
      
  }

  Iniciar= async() =>{
    this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    let respuesta= await conexiones.Leer_C(['Data_personal','Data_perfil_amolatina'], 
      {
        'Data_personal':{$or:[ {categoria:3} , {categoria:4}]},
        'Data_perfil_amolatina':{suspended: false},
      }
    );
    let personal=[]
    let perfiles=[]
    let personalb=[]
    if (respuesta.Respuesta==='Ok'){
        // personal= respuesta.datos['Data_personal'].sort((a, b) => (a.categoria >b.categoria ? -1 : a.categoria < b.categoria ? 1 : 0))
        personal= respuesta.datos['Data_personal'] //.filter(f=> f.turno.indexOf('Break')===-1)
        .sort((a, b) => (a.categoria >b.categoria ? 1 : a.categoria < b.categoria ? -1 : 0))
        perfiles=respuesta.datos['Data_perfil_amolatina'].filter(f=> !f.suspended)
        
    }
    
    let datos = {
      Grupos:[],
      Libres:[],
    }

    personal.map(val=>{
      const childen = val.perfiles.filter(o=>{
        if (o.turno === val.turno)
          return {...o, key:o._id, titulo:<ItemPerfiles op={{...o, perfil:false}}/>}
        return -1
      })
      datos.Grupos.push(
        {...val, key:val._id, 
          titulo:<ItemTituloSupervisor sup={{...val, cantidad: childen.length, childen}}/>, 
          childen 
      })
      return val    
    })
    personalb=datos.Grupos.filter(f=> f.turno.indexOf('Break')!==-1)
    datos.Grupos = datos.Grupos.filter(f=> f.turno.indexOf('Break')===-1).sort((a, b) => (a.turno > b.turno ? 1 : a.turno < b.turno ? -1 : 0))
    datos.Libres = perfiles.map( p =>{
      let operadores=[]//=p.operadores
      datos.Grupos.map(g=>{
        const pos = operadores.findIndex(v=> v._id===g._id) 
        const post = operadores.findIndex(v=> v.turno===g.turno) 
        if (g.childen.findIndex((v)=> v._id===p._id)!==-1){
          const anterior = post===-1 || operadores[post]._id===g._id ? '' :  operadores[post].turno
          if (pos===-1){
            operadores.push({
              _id:g._id, nombres:g.nombres, apellidos:g.apellidos, 
              turno:g.turno, cedula:g.cedula, foto:g.foto, 
              suplente: anterior!==g.turno ? '' : 'Suplente'
            })
          }else{
            operadores[pos]={
              _id:g._id, nombres:g.nombres, apellidos:g.apellidos, 
              turno:g.turno, cedula:g.cedula, foto:g.foto, 
              suplente: anterior!==g.turno ? '' : 'Suplente'
            }
          }
        }else if (pos!==-1) {
          operadores.splice(pos, 1)
        }
        return g
      })
      operadores.sort((a,b)=> a.turno > b.turno ? 1 : a.turno < b.turno ? -1 : 0)
      return {...p, key:p._id, operadores, titulo:<ItemPerfiles op={{...p, perfil:true, operadores}}/>}
    })
    
    this.setState({personalb, datos, Quitarl: false, Quitarg: false, 
      TituloL:'Perfiles', 
      TituloG:
      <div style={{display:'flex'}}>
          <Typography variant="h5" component="h4" gutterBottom 
            style={{ color:'#ffffff', marginTop:10}}
          >
            {'Supervisores / Operadores'}
          </Typography>
          <IconButton aria-label="atras" onClick={this.Ver}>
            <FindInPageIcon  style={{ color: '#ffffff' }}/>
          </IconButton>
          
        </div>,  
      Esperar:{esperar:false}
    })
    this.Actualizar(datos)
  }
  
  Ver = () =>{
    let {Dialogo, personalb}=this.state
    Dialogo.open=true
    Dialogo.Titulo=`Personal de los Break`
    Dialogo.Cuerpo= <Pbreak personal={personalb}/>
    this.setState({Dialogo})
  }

  Cerrar_dialogo = () =>{
    let {Dialogo}=this.state
    Dialogo.open=false
    this.setState({Dialogo})
  }

  Agregar_break = (dato, personal) =>{
    personal = personal.map(p=>{
      if (
        p.turno.split('-')[1] === dato.turno.split('-')[1] &&
        p.turno.split('(')[1].split(')')[0] === dato.turno.split('(')[1].split(')')[0]
      ){
        p.childen=[...p.childen, ...dato.childen]
        p.titulo=<ItemTituloSupervisor sup={{...p, cantidad:p.childen.length}}/>
      }
      return p
    })
    return personal
  }

  Actualizar = (datos) =>{
    let {personalb}= this.state
    personalb=personalb.map(p=>{

      return {...p, childen:[]}
    })

    datos.Grupos= datos.Grupos.map(g=>{
      let childen=[];
      g.childen.map(o=>{
        
        if (o.turno || o.operadores.length<=0 || o.operadores[0].turno.split('-')[1]=== g.turno.split('-')[1]){
          childen.push({...o, key:o._id, titulo:<ItemPerfiles op={{...o, perfil:false}}/>})
        }
        return {...o, key:o._id, titulo:<ItemPerfiles op={{...o, perfil:false}}/>}
      })
      g.childen=childen;
      g.titulo=<ItemTituloSupervisor sup={{...g, cantidad:g.childen.length}}/>
      personalb= this.Agregar_break(g,personalb)
      return g
    })
    
    datos.Libres = datos.Libres.map( p =>{

      let operadores=p.operadores
      datos.Grupos.map(g=>{
        const pos = operadores.findIndex(v=> v._id===g._id) 
        const post = operadores.findIndex(v=> v.turno===g.turno) 
        if (g.childen.findIndex((v)=> v._id===p._id)!==-1){
          const anterior = post===-1 || operadores[post]._id===g._id ? '' :  operadores[post].turno
          if (pos===-1){
            operadores.push({
              _id:g._id, nombres:g.nombres, apellidos:g.apellidos, 
              turno:g.turno, cedula:g.cedula, foto:g.foto, 
              suplente: anterior!==g.turno ? '' : 'Suplente'
            })
          }else{
            operadores[pos]={
              _id:g._id, nombres:g.nombres, apellidos:g.apellidos, 
              turno:g.turno, cedula:g.cedula, foto:g.foto, 
              suplente: anterior!==g.turno ? '' : 'Suplente'
            }
          }
        }else if (pos!==-1) {
          operadores.splice(pos, 1)
        }
        return g
      })
      // personalb.map(b=>{
      //   const pos = operadores.findIndex(v=> v._id===b._id) 
      //   const post = operadores.findIndex(v=> v.turno===b.turno) 
      //   if (b.childen.findIndex((v)=> v._id===p._id)!==-1){
      //     const anterior = post===-1 || operadores[post]._id===b._id ? '' :  operadores[post].turno
      //     if (pos===-1){
      //       operadores.push({
      //         _id:b._id, nombres:b.nombres, apellidos:b.apellidos, 
      //         turno:b.turno, cedula:b.cedula, foto:b.foto, 
      //         suplente: anterior!==b.turno ? '' : 'Suplente'
      //       })
      //     }else{
      //       operadores[pos]={
      //         _id:b._id, nombres:b.nombres, apellidos:b.apellidos, 
      //         turno:b.turno, cedula:b.cedula, foto:b.foto, 
      //         suplente: anterior!==b.turno ? '' : 'Suplente'
      //       }
      //     }
      //   }else if (pos!==-1) {
      //     operadores.splice(pos, 1)
      //   }

      //   return b
      // })
      operadores.sort((a,b)=> a.turno > b.turno ? 1 : a.turno < b.turno ? -1 : 0)
      return {...p, key:p._id, operadores, titulo:<ItemPerfiles op={{...p, perfil:true, operadores}}/>}
    })

    
    // datos.Libres.map(l=>{
    //   l={...l, titulo:<Item_supervisor op={{...l, turno:l.turno}}/>}
    //   return
    // })
    // datos.Grupos=datos.Grupos.map(g=>{
    //   g.childen = g.childen.filter(o=>{ 
    //     if (o.turno===g.turno){
    //       return o
    //     }else{
    //       datos.Libres.push(o)
    //     }
    //   })
      
    //   return {...g, operadores:g.childen, titulo: <Item_titulo_supervisor sup={{...g,operadores:g.childen}}/>}
    // })
    
    this.setState({datos, personalb})
  }
  async componentDidMount(){
    this.Iniciar()
    // let resultados= await conexiones.Leer_C(['Data_personal'], 
    //   {['Data_personal']:{$and:[ {categoria:{$ne:0}} , {categoria:{$ne:5}}]},}
    //   );
    // console.log(resultados)
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        console.log('por este en turno')
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  render(){
    return this.state.Esperar.esperar ? 
      <Esperar {...this.state.Esperar}/> :
      <div>
        <Pagee  {...this.state}/>  
        <Dialogo {...this.state.Dialogo}/>
      </div>
    
  }
}