import React , {Component} from 'react';
// import { makeStyles, 
//           // createMuiTheme
//         } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import socketIOClient from "socket.io-client";

//Constantes
import {Valord} from '../../constantes';
let config = Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
if (config===null) config= Valord;

// const categoria_usuario= config.categoria_usuario;

// const useStyles = makeStyles((theme) => ({
    
//     resumenes: {
//       flexGrow: 1,
      
//     },
    
//     paperformulario:{
//       // padding: theme.spacing(1),
//       // paddingRight: theme.spacing(2),
      
//     },
//     avatar: {
//         width: theme.spacing(7),
//         height: theme.spacing(7),
//         marginRight:theme.spacing(1)
//     },
    
// }));

// const theme = createMuiTheme({
//     palette: {
//       error: {
//         main: '#b71c1c',
//       },
//       primary: {
//         main: '#1b5e20',
//       },
//       secondary: {
//         main: '#ffeb3b',
//       },
//     },
// });

export default class Hora extends Component {
  constructor(props) {
      super(props);

      this.state = {
        hora:new Date()
      }
  }
  componentDidMount(){
    
    const socket=socketIOClient("/")
    socket.on('Usuario_presentes',(dato)=>{
        this.setState({hora:new Date()})
    })
    this.setState({socket})
  }
  componentWillUnmount(){
      let { socket}=this.state
      if (socket !== undefined && socket!==null){
        socket.disconnect();
        socket=null
      }
  }
  render(){
    const {hora}=this.state;
    return(
      <div style={{flexGrow: 1,}} >
          <Typography variant="h6" noWrap style={{marginTop:10, marginRight:10}}>
            {`Sistema: ${moment.utc(hora).format('HH:mm')} Local: ${moment(hora).format('HH:mm')}`}
          </Typography>
      </div>
    )
  }
}  
// export default function Hora() {
//     const classes = useStyles();
//     const [hora, setHora] = React.useState(new Date());
    
//     // setInterval(()=>{
//     //   setHora(new Date())
//     // }, 10000);
//     return(
//         <div className={classes.resumenes} >
//            <Typography variant="h6" noWrap className={classes.title} style={{marginTop:10, marginRight:10}}>
//              {`Sistema: ${moment.utc(hora).format('HH:mm')} Local: ${moment(hora).format('HH:mm')}`}
//             </Typography>
//         </div>
//     )
// }