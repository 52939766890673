import React, {createRef, Component} from 'react';
// import React,  {useEffect, useState} from 'react'
import socketIOClient from "socket.io-client";
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './App.css';
import {const_procesos, Valord} from './constantes';
// import Menu from './components/menu';
// import Estructura from './components/chsmenu';
import Login from './components/login';
import Ocean from './components/ocean';
import {conexiones} from './procesos/servicios';
import Esperar from './components/esperar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

class App extends Component {
  constructor(props) {
      super(props);
      this.state = {
        props: this.props,
        esperar:true,
        geodata:null,
        hora: new Date()
      }

  }

  wrapper = createRef();
  Cerrar_sesion = async(user) =>{

    confirmAlert({
      title: 'Cerrar Sesion',
      message: `Desea cerrar sesion de "${user.username}"? `,
      buttons: [
        {
          label: 'SI',
          onClick: async() => {
            await localStorage.setItem(const_procesos.dir_user,null);
            this.setState({user:null})
            try {
              this.state.socket.emit('desconectar')
            }catch(error) {
    
            }
          
          }
        },
        {
          label: 'NO',
        }
      ]
    });
  }
  
  async componentDidMount(){
    
    this.setState({esperar:true,mensaje:'Cargando datos...'})
    fetch('https://ipapi.co/json')
      .then(response => response.json())
      .then(data => {
        this.setState({geodata:data})
      });
    let user = await JSON.parse(localStorage.getItem(const_procesos.dir_user));
    if (user!==null){
      let verificar = await conexiones.Verificar({username:user.username, password: user.password, token: user.token});
      // console.log(verificar)
      if (verificar.Respuesta==='Error'){
        user=null
      }
    }
    let socket=socketIOClient("/");
    if (user!==null){
      let respuesta= await conexiones.Leer_C(['Data_personal', 'User_api'], 
        {
          'Data_personal':{cedula:user.cedula===undefined ? 0 : user.cedula},
          'User_api':{_id:user._id},
        }
      );
      if (respuesta.Respuesta==='Ok'){
        user={...user,...respuesta.datos.User_api[0],...respuesta.datos.Data_personal[0]}
        // console.log('<<<<<<<  Respuesta',user)
        
        localStorage.setItem(const_procesos.dir_user,JSON.stringify(user));
      }
      
      socket.on("conectado", data => {
        // console.log('>>>>>>>>>>>>>>>>>>>>>Conectado',data,user)
        // console.log(this.state.props)
        // this.state.props.noticia('success')
        socket.emit('conectar',{[data.id]:{
          username:user.username, 
          geodata:this.state.geodata,
          token:user.token, categoria:user.categoria,
          nombres:user.nombres, apellidos: user.apellidos,
          foto:user.foto
        }})
      })
      socket.on("Actualizando_mes", async data => {
        let user = await JSON.parse(localStorage.getItem(const_procesos.dir_user));
        if ([0,'0',1,'1',2,'2'].indexOf(user.categoria)!==-1){
          this.state.props.noticia(`${data.mensaje}`,
            { 
              variant: ['Listo','Finalizo'].indexOf(data.dia)!==-1 ? 'success' : 'info',
              autoHideDuration: 10000,
              persist: data.dia==='Finalizo',
              action: this.state.props.action
            }
          )
        }  
      })
      
      // setInterval(()=>{
      //   this.setState({hora: new Date()})
      //  //  console.log('por qquii')
      //  }, 5000);
     
    }

    const config= Valord//JSON.parse(localStorage.getItem(const_procesos.dir_config));
    
    this.setState({user, config, esperar:false, socket})
  }
  
  componentWillUnmount(){
    let { socket}=this.state
    if (socket!==undefined && socket!==null){
        socket.disconnect();
        socket=null
    }
  }
  
  Refrescar =()=>{

  }

  render(){
    
    return this.state.esperar ? 
      <div ref={this.wrapper}>
        <Esperar esperar={this.state.esperar} mensaje={this.state.mensaje}/> 
      </div>:
      
      this.state.user ? (
          <Ocean  ref={this.wrapper} Cerrar_sesion = {this.Cerrar_sesion} socket={this.state.socket} Geodata={this.state.geodata}/>
      ): (
            <Login  ref={this.wrapper} Refrescar={this.Refrescar} socket={this.state.socket}/>
          );
        
  }
}

const useStyles = makeStyles(theme => ({
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    color:'#ffffff'
  },
}));

function MyApp() {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClickVariant = (mensaje, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(mensaje, variant);
  };

  // const handleDismiss = useCallback(() => {
  //   closeSnackbar(props.id);
  // }, [props.id, closeSnackbar]);
  
  const handleDismiss = key =>{
    closeSnackbar(key)
  }
  const action = key => (
    <IconButton  className={classes.expand} onClick={()=>handleDismiss(key)}>
        <CloseIcon />
    </IconButton>
  );

  return (
    <React.Fragment>
      
      <App noticia={handleClickVariant} action={action}/>
    </React.Fragment>
  );
}
export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={5} dense>
      <MyApp />
    </SnackbarProvider>
  );
}
