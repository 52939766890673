import React, {Component} from 'react';
import {conexiones} from '../../procesos/servicios';
import Esperar from '../esperar';
import Pagee from '../conexiones';
import moment from "moment";
import { ItemDias, ItemDiasGrupo} from '../conexiones/item';
import Dialogo from '../formulario/dialogo';
import Vercalendario from './ver_calendario';
import Calendario from './calendario'
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
// import FindInPageIcon from '@material-ui/icons/FindInPage';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import Asignar from './planificacion_dia';

//Asignar operadores a supervisores
export default class Planificacion extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{Grupos:[]},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          calendario:{
            fecha: new Date(),
            Cambio_fecha: this.Cambio_fecha,
          },
          Guardar: this.Guardar,
          
          Actualizar:this.Actualizar,
          Copiar: this.Copiar,
          boton_copiar:true,
          boton_modificar:false,
          Dialogo:{
            open:false,
            Titulo:'Mi cuenta 1',
            tam:'xl',
            Cerrar: this.Cerrar_dialogo,

          },
          Editar_dialogo:{
            tam:'xl',
            Cuerpo:(datos)=><Asignar {...datos} Guardar={this.Guardar_uno}/>,
            editar:true,
            copiar:this.Copiar_uno,
            eliminar: this.Eliminar,
          }

      }
  }
  
  Eliminar = async(valor)=>{
    
    confirmAlert({
      title: 'Eliminar',
      message: 'Desea eliminar plan del dia?',
      buttons: [
        {
          label: 'SI',
          onClick: async () => {
            this.setState({Esperar:{esperar:true,mensaje:'Guardando cambios...'}})
            await conexiones.Eliminar(valor,['Data_planificacion_dia'])
            this.Cerrar_dialogo()
            this.Iniciar()
          }
        },
        {
          label: 'NO',

        }
      ]
    });
  }
  Guardar_uno = async(enviar, iniciar=true)=>{
    this.setState({Esperar:{esperar:true,mensaje:'Guardando cambios...'}})
    let respuesta = await conexiones.Leer_C(['Data_planificacion_dia'],{
      'Data_planificacion_dia':{fecha:enviar.fecha}
    })
    delete enviar._id
    if (respuesta.datos['Data_planificacion_dia'].length!==0){
      enviar._id=respuesta.datos['Data_planificacion_dia'][0]._id
    }
    await conexiones.Guardar(enviar,'Data_planificacion_dia')
    if (iniciar){
      this.setState({Esperar:{esperar:false}})
      this.Cerrar_dialogo()
      this.Iniciar()
    }
  }

  Copiar_uno = (valores) =>{
    let {Editar_dialogo}=this.state
    Editar_dialogo.pegar=this.Pegar_uno

    this.setState({Editar_dialogo,
      valor_copiado:{
        fecha:valores.dia,
        dia:valores.dia_d,
        asistencia: valores.asistencia,
        asignados:valores.asignados, 
        operador_vs_perfil:valores.operador_vs_perfil,
        supervisor_vs_operador:valores.supervisor_vs_operador,
        turnos_asignados:valores.turnos_asignados,
      }
    })
    
  }
  Pegar_uno = (valores) =>{
    let {valor_copiado}=this.state
    valor_copiado.fecha=`${valores.dia.split('-')[2]}-${valores.dia.split('-')[1]}-${valores.dia.split('-')[0]}`;
    valor_copiado.dia=valores.dia_d;
    // delete Editar_dialogo.pegar
    
    this.Guardar_uno(valor_copiado)
    // this.setState({Editar_dialogo})
    
    
  }

  Guardar = async(datos)=>{
    this.setState({Esperar:{esperar:true,mensaje:'Copiando semana...'}})
    Promise.all( 
      await datos.Grupos.map(async(g,i)=>{
        let respuesta = await conexiones.Leer_C(['Data_planificacion_dia'],{
          'Data_planificacion_dia':{fecha:g.fecha}
        })
        delete g._id
        if (respuesta.datos['Data_planificacion_dia'].length!==0){
          g._id=respuesta.datos['Data_planificacion_dia'][0]._id
        }
        delete g.titulo
        const valor = await conexiones.Guardar({...g, dia:g.dia_d},'Data_planificacion_dia')

        return valor
      })
    ).then(values => {
      console.log('Termino');
      // this.setState({datos, Esperar:{esperar:false}})
      this.Iniciar()
    }, reason => {
      console.log('Error')
      this.setState({datos, Esperar:{esperar:false}})
    });
        // this.setState({datos, Esperar:{esperar:false}})
    // this.setState({Esperar:{esperar:true,mensaje:'Guardando cambios...'}})
    // Promise.all( 
    //   await datos.Grupos.map(async g =>{
    //     g.personal= g.childen.map( o =>{
    //       o={
    //         _id:o._id, nombres:o.nombres, apellidos:o.apellidos, categoria: o.categoria,
    //         foto:o.foto, turno: o.turno, cedula:o.cedula, 
    //       }
    //       return o
    //     })
    //     g.dia=g.dia_d
    //     const dat={_id:g._id, dia:g.dia, personal:g.personal, fecha:g.fecha}
    //     console.log(dat)
    //     const valor = await conexiones.Guardar(dat,'Data_planificacion')
    //     return valor
        
    //   })
    // ).then(values => {
    //   console.log('Termino');
    //   // this.setState({datos, Esperar:{esperar:false}})
    //   this.Iniciar()
    // }, reason => {
    //   console.log('Error')
    //   this.setState({datos, Esperar:{esperar:false}})
    // });
    // // this.setState({datos, Esperar:{esperar:false}})
    
  }
  Copiar= (datos)=>{
    
    let nuevo={Grupos:[]}
    let inicio;
    let fin;
    datos.Grupos.map(val=>{
      let fecha=val.fecha.split('-')
      fecha=new Date(`${fecha[0]}-${fecha[1]}-${fecha[2]}`)
      fecha.setDate(fecha.getDate()+8)
      if (val.dia_d==='Lunes') inicio=fecha
      if (val.dia_d==='Domingo') fin=fecha
      nuevo.Grupos.push({
        ...val,
        fecha:moment(fecha).format('YYYY-MM-DD'), key:fecha, dia_d:val.dia_d,dia:moment(fecha).format('DD-MM-YYYY')
      })
      nuevo.Grupos[nuevo.Grupos.length-1].titulo=<ItemDias dato={nuevo.Grupos[nuevo.Grupos.length-1]}/>
      return val
    })
    let {calendario}=this.state
    calendario.fechai=inicio
    calendario.fechaf=fin
    const semana=`del ${moment(inicio).format('DD-MM-YYYY')} al ${moment(fin).format('DD-MM-YYYY')}`
    this.setState({
      datos:nuevo,
      calendario,
      TituloG:
        <div style={{display:'flex'}}>
          <Typography variant="h5" component="h4" gutterBottom 
            style={{ color:'#ffffff', marginTop:10}}
          >
            {`Planificar horarios: (Guardar para copiar)`}
          </Typography>
          <Typography variant="h5" component="h4" gutterBottom 
            style={{ color:'#ffffff', marginTop:10}}
          >
            {`${semana}`}
          </Typography>
          <IconButton aria-label="atras" onClick={()=>this.Atras(inicio)}>
            <ArrowBackIcon  style={{ color: '#ffffff' }}/>
          </IconButton>
          <Calendario {...calendario}/>
          <IconButton aria-label="atras" onClick={()=>this.Siguiente(inicio)}>
            <ArrowForwardIcon  style={{ color: '#ffffff' }}/>
          </IconButton>
        </div>
    })
  }
  Iniciar= async() =>{
    let {fecha, fechai}=this.state.calendario
    if (fechai===undefined){
      fecha.setDate(fecha.getDate()+ 7)
      fecha.setHours(0, 0, 0)
    }else{
      fecha=fechai
    }
    this.Cambio_fecha(fecha)
  }

  Atras = (fecha)=>{
    fecha.setDate(fecha.getDate()-7)
    this.Cambio_fecha(fecha)
  }
  Siguiente = (fecha)=>{
    fecha.setDate(fecha.getDate()+7)
    this.Cambio_fecha(fecha)
  }
  //////Dialogo///////
  Editar = () =>{
    console.log('Abrir nuevo')
  }
  Ver = () =>{
    let {Dialogo, calendario}=this.state
    Dialogo.open=true
    Dialogo.Titulo=`Semana del ${moment(calendario.fechai).format('DD-MM-YYYY')} al ${moment(calendario.fechaf).format('DD-MM-YYYY')}`
    Dialogo.Cuerpo= <Vercalendario  calendario={calendario} datos={this.state.datos}/>
    
    this.setState({Dialogo})
  }
  Cerrar_dialogo = () =>{
    let {Dialogo}=this.state
    Dialogo.open=false
    this.setState({Dialogo})
  }
  Cambio_fecha = async(dato) =>{
    this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    let {calendario, boton_copiar, boton_modificar}=this.state
    var inicio =new Date(dato)
    inicio.setHours(0, 0, 0, 0)
    let dia = inicio.getDay()
    if (dia===0) dia=6
    else dia-=1
    inicio.setDate(inicio.getDate() - dia)
    var iniciob =new Date(inicio)
    iniciob.setHours(0, 0, 0, 0)
    iniciob.setDate(iniciob.getDate() - 1)
    var fin = new Date(inicio);
    fin.setHours(0, 0, 0, 0)
    fin.setDate(fin.getDate() + 6 )
    calendario.fechai=inicio
    calendario.fechaf=fin
    const lunes = inicio;
    var martes= new Date(inicio)
    martes.setHours(0, 0, 0)
    martes.setDate(martes.getDate() + 1)
    var miercoles= new Date(inicio)
    miercoles.setHours(0, 0, 0)
    miercoles.setDate(miercoles.getDate() + 2)
    var jueves= new Date(inicio)
    jueves.setHours(0, 0, 0)
    jueves.setDate(jueves.getDate() + 3)
    var viernes= new Date(inicio)
    viernes.setHours(0, 0, 0)
    viernes.setDate(viernes.getDate() + 4)
    var sabado= new Date(inicio)
    sabado.setHours(0, 0, 0)
    sabado.setDate(sabado.getDate() + 5)
    var finb= new Date(inicio)
    finb.setHours(0, 0, 0)
    finb.setDate(finb.getDate() + 7)

    let horario= [
      { dia_d:'Lunes', dia:moment(lunes).format('DD-MM-YYYY'), personal:[], childen:[], fecha:lunes ,
        titulo:<ItemDias key={lunes} dato={{dia_d:'Lunes', dia:moment(lunes).format('DD-MM-YYYY'), personal:[], childen:[], fecha:lunes }}/>,
        editar_titulo: `Editar Lunes ${moment(lunes).format('DD-MM-YYYY')}`
      },
      { dia_d:'Martes', dia:moment(martes).format('DD-MM-YYYY'), personal:[], childen:[],fecha:martes,
        titulo:<ItemDias key={martes} dato={{dia_d:'Martes', dia:moment(martes).format('DD-MM-YYYY'), personal:[], childen:[],fecha:martes}}/>,
        editar_titulo: `Editar Martes ${moment(martes).format('DD-MM-YYYY')}`
      },
      { dia_d:'Miercoles', dia:moment(miercoles).format('DD-MM-YYYY'), personal:[], childen:[],fecha:miercoles,
        titulo:<ItemDias key={miercoles} dato={{dia_d:'Miercoles', dia:moment(miercoles).format('DD-MM-YYYY'), personal:[], childen:[],fecha:miercoles}}/>,
        editar_titulo: `Editar Miercoles ${moment(miercoles).format('DD-MM-YYYY')}`
      },
      { dia_d:'Jueves', dia:moment(jueves).format('DD-MM-YYYY'), personal:[], childen:[], fecha:jueves,
      titulo:<ItemDias key={jueves} dato={{dia_d:'Jueves', dia:moment(jueves).format('DD-MM-YYYY'), personal:[], childen:[], fecha:jueves}}/>,
      editar_titulo: `Editar Jueves ${moment(jueves).format('DD-MM-YYYY')}`
      },
      { dia_d:'Viernes', dia:moment(viernes).format('DD-MM-YYYY'), personal:[], childen:[],fecha:viernes,
        titulo:<ItemDias key={viernes} dato={{dia_d:'Viernes', dia:moment(viernes).format('DD-MM-YYYY'), personal:[], childen:[],fecha:viernes}}/>,
        editar_titulo: `Editar Viernes ${moment(viernes).format('DD-MM-YYYY')}`
      },
      { dia_d:'Sabado', dia:moment(sabado).format('DD-MM-YYYY'), personal:[], childen:[],fecha:sabado,
        titulo:<ItemDias key={sabado} dato={{dia_d:'Sabado', dia:moment(sabado).format('DD-MM-YYYY'), personal:[], childen:[],fecha:sabado}}/>,
        editar_titulo: `Editar Sabado ${moment(sabado).format('DD-MM-YYYY')}`
      },
      { dia_d:'Domingo', dia:moment(fin).format('DD-MM-YYYY'), personal:[], childen:[],fecha:fin,
      titulo:<ItemDias key={fin} dato={{dia_d:'Domingo', dia:moment(fin).format('DD-MM-YYYY'), personal:[], childen:[],fecha:fin}}/>,
      editar_titulo: `Editar Domingo ${moment(fin).format('DD-MM-YYYY')}`
      },
    
    ]
    inicio.setHours(inicio.getHours()-inicio.getHours(),0,0)
    // fin.setHours(fin.getHours()+1,0,0)
    const semana=`del ${moment(inicio).format('DD-MM-YYYY')} al ${moment(fin).format('DD-MM-YYYY')}`
    
    // let personal=[];
    let respuesta= await conexiones.Leer_C(['Data_personal','Data_planificacion_dia'], 
      {
        'Data_personal':{$or:[ {categoria:3} , {categoria:4}]},
        'Data_planificacion_dia':{$and:[{fecha:{$gte:moment(inicio).format('YYYY-MM-DD')}}, {fecha:{$lte:moment(fin).format('YYYY-MM-DD')}}]}
      }
    );
    if (respuesta.Respuesta==='Ok'){
      // if (respuesta.datos['Data_planificacion_dia'].length===6){
      //   inicio.setDate(inicio.getDate() - 1)
      //   let nuevo = await conexiones.Leer_C(['Data_planificacion_dia'], 
      //     {
      //       'Data_planificacion_dia':{$and:[{fecha:{$gte:inicio}}, {fecha:{$lte:fin}}]}
      //     }
      //   );
      //   respuesta.datos['Data_planificacion_dia']=nuevo.datos['Data_planificacion_dia']
      //   inicio.setDate(inicio.getDate() + 1)
      // }
      if (respuesta.datos['Data_planificacion_dia'].length===0){
        boton_copiar=false;
      }else{
        boton_copiar=true;
      }
      if (inicio> new Date()){
        boton_modificar=false
      }else{
        boton_modificar=true
      }
      // personal= respuesta.datos['Data_personal']
      if (respuesta.datos['Data_planificacion_dia'].length!==0){
        respuesta.datos['Data_planificacion_dia'].map(h=>{
          const pos = horario.findIndex(v=> v.dia_d===h.dia)
          const childen = h.asistencia.map(p=>{
            return {...p, key:p._id, titulo:<ItemDiasGrupo op={p}/>}
          })
          let dato ={...horario[pos],...h, key:h.fecha, dia: `${h.fecha.split('-')[2]}-${h.fecha.split('-')[1]}-${h.fecha.split('-')[0]}`, childen} 
          dato={...dato, key:h.fecha, titulo:<ItemDias key={h.fecha} dato={dato}/>}
          if (pos!==-1){
            horario[pos]=dato
          }
          return h
        })
      }
    }
    
    // personal = personal.sort((a, b) => (a.categoria >b.categoria ? 1 : a.categoria < b.categoria ? -1 : 0))
    let datos = {
      Grupos:horario
    }

    this.setState({
        datos, Quitarl: false, Quitarg: false, TituloL:'Personal', 
        boton_copiar,
        boton_modificar,
        TituloG:
        <div style={{display:'flex'}}>
          <Typography variant="h5" component="h4" gutterBottom 
            style={{ color:'#ffffff', marginTop:10}}
          >
            {`Planificar horarios:`}
          </Typography>
          <Typography variant="h5" component="h4" gutterBottom 
            style={{ color:'#ffffff', marginTop:10}}
          >
            {`${semana}`}
          </Typography>
          <IconButton aria-label="atras" onClick={()=>this.Atras(inicio)}>
            <ArrowBackIcon  style={{ color: '#ffffff' }}/>
          </IconButton>
          <Calendario {...calendario}/>
          <IconButton aria-label="atras" onClick={()=>this.Siguiente(inicio)}>
            <ArrowForwardIcon  style={{ color: '#ffffff' }}/>
          </IconButton>
          {/* <IconButton aria-label="atras" onClick={this.Ver}>
            <FindInPageIcon  style={{ color: '#ffffff' }}/>
          </IconButton> */}
          
        </div>, 
        calendario, Esperar:{esperar:false}})
    // this.Actualizar(datos)

  }
  Actualizar = (datos) =>{
    
    datos.Grupos=datos.Grupos.map(g=>{
      const childen =  g.childen.map(p=>{
        return {...p, key:p._id, titulo:<ItemDiasGrupo op={{...p, dias:undefined}}/>}
      })
      let dato ={...g, childen} 
      dato={...dato, key:dato.dia, titulo:<ItemDias dato={dato}/>}
      return dato 
    })
    
    
    this.setState({datos})
  }
  async componentDidMount(){
    this.Iniciar()
    
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  render(){
    return this.state.Esperar.esperar ? 
      <Esperar {...this.state.Esperar}/> :
      <div>
        <Pagee  {...this.state}/> 
        <Dialogo {...this.state.Dialogo}/>
      </div>
    
  }
}