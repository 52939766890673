import React, {Component} from 'react';
import {conexiones} from '../../procesos/servicios';
import Esperar from '../esperar';
import Pagee from '../conexiones';
import {ItemTituloTurnos, ItemTurnos} from '../conexiones/item';


export default class Turnos extends Component {
  constructor(props) {
      super(props);

      this.state = {
          props: this.props,
          turnos:{},
          datos:{Libres:[],Grupos:[]},
          Esperar:{
            esperar:false,
            mensaje:'Cargando...',
          },
          
          Guardar: this.Guardar,
          
          Iniciar: this.Iniciar,
          Actualizar:this.Actualizar
      }
  }

  Guardar = async(datos)=>{
    this.setState({Esperar:{esperar:true,mensaje:'Guardando cambios...'}})
    let personal=[]
    datos.Grupos.map(g=>{
      g.childen.map(p=>{
        personal.push({_id:p._id, turno:g.key+'-'+g.pais})
        return p
      })
      return g
    })
    datos.Libres.map(p=>{
      personal.push({_id:p._id, turno:''})
      return p
    })
    Promise.all(
      await personal.map(async (val)=>{
        const valor = await conexiones.Guardar(val,'Data_personal')
        return valor
      })
    ).then(values => {
      
      this.setState({datos, Esperar:{esperar:false}})
    }, reason => {
      
      this.setState({datos, Esperar:{esperar:false}})
    });
    
  }

  Iniciar= async() =>{
    
    this.setState({Esperar:{esperar:true,mensaje:'Cargando datos...'}})
    let respuesta= await conexiones.Leer_C(['Data_personal', 'Data_turno'], 
      {
        'Data_personal':{$and:[ {categoria:{$ne:0}} , {categoria:{$ne:5}}]},
        'Data_turno':{},
      }
    );
    let personal=[]
    let turnos=[]
    if (respuesta.Respuesta==='Ok'){
        personal= respuesta.datos['Data_personal']
        turnos=respuesta.datos['Data_turno']
    }
    
    let Libres=[]
    let Grupos=[]
    // console.log(personal)
    personal.map(p=>{
      const pos= turnos.findIndex(v=> (v.turno+'-'+v.pais===p.turno)|| (v.descanso &&  p.turno===`Break (${v.turno.split('(')[1].split(')')[0]})-${v.pais}`))
      if (pos===-1) Libres.push({...p, 
        key:p._id, 
        titulo:<ItemTurnos p={p}/>
      })
      return p
    })
    let descansos = []
    turnos.map(t=>{
      const turno= t.turno
      // let grupo={key:turno, titulo:<Item_titulo_turnos p={{turno}} />, childen:[]}
      if (t.descanso){
        const key=`Break (${turno.split('(')[1].split(')')[0]})`
        // const corte=`Corte (${turno.split('(')[1].split(')')[0]})`
        const post= descansos.findIndex(v=> (v.key===key && v.pais===t.pais)|| v.key=== key+'-'+t.pais)
        if (post===-1){
          descansos.push({key, pais:t.pais, childen:[]})
          personal.map(p=>{
            if ((p.turno===key && p.pais===t.pais)|| p.turno=== key+'-'+t.pais) {
              descansos[descansos.length-1].childen.push({...p, key:p._id, titulo: <ItemTurnos p={p}/>})
            }
            return p
          })
          descansos[descansos.length-1].titulo= <ItemTituloTurnos p={{turno: key, pais:t.pais, cantidad:descansos[descansos.length-1].childen.length}} />
          
          // descansos.push({key:corte, pais:t.pais, childen:[]})
          // personal.map(p=>{
          //   if (p.turno===turno && p.pais===t.pais) {
          //     descansos[descansos.length-1].childen.push({...p, key:p._id, titulo: <Item_turnos p={p}/>})
          //   }
          //   return p
          // })
          // descansos[descansos.length-1].titulo= <Item_titulo_turnos p={{turno: corte, pais:t.pais, cantidad:descansos[descansos.length-1].childen.length}} />
        }
      }
      Grupos.push({key:turno, pais:t.pais, childen:[]})
      personal.map(p=>{
        if ((p.turno===turno && p.pais===t.pais) || p.turno=== turno+'-'+t.pais) {
          Grupos[Grupos.length-1].childen.push({...p, key:p._id, titulo: <ItemTurnos p={p}/>})
        }
        return p
      })
      Grupos[Grupos.length-1].titulo= <ItemTituloTurnos p={{turno, pais:t.pais, cantidad:Grupos[Grupos.length-1].childen.length}} />
      return t
    })
    Grupos=[...Grupos, ...descansos]
    const datos = {Grupos, Libres}
    this.setState({datos, TituloL:'Personal sin Turno', TituloG:'Turnos',Esperar:{esperar:false,mensaje:'Cargando datos...'}})
  }

  Actualizar = (datos) =>{
    datos.Grupos=datos.Grupos.map(g=>{
      
      if (g.key.indexOf('Break')!==-1){
        g.key='Break ('+ g.key.split('(')[1]
      }
      return {...g, titulo:<ItemTituloTurnos p={{turno:g.key, pais:g.pais, cantidad:g.childen.length}} />}
    })
    this.setState({datos})
  }
  async componentDidMount(){
    this.Iniciar()
    // let resultados= await conexiones.Leer_C(['Data_personal'], 
    //   {['Data_personal']:{$and:[ {categoria:{$ne:0}} , {categoria:{$ne:5}}]},}
    //   );
    // console.log(resultados)
  }

  static getDerivedStateFromProps(props, state) {

    if (props !== state.props) {
        
      return {
        props,
      };
    }
    // No state update necessary
    return null;
  }

  render(){
    return this.state.Esperar.esperar ? 
      <Esperar {...this.state.Esperar}/> :
      <div>
        <Pagee  {...this.state}/>  
      </div>
  }
}